import {SRLWrapper} from "simple-react-lightbox";
import {Media} from "reactstrap";
import configuration from "../../configuration/configuration";
import React from "react";

function PictureHidden({assetName, height = "10em", width = "10em", display}) {
    const url = configuration.PhotoBase + assetName
    if (assetName !== "") {
        return <SRLWrapper>
            <Media
                style={{display: display}}
                className="mb-1"
                left href={url} target="_blank">
                <Media
                    title="Voir plus"
                    object
                    style={{height: height, width: width}}
                    src={url} alt={assetName}/>
            </Media>
        </SRLWrapper>
    }

    return ""
}

export default PictureHidden;