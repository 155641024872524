import {inject, observer} from "mobx-react";
import React from "react";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Button from "reactstrap/lib/Button";


const AddFournisseurs = (inject("rootStore") (observer( class AddFournisseurs extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    get injected() {
        return this.props;
    }

    render() {
        const {nom, first_name, last_name, phone, account_number, siteweb, address, city, postal_code } = this.props
        return(
            <>
                <Form>
                    <FormGroup>
                        <Label for="nom">Nom de l'entreprise</Label>
                        <Input type="text" name="nom"
                               value={ nom || "" }
                               onChange={  e => this.injected.onCallbackChange(e) }
                               required={true}
                        />
                    </FormGroup>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="first_name">Prénom</Label>
                                <Input type="text"  name="first_name"
                                       value={ first_name || "" }
                                       onChange={  e => this.injected.onCallbackChange(e) }
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="last_name">Nom</Label>
                                <Input type="text"  name="last_name"
                                       value={ last_name ||"" }
                                       onChange={  e => this.injected.onCallbackChange(e) }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="phone">Numéro de téléphone</Label>
                                <Input type="text"  name="phone"
                                       value={ phone || "" }
                                       onChange={  e => this.injected.onCallbackChange(e) }
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="account_number">Numéro d'account</Label>
                                <Input type="text"  name="account_number"
                                       value={ account_number || "" }
                                       onChange={  e => this.injected.onCallbackChange(e) }
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label for="siteweb">Site internet</Label>
                        <Input type="text" name="siteweb"
                               value={ siteweb || "" }
                               onChange={  e => this.injected.onCallbackChange(e) }
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="address">Adresse</Label>
                        <Input type="text" name="address"
                               value={ address || "" }
                               onChange={  e => this.injected.onCallbackChange(e) }
                        />
                    </FormGroup>

                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="city">Ville</Label>
                                <Input type="text" name="city"
                                       value={ city || "" }
                                       onChange={  e => this.injected.onCallbackChange(e) }
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="postal_code">Code postal</Label>
                                <Input type="text" name="postal_code"
                                       value={ postal_code || "" }
                                       onChange={  e => this.injected.onCallbackChange(e) }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button color="primary" onClick={e=> this.injected.onCallbackSubmit(e)}>Valider</Button>
                </Form>
            </>
        )
    }
})));

export default AddFournisseurs;
