import React from "react";
import {inject, observer} from "mobx-react";
import {Card, CardBody, CardTitle} from "reactstrap";
import Button from "reactstrap/lib/Button";
import ModalAkitatek from "../Modal/Modal";
import AddCustomer from "../../../views/admin/Customers/AddCustomer/AddCustomer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import UserInformation from "../AdressCustomer";

const AutoCompleteSearch = inject("rootStore")(observer(class Search extends React.Component {

    user
    myStatus

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            search: "",
            myStatus: this.injected.myStatus ?? "",
            user: this.injected.user,
            status: this.injected.status,
            status2: this.injected.status2,
            addUser: false,
            adresse: this.injected.adresse,
            adresse_livraison: this.injected.adresse_livraison
        }

        this.injected.rootStore.userStore.searchUsersAction("")
    }


    async componentDidUpdate(prevProps) {
        if (
            this.injected.myStatus !== prevProps.myStatus ||
            this.injected.user !== prevProps.user ||
            this.injected.adresse_livraison !== prevProps.adresse_livraison ||
            this.injected.adresse !== prevProps.adresse
        ) {
            await this.setState({
                search: "",
                user: this.injected.user,
                myStatus: this.injected.myStatus,
                status: this.injected.status,
                status2: this.injected.status2,
                adresse: this.injected.adresse,
                adresse_livraison: this.injected.adresse_livraison
            })
        }
    }

    setSuggestions() {

        return (
            <Autocomplete
                value={this.state.user}
                options={this.injected.rootStore.userStore.searchUsers}
                disabled={
                    this.state.myStatus === this.state.status ||
                    this.state.myStatus === this.state.status2
                }
                getOptionLabel={(option) => {
                    if (option?.id) {
                        return "#" + option?.id + " " + option?.fullname
                    } else {
                        return ""
                    }
                }}
                style={{width: "auto"}}
                onChange={(event, newValue) => {
                    this.selection(newValue);
                }}
                selectOnFocus
                renderInput={(params) => <TextField {...params}
                                                    margin="normal"
                                                    label="Sélectionner un client"
                                                    variant="outlined"/>}
            />)
    }


    onClose = (childData) => {
        this.setState({
            addUser: false
        })

        if (childData) {
            this.injected.callbackUser(childData)
        }
    };


    async selection(user) {
        await this.setState({
            user: user ?? ""
        });

        if (user?.id) {
            await this.injected.rootStore.savedDatasStore.findUserById(user?.id);
        }
        this.injected.callbackUser(user)
    }


    render() {
        return (
            <div className="bg-transparent mt-1">
                <Card style={{width: "30em"}}>
                    <CardBody>
                        <CardTitle style={{fontWeight: "bold"}}>
                            {this.setSuggestions()}

                        </CardTitle>
                        <UserInformation user={this.state.user} adresse={this.state.adresse}
                                         adresseLivraison={this.state.adresse_livraison}/>
                    </CardBody>


                    {this.state.myStatus !== this.state.status && this.state.myStatus !== this.state.status2 && (
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div className={"mb-2"} style={{fontWeight: "bolder", textAlign: "Center"}}>OU</div>
                            <Button onClick={e => this.setState({addUser: true})} className={"mb-2 mr-auto ml-auto"}
                                    color={"success"} style={{width: "fit-content"}}>
                                Créer un nouveau client <i className="fa fa-user-plus" aria-hidden="true"></i>
                            </Button>
                        </div>
                    )
                    }
                </Card>

                <ModalAkitatek title={"Ajouter un client"} show={this.state.addUser}
                               children={<AddCustomer card={false} onCallback={this.onClose}></AddCustomer>}
                               onCallback={e => this.onClose()}/>

            </div>

        )
    }
}));

export default AutoCompleteSearch;
