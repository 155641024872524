import React from "react";
import {inject, observer} from "mobx-react";
import Button from "react-bootstrap/Button";
import {Link, withRouter} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";
import {Dropdown} from "react-bootstrap";
import moment from "moment";
import getRoutes from "../../../components/Akitatek/getRoutes";
import ActionsPDF from "../Invoices/ActionsPDF";


const ActiondatasRow = inject("rootStore")(observer(class ActiondatasRow extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        moment.locale('fr');
    }


    check(title) {
        if (title === "attente") {
            return (<span className="badge badge-pill badge-primary">{title}</span>)
        } else if (title === "archive") {
            return (<span className="badge badge-pill badge-success">archivé</span>)
        } else if (title === "valider") {
            return (<span className="badge badge-pill badge-success">validé</span>)
        } else if (title === "refuser") {
            return (<span className="badge badge-pill badge-danger">refusé</span>)
        } else if (title === "élaboration") {
            return (<span className="badge badge-pill badge-light">élaboration</span>)
        }
    }

    async onCopy(e) {
        e.preventDefault()
        await this.injected.rootStore.notificationStore.setQuestion("Voulez vous copier le devis " + this.injected.datas.titre + "?", "Copier #" + this.injected.datas.id, e => this.onChange())
    }

    async onCopyTo(e) {
        e.preventDefault()
        await this.injected.rootStore.notificationStore.setQuestion("Voulez vous transformer le devis " + this.injected.datas.titre + " en facture?", "Transformer #" + this.injected.datas.id, e => this.onChangeTo())

    }

    async onChangeTo() {
        let authResponse = await this.injected.rootStore.devisStore.copyDevisToFacture(this.injected.datas.id)
        if (authResponse?.status === "success") {
            this.injected.history.push(
                getRoutes("Gérer une facture", authResponse.datas.id, "élaboration"))
        }
    }

    async onChange() {
        let authResponse = await this.injected.rootStore.devisStore.copyDevis(this.injected.datas.id)
        if (authResponse?.status === "success") {
            getRoutes("Gérer un devis", authResponse.datas.id)
        }
    }

    render() {
        const {datas} = this.props

        return (
            <>
                <td className={"th-price"}>
                    <div style={{display: "flex"}}>
                        <Link to={{
                            pathname: getRoutes("Gérer un devis", datas?.id),
                            aboutProps: {
                                isCommandeValid: true,
                                datas: datas
                            }
                        }}>
                            <Button className="mr-2" color="primary" type="button"
                                    href={"#/datas/edit/" + datas?.id}
                                    id={"UncontrolledTooltipExamplePencil" + datas?.id}>
                                <i className="fa fa-pencil" aria-hidden="true"/>
                                <UncontrolledTooltip placement="top"
                                                     target={"UncontrolledTooltipExamplePencil" + datas?.id}>
                                    Editer
                                </UncontrolledTooltip>
                            </Button>
                        </Link>

                        <ActionsPDF data={datas} api={this.injected.rootStore.devisStore} filename={"devis" + datas?.id + "_" + datas?.created_at + ".pdf"}/>
                        
                        <Dropdown className={"ml-2"}>
                            <Dropdown.Toggle variant="warning"
                                             id={"UncontrolledTooltipExampleCopy" + datas?.id}>
                                <i className="fa fa-copy" aria-hidden="true"/>
                                <UncontrolledTooltip placement="top"
                                                     target={"UncontrolledTooltipExampleCopy" + datas?.id}>
                                    Copier / Transformer
                                </UncontrolledTooltip>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={e => this.onCopy(e)}>Copier le devis</Dropdown.Item>
                                <Dropdown.Item onClick={e => this.onCopyTo(e)}>Transformer en facture</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </td>
            </>
        );
    }

}));

export default withRouter(ActiondatasRow);
