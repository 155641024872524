import {inject, observer} from "mobx-react";
import React from "react";
import {Container} from "react-bootstrap";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";
import StatsCmdClient from "./StatsCmdClients";


const CommandeClient = (inject("rootStore")(observer(class CommandeClient extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Commandes Client", "Gestion des commandes client", "commande.jpg", this.injected.rootStore.commandeClientStore)
        this.injected.rootStore.commandeClientStore.getStats();
    }

    componentDidMount() {
        this.injected.rootStore.commandeClientStore.getStats();

    }

    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <StatsCmdClient stats={this.injected.rootStore.commandeClientStore.stats}/>
                    <GenericTableAffichage api={this.injected.rootStore.commandeClientStore}
                                           user={false}
                                           status={true}
                                           filters={this.injected.rootStore.commandeClientStore.defaultFilter}
                                           statusHeader={true}
                                           icon={<i className="fa fa-user-circle mr-2" aria-hidden="true"/>}
                                           name={"Mes commandes clients"}
                                           type="cmdClient"/>
                </Container>
            </>
        )
    }
})));

export default CommandeClient;
