import React from "react";


function StatsFournisseurs({stats}) {
    return <div style={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "start"
    }}>

        <div className="callout callout-info b-t-1 b-r-1 b-b-1 mr-2 ml-2">
            <small className="text-muted">Nombre de commande passés : </small><br/>
            <strong className="h4">{stats['nb']}</strong>
        </div>

        <div className="callout callout-danger b-t-1 b-r-1 b-b-1 mr-2 ml-2">
            <small className="text-muted">Investissements passés : </small> <br/>
            <strong className="h4">{stats['sum']} €</strong>
        </div>

        <div className="callout callout-warning b-t-1 b-r-1 b-b-1 mr-2 ml-2">
            <small className="text-muted">Commande en cours : </small> <br/>
            <strong className="h4">{stats['encours']}</strong>
        </div>

        <div className="callout callout-primary b-t-1 b-r-1 b-b-1 mr-2 ml-2">
            <small className="text-muted">Investissements en cours : </small> <br/>
            <strong className="h4">{stats['sumencours']} €</strong>
        </div>

        <div className="callout callout-success b-t-1 b-r-1 b-b-1 mr-2 ml-2">
            <small className="text-muted">Investissement encore en stock: </small> <br/>
            <strong className="h4">{stats['lot']} €</strong>
        </div>
    </div>
}

export default StatsFournisseurs;
