import React, {useState} from "react";
import Card from "reactstrap/lib/Card";
import {CardBody} from "reactstrap";
import Button from "react-bootstrap/Button";
import CreateMarque from "../../Annuaire/CreateMarque";
import ModalAkitatek from "../../../../components/Akitatek/Modal/Modal";
import CreateProduct from "../../Annuaire/CreateProduct";
import AddOrEditPrestation from "../../Annuaire/Prestation/AddOrEditPrestation";
import ErrorBoundary from "../../../../components/Akitatek/Error/ErrorBounday";


function PickAction({type = "marque", reload, label, data}) {

    const [show, setShow] = useState(false);

    function valid() {
        if (type === "prestationRien") {
            reload("valid", "")
        } else {
            setShow(true)
        }
    }

    function onClose() {
        if (type === "marque") {
           reload("clean")
        }

        if (type === "produit") {
            reload("marque", data)
        }

        if (type === "prestation") {
            reload("product", data)
        }

        if (type === "prestationRien") {
            reload("valid", "")
        }

        setShow(false)
    }

    return <ErrorBoundary>
        <Card className={"mr-4"}
              style={{flex: "0 0 10%"}}>
            <CardBody style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Button
                    onClick={e => valid()}
                    variant="warning" type="button" className="mr-1">
                    {type === "prestationRien" ? (
                        "Autres : Ne pas ajouter de prestation"
                    ) :
                        ("Ajouter une " + type)
                    }
                    <i className="fa fa-plus-circle"/>
                </Button>
            </CardBody>

            {type === "marque" && (
                <ModalAkitatek title={"Créer une marque"} show={show}
                               children={<CreateMarque card={false} onCallback={onClose}
                                                       update={true}></CreateMarque>} onCallback={onClose}/>
            )}

            {type === "produit" && (
                <ModalAkitatek title={"Ajouter un produit à une marque"} show={show}
                               children={<CreateProduct marqueId={data?.id} card={false}
                                                        onCallback={onClose}></CreateProduct>}
                               onCallback={onClose}/>
            )}

            {type === "prestation" && (
                <ModalAkitatek title={"Ajouter une prestation"} show={show}
                               children={<AddOrEditPrestation onCallback={onClose} data={data}
                                                              label={label}></AddOrEditPrestation>}
                               onCallback={onClose}/>
            )}


        </Card>
    </ErrorBoundary>
}

export default PickAction;
