import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import i18n from "i18next";
import {inject, observer} from "mobx-react";
import Image from '../../components/core-ui/assets/img/technology-2818664_1280.jpg'; // Import using relative path

const Login = inject("rootStore")(observer(class Login extends Component {


  get injected() {
    return this.props;
  }

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loading: false,
    };

    this.onSubmit.bind(this)
  }

  async onSubmit(e) {
    e.preventDefault();
    this.setState({
      loading: true
    })
    const { username, password } = this.state;

    if (username && password) {
      await this.injected.rootStore.authStore.login(username, password);

      if( this.injected.rootStore.authStore.isLogged) {
        this.injected.history.push("/")
      }
    }
    this.setState({
      loading: false
    })
  }

  render() {
    return (
      <div className="app flex-row align-items-center" style={{ backgroundImage: `url(${Image})`, backgroundSize: "cover"}}>
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form
                        role="form"
                        id="form"
                        onSubmit={ e => this.onSubmit(e) }>
                      <h1>Login</h1>
                      <p className="text-muted">{i18n.t('page.auth.welcome')}</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Email"
                            type="email"
                            id='email'
                            value={ this.state.username ||'' }
                            onChange={  e => this.setState({username: e.target.value})}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder={i18n.t('page.auth.password')}
                            type="password"
                            id='password'
                            value={ this.state.password ||'' }
                            onChange={ e  => this.setState({password: e.target.value})}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button
                              className="my-4"
                              color="primary"
                              disabled={this.state.loading}
                              type="submit">
                            {i18n.t('page.auth.login')}
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0">{i18n.t('page.auth.forgotPassword')}</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}));

export default Login;
