import {inject, observer} from "mobx-react";
import React from "react";
import {Container} from "react-bootstrap";
import {Card, CardBody, Col, Input, Progress, Row} from "reactstrap";
import styles from "./Stats.module.css"
import * as moment from 'moment'
import {Line} from 'react-chartjs-2';
import 'moment/locale/fr';
import Button from "reactstrap/lib/Button";
import PieStats from "./Pie";


const Stats = (inject("rootStore")(observer(class Stats extends React.Component {


    statsStore = this.injected.rootStore.statsStore;

    get injected() {
        return this.props;
    }


    async componentDidMount() {
        await this.statsStore.getStats( this.statsStore.actual.moisNumber,  this.statsStore.actual.year);
    }


    constructor(props, context) {
        super(props, context);

        moment.locale('fr');

        this.injected.rootStore.savedDatasStore.setHeader("Statistique de l'activité", "", "stats.jpg")
    }

    async sendRequest(month, year) {
        this.statsStore.actual.year = year;
        this.statsStore.actual.moisNumber = month;
        await this.statsStore.getStats(month, year);
    }

    async downloadZip(e, month, year) {
        e.preventDefault()
        const file = await this.injected.rootStore.statsStore.downloadZip(month, year);

        if (file) {
            const fileURL = URL.createObjectURL(file);
            var downloadLink = document.createElement("a");
            downloadLink.href = fileURL;
            downloadLink.download = "invoice" + month + "-" + year + "." + "zip"

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }


    async getToPrecedent(e) {
        e.preventDefault();
        this.statsStore.actual.year = this.statsStore.precedent.moisMoment.format("YYYY");
        this.statsStore.actual.moisNumber = this.statsStore.precedent.moisMoment.format("M");
        await this.statsStore.getStats(this.statsStore.precedent.moisMoment.format("MM"), this.statsStore.precedent.moisMoment.format("YYYY"));
    }

    async getToNext(e) {
        e.preventDefault();
        this.statsStore.actual.year = this.statsStore.precedent.moisMoment.format("YYYY");
        this.statsStore.actual.moisNumber = this.statsStore.precedent.moisMoment.format("M");
        await this.statsStore.getStats(this.statsStore.suivant.moisMoment.format("MM"), this.statsStore.suivant.moisMoment.format("YYYY"));
    }

    setOptions() {
        let m = moment();
        let options = [];
        for (let i = 0; i < 12; i++) {
            let month = m.months(i).format('MMMM');
            options.push(
                <option
                    className={styles.customInput}
                    value={i + 1}>
                    {month.charAt(0).toUpperCase() + month.slice(1)}
                </option>
            );
        }

        return options;
    }

    setChart() {
    }

    render() {
        return (
            <Container fluid>

                <div className="animated fadeIn">
                    <Row>

                        <Col xs="12" sm="4" lg="4">
                            <Card className="text-white bg-success">
                                <Row>
                                    <Col className={styles.imageCard} sm={4}>
                                        <i className="fa fa-chevron-left fa-5x" style={{cursor: "pointer"}}
                                           onClick={e => this.getToPrecedent(e)}/>
                                    </Col>
                                    <Col sm={8}>
                                        <CardBody className="pb-0" style={{textAlign: "center", height: "15em"}}>
                                            <div className="text-value  mb-4"><span
                                                style={{textTransform: "capitalize"}}>    {this.injected.rootStore.statsStore.precedent.moisString} </span>
                                            </div>
                                            <div>
                                                {this.statsStore.precedent.benefice} € de bénéfices
                                            </div>


                                            <Progress multi style={{height: "10px", margin: "1em"}}>
                                                <Progress bar color="warning"
                                                          value={this.statsStore.precedent.pourcent?.ca}/>
                                                <Progress bar color="danger"
                                                          value={this.statsStore.precedent.pourcent?.expense}/>
                                            </Progress>
                                            <div>{this.statsStore.precedent.cattc} € de CA TTC</div>

                                            <div>  {this.statsStore.precedent.ca} € de CA</div>
                                        </CardBody>
                                    </Col>

                                </Row>

                            </Card>
                        </Col>

                        <Col xs="12" sm="4" lg="4">
                            <Card className="text-white bg-success">
                                <CardBody className="pb-0" style={{textAlign: "center", height: "15em"}}>
                                    <div className="text-value  mb-4">
                                        <Row>
                                            <Col>
                                                <Input style={{
                                                    fontSize: "1.3125rem",
                                                    fontWeight: "600",
                                                    backgroundColor: "transparent",
                                                    color: "white"
                                                }}
                                                       type="select" name="select"
                                                       value={this.statsStore.actual.moisNumber || 0}
                                                       onChange={e => this.sendRequest(e.target.value, this.statsStore.actual.year)}
                                                >
                                                    {this.setOptions()}
                                                </Input>
                                            </Col>
                                            <Col>
                                                <Input style={{
                                                    fontSize: "1.3125rem",
                                                    fontWeight: "600",
                                                    backgroundColor: "transparent",
                                                    color: "white"
                                                }}
                                                       type="number" name="year"
                                                       value={this.statsStore.actual.year || 0}
                                                       onChange={e => this.sendRequest(this.statsStore.actual.moisNumber, e.target.value)}
                                                >
                                                </Input>
                                            </Col>
                                        </Row>


                                        {this.statsStore.actual.moisString}   </div>
                                    <div>
                                        {this.statsStore.actual.benefice} € de bénéfices
                                    </div>
                                    <Progress multi style={{height: "10px", margin: "1em"}}>
                                        <Progress bar color="warning" value={this.statsStore.actual.pourcent?.ca}/>
                                        <Progress bar color="danger" value={this.statsStore.actual.pourcent?.expense}/>
                                    </Progress>
                                    <div>{this.statsStore.actual.cattc} € de CA TTC</div>

                                    <div>   {this.statsStore.actual.ca} € de CA HT

                                    </div>


                                    <div className={"mt-2"}>
                                        Services : {this.statsStore.caDetails.mo} € /
                                        Marchandises: {this.statsStore.caDetails.othersAll} €
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs="12" sm="4" lg="4">
                            <Card className="text-white bg-warning">
                                <Row>
                                    <Col sm={8}>
                                        <CardBody className="pb-0" style={{textAlign: "center", height: "15em"}}>
                                            <div className="text-value mb-4"><span
                                                style={{textTransform: "capitalize"}}>  {this.statsStore.suivant.moisString}  </span>
                                            </div>
                                            <div>
                                                {this.statsStore.suivant.benefice} € de bénéfices
                                            </div>

                                            <Progress multi style={{height: "10px", margin: "1em"}}>
                                                <Progress bar color="success"
                                                          value={this.statsStore.suivant.pourcent?.ca}/>
                                                <Progress bar color="danger"
                                                          value={this.statsStore.suivant.pourcent?.expense}/>
                                            </Progress>
                                            <div>{this.statsStore.suivant.cattc} € de CA TTC</div>

                                            <div>{this.statsStore.suivant.ca} € de CA</div>
                                        </CardBody>


                                    </Col>
                                    <Col className={styles.imageCard} sm={4}>
                                        <i className="fa fa-chevron-right fa-5x" style={{cursor: "pointer"}}
                                           onClick={e => this.getToNext(e)}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row>

                        <Col xs="12" sm="4" lg="4">
                            <Card className={["text-white bg-primary", styles.invoiceIcon]}>
                                <CardBody className="pb-0" style={{height: "15em"}}>
                                    <div className="text-value  mb-4">{this.statsStore.facture.mois} facture(s)</div>
                                    <div> + {this.statsStore.actual.fr} facture(s) réglé(s)</div>

                                    <div>
                                        {this.statsStore.facture.difference} % par rapport au mois dernier
                                    </div>
                                    <div>
                                        + {this.statsStore.facture.client_unique} client(s) uniques(s)
                                    </div>

                                    <p><Button className={"mt-2"}
                                               onClick={e => this.downloadZip(e, this.statsStore.actual.moisNumber, this.statsStore.actual.year)}> Télécharger</Button>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs="12" sm="4" lg="4">
                            <Card className={["text-white bg-warning", styles.clientIcon]}>
                                <CardBody className="pb-0" style={{height: "14em"}}>
                                    <div className="text-value  mb-4">{this.statsStore.client.actual} Nouveau(x)
                                        client(s)
                                    </div>
                                    <div>
                                        {this.statsStore.client.difference} % par rapport
                                        à {this.injected.rootStore.statsStore.precedent.moisString}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs="12" sm="4" lg="4">
                            <Card className={["text-white bg-danger", styles.expenseIcon]}>
                                <CardBody className="pb-0" style={{height: "14em"}}>
                                    <div className="text-value mb-0">
                                        <p>{this.statsStore.actual.expense} € Dépenses En cours TTC</p>
                                    </div>
                                    <div>- {this.statsStore.actual.tva} € TVA Collectée</div>

                                    <div>+ {this.statsStore.actual.tva_deductible} € TVA Déductible des commandes</div>
                                    <div>TVA à payer = {this.statsStore.actual.tva_credit} €</div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {this.setChart()}
                </div>

                <div style={{backgroundColor: "white"}}>
                    <article style={{height: "25em", width: "100%"}}>

                        <Line
                            data={{
                                labels: this.statsStore.chart.labels.slice(),
                                datasets: [{
                                    label: ["Chiffre d'affaire"],
                                    data: this.statsStore.chart.contents.slice(),
                                    backgroundColor: 'rgba(0, 255, 0, 0.3)',
                                    borderColor: 'rgba(61, 157, 1, 1)',
                                    borderWidth: 2
                                },
                                    {
                                        label: ['Dépenses'],
                                        data: this.statsStore.chart.expenses.slice(),
                                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                        borderColor: 'rgba(255, 99, 132, 1)',
                                        borderWidth: 2
                                    }
                                ]
                            }}
                            options={{
                                maintainAspectRatio: false,
                                title: {
                                    display: true,
                                    text: 'CA par mois TTC',
                                    fontSize: 20
                                },
                                legend: {
                                    display: true,
                                    position: 'top'
                                },
                                scales: {
                                    xAxes: [{
                                        barThickness: 15,
                                    }]
                                }
                            }}
                        />
                    </article>
                </div>
                {this.statsStore.caDetails.total > 0 && (
                    <div style={{backgroundColor: "white", display: "flex"}} className={"mt-4 mb-2"}>
                        <PieStats
                            label={"CA par type HT"}
                            stats={[this.statsStore.caDetails.mo, this.statsStore.caDetails.fdp, this.statsStore.caDetails.notMo]}
                            labels={['Services', 'FDP', 'Marchandises']}/>

                        <PieStats
                            label={"CA par encaissement HT"}
                            stats={[this.statsStore.caDetails.paypal, this.statsStore.caDetails.espece, this.statsStore.caDetails.virement, this.statsStore.caDetails.cb]}
                            labels={['Paypal', 'Espèce', 'Virement', 'CB']}/>
                    </div>
                )}

                {this.statsStore.caDetails.total > 0 && (
                    <div style={{backgroundColor: "white", display: "flex"}} className={"mt-4 mb-2"}>
                        <PieStats
                            label={"Factures par encaissement (" + this.statsStore.caDetails.nb + ")"}
                            stats={[this.statsStore.caDetails.paypalNb, this.statsStore.caDetails.especeNb, this.statsStore.caDetails.virementNb, this.statsStore.caDetails.cbNb]}
                            labels={['Paypal', 'Espèce', 'Virement', 'CB']}/>
                        <PieStats
                            label={"Type Prise en charge (" + this.statsStore.caDetails.pec + ")"}
                            stats={[this.statsStore.caDetails.pecInternet, this.statsStore.caDetails.pecMagasin]}
                            labels={['Internet', 'Magasin']}/>

                    </div>
                )}


                {this.statsStore.caDetails.total > 0 && (
                    <div style={{backgroundColor: "white", display: "flex"}} className={"mt-4 mb-2"}>
                        <PieStats
                            label={"Produits par Prise en charge (" + this.statsStore.caDetails.produitsNb + ")"}
                            stats={this.statsStore.caDetails.produitsValues}
                            labels={this.statsStore.caDetails.produitsLabels}/>
                        <PieStats
                            label={"Marques par Prise en charge"}
                            stats={this.statsStore.caDetails.marquesValues}
                            labels={this.statsStore.caDetails.marquesLabels}/>
                    </div>
                )}

                {this.statsStore.actual.tva > 0 && (
                    <div style={{backgroundColor: "white", display: "flex"}} className={"mt-4 mb-2"}>
                        <PieStats
                            label={"TVA"}
                            stats={[this.statsStore.actual.tva, this.statsStore.actual.tva_deductible]}
                            labels={["TVA collectée", "TVA déductible"]}/>
                    </div>
                )}

            </Container>)
    }
})));

export default Stats;
