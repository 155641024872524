import TextField from "@material-ui/core/TextField";
import React from "react";
import {Icon} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import {inject, observer} from "mobx-react";

const ToDoListInput = (inject("rootStore")(observer(class ToDoListInput  extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            content: ""
        }
    }

    get injected() {
        return this.props;
    }

    async onSubmit(e) {
        e.preventDefault()
        let authResponse = await this.injected.rootStore.todoStore.create(this.state)
        if (authResponse.status === "success") {
            this.setState({
                content: ""
            })
        }
    }

    enter(e) {
        if (e.key === 'Tab' || e.key === 'Enter') {
            e.preventDefault()
            this.onSubmit(e)
        }
    }

    render() {
        return (
            <>
            <div style={{ display: "flex", alignItems: "flex-end"}}>
            <Icon className="mr-2" sx={{ color: green[500] }}>add_circle</Icon>
            <TextField fullWidth id="standard-basic" label="Ajouter une tâche" variant="standard"
                       name={"content"}
                       onKeyPress={e => this.enter(e)}
                       onChange={e => this.setState({content:  e.target.value})}
                       value={this.state.content}/>
                <div className="font-icon-wrapper" onClick={e=> this.onSubmit(e)}>
                    <IconButton color="secondary" aria-label="valide" >
                        <i className="fa fa-check-circle" aria-hidden="true"/>
                </IconButton>
                </div>
                </div>
            </>
        );
    }
})));

export default ToDoListInput