import React from 'react';
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import i18n from "i18next";
import {ContentState, EditorState} from 'draft-js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {withRouter} from "react-router-dom";
import Tabs from "../../../components/Akitatek/Tabs/Tabs";
import Products from "./Product/Produtcs";
import styles from "./Prestation/Prestation.module.css";
import htmlToDraft from 'html-to-draftjs';
import ChoiceImageInput from "../../../components/Akitatek/Form/ChoiceImageInput";
import EditorWord from "../../../components/Akitatek/EditorWord";
import SetCategories from "./Categories";
import GenericTableAffichageWithDatas from "../../../components/Akitatek/Table/GenericTableAffichageWithDatas";

var slugify = require('slugify')

const CreateMarque = (inject("rootStore")(observer(class CreateMarque extends React.Component {
    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            marque: '',
            title: '',
            metadescription: '',
            file: "",
            img: null,
            tri: "",
            inputValue: '',
            value: [],
            card: this.injected.card ?? true,
            description: EditorState.createEmpty(),
            url: '',
            activeTab: 0,
            isVisible: 0,
            assetName: "",
            asset_id: ""
        };

        if (this.state.card) {
            this.injected.rootStore.savedDatasStore.setHeader("Gestion des marques", "Gérer vos marques", "annuaire_marque.jpg")
        }

        this.toggleDanger = this.toggleDanger.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const name = e.target.name
        const type = e.target.type
        const value = type === "radio" ? parseInt(e.target.value) : e.target.value
        this.setState({[name]: value});
    }

    UNSAFE_componentWillMount() {
        if (this.injected.location.aboutProps?.data) {
            this.setInitialData(this.injected.location.aboutProps?.data)
            this.injected.rootStore.annuaireStore.productStore.marqueId = this.injected.location.aboutProps?.data?.id
            this.injected.rootStore.annuaireStore.productStore.marque = "De " + this.injected.location.aboutProps?.data?.nom
            this.injected.rootStore.annuaireStore.productStore.getProducts();
        }
    }

    async toggleDanger() {
        await this.injected.rootStore.notificationStore.setWarning("Marque #" + this.state.id, event => this.onDelete(this.state.id))
    }

    async onDelete(deleteId) {
        let status = await this.injected.rootStore.annuaireStore.marqueStore.deleteMarque(deleteId);

        if (status === "success") {
            await this.getBack()
        }
    }

    setInitialData(marque) {
        let editorState;
        if (marque?.description != null) {
            let blocksFromHTML = htmlToDraft(marque.description);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

            editorState = EditorState.createWithContent(contentState);
        } else {
            editorState = EditorState.createEmpty()
        }

        this.setState({
            marque: marque?.nom ?? "",
            img: marque?.photo ?? "",
            id: marque?.id ?? "",
            danger: false,
            description: editorState,
            url: marque?.url ?? "",
            assetName: marque?.asset?.filename ?? "",
            asset_id: marque?.asset?.id ?? "",
            title: marque?.title ?? "",
            metadescription: marque?.metadescription ?? "",
            isVisible: marque?.isVisible ?? ""
        });
    }

    async componentDidMount() {
        this.id = this.props.id || (this.props.match && parseInt(this.props.match.params.id, 10))

        if (this.id && this.state.card) {
            let marque = await this.injected.rootStore.annuaireStore.marqueStore.findMarqueById(this.id);

            if (marque) {
                this.setInitialData(marque)
                this.injected.rootStore.annuaireStore.productStore.marqueId = marque?.id
                this.injected.rootStore.annuaireStore.productStore.marque = "De " + marque?.nom
                this.injected.rootStore.annuaireStore.productStore.getProducts();
            }
        }
    }


    async getBack() {
        await this.injected.rootStore.annuaireStore.marqueStore.getMarques();
        this.injected.history.push('/marques')
    }

    async onSubmit(e) {
        e.preventDefault();
        let authResponse;
        const datas = Object.assign({}, this.state);

        if (this.state.id) {
            authResponse = await this.injected.rootStore.annuaireStore.marqueStore.updateMarque(datas);
        } else {
            authResponse = await this.injected.rootStore.annuaireStore.marqueStore.createMarque(datas);
        }

        if (authResponse?.status === "success") {
            if (this.state.card) {
                this.setInitialData(authResponse?.datas)
            } else {
                this.injected.onCallback()
            }
        }
    }

    changeMarque(e) {
        if (!this.state.id) {
            this.setState({marque: e.target.value, url: slugify(e.target.value)})
        } else {
            this.setState({marque: e.target.value})
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            description:
            editorState,
        });
    };


    onPickAssetFilename = (action) => {
        if (action === "add") {
            this.setState({
                assetName: this.injected.rootStore.imageStore.pickAsset?.filename,
                asset_id: this.injected.rootStore.imageStore.pickAsset?.id,
            })
        } else {
            this.setState({
                assetName: "",
                asset_id: "",
            })
        }
    }

    setForm() {
        return (
            <Form style={{width: "100%"}}
                  role="form"
                  id="myFormUpdate"
                  onSubmit={e => this.onSubmit(e)}

            >

                <div className="pl-lg-4">
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                >
                                    {i18n.t('page.admin.marque.title')}
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    value={this.state.marque || ''}
                                    onChange={e => this.changeMarque(e)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <ChoiceImageInput assetName={this.state.assetName}
                                          onPickAssetFilename={this.onPickAssetFilename}/>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                >
                                    URL
                                </label>

                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    name="url"
                                    onChange={this.onChange}
                                    value={this.state.url || ''}
                                />
                            </FormGroup>
                        </Col>
                    </Row>


                    <EditorWord editorState={this.state.description} onEditorStateChange={this.onEditorStateChange}/>


                    <Row>
                        <Col style={{display: "flex", justifyContent: "center"}}>
                            <FormGroup className={"mr-2"}>
                                <label>
                                    <input type="radio"
                                           name="isVisible"
                                           onChange={this.onChange}
                                           value={1}
                                           checked={this.state.isVisible === 1}
                                    />
                                    <span className="badge badge-pill badge-success">PUBLIER</span>
                                </label>
                            </FormGroup>
                            <FormGroup>
                                <label>
                                    <input type="radio"
                                           name="isVisible"
                                           onChange={this.onChange}
                                           value={0}
                                           checked={this.state.isVisible === 0}
                                    />
                                    <span className="badge badge-pill badge-warning">NON PUBLIER</span>
                                </label>
                            </FormGroup>
                        </Col>
                    </Row>

                    {!this.state.id && (
                        <div className="pl-lg-4 mt-3" style={{textAlign: "center"}}>
                            <Button color="success" className="mr-1" type="submit">
                                {i18n.t('button.submit')}
                            </Button>
                        </div>
                    )}
                </div>
            </Form>
        )
    }

    setProduct() {
        return (<Products marqueId={this.state.id}/>)
    }

    onBack(e) {
        e.preventDefault()
        this.injected.history.goBack()
    }

    //Callback
    callbackOnChange = (name, value) => {
        this.setState({[name]: value});
    }

    setPec() {
        return <GenericTableAffichageWithDatas
            api={this.injected.rootStore.prisenchargeStore}
            filters={{
                limit: "15",
                annuaire_marque_id: this.state.id,
                name: "updated_at",
                order: false,
            }}
            key={"pec"}
            type="pec"/>
    }


    render() {
        return (
            <>
                {this.state.card && (
                    <Container className="mt-2" fluid>
                        <Row className="mb-3">
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="bg-transparent">
                                        {this.state.id ? (
                                                <h3 className="mb-0"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        color: "#20a8d8"
                                                    }}>
                                                    <div>
                                                        <i onClick={e => this.onBack(e)} style={{cursor: "pointer"}}
                                                           className="fa fa-arrow-left mr-2" aria-hidden="true"/>
                                                        Marque #{this.state.id}
                                                    </div>
                                                    <Row>
                                                        <Col>
                                                            <Button color="success" className="mr-1" type="submit"
                                                                    onClick={e => this.onSubmit(e)}>
                                                                {i18n.t('button.submit')}
                                                            </Button>

                                                            {this.state.id &&
                                                            <Button color="danger" type="button" className="mr-1"
                                                                    onClick={this.toggleDanger}>
                                                                Supprimer
                                                            </Button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </h3>) :
                                            <h3>{i18n.t('page.admin.marque.title')}</h3>
                                        }
                                    </CardHeader>

                                    {this.state.id ? (
                                            <Tabs
                                                callbackOnChange={this.callbackOnChange}
                                                activeTab={this.state.activeTab}
                                                children={[this.setForm(), this.setProduct(),
                                                    <SetCategories
                                                        api={this.injected.rootStore.annuaireStore.annuaireCategoryStore}
                                                        id={this.state.id}/>, this.setPec()]}
                                                titles={["General", "Produits", "Modèles", "Pec"]}/>) :
                                        (<CardBody> <Row>{this.setForm()} </Row></CardBody>)
                                    }
                                </Card>
                            </div>
                        </Row>
                    </Container>
                )}

                {!this.state.card && (
                    this.setForm()
                )}
            </>
        );
    }
})));

export default withRouter(CreateMarque);
