import React from "react";
import AnnuaireCard from "./AnnuaireCard";
import configuration from "../../../configuration/configuration";
import getRoutes from "../../../components/Akitatek/getRoutes";
import GenericStaticCard from "../../../components/Akitatek/Card/GenericStaticCard";
import HeaderAnnuaire from "./HeaderAnnuaire";
import {inject, observer} from "mobx-react";


const ChoixElementAnnuaire = (inject("rootStore")(observer(class Annuaire extends React.Component {
        setTitle(data) {
            if (data.photo) {
                return data.photo
            }

            if (data.icon) {
                return data.icon
            }

            return ""
        }


        render() {
            const {type, rootStore, datas, name, title, select, title2, search} = this.props
            return (
                <>

                    {datas?.length > 0 ? (
                        <GenericStaticCard element={
                            <HeaderAnnuaire
                                search={search}
                                text={title2 + " " + select}
                                name={type}/>

                        }>
                            <div  id={type + "Card"} style={{display: "flex", flexWrap: "wrap"}}>
                                {datas
                                    .map((data, id) => {
                                        return (
                                            <AnnuaireCard
                                                key={"card" + id}
                                                data={data}
                                                rootStore={rootStore}
                                                name={name}
                                                url={data.asset ? configuration.PhotoBase + data.asset?.filename : configuration.Photo + type + "/" + this.setTitle(data)}
                                                route={getRoutes(title, data.id)}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </GenericStaticCard>
                    ) : (
                        <div id={type + "Card"} style={{display: "flex", flexWrap: "wrap"}}>
                            { type === "marque" || type !== "marque" && search !== "" && (
                            <GenericStaticCard element={
                                <HeaderAnnuaire
                                    search={search}
                                    text={title2 + " " + select}
                                    name={type}/>

                            }> </GenericStaticCard>  )}
                        </div>
                    )}
                </>
            );
        }
    })))
;

export default ChoixElementAnnuaire;