import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;

    const icons = {
        1: <i className="fa fa-address-book font-2xl" aria-hidden="true"></i>
        ,
        2: <i className="fa fa-laptop font-2xl" aria-hidden="true"></i>
        ,
        3: <i className="fa  fa-exclamation-triangle font-2xl" aria-hidden="true"></i>
        ,
        4: <i className="fa fa-shopping-bag font-2xl" aria-hidden="true"></i>
        ,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}
export default ColorlibStepIcon