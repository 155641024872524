import React from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {inject, observer} from "mobx-react";
import ToDoListInput from "./ToDoListInput";
import ToDoListGet from "./ToDoListGet";


const ToDo = (inject("rootStore")(observer(class ToDo extends React.Component {


    componentWillMount() {
        console.log("t")
        this.injected.rootStore.todoStore.getDatas()
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("None")
    }

    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Container className="mt-2 mb-2" fluid style={{ height: "100%"}}>
                    <Row style={{ height: "100%"}}>
                        <Col>
                            <Card className="shadow" style={{ height: "95%"}}>
                                <CardHeader
                                    style={{display: "flex", justifyContent: "space-between", color: "#20a8d8"}}>
                                    <h4 className="mb-0">
                                        To Do
                                    </h4>
                                </CardHeader>

                                <CardBody>
                                            <h1> A venir </h1>
                                            <ToDoListInput/>
                                    <div className={"mt-4"}>
                                        {
                                            this.injected.rootStore.todoStore.datas.filter((el) => el.isDone === 0).map(data => {
                                                return <ToDoListGet data={data}/>

                                            })
                                        }
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                        <Col>
                            <Card className="shadow" style={{ height: "95%"}}>
                                <CardHeader
                                    style={{display: "flex", justifyContent: "space-between", color: "#20a8d8"}}>
                                    <h4 className="mb-0">
                                        Fait
                                    </h4>
                                </CardHeader>

                                <CardBody>
                                    {
                                        this.injected.rootStore.todoStore.datas.filter((el) => el.isDone === 1).map(data => {
                                            return <ToDoListGet data={data}/>

                                        })
                                    }
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>


                </Container>
            </>
        );
    }
})));

export default ToDo;