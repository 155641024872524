import axios from "axios";
import configuration from "../configuration/configuration";

export default class StatAPI {

    async getStats(month, year) {
        return new Promise(async(resolve, reject) => {
            let url = `${configuration.API}/api/stats?month=${month}&year=${year}`;
            if(year == null && month == null) {
                url = `${configuration.API}/api/stats`;
            } else if(year == null) {
                url = `${configuration.API}/api/stats?month=${month}`;
            } else if( month == null) {
                url = `${configuration.API}/api/stats?year=${year}`;
            }
            try {
                const response = await axios({
                    method: 'get',
                    url: url,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async getDetailsYear(month, year) {
        return new Promise(async(resolve, reject) => {
            let url = `${configuration.API}/api/statsYear?month=${month}&year=${year}`;
            if(year == null && month == null) {
                url = `${configuration.API}/api/statsYear`;
            } else if(year == null) {
                url = `${configuration.API}/api/statsYear?month=${month}`;
            } else if( month == null) {
                url = `${configuration.API}/api/statsYear?year=${year}`;
            }
            try {
                const response = await axios({
                    method: 'get',
                    url: url,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async getLittleStats() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/dash/stats`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    async downloadZip(month, year) {
        const response = await axios({
            method: 'get',
            url: `${configuration.API}/api/getInvoicesZip?month=${month}&year=${year}`,
            headers: {
                'content-type': '*/*'
            },
            responseType: 'blob'
        });

        const file = response.data;
        file.name =  response.data.filename;
        file.lastModified = new Date();
        return file;
    }

}