/* Global import */
import {observable, decorate} from 'mobx';

/* Custom import */
import Api from '../util/api'
import * as moment from 'moment'

export default class StatsStore {

    actual = observable({
        mois: "",
        fr: 0,
        moisNumber : moment().format("M") ,
        year: moment().format("YYYY"),
        moisMoment: "",
        expense : 0,
        ca: 0,
        benefice : 0 ,
        pourcent : { ca: 0, expense: 0}
    });

    boardStats = observable([])

    caDetails = observable({
        mo : "",
        fdp: "",
        others : 0,
        othersAll: 0,
        total : 0
    });

    details = observable([]);
    detailsYear  = observable([]);

    suivant = observable({
        mois : "",
        moisMoment: "",
        expense : 0,
        ca: 0,
        benefice : 0 ,
        pourcent : { ca: 0, expense: 0}
    });


    precedent = observable({
        mois : "",
        moisMoment: "",
        expense : 0,
        ca: 0,
        benefice : 0 ,
        pourcent : { ca: 0, expense: 0}
    });



    client = observable({actual:0, precedent:0, difference:0});
    facture = observable({mois:0, difference:0, client_unique:0});

    chart = observable({
        contents: [],
        labels: [],
        expenses: []
        }

    );

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }


    async getStats(month, year)  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let datas = await this.api.stats.getStats(month, year);

            if(datas) {
                this.setDatas(datas);
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return datas;

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async downloadZip(month, year) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const file = await this.api.stats.downloadZip(month, year);
            this.rootStore.savedDatasStore.setLoading(false)
            return file;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async getLittleStats()  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let datas = await this.api.stats.getLittleStats();

            if(datas) {
                this.boardStats = datas
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return datas;

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async getDetailYear(month, year)  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.stats.getDetailsYear(month, year);

            if(authResponse.status === "success") {
                this.detailsYear = authResponse.datas;
                this.setSomeDatas(authResponse)
            }

            this.rootStore.savedDatasStore.setLoading(false)

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    setSomeDatas(d) {

        this.actual = d.actual;
        this.suivant = d.suivant;
        this.precedent = d.precedent;

        this.actual.moisMoment = moment(d.actual.mois);
        this.suivant.moisMoment = moment(d.suivant.mois);
        this.precedent.moisMoment = moment(d.precedent.mois);

        this.actual.year = this.actual.moisMoment.format("YYYY");
        this.actual.moisNumber = this.actual.moisMoment.format("M");
        this.suivant.moisString = this.suivant.moisMoment.format("MMMM") + " " +  this.suivant.moisMoment.format("YYYY");
        this.precedent.moisString = this.precedent.moisMoment.format("MMMM") + " " +  this.precedent.moisMoment.format("YYYY");
    }

    setDatas(d) {

        this.actual = d.actual;
        this.suivant = d.suivant;
        this.precedent = d.precedent;

         this.actual.moisMoment = moment(d.actual.mois);
         this.suivant.moisMoment = moment(d.suivant.mois);
         this.precedent.moisMoment = moment(d.precedent.mois);

         this.actual.year = this.actual.moisMoment.format("YYYY");
         this.actual.moisNumber = this.actual.moisMoment.format("M");
         this.suivant.moisString = this.suivant.moisMoment.format("MMMM") + " " +  this.suivant.moisMoment.format("YYYY");
         this.precedent.moisString = this.precedent.moisMoment.format("MMMM") + " " +  this.precedent.moisMoment.format("YYYY");

        this.caDetails = d.caDetails;
        this.details = d.details;

         this.actual.fr = d.actual.fr;
         this.client = d.client;
         this.facture = d.facture;

         this.chart = d.chart;

     }


}

decorate(StatsStore, {
    actual: observable,
    precedent: observable,
    suivant: observable,
    client: observable,
    facture: observable,
    chart: observable,
    detailsYear: observable,
    details: observable,
    boardStats: observable
});