import {decorate, observable} from "mobx";
import Api from "../util/api";

export default class FournisseurStore {

    datas = observable([]);
    fournisseurs = observable([]);

    filter = {
        name: "created_at",
        order: true,
        limit: "15",
        page: "1",
        last_page: "1",
    }


    defaultFilter = {
        limit: "15",
        page: "1",
        last_page: "1",
        name: "created_at",
        order: true,

    }

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.resource.get("fournisseurs", this.filter);

            if (this.datas.length > 0) {
                this.datas.replace(authResponse.data)
            } else {
                this.datas = authResponse.data;
            }

            this.filter.last_page = authResponse.last_page;
            this.filter.page = authResponse.current_page;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }

    async getAll() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.resource.get("fournisseurs/all", this.filter);

            if (this.fournisseurs.length > 0) {
                this.fournisseurs.replace(authResponse)
            } else {
                this.fournisseurs = authResponse;
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }


    async find(id) {
        return await this.api.resource.getAction("fournisseur", id);
    }

    async create(datas) {
        this.rootStore.savedDatasStore.setLoading(true)

        let authResponse = await this.api.resource.save("fournisseurs", datas);

        if (authResponse?.status === "success") {
            this.rootStore.notificationStore.setNotification("Fournisseur " + authResponse.datas.id + " ajouté", "success");
            this.getDatas()
        }
        this.rootStore.savedDatasStore.setLoading(false)

        return authResponse
    }

    async update(datas) {
        this.rootStore.savedDatasStore.setLoading(true)

        let authResponse = await this.api.resource.update("fournisseurs", datas);

        if (authResponse?.status === "success") {
            this.rootStore.notificationStore.setNotification("fournisseur modifié", "success");
            this.getDatas()

        }
        this.rootStore.savedDatasStore.setLoading(false)

        return authResponse
    }


    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)

            let authResponse = await this.api.resource.delete("fournisseur", id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("fournisseur # " + id + " supprimée", "success");
                this.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(FournisseurStore, {
    datas: observable,
    fournisseurs: observable,
    filter: observable
});

