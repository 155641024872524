import React from "react";
import styles from "./Screen.module.css";
import {Animated} from "react-animated-css";
import GamepadList from "../admin/Outils/Outils";

function GamePadMode() {
    return  <Animated animationIn="fadeInRight" animationOut="fadeInRight" animationInDuration={1000}>
    <div className={"container-fluid pt-5 min-vh-100"} style={{ backgroundColor: "white"}}>
    <div style={{ color: "black"}}>GamePad Mode
        <GamepadList/>
    </div>
    </div>
    </Animated>
}
export default GamePadMode