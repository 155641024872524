import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";
import encodeDataToURL from "../components/Akitatek/encodeDatatoURL";

export default class UserAPI {

    async getAllUsers() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/users/all`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    async getUsers(filter) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/users?` + encodeDataToURL(filter),
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async onBan(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/users/ban/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async onActivate(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/users/autorisation/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }





    createUser(state) {
        return new Promise(async (resolve, reject) => {

            let nom = state.name;
            let prenom = state.firstname;
            let email = state.email;
            let entreprise = state.entreprise;
            let adresse = state.address;
            let adresseSuite = state.address2;
            let codepostal = state.postalCode;
            let ville = state.city;
            let telephone_portable = state.phone;
            let telephone_fixe = state.phoneHome;
            let password = state.password;
            let typeAccount = state.typeAccount;

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/users`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        nom,
                        prenom,
                        email, adresse, adresseSuite, codepostal, ville, telephone_portable, telephone_fixe,
                        password, typeAccount,
                        entreprise
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    updateUser(state) {
        return new Promise(async (resolve, reject) => {

            let userId = state.userId;
            let id = state.id;
            let nom  = state.name;
            let prenom = state.firstname;
            let entreprise = state.entreprise;
            let comment = state.comment;
            let website = state.website;
            let siret = state.siret;
            let password = state.password;
            let typeAccount = state.typeAccount;
            let email = state.email;

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/users`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        id,
                        userId,
                        nom,
                        prenom,
                        password,
                        typeAccount,
                        entreprise,
                        comment,
                        website,
                        siret,
                        email
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }


    updateUserProfile(state) {
        return new Promise(async (resolve, reject) => {

            let userId = state.userId;
            let id = state.adresse_id;
            let nom = state.adresse_name;
            let prenom = state.adresse_firstname;
            let adresse = state.adresse_rue;
            let adresseSuite = state.adresse_ruesuite;
            let codepostal = state.codepostal;
            let ville = state.ville;
            let telephone_portable = state.telephone_portable;
            let telephone_fixe = state.telephone_fixe;
            let entreprise = state.adresse_entreprise;

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/userprofile`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        id,
                        userId,
                        nom,
                        prenom,
                        adresse, adresseSuite, codepostal, ville, telephone_portable, telephone_fixe,
                        entreprise
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async findUserById(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/find/user/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async saveCustomersMachine(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/customersMachine`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async updateCustomersMachine(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/customersMachine`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async deleteCustomersMachine(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/customersMachine`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }


}