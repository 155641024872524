
import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Media, Row} from "reactstrap";
import Carousel from "react-bootstrap/Carousel";
import CardFooter from "reactstrap/lib/CardFooter";
import i18n from "i18next";
import configuration from "../../../../configuration/configuration";
import ChoiceImageInput from "../../../../components/Akitatek/Form/ChoiceImageInput";
import {SRLWrapper} from "simple-react-lightbox";


const Carrousel = (inject("rootStore") (observer( class Carrousel extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.carrouselStore.getCarrousels();

        this.state = {
            id: '',
            order: '',
            image: '',
            asset_id: '',
            titre: '',
            descriptif: '',
            link: '',
            actif: '1',
        };

        this.onSubmit.bind(this);
    }

    get injected() {
        return this.props;
    }

    async onSubmit(e) {
        e.preventDefault();
        let datas = Object.assign({}, this.state);

        // Logique modification ou creation
        if(this.state.id) {
            await this.injected.rootStore.carrouselStore.updateCarrousel(datas);
        }
        else {
            await this.injected.rootStore.carrouselStore.createCarrousel(datas);
        }
    }

    onUpdate(e, carrousel) {
        this.setState({
            id: carrousel.id,
            order: carrousel.order,
            image: carrousel.asset?.filename,
            titre: carrousel.titre,
            descriptif: carrousel.descriptif,
            link: carrousel.link,
            actif: carrousel.actif,
            asset_id:carrousel.asset_id
        })
    }
    addCarrousel(){
        return (
            <Row>
                <Form style={{ width : "100%"}}
                      role="form"
                      id="myForm"
                      onSubmit={ e => this.onSubmit(e) }
                >
                    <div className="pl-lg-4" >
                        {this.state.displayPassword && (
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-email"
                                        >
                                            Email
                                        </label>
                                        <Input
                                            autocomplete="chrome-off"
                                            className="form-control-alternative"
                                            id="input-email"
                                            type="email"
                                            value={ this.state.email ||'' }
                                            onChange={  e => this.setState({email: e.target.value})}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>)}
                    </div>
                    {/* Address */}
                    <div className="pl-lg-4">
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-address2"
                                    >
                                    Titre
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="Titre"
                                        type="text"
                                        value={ this.state.titre ||'' }
                                        onChange={  e => this.setState({titre: e.target.value})}
                                    />
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-address2"
                                    >
                                        Url
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="Url"
                                        type="text"
                                        value={ this.state.link ||'' }
                                        onChange={  e => this.setState({link: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-address2"
                                    >
                                        Descriptif
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="Descriptif"
                                        type="text"
                                        value={ this.state.descriptif ||'' }
                                        onChange={  e => this.setState({descriptif: e.target.value})}
                                    />
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-address2"
                                    >
                                        Order
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="order"
                                        type="text"
                                        value={ this.state.order ||'' }
                                        onChange={  e => this.setState({order: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ display: "flex", justifyContent: "center"}}>
                                <FormGroup className={"mr-2"}>
                                    <label>
                                        <input type="radio"
                                               checked={this.state.actif === 1}
                                               onClick={  e => this.setState({actif: 1})}
                                        />
                                        <span className="badge badge-pill badge-success">Visible</span>
                                    </label>
                                </FormGroup>
                                <FormGroup >
                                    <label>
                                        <input type="radio"
                                               checked={this.state.actif === 0}
                                               onClick={  e => this.setState({actif: 0})}
                                        />
                                        <span className="badge badge-pill badge-warning">Pas visible</span>
                                    </label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div className="pl-lg-4 mb-2" style={{ textAlign: "center"}}>
                        <Row>
                            <Col>
                                <ChoiceImageInput onPickAssetFilename={this.onPickAssetFilename} assetName={this.state.image}/>
                            </Col>
                        </Row>
                    </div>
                    <div className="pl-lg-4" style={{ textAlign: "center"}}>
                        <Row>
                            <Col>
                                <Button color="success" type="submit" >
                                    {i18n.t('button.submit')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Row>)
    }

    onPickAssetFilename = (action) => {
        if (action === "add") {
            this.setState({
                image: this.injected.rootStore.imageStore.pickAsset?.filename,
                asset_id: this.injected.rootStore.imageStore.pickAsset?.id,
            })
        } else {
            this.setState({
                image: "",
                asset_id: "",
            })
        }
    }

    render() {
        return (
            <>
                <Container  className="mt-2"  fluid>

                    <Card className="shadow">
                        <CardHeader

                            style={{display: "flex", justifyContent: "center"}}>
                            <h4 className="mb-0">Carrousel</h4>
                        </CardHeader>

                        <CardBody>
                            <Carousel>
                                {  this.injected.rootStore.carrouselStore.carrousels
                                    .map(carrousel => {
                                        return <Carousel.Item key={carrousel.id}>
                                            <SRLWrapper>
                                                <Media
                                                    className="d-block"
                                                    src={ configuration.PhotoBase + carrousel.asset?.filename  }
                                                    alt={carrousel.titre}
                                                    style={{width:"100%",height:"30vw",oObjectFit:"cover",objectFit:"cover",opacity:"0.9"}}
                                                /> </SRLWrapper>
                                            <Carousel.Caption>
                                                <h3>{carrousel.titre}</h3>
                                                <p>{carrousel.descriptif}</p>
                                                <Button onClick={e => this.onUpdate(e, carrousel)}>Modifier</Button>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    })
                                }
                            </Carousel>
                        </CardBody>
                        <CardFooter
                            style={{display: "flex", justifyContent: "center"}}>
                            <h4 className="mb-0">Ajouter un element</h4>
                            { this.addCarrousel() }
                        </CardFooter>

                    </Card>
                </Container>
            </>
        );
    }
})));

export default Carrousel;
