import React from "react";
import {inject, observer} from "mobx-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {Card, CardHeader} from "reactstrap";
import ChoixElement from "./ChoixElement";

const SearchMachine = inject("rootStore")(observer(class SearchMachineUser extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }


    selection(appareil) {
        this.injected.reload("productSearch", appareil)
        if (document.getElementById('prestationCard')) {
            document.getElementById('prestationCard').scrollIntoView()
        }
    }

    async onBack() {
        this.injected.reload("clean")
        this.injected.callbackOnChange("typeAddMachine", "none")
    }

    setMarque(option) {
       return option?.appareil?.marque?.nom ?? option?.marque?.nom
    }

    setAppareil(option) {
        return option?.appareil?.nom ?? option?.nom
    }
    render() {
        const {user, machineClient, machinesClient, selectPrestation, prestations, prestationsPec, selectPrestationApp} = this.props

        return (
            <Card>
                {machinesClient &&
                (<CardHeader style={{padding: "0.2em"}} className={"bg-primary"}>
                        <div style={{display: "flex", justifyContent: "space-between", fontSize: "1.2em"}}>
                            <div className="mr-4 ml-2">
                                <i onClick={e => this.onBack(e)} style={{cursor: "pointer"}}
                                   className="fa fa-arrow-left mr-2" aria-hidden="true"/>
                                Ajouter la prise en charge des machines appartenant au
                                client {user?.fullname}
                            </div>
                        </div>
                    </CardHeader>
                )}

                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Autocomplete
                        className={"mr-2 ml-2"}
                        value={machineClient}
                        options={machinesClient}
                        getOptionLabel={(option) => {
                            if (option?.id) {
                                return "Machine " + this.setMarque(option) + " " + this.setAppareil(option) + " avec numéro de série:" + (option?.serial ? option?.serial : "")
                            } else {
                                return ""
                            }
                        }}
                        style={{width: "50em"}}
                        onChange={async (event, newValue) => {
                            await this.selection(newValue);
                        }}
                        selectOnFocus
                        renderInput={(params) => <TextField {...params}
                                                            margin="normal"
                                                            label={"Rechercher une machine appartenant au client " + user?.fullname}
                                                            variant="outlined"/>}
                    />
                </div>

                {selectPrestation && (
                    <ChoixElement datas={prestations}
                                  type={"prestation"}
                                  ancre={"prestationCard"}
                                  sendDataBack={machineClient}
                                  text={"Sélectionnez la prestation de " + machineClient?.marque?.nom + " " + machineClient?.nom}
                                  onBack={e => this.onBack()}
                                  callbackOnChange={this.injected.callbackChange}
                                  reload={this.injected.reload}

                    />)}

                {selectPrestationApp && (
                    <ChoixElement datas={prestationsPec}
                                  type={"prestationPec"}
                                  sendDataBack={machineClient}
                                  text={"Supprimer la prestation de " + machineClient?.marque?.nom + " " + machineClient?.nom}
                                  callbackOnChange={this.injected.callbackChange}
                                  reload={this.injected.reload}

                    />)}

            </Card>


        )
    }
}));

export default SearchMachine;
