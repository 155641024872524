import React from 'react';
import {inject, observer} from "mobx-react";
import 'moment/locale/fr';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Form from "reactstrap/lib/Form";
import EditorWord from "../../../../components/Akitatek/EditorWord";

const TimelineComponent = inject("rootStore")(observer(class TimelineComponent extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props)
    }

    onEditorStateChange = (editorState) => {
        this.injected.callbackOnChange("comment", editorState)
    };


    render() {
        const {comment} = this.props

        return (

            <Form>
                <EditorWord editorState={comment} onEditorStateChange={this.onEditorStateChange}/>
            </Form>

        );
    }
}));

export default TimelineComponent

