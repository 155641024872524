import TableGeneric from "../../../../components/Akitatek/Headers/TableGeneric";
import MachineUserRow from "./MachineUserRow";
import React from "react";

function TableMachine({appareils, callbackOnChangeList, onCallbackAction,  titles = [
    "Marque",
    "Produit",
    "Numéro de série",
    "Code",
    "Defauts",
    "Actions"
], callbackChange, reload, type = "user"}) {

    return (<TableGeneric titles={titles}>

        {appareils?.map((appareil, id) => {
            return (<MachineUserRow
                id={appareil.annuaire_appareil_id ?? appareil.id}
                appareil={appareil}
                type={type}
                serial={appareil.pivot?.serial ?? appareil.serial }
                nom={appareil?.nom ?? appareil?.appareil?.nom }
                defauts={appareil.pivot?.defauts ?? appareil.defauts}
                marque={appareil.marque ?? appareil?.appareil?.marque}
                code={appareil.pivot?.code ?? appareil.code}
                accessoires={appareil.pivot?.accessoires}
                casier={appareil.pivot?.casier}
                prestations={appareil.pivot?.prestations}
                callbackChange={callbackChange}
                reload={reload}
                callbackOnChange={callbackOnChangeList}
                onCallbackAction={onCallbackAction}
                key={id}
                index={id}
            />)
        })}
        {(appareils?.length === 0) && (
            <tr>
                <td className={"no_td"} colSpan={titles.length}>Pas d'appareils enregistrés
                </td>
            </tr>
        )}
    </TableGeneric>)
}

export default TableMachine;