import React from "react";
import {inject, observer} from "mobx-react";
import i18n from "i18next";
import {Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import Select from 'react-select';
import {ContentState, EditorState} from 'draft-js';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from "./Prestation.module.css";
import {withRouter} from "react-router-dom";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import htmlToDraft from 'html-to-draftjs';
import ChoiceImageInput from "../../../../components/Akitatek/Form/ChoiceImageInput";
import EditorWord from "../../../../components/Akitatek/EditorWord";

var slugify = require('slugify')

const AddOrEditPrestation = inject("rootStore")(observer(class AddOrEditPrestation extends React.Component {

    id;

    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);

        this.state = {
            titleofpage: '',
            metadescription: '',
            title: '',
            global_price_mo: 0,
            text: EditorState.createEmpty(),
            isVisible: 0,
            option_price: "EGAL",
            file: "",
            url: "",
            assetName: "",
            video1: "",
            video2: "",
            video3: "",
            asset_id: "",
            label: "Choisir une prestation existante",
            productLabel: {
                value: this.injected.data?.id ?? this.injected.rootStore.annuaireStore.prestationStore.appareilId,
                label:  this.injected.data?.nom  ?? this.injected.rootStore.annuaireStore.prestationStore.appareil
            },
        };
        if (this.injected.location.aboutProps?.subCategory === "marque") {
            this.injected.rootStore.savedDatasStore.setHeader("Gestion des marques", "Gérer vos marques", "annuaire_marque.jpg")
        }
    }


    async componentDidMount() {
        await this.injected.rootStore.annuaireStore.productStore.getAllProducts()
        this.injected.rootStore.annuaireStore.prestationStore.getOptionsPrestations()
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState:
            editorState,
        });
    };

    async changePresta(value) {
        await this.getPrestaById(value.value)
    }

    async onSubmit(e) {
        e.preventDefault();
        const datas = Object.assign({}, this.state);

        let authResponse = await this.injected.rootStore.annuaireStore.prestationStore.createPrestation(datas);

        if (authResponse?.status === "success") {
            if (this.injected.card) {
                this.injected.history.replace(authResponse.data.id.toString())
            } else {
                this.injected.onCallback()
            }
        }
    }

    changeTitle(e) {
        if(!this.state.id) {
            this.setState({title: e.target.value, url: slugify(e.target.value)})
        } else {
            this.setState({title: e.target.value})
        }
    }


    async getPrestaById(id) {
        let authResponse = await this.injected.rootStore.annuaireStore.prestationStore.findPrestationById(id)

        if (authResponse?.status === "success") {
            let presta = authResponse.datas
            let editorState
            if (presta?.description != null) {
                let blocksFromHTML = htmlToDraft(presta?.description);
                const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
                editorState = EditorState.createWithContent(contentState);
            } else {
                editorState = EditorState.createEmpty()
            }

            await this.setState({
                title: presta?.title ?? "",
                price: presta?.price ?? "",
                option_price: presta?.option_price ?? "",
                isVisible: presta?.isVisible,
                global_price_mo: presta?.global_price_mo ?? "",
                editorState: editorState,
                assetName: presta?.asset?.filename ?? "",
                asset_id: presta?.asset?.id ?? "",
                pickAsset: false,
                url: presta?.url,
                label: {value: presta?.id, label: presta?.title},
                titleofpage: presta?.titleofpage ?? "",
                metadescription: presta?.metadescription ?? "",
                video1: presta?.video1,
                video2: presta?.video2,
                video3: presta?.video3,
            })

        }
    }

    onPickAssetFilename = (action) => {
        if (action === "add") {
            this.setState({
                assetName: this.injected.rootStore.imageStore.pickAsset?.filename,
                asset_id: this.injected.rootStore.imageStore.pickAsset?.id,
            })
        } else {
            this.setState({
                assetName: "",
                asset_id: "",
            })
        }
    }

    render() {
        return (
            <>
                <FormGroup>
                    <Select
                        value={this.state.label}
                        className={"mb-3"}
                        onChange={e => this.changePresta(e)}
                        options={this.injected.rootStore.annuaireStore.prestationStore.optionsPrestation.map((presta) => {
                            return {value: presta.id, label: presta.title}
                        })}
                    />
                </FormGroup>


                <Row className="mb-3">
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                <h4 className="mb-0">{i18n.t('page.admin.prestations.add')}</h4>
                            </CardHeader>

                            <CardBody>
                                <Form
                                    role="form"
                                    onSubmit={e => this.onSubmit(e)}>

                                    {this.injected.rootStore.authStore.options.metadata === 1 && (
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <label
                                                        className={styles.typeField}
                                                        htmlFor="input-first-name"
                                                    >
                                                        Titre de la page (Si vide généré auto)
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-first-name"
                                                        type="text"
                                                        value={this.state.titleofpage || ''}
                                                        onChange={e => this.setState({titleofpage: e.target.value})}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <label
                                                        className={styles.typeField}
                                                        htmlFor="input-first-name"
                                                    >
                                                        Métadescription (si vide : généré auto)
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-first-name"
                                                        type="text"
                                                        value={this.state.metadescription || ''}
                                                        onChange={e => this.setState({metadescription: e.target.value})}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className={styles.typeField}>
                                                    {i18n.t('page.admin.prestations.name')}:
                                                </Label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-first-title"
                                                    type="text"
                                                    value={this.state.title}
                                                    onChange={e => this.changeTitle(e)}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className={styles.typeField}>
                                                    Prix mo
                                                </Label>
                                                <InputGroup>
                                                    <ButtonGroup>
                                                        <Button color="primary"
                                                                onClick={() => this.setState({option_price: "EGAL"})}
                                                                active={this.state.option_price === "EGAL"}>Egal</Button>
                                                        <Button color="primary"
                                                                onClick={() => this.setState({option_price: "FROM"})}
                                                                active={this.state.option_price === "FROM"}>A partir
                                                            de</Button>
                                                    </ButtonGroup>

                                                    <Input
                                                        type="number"
                                                        value={this.state.global_price_mo}
                                                        onChange={e => this.setState({global_price_mo: e.target.value})}
                                                        required
                                                    />

                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText>€</InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </FormGroup>


                                        </Col>
                                    </Row>

                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className={styles.typeField}>
                                                    URL:
                                                </Label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-first-url"
                                                    type="text"
                                                    value={this.state.url}
                                                    onChange={e => this.setState({url: e.target.value})}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className={styles.typeField}>
                                                    Produit:
                                                </Label>
                                                <Select
                                                    required
                                                    value={this.state.productLabel}
                                                    className={"mb-3"}
                                                    onChange={e => this.setState({
                                                        productLabel: {
                                                            value: e.value,
                                                            label: e.label
                                                        }
                                                    })}
                                                    options={this.injected.rootStore.annuaireStore.productStore.allProducts.map((product) => {
                                                        return {value: product.id, label: product.nom}
                                                    })}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label> Lien de la vidéo 1 </Label>
                                                    <InputGroup>
                                                        <Input
                                                            value={this.state.video1}
                                                            onChange={e => this.setState({video1: e.target.value})}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label> Lien de la vidéo 2 </Label>
                                                    <InputGroup>
                                                        <Input
                                                            value={this.state.video2}
                                                            onChange={e => this.setState({video2: e.target.value})}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label> Lien de la vidéo 3 </Label>
                                                    <InputGroup>
                                                        <Input
                                                            value={this.state.video3}
                                                            onChange={e => this.setState({video3: e.target.value})}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                    </Row>

                                    <EditorWord editorState={this.state.editorState}
                                                onEditorStateChange={this.onEditorStateChange}/>

                                    <hr className="my-4"/>

                                    <Row form>
                                        <ChoiceImageInput assetName={this.state.assetName}
                                                          onPickAssetFilename={this.onPickAssetFilename}/>
                                    </Row>

                                    <Row>
                                        <Col style={{display: "flex", justifyContent: "center"}}>
                                            <FormGroup className={"mr-2"}>
                                                <label>
                                                    <input type="radio"
                                                           checked={this.state.isVisible === 1}
                                                           onClick={e => this.setState({isVisible: 1})}
                                                    />
                                                    <span className="badge badge-pill badge-success">PUBLIER</span>
                                                </label>
                                            </FormGroup>
                                            <FormGroup>
                                                <label>
                                                    <input type="radio"
                                                           checked={this.state.isVisible === 0}
                                                           onClick={e => this.setState({isVisible: 0})}
                                                    />
                                                    <span className="badge badge-pill badge-warning">NON PUBLIER</span>
                                                </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button className={"m-auto"} color="success" style={{display: "flex"}}
                                            type="submit">
                                        Valider
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </>
        );
    }
}));

export default withRouter(AddOrEditPrestation);
