import React from "react";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {Button, UncontrolledTooltip} from "reactstrap";

const ActionFiche = inject("rootStore")(observer(class ActionFiche extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }

    toggleDanger(event, data) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning("Fiche #" + data.id, event => this.onDelete(data.id))
    }

    async onDelete(deleteId) {
        await this.injected.rootStore.ficheStore.delete(deleteId);
    }

    render() {
        const {data} = this.props
        return (
            <>
                <tr>
                    <td className={"th-actions"}>
                                <Button className={"ml-2 mr-2"} color="primary" type="button"
                                        id={"UncontrolledTooltipExampleVoir" + data?.id}>
                                    <i className="fa fa-eye" aria-hidden="true"/>
                                    <UncontrolledTooltip placement="top"
                                                         target={"UncontrolledTooltipExampleVoir" + data?.id}>
                                        Voir
                                    </UncontrolledTooltip>
                                </Button>
                    </td>
                </tr>
            </>
        );
    }

}));

export default withRouter(ActionFiche);
