import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import InfoIcon from '@material-ui/icons/Info';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {inject, observer} from "mobx-react";
import styles from '../Timeline/Timeline.module.css'
import AddIcon from '@material-ui/icons/Add';
import Input from "reactstrap/lib/Input";
import moment from "moment";
import 'moment/locale/fr';
import {Button} from "reactstrap";
import i18n from "i18next";
import {TextareaAutosize} from "@material-ui/core";

const Historique = inject("rootStore") (observer( class Historique extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props)
        moment.locale('fr');
        this.state = {
            timer: null
        }
        this.countingSecond = this.countingSecond.bind(this)
    }


    countingSecond() {
        this.setState({
            time: moment().format('LTS'),
        })
    }

    componentWillUnmount() {
        window.clearInterval(this.state.timer)
        this.setState({timer: null})

    }

    componentDidMount() {
        this.setState({timer: window.setInterval(this.countingSecond, 1000)})
    }

    //Refresh
    onChange(e) {
        this.injected.callbackOnChange(e.target.name, e.target.value)
    };

    async enter(e) {
        if (e.key === 'Enter' ) {
            e.preventDefault()
            this.injected.callbackSubmit(this.props.type)
        }
    }

    setComponentMessage(note) {
        if(note.user?.role  === "admin") {
            return  <TimelineItem position={"right"}>
                <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                        <i className={note.icone.replace("fas", "fa")}></i>
                    </TimelineDot>
                    <TimelineConnector/>
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={styles.paper}>
                        <Typography variant="h6" component="h1">
                            {note.titre}
                        </Typography>
                        <Typography> {note.texte}</Typography>
                    </Paper>
                    <Typography variant="body2" color="textSecondary">
                        {note.user?.fullname} {" le "}
                        {moment(note.created_at, 'YYYY-MM-DD hh:mm:ss').format('Do MMMM YYYY hh:mm:ss')}
                    </Typography>
                </TimelineContent>
            </TimelineItem>
        } else  return <TimelineItem position={"left"}>
            <TimelineOppositeContent>
                <Paper elevation={3}  className={styles.paper2}>
                    <Typography variant="h6" component="h1">
                        {note.titre}
                    </Typography>
                    <Typography> {note.texte}</Typography>
                </Paper>
                <Typography variant="body2" color="textSecondary">
                    {note.user?.fullname} {" le "}
                    {moment(note.created_at, 'YYYY-MM-DD hh:mm:ss').format('Do MMMM YYYY hh:mm:ss')}
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                    <i className={note.icone.replace("fas", "fa")}></i>
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent>
                <Typography variant="body2" color="textSecondary">
                </Typography>
            </TimelineContent></TimelineItem>

    }


    setComponent(note) {
        return ( <TimelineItem key={note.id}>
            <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                    {moment(note.created_at).format('LLLL')}
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                    <InfoIcon />
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent>
                <Paper elevation={3} className={styles.paper}>
                    <Typography variant="h6" component="h1">
                        {note.title}
                    </Typography>
                    <Typography> {note.description}</Typography>
                </Paper>
            </TimelineContent>
        </TimelineItem>)
    }

    createComponentMessage(descriptionNote, titleNote) {
        return ( <TimelineItem>
            <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                    {this.injected.rootStore.authStore.user?.fullname} {" le "}
                    {moment().format('Do MMMM YYYY')} {this.state.time}
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                    <i className="fa fa-comments" />
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent>
                <Paper elevation={3} className={styles.paper}>
                    <Typography variant="h6" component="h1">
                        <Input
                            id={"refTimelinePec"}
                            value={titleNote}
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                            placeholder={"Entrer votre titre"}
                            type="text"
                            name="titleLigne"  />
                    </Typography>
                    <Typography>


                        <TextareaAutosize
                            minRows={2}
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                            style={{width: "100%"}}
                            name="descriptionLigne"
                            defaultValue={descriptionNote}
                            placeholder={"Noter"}

                        />

                        <Button color="success" onClick={e => this.injected.callbackSubmit(this.props.type)}>
                            {i18n.t('button.submit')}
                        </Button>


                    </Typography>
                </Paper>
            </TimelineContent>
        </TimelineItem>)
    }

    createComponent(descriptionNote, titleNote) {
        return ( <TimelineItem>
            <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                    {moment().format('LL')} {this.state.time}
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                    <AddIcon />
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent>
                <Paper elevation={3} className={styles.paper}>
                    <Typography variant="h6" component="h1">
                        <Input
                            id={"refTimelineHistorique"}
                            value={titleNote}
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                            placeholder={"Entrer votre titre"}
                            type="text"
                            name="titleNote"  />
                    </Typography>
                    <Typography><Input
                        value={descriptionNote}
                        onChange={(e) => {
                            this.onChange(e)
                        }}
                        onKeyPress={e => this.enter(e)}
                        placeholder={"Notez et appuyez sur entrer"}
                        type="textarea"
                        name="descriptionNote" />

                    </Typography>
                </Paper>
            </TimelineContent>
        </TimelineItem>)
    }


    render() {
        const {notes, descriptionNote,titleNote} = this.props

        return (

            <Timeline>
                {this.props.type === "historique" ? this.createComponent(descriptionNote, titleNote) : this.createComponentMessage(descriptionNote, titleNote)}

                {notes.map(note => {
                    return this.props.type === "historique" ? this.setComponent(note) : this.setComponentMessage(note)
                })}
            </Timeline>
        );
    }


}));

export default Historique

