import {inject, observer} from "mobx-react";
import React from "react";
import {Card, CardBody, CardHeader, Container, Row} from "reactstrap";
import AddFournisseurs from "../Commande/AddFournisseurs";
import GenericStaticCard from "../../../components/Akitatek/Card/GenericStaticCard";
import Dropdown from "react-bootstrap/Dropdown";
import Tabs from "../../../components/Akitatek/Tabs/Tabs";
import GenericTableAffichageWithDatas from "../../../components/Akitatek/Table/GenericTableAffichageWithDatas";
import StatsFournisseur from "./StatsFournisseur";


const UpdateFournisseur = (inject("rootStore") (observer( class UpdateFournisseur extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des fournisseurs", "Gérer les fournisseurs / Regrouper les commandes", "facture.jpg", this.injected.rootStore.fournisseurStore)


        this.state = {
            id: "",
            nom: "",
            first_name: "",
            last_name: "",
            commentaire: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            postal_code: "",
            account_number: "",
            siteweb: "",
            activeTab: 0,
        }
    }


    get injected() {
        return this.props;
    }

    componentDidMount() {
        if (this.injected.match.params.id && this.injected.match.params.id !== "add") {
            this.onRouteChanged()
        }
    }

    async onRouteChanged() {
        let authResponse = await this.injected.rootStore.fournisseurStore.find(this.injected.match.params.id)
        await this.onChangeEditor(authResponse)
    }

    async onChangeEditor(data) {
        await this.setState({
            id: data.id,
            nom: data.nom,
            first_name: data.first_name,
            last_name: data.last_name,
            commentaire: data.commentaire,
            email: data.email,
            phone: data.phone,
            address: data.address,
            city: data.city,
            state: data.state,
            postal_code: data.postal_code,
            account_number: data.account_number,
            siteweb: data.siteweb,
            stats: data.stats,
        });
    }


    onChange = (e) => {
        const name = e.target.name
        this.setState({[name]: e.target.value});
    };

    onSubmit = async (event) => {
        event.preventDefault()
        let authResponse;
        if (this.state.id) {
            authResponse = await this.injected.rootStore.fournisseurStore.update(this.state)
        } else {
            authResponse = await this.injected.rootStore.fournisseurStore.create(this.state)
        }

        if (authResponse?.status === "success") {
            await this.onChangeEditor(authResponse.datas)
        }
    }

    //Callback
    callbackOnChange = (name, value) => {
        this.setState({[name]: value});
    }
    render() {
        return(
            <>
                <Container className="mt-2" fluid>
                    {!this.state.id ? (
                            <Row className="mb-3">
                                <div className="col">·
                                    <Card className="shadow">
                                        <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                            <h4 className="mb-0">Ajouter une fournisseur</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <AddFournisseurs nom={this.state.nom}
                                                             id={this.state.id}
                                                             first_name={this.state.first_name}
                                                             last_name={this.state.last_name}
                                                             commentaire={this.state.commentaire}
                                                             email={this.state.email}
                                                             phone={this.state.phone}
                                                             address={this.state.address}
                                                             city={this.state.city}
                                                             state={this.state.state}
                                                             postal_code={this.state.postal_code}
                                                             account_number={this.state.account_number}
                                                             siteweb={this.state.siteweb}
                                                             onCallbackChange={this.onChange}
                                                             onCallbackSubmit={this.onSubmit}


                                            />
                                        </CardBody>
                                    </Card>
                                </div>
                            </Row>
                    ) : (
                        <GenericStaticCard element={
                            <div className="mb-0" style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                <h3>#{this.state.id} - Entreprise {this.state.nom}
                                </h3>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <Dropdown className={"mr-2"}>
                                        <Dropdown.Toggle variant="info">
                                            <i className="fa fa-plus-circle" aria-hidden="true"/>
                                            Nouveau
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                          > Commande </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        }>

                            <div>
                                <StatsFournisseur stats={this.state.stats} />

                            </div>

                            <Tabs
                                callbackOnChange={this.callbackOnChange}
                                activeTab={this.state.activeTab}
                                children={[
                                    <CardBody>
                                    <AddFournisseurs nom={this.state.nom}
                                                     id={this.state.id}
                                                     first_name={this.state.first_name}
                                                     last_name={this.state.last_name}
                                                     commentaire={this.state.commentaire}
                                                     email={this.state.email}
                                                     phone={this.state.phone}
                                                     address={this.state.address}
                                                     city={this.state.city}
                                                     state={this.state.state}
                                                     postal_code={this.state.postal_code}
                                                     account_number={this.state.account_number}
                                                     siteweb={this.state.siteweb}
                                                     onCallbackChange={this.onChange}
                                                     onCallbackSubmit={this.onSubmit}


                                    /> </CardBody>,
                                    <GenericTableAffichageWithDatas
                                        api={this.injected.rootStore.commandeStore}
                                        filters={{
                                            limit: "15",
                                            fournisseur_id: this.state.id,
                                            name: "updated_at",
                                            order: false,
                                        }}
                                        name={"Commandes"}
                                        type="cmd"/>
                                   ]}
                                titles={["Infos", "Commandes"]}/>
                        </GenericStaticCard>
                    )}
                </Container>

            </>
        )
    }
})));

export default UpdateFournisseur;
