import React from "react";
import {Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import ChoixElementAnnuaire from "./ChoixElementAnnuaire";


const Annuaire = (inject("rootStore")(observer(class Annuaire extends React.Component {

    parentCallbackPage = (childData) => {
        this.injected.rootStore.annuaireStore.marqueStore.currentPage = childData
        this.injected.rootStore.annuaireStore.marqueStore.getMarques();
    };

    async componentDidMount() {
        await this.injected.rootStore.annuaireStore.marqueStore.getMarques();
        if (document.getElementById('productCard')) {
            document.getElementById('productCard').scrollIntoView()
        }
        if (document.getElementById('prestationsCard')) {
            document.getElementById('prestationsCard').scrollIntoView()
        }
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des marques", "Gérer vos marques", "annuaire_marque.jpg")
    }

    get injected() {
        return this.props;
    }


    render() {
        return (
            <>

                <Container className="mt-2" fluid>
                    <ChoixElementAnnuaire
                        rootStore={this.injected.rootStore}
                        datas={this.injected.rootStore.annuaireStore.marqueStore?.datas}
                        type={"marque"}
                        select={""}
                        search={this.injected.rootStore.annuaireStore.marqueStore?.search}
                        title2={"Marque"}
                        title={"Modifier une marque"}
                        name={"product"}
                    />

                    <ChoixElementAnnuaire
                        rootStore={this.injected.rootStore}
                        datas={this.injected.rootStore.annuaireStore.productStore?.products}
                        select={this.injected.rootStore.annuaireStore.productStore.marque}
                        search={this.injected.rootStore.annuaireStore.productStore?.search}
                        type={"product"}
                        title2={"Produit"}
                        title={"Afficher un Produit"}
                        name={"presta"}
                    />


                    <ChoixElementAnnuaire
                        rootStore={this.injected.rootStore}
                        datas={this.injected.rootStore.annuaireStore.prestationStore?.prestations}
                        search={this.injected.rootStore.annuaireStore.prestationStore?.search}
                        type={"prestations"}
                        title2={"Prestation"}
                        select={this.injected.rootStore.annuaireStore.prestationStore.appareil}
                        title={"Modifier une prestation"}
                        name={""}
                    />
                </Container>
            </>
        );
    }
})));

export default Annuaire;
