import React from "react";
import {inject, observer} from "mobx-react";
import {Container} from "reactstrap";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";


const Promotions = inject("rootStore")(observer(class Promotions extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.injected.rootStore.savedDatasStore.setHeader("Promotions", "Faire des supers promos", "motherboard.jpg")
    }

    // toggleDanger() {
    //     this.injected.rootStore.notificationStore.setWarning("Oui", event => this.onDelete())
    // }
    //
    // async onDelete() {
    //     this.injected.onCallbackAction(this.props.index, "delete")
    // }
    //
    // async onUpdate(e) {
    //     if (e) {
    //         e.preventDefault()
    //     }
    //     this.injected.onCallbackAction(this.props.index, "update")
    // }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericTableAffichage api={this.injected.rootStore.promotionStore}
                                           user={false}
                                           filters={this.injected.rootStore.promotionStore.defaultFilter}
                                           status={false}
                                           statusHeader={false}
                                           icon={<i className="fa fa-percent" aria-hidden="true"/>}
                                           name={"Promotions "}
                                           type="promotions"/>
                </Container>
            </>
        );
    }
}));

export default Promotions;
