import React from "react";
import {inject, observer} from "mobx-react";
import {Card, CardBody, CardHeader} from "reactstrap";
import Form from "reactstrap/lib/Form";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import moment from "moment";

const DetailCommande = inject("rootStore")(observer(class DetailCommande extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }


    //Refresh
    onChange(e) {
        this.injected.callbackChange(e.target.name, e.target.value)
    };


    render() {
        const {title, reference, created_at, comment} = this.props

        return (
            <div className="bg-transparent mt-1">

                <Card style={{minWidth: "20em"}}>
                    <CardHeader
                        style={{display: "flex", justifyContent: "center", alignItems: "center", color: "#20a8d8"}}>
                        <h5> Ajouter les détails de la commande </h5>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label for="titreCommande">Titre de la commande</Label>
                                <Input type="text" name="title"
                                       value={title}
                                       onChange={e => {
                                           this.onChange(e)
                                       }}
                                       required
                                />
                            </FormGroup>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="numCommande">Numéro de commande</Label>
                                        <Input type="text"
                                               name="ref"
                                               value={reference}
                                               onChange={e => {
                                                   this.onChange(e)
                                               }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="dateExpedition">Date de création</Label>
                                        <Input
                                            type="date"
                                            name="created_at"
                                            value={moment(created_at).format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")}
                                            onChange={e => {
                                                this.onChange(e)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for="notes">Notes</Label>
                                <Input type="textarea"
                                       name="comment"
                                       value={comment}
                                       onChange={e => {
                                           this.onChange(e)
                                       }}/>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
            </div>

        )
    }
}));

export default DetailCommande;
