import axios from "axios";
import configuration from "../configuration/configuration";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import {default as i18n} from "i18next";
import encodeDataToURL from "../components/Akitatek/encodeDatatoURL";

export default class PromotionAPI {
    async get(filter) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/promotions?` + encodeDataToURL(filter),
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    createPromotion(datas) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/promotion`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


   updatePromotion(datas, id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'PUT',
                    url: `${configuration.API}/api/promotion/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async delete(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/promotion/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async find(id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/promotion/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }
}