import axios from "axios";
import configuration from "../configuration/configuration";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import {default as i18n} from "i18next";

export default class MarqueAPI {

    createMarqueWithFile(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                if (datas?.description) {
                    datas.description = draftToHtml(convertToRaw(datas?.description.getCurrentContent()));
                }
                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/annuaire/marques`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    updateMarqueWithFile(datas) {
        return new Promise(async (resolve, reject) => {
            try {

                if (datas.description) {
                    datas.description = draftToHtml(convertToRaw(datas.description.getCurrentContent()));
                }

                const response = await axios({
                    method: 'PUT',
                    url: `${configuration.API}/api/annuaire/marques`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async getMarques(search) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/annuaire/marques?search=${search}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async findMarqueById(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/marques/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    async allMarques() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/annuaire/all/marques`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async deleteMarques(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/marques/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data.status)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }
}