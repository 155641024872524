import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import AddFournisseurs from "./AddFournisseurs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const AutoCompleteCommande = inject("rootStore")(observer(class Search extends React.Component {

    commande

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            search: "",
            idF: "",
            modeSearch: true,
            nom: "",
            first_name: "",
            last_name: "",
            commentaire: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            postal_code: "",
            account_number: "",
            siteweb: ""
        }
        this.injected.rootStore.fournisseurStore.getAll();
    }

    onChangeFournisseur = (e) => {
        const name = e.target.name
        this.setState({[name]: e.target.value});
    };

    onSubmitFournisseur = async (event) => {
        event.preventDefault()
        let authResponse;
        const datas = Object.assign({}, this.state);
        datas.id = this.state.idF;
        if (this.state.idF) {
            authResponse = await this.injected.rootStore.fournisseurStore.update(datas)
        } else {
            authResponse = await this.injected.rootStore.fournisseurStore.create(datas)
        }

        if (authResponse?.status === "success") {
            await this.onChangeEditor(authResponse.datas)
            this.injected.callbackChange("fournisseur_id", authResponse.datas?.id ?? "")
            this.injected.callbackChange("fournisseur", authResponse.datas ?? "")
        }

    }


    async onChangeEditor(data) {
        await this.setState({
            nom: "",
            modeSearch: true,
            search: data.nom,
            first_name: "",
            last_name: "",
            commentaire: "",
            email: "",
            phone: "",
            address: "",
            city:"",
            state: "",
            postal_code: "",
            account_number: "",
            siteweb: ""
        });
    }


    setSuggestions(fournisseur) {
        return (
            <Autocomplete
                value={fournisseur}
                options={this.injected.rootStore.fournisseurStore.fournisseurs}
                getOptionLabel={(option) => {
                    if (option?.id) {
                        return "#" + option?.id + " " + option?.nom
                    } else {
                        return ""
                    }
                }}
                style={{width: 250}}
                onChange={(event, newValue) => {
                    this.selection(newValue);
                }}
                selectOnFocus
                renderInput={(params) => <TextField {...params}
                                                    margin="normal"
                                                    label="Choisir un fournisseur"
                                                    variant="outlined"/>}
            />)
    }

    setInformation(fournisseur) {
        if (fournisseur !== "" || fournisseur !== undefined) {
            return (<div style={{fontStyle: "italic"}}>
                <div></div>
                <div> {fournisseur?.nom} </div>
                <div> {fournisseur?.first_name} {fournisseur?.last_name} </div>
                <div> {fournisseur?.address} </div>
                <div> {fournisseur?.postal_code} {fournisseur?.state} {fournisseur?.city} </div>
                <div> {fournisseur?.email} </div>
                <div> {fournisseur?.phone} </div>
                <div> {fournisseur?.website} </div>
            </div>);
        }

    }


    selection(fournisseur) {
        this.setState({
            search: "",
            idF: fournisseur?.id
        })
        this.injected.callbackChange("fournisseur_id", fournisseur?.id ?? "")
        this.injected.callbackChange("fournisseur", fournisseur ?? "")
    }

    async edit(id) {
        let authResponse = await this.injected.rootStore.fournisseurStore.find(this.state.idF !== "" ? this.state.idF : id)
        await this.onChangeEditorData(authResponse)
    }


    async onChangeEditorData(data) {
        await this.setState({
            idF: data.id,
            modeSearch: !this.state.modeSearch,
            nom: data.nom,
            first_name: data.first_name,
            last_name: data.last_name,
            commentaire: data.commentaire,
            email: data.email,
            phone: data.phone,
            address: data.address,
            city: data.city,
            state: data.state,
            postal_code: data.postal_code,
            account_number: data.account_number,
            siteweb: data.siteweb
        });
    }

    setTitle(fournisseur) {
        if (this.state.modeSearch) {
            return (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", color: "#20a8d8"}}>
                    <h5 className="mr-2"> Chercher un fournisseur</h5>
                    <Button className="mr-1" style={{marginLeft: "auto"}} color="success"
                            onClick={e => this.setState({modeSearch: !this.state.modeSearch})}>
                        <i className="fa fa-plus-circle" aria-hidden="true"/>
                    </Button>
                    <Button style={{marginLeft: "auto"}} color="warning"
                            onClick={e => this.edit(fournisseur?.id)}>
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                    </Button>
                </div>
            )
        } else {
            return (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <h5 className="mr-2"> Créer un fournisseur</h5>
                    <Button style={{marginLeft: "auto"}} color="warning"
                            onClick={e => this.setState({modeSearch: !this.state.modeSearch})}>
                        <i className="fa fa-search" aria-hidden="true"/>
                    </Button>
                </div>)
        }
    }


    setMode(fournisseur) {
        if (this.state.modeSearch) {
            return (
                <CardBody>
                    <CardTitle style={{fontWeight: "bold"}}>
                        {this.setSuggestions(fournisseur)}
                    </CardTitle>
                    {this.setInformation(fournisseur)}
                </CardBody>

            )
        } else {
            return (<CardBody>
                <AddFournisseurs
                    nom={this.state.nom}
                    id={this.state.idF}
                    first_name={this.state.first_name}
                    last_name={this.state.last_name}
                    commentaire={this.state.commentaire}
                    email={this.state.email}
                    phone={this.state.phone}
                    address={this.state.address}
                    city={this.state.city}
                    state={this.state.state}
                    postal_code={this.state.postal_code}
                    account_number={this.state.account_number}
                    siteweb={this.state.siteweb}
                    onCallbackChange={this.onChangeFournisseur}
                    onCallbackSubmit={this.onSubmitFournisseur}
                />
            </CardBody>)
        }
    }

    render() {
        const {fournisseur} = this.props

        return (
            <div className="bg-transparent mt-1">

                <Card style={{minWidth: "20em"}}>
                    <CardHeader>
                        {this.setTitle(fournisseur)}
                    </CardHeader>
                    {this.setMode(fournisseur)}
                </Card>
            </div>

        )
    }
}));

export default AutoCompleteCommande;
