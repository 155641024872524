import React from "react";
import {inject, observer} from "mobx-react";
import i18n from "i18next";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import 'react-phone-input-2/lib/style.css'
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import styles from "../../Annuaire/Prestation/Prestation.module.css";
import configuration from "../../../../configuration/configuration";
import htmlToDraft from 'html-to-draftjs';
import EditorWord from "../../../../components/Akitatek/EditorWord";

const UpdateFiche = inject("rootStore")(observer(class UpdateFiche extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            title: "",
            id: "",
            editorState: EditorState.createEmpty(),
        };

        this.onSubmit.bind(this);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des fiches", "Aide à la réparation", "devis.jpg")
    }


    componentDidMount() {
        if (this.injected.match.params.id && this.injected.match.params.id !== "add") {
            this.onRouteChanged()
        }
    }

    async onRouteChanged() {
        let authResponse = await this.injected.rootStore.ficheStore.find(this.injected.match.params.id)
        await this.onChangeEditor(authResponse)
    }

    async onChangeEditor(data) {
        let editorState;
        if (data.content) {
            let blocksFromHTML = htmlToDraft(data.content);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            editorState = EditorState.createWithContent(contentState);
        } else {
            editorState = EditorState.createEmpty()
        }
        await this.setState({
            id: data.id,
            editorState: editorState,
            title: data.title,
        });
    }

    async onSubmit(e) {
        e.preventDefault()
        let data = {
            id: this.state.id,
            title: this.state.title,
            content: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        }
        let authResponse;
        if (this.state.id) {
            authResponse = await this.injected.rootStore.ficheStore.update(data)
        } else {
            authResponse = await this.injected.rootStore.ficheStore.create(data)
        }

        if (authResponse?.status === "success") {
            await this.onChangeEditor(authResponse.data)
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState:
            editorState,
        });
    };


    onChange(e) {
        const name = e.target.name
        this.setState({[name]: e.target.value});
    }

    show() {
        return (
            <Row>
                <Form style={{width: "100%"}}
                      role="form"
                      id="myForm"
                      onSubmit={e => this.onSubmit(e)}
                >
                    <div>
                        <Row className="pl-3 pr-3">
                            <Col>
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                    >
                                        Titre de la page
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="title"
                                        value={this.state.title || ''}
                                        onChange={e => this.onChange(e)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Col>
                            <EditorWord editorState={this.state.editorState}
                                        onEditorStateChange={this.onEditorStateChange}/>
                        </Col>
                    </div>
                    <div className="pl-lg-4" style={{textAlign: "center"}}>
                    <Row>
                        <Col>
                            <Button color="success" type="submit">
                                {i18n.t('button.submit')}
                            </Button>
                        </Col>
                    </Row>
                </div>
                </Form>
            </Row>)
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <Card className="shadow">
                        <CardHeader className="bg-transparent" style={{display: "flex", color: "#20a8d8"}}>
                            <h4 className="mb-0"> Modifier la page {this.state.title}</h4>
                        </CardHeader>
                        <CardBody>
                            {this.show()}
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}));

export default UpdateFiche;
