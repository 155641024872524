import {CardBody, Input, InputGroup, InputGroupAddon, Row} from "reactstrap";
import {Col} from "react-bootstrap";
import Label from "reactstrap/lib/Label";
import FormGroup from "reactstrap/lib/FormGroup";
import ErrorBoundary from "../../../../components/Akitatek/Error/ErrorBounday";
import AutoCompleteSearch from "../../../../components/Akitatek/Search/AutoCompleteSearch";
import React from "react";
import {inject, observer} from "mobx-react";
import InputTitle from "../../../../components/Akitatek/Form/InputTitle";
import moment from "moment";
import {TextareaAutosize} from "@material-ui/core";

const FormulairePriseEnChargeStepOne = inject("rootStore")(observer(class StepOne extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props);
    }

    //Refresh
    onChange(e) {
        this.injected.callbackOnChange(e.target.name, e.target.value)
    };

    onChangePresta(e) {
        this.injected.callbackOnChangePresta(e.target.name, e.target.value)
    };

    //Refresh
    callbackUser = (childData) => {
        this.injected.callbackOnChange("user", childData)
    };

    render() {
        const {title, suivi, description, online, status, user, id, description_client, created_at, date_arrived, date_end, date_accepted, prestations, description_tech, total} = this.props

        return (
            <>
                <CardBody>
                    <Col>
                        <InputTitle
                            placeholder="Titre (description courte)"
                            name="title"
                            value={title}
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                        />


                        <Row className="ml-2 mr-2" style={{justifyContent: "space-between"}}>
                            <Col form className="mr-4">
                                {id && prestations?.map((presta, key) => {
                                    return (<Row className={"mb-2"}>
                                        <Col  md={2} >
                                           Prestation {presta.title} :
                                        </Col>
                                        <Col md={4} >
                                            <InputGroup>
                                                <Input type="number"
                                                       name={key}
                                                       value={presta.pivot.total_actual}
                                                       onChange={(e) => {
                                                           this.onChangePresta(e)
                                                       }}

                                                >
                                                </Input>
                                                <InputGroupAddon addonType="append">€ TTC</InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                    </Row>)
                            })}
                                 <Row className={"mb-2"}>
                                    <Col  md={2} >
                                       Autre prix:
                                    </Col>
                                    <Col md={4} >
                                        <InputGroup>
                                            <Input type="text"
                                                   value={total}
                                                   name="total"
                                                   onChange={(e) => {
                                                       this.onChange(e)
                                                   }}
                                            >
                                            </Input>
                                            <InputGroupAddon addonType="append">€ TTC</InputGroupAddon>
                                        </InputGroup>

                                    </Col>
                                </Row>

                                {online.toString() === "1" && id && description_client && (
                                    <Row form>
                                        <Col md={2}>
                                            <Label for="exampleEmail">Description laissée par le
                                                client</Label>
                                        </Col>
                                        <Col>
                                             {description_client}
                                        </Col>
                                    </Row>)}


                                <Row form>
                                    <Col md={2}>
                                        <Label for="exampleEmail">Description pour la prise en charge du
                                            client ou détails acceptation ou refus pour internet (VISIBLE) </Label>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <TextareaAutosize
                                                minRows={5}
                                                onChange={(e) => {
                                                    this.onChange(e)
                                                }}
                                                name="description_tech"
                                                defaultValue={description_tech}
                                                placeholder="Pour le client..."
                                                style={{ width: "100%"}}

                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={2}>
                                        <Label for="exampleEmail">Description longue par le technicien (secrète) -
                                            Tests </Label>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <TextareaAutosize
                                                minRows={3}
                                                onChange={(e) => {
                                                    this.onChange(e)
                                                }}
                                                name={"description"}
                                                defaultValue={description}
                                                placeholder="Qu'avons nous en face des yeux?..."
                                                style={{ width: "100%"}}

                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={2}>
                                        <Label for="exampleEmail">Status</Label>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <FormGroup>
                                                <ErrorBoundary>
                                                    <Input type="select"
                                                           name="status"
                                                           value={status}
                                                           onChange={(e) => {
                                                               this.onChange(e)
                                                           }}

                                                    >
                                                        <option value={"DRAFT"}>BROUILLON</option>
                                                        <option value={"ARRIVED"}>ARRIVEE</option>
                                                        <option value={"DIAGNOSTIC"}>DIAGNOSTIC</option>

                                                        {online === "1" && (
                                                            <option value={"VALIDATE"}>VALIDER</option>
                                                        )}

                                                        {online.toString() === "1" && (
                                                            <option value={"SUBMIT"}>SOUMIS</option>
                                                        )}

                                                        {online.toString() === "1" && (
                                                            <option value={"SUBMIT"}>REFUSER</option>
                                                        )}

                                                        {online.toString() === "1" && (
                                                            <option value={"EXPEDIATE"}>EXPEDIEE</option>
                                                        )}
                                                        {online.toString() === "1" && (
                                                            <option value={"EXPIRED"}>EXPIREE</option>
                                                        )}
                                                        <option value={"WAITING_DEVIS"}>EN ATTENTE DE DEVIS</option>
                                                        <option value={"WAITING_REPAIR"}>EN ATTENTE DE REPARATION
                                                        </option>
                                                        <option value={"WAITING_PAID"}>EN ATTENTE DU PAIEMENT
                                                        </option>
                                                        <option value={"WAITING_CLIENT"}>EN ATTENTE REPONSE CLIENT
                                                        </option>
                                                        <option value={"WAITING_PIECES"}>EN ATTENTE DE PIECES
                                                        </option>
                                                        <option value={"REPARATION"}>REPARATION EN COURS</option>

                                                        <option value={"TESTS"}>TESTS QUALITES</option>
                                                        <option value={"TO_GET_BACK_SUCESSFULLY"}> A RECUPERER ET
                                                            PAYER
                                                        </option>
                                                        <option value={"TO_GET_BACK"}>A RECUPERER SANS PAYER (ECHEC)
                                                        </option>
                                                        <option value={"TO_SEND_BACK"}>A RENVOYER</option>
                                                        <option value={"OVER"}>TERMINE AVEC SUCCES</option>
                                                        <option value={"CLOT"}> CLOT</option>
                                                    </Input>
                                                </ErrorBoundary>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={2}>
                                        <Label for="exampleEmail">Option</Label>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <FormGroup>
                                                <Input type="select"
                                                       name="online"
                                                       value={online}
                                                       onChange={(e) => {
                                                           this.onChange(e)
                                                       }}

                                                >
                                                    <option value={"0"}>Magasin</option>
                                                    <option value={"1"}>Internet</option>
                                                </Input>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {online.toString() === "1" && !id && (
                                    <Row>
                                        <Col md={2}>
                                            <Label> Signature</Label>
                                        </Col>
                                        <Col md={4}>
                                            <Input type="select"
                                                   name="suivi"
                                                   value={suivi}
                                                   onChange={(e) => {
                                                       this.onChange(e)
                                                   }}

                                            >
                                                <option value={"sans signature"}>sans signature</option>
                                                <option value={"avec signature"}>avec signature</option>
                                            </Input>
                                        </Col>
                                    </Row>
                                )}

                                {id && online.toString() === "1" && (
                                    <Row>
                                        <Col md={2}>
                                            <Label> Date création </Label>
                                        </Col>
                                        <Col>
                                            {moment(created_at).format("LLLL")}
                                        </Col>
                                    </Row>)}

                                {id && online.toString() === "1" && (
                                    <Row>
                                        <Col md={2}>
                                            <Label> Date d'acceptation </Label>
                                        </Col>
                                        <Col>
                                            {date_accepted && (
                                                moment(date_accepted).format("LLLL")
                                            )}
                                        </Col>
                                    </Row>)}

                                {id && (
                                    <Row>
                                        <Col md={2}>
                                            <Label> Date d'arrivée </Label>
                                        </Col>
                                        <Col>
                                            {moment(date_arrived).format("LLLL")}
                                        </Col>
                                    </Row>)}

                                {id && (
                                    <Row>
                                        <Col md={2}>
                                            <Label> Date de fin </Label>
                                        </Col>
                                        <Col>
                                            {date_end && (
                                                moment(date_end).format("LL")
                                            )}
                                        </Col>
                                    </Row>)}

                            </Col>
                            <Row>
                                <AutoCompleteSearch
                                    callbackUser={this.callbackUser}
                                    myStatus={status}
                                    user={user}
                                    store={this.injected.rootStore.prisenchargeStore} status="OVER" status2="CLOT"/>

                            </Row>
                        </Row>
                    </Col></CardBody>
            </>
        );
    }
}));


export default FormulairePriseEnChargeStepOne;


