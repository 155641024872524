import React from "react";
import {inject, observer} from "mobx-react";
import ChoixElement from "./ChoixElement";


const PickProduct = (inject("rootStore")(observer(class PickProduct extends React.Component {


    constructor(props, context) {
        super(props, context);
    }

    get injected() {
        return this.props;
    }


    async onBackMarque() {
        this.injected.reload("clean")
    }

    async onBackProduct() {
        this.injected.reload("marque", this.props.marque)
    }


    async onBack() {
        this.injected.callbackOnChange("typeAddMachine", "none")
    }

    //Callback
    callbackOnChange = (name, value) => {
        this.injected.callbackOnChange(name, value)
    }

    callback = async (childData, name) => {
        this.injected.callback(childData, name)
    };


    render() {
        const {selectMarque, selectProduct, selectPrestation, products, marques, prestations, marque, product} = this.props

        return (
            <>


                {selectMarque && (
                    <ChoixElement datas={marques}
                                  text={"Choisir la marque"}
                                  type={"marque"}
                                  ancre={"marqueCard"}
                                  onBack={e => this.onBack()}
                                  reload={this.injected.reload}
                                  callbackOnChange={this.injected.callbackChange}
                    />
                )}


                {selectProduct && (
                    <ChoixElement datas={products}
                                  type={"produit"}
                                  ancre={"productCard"}
                                  text={"Sélectionner le Produit " + marque?.nom}
                                  onBack={e => this.onBackMarque()}
                                  reload={this.injected.reload}
                                  sendDataBack={marque}
                                  callbackOnChange={this.injected.callbackChange}
                    />)}

                {selectPrestation && (
                    <ChoixElement datas={prestations}
                                  type={"prestation"}
                                  ancre={"prestationCard"}
                                  sendDataBack={product}
                                  text={"Sélectionnez la prestation de " + marque?.nom + " " + product?.nom}
                                  onBack={e => this.onBackProduct()}
                                  callbackOnChange={this.injected.callbackChange}
                                  reload={this.injected.reload}

                    />)}
            </>
        );
    }
})));

export default PickProduct;
