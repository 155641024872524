import {CardBody, Input, Row} from "reactstrap";
import {Col} from "react-bootstrap";
import React from "react";
import {inject, observer} from "mobx-react";
import InputText from "../../../../components/Akitatek/Form/InputText";
import Label from "reactstrap/lib/Label";

const FormOption = inject("rootStore")(observer(class FormOption extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props);
    }

    //Refresh
    onChange(e) {
        this.injected.callbackOnChange(e.target.name, e.target.value)
    };


    //Refresh
    callbackUser = (childData) => {
        this.injected.callbackOnChange("user", childData)
    };

    render() {
        const {suivi_client, suivi_text, suivi, poids} = this.props

        return (
            <>
                <CardBody>
                    <Col>

                        <InputText
                            placeholder="Suivi client"
                            name="suivi_client"
                            value={suivi_client}
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                        />

                        <Row>
                            <Col md={2}>
                                <Label> Signature</Label>
                            </Col>
                            <Col md={4}>
                                <Input type="select"
                                       name="suivi"
                                       value={suivi}
                                       onChange={(e) => {
                                           this.onChange(e)
                                       }}

                                >
                                    <option value={"sans signature"}>sans signature</option>
                                    <option value={"avec signature"}>avec signature</option>
                                </Input>
                            </Col>
                        </Row>

                        <InputText
                            placeholder="Poids du colis en kg"
                            name="poids"
                            type={"number"}
                            value={poids}
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                        />


                        <InputText
                            placeholder="Suivi AkitaTek"
                            name="suivi_text"
                            value={suivi_text}
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                        />

                    </Col>
                </CardBody>
            </>
        );
    }
}));


export default FormOption;


