import React from 'react';
import {inject, observer} from "mobx-react";
import 'moment/locale/fr';
import Button from "reactstrap/lib/Button";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";


const PrintImpression = inject("rootStore") (observer( class PrintImpression extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props)
        this.state = {
            download: "all",
            printerId: this.injected.rootStore.printerStore.datas[0]?.id,
            showOtherPrinter: false
        }
    }

    async componentDidMount() {
        await this.injected.rootStore.printerStore.getDatas()
    }


    render() {
        const {api, type, datas, id, online} = this.props
        return (

            <div>
                    <Card>
                        <CardBody>
                            <h4>Imprimer les étiquettes</h4>
                            {datas.length > 0 && (
                                <FormGroup>
                                <Label for="exampleSelect">Choisir l'étiquettes à imprimer</Label>
                                <Input type="select" name="select" id="exampleSelect"
                                       value={this.state.download}
                                       onChange={ e => {
                                           this.setState({download: e.target.value})
                                       }}
                                >

                                    {datas.length !== 0 && (
                                        <option value={"all"}>Toutes</option>
                                    )}
                                    {datas.map((data)=> {
                                        return (
                                            <option value={data?.id}>
                                                {type === "pec"  && (
                                                    "Machine " + data?.appareil?.marque?.nom + " " + data?.appareil?.nom + " - Casier: " + (data?.pivot?.casier?.name ? data?.pivot?.casier.name  : "")
                                                )}

                                                {type === "stock"&& (
                                                    data.pid + " - "  + data.fournisseur?.nom + "-Qte: " + data.stock
                                                )}
                                                </option>

                                        )})}
                                </Input>
                            </FormGroup>
                            )}

                            <FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" onChange={e => this.setState({showOtherPrinter: !this.state.showOtherPrinter})}/>{' '}
                                        Choisir une autre imprimante
                                    </Label>
                                </FormGroup>

                                {this.state.showOtherPrinter && (
                                <Input type="select" name="select" id="exampleSelect"
                                       value={this.state.printerId}
                                       onChange={ e => {
                                           this.setState({printerId: e.target.value})
                                       }}
                                >
                                    {   this.injected.rootStore.printerStore.datas?.map((printer, key)=> {
                                        return (
                                            <option key={key} value={printer?.id}>{printer.name}</option>

                                        )})}
                                </Input>)}
                            </FormGroup>
                            {this.injected.rootStore.authStore.modules['print'] === 1 && (
                            <Button color="primary"
                                    onClick={e => api.downloadPdf(e, id, this.state.download,this.state.showOtherPrinter ? this.state.printerId : "")}
                            >
                                Imprimer
                            </Button>)}

                            <Button
                                className={"ml-2"}
                                color="success"
                                onClick={e => api.openPdf(e, this.state.download, id)}>
                                Visualiser
                            </Button>
                        </CardBody>
                    </Card>

                <Card>
                    <CardBody>
                        <h4>Prise en charge à signer</h4>
                            <div>
                                {this.injected.rootStore.authStore.modules['print'] === 1 && (
                                    <Button color="primary"
                                            onClick={e => online === "1" ? this.injected.rootStore.prisenchargeStore.openPdfPecOnline(e, id, "1") : this.injected.rootStore.prisenchargeStore.openPdfPec(e, id, "1")}>
                                        Imprimer
                                    </Button>)}
                                <Button
                                    className={"ml-2"}
                                    color="success"
                                    onClick={e => online === "1" ? this.injected.rootStore.prisenchargeStore.openPdfPecOnline(e, id, "0") : this.injected.rootStore.prisenchargeStore.openPdfPec(e, id, "0")}>
                                    Visualiser
                                </Button>
                            </div>
                    </CardBody>
                </Card>
            </div>

        );
    }
}));

export default PrintImpression

