import React from "react";
import RowTD from "./RowTD";
import RowThLink from "./RowThLink";
import DateRow from "./Date";
import UserRow from "./UserRow";
import StatusRow from "./StatusRow";
import PhoneRow from "./PhoneRow";
import ActionPencilLink from "./ActionsPencilLink";
import MapRow from "./MapRow";
import MagasinOuInternet from "./MagasinOuInternet";
import AssetRow from "./AssetRow";
import PrestationRow from "./PrestationRow";
import EnabledRow from "./isVisibleRow";

function TypeRow({element, data, rootStore, children}) {
    if ((element?.key && !data[element.key]) && (element?.sub && !data[element.sub]?.[element.key])
        && (element?.before && !data[element.before]?.[element.sub]?.[element.key])
    ) {
        return <td></td>
    }
    if (element?.action === "tr") {
        return <RowTD alerte={data[element.alerte]}
                      title={data[element.sub]?.[element.last]?.[element.key] ?? data[element.sub]?.[element.key] ?? data[element.key]}
                      action={element.title}/>
    }

    if (element?.action === "email" || element?.action === "phone") {
        return <th className="text-center"><PhoneRow
            title={data[element.before]?.[element.sub]?.[element.key] ?? data[element.sub]?.[element.key] ?? data[element.key]}
            action={element.key}/></th>
    }

    if (element?.action === "map") {
        return <MapRow element={data[element.key]} href={element.href} type={element.type}/>
    }

    if (element?.action === "thLink") {

        return <RowThLink title={data[element.key]} data={data} href={element.href}/>
    }

    if (element?.action === "date") {
        return <DateRow title={data[element.key]}/>
    }

    if (element?.action === "prestation") {
        return <PrestationRow data={data[element.key]}/>
    }

    if (element?.action === "user") {
        return <UserRow data={data[element.key]}/>
    }

    if (element?.action === "enabled") {
        return <EnabledRow data={data[element.key]}/>
    }

    if (element?.action === "asset") {
        return <AssetRow data={data}/>
    }

    if (element?.action === "status") {
        return <StatusRow title={data[element.key]} enumStatus={data.statusEnum ?? data.status} rootStore={rootStore}/>
    }

    if (element?.action === "actionpencillink") {
        return <ActionPencilLink title={data[element.key]} data={data} href={element.href}/>
    }

    if (element?.action === "magasinouinternet") {
        return <MagasinOuInternet data={data[element.key]}/>
    }

    return <React.Fragment> {children}</React.Fragment>
}

export default TypeRow;
