import configuration from "../../configuration/configuration";
import styles from "./PECBoard.module.css";
import {Animated} from "react-animated-css";
import React from "react";
import {inject, observer} from "mobx-react";
import Moment from "react-moment";
import moment from 'moment/min/moment-with-locales';

moment.locale('fr')

// Sets the moment instance to use.
Moment.globalMoment = moment;

// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'fr';

const Horloge = (inject("rootStore")(observer(class Horloge extends React.Component {

    constructor(props, context) {
        super(props, context);


        this.state = {
            animate: true,
            transitionTime: 20000,
            isVisible: true,
            timer: null,
            logo: this.injected.rootStore.authStore.logo ?? ""
        }
        this.countingSecond = this.countingSecond.bind(this)

    }


    countingSecond() {
        this.setState({
            animate: !this.state.animate,
            time: moment().format('LTS'),
        })
    }


    componentDidMount() {
        this.setState({timer: window.setInterval(this.countingSecond, 1000)})
    }


    componentWillUnmount() {
        window.clearInterval(this.state.timer)
        this.setState({timer: null})

    }

    get injected() {
        return this.props;
    }


    render() {
        return (
            <>
                <nav className="navbar navbar-light bg-light">
                    <a className="navbar-brand p-sm-4 p-4" href="/">
                        <img src={configuration.API + "/magasin/svg/" + this.state.logo} alt="logo"
                             style={{height: '75px', margin: '-15px'}}/>
                    </a>
                    <span className="navbar-brand">
          <div style={{'color': '#fff', 'textShadow': '0 0 6px #ff0'}} className={styles.digitalClock}
               id="mytarget">{this.state.time}</div>
      </span>
                    <span className="navbar-brand">

            {(this.injected.rootStore.authStore.isLoggedDash && this.injected.rootStore.authStore.isLoggedEcho) ? (
                <Animated animationIn="fadeIn" animationOut="fadeOut"
                          isVisible={this.state.animate}>
                    <div className="alert alert-success blinkText m-0" role="alert">
                        Connecté au serveur d'affichage
                    </div>
                </Animated>
            ) : (
                <div className="alert alert-danger blinkText m-0" role="alert">
                    Déconnecté du serveur d'affichage
                </div>
            )}
      </span>
                </nav>
            </>
        );
    }
})));

export default Horloge;
