import React from "react";
import {inject, observer} from "mobx-react";
import AddMachineUser from "./AddMachineUser";
import TableMachine from "./TableMachine";


const UpdateMachine = inject("rootStore")(observer(class UpdateMachine extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }

    //Callback
    callbackOnChange = (name, value) => {
        this.injected.callbackOnChange(name, value)
    }

    render() {
        const {appareils, userId, serial, appareil, defauts, marque, code} = this.props

        return (
            <>
                <div>
                    <AddMachineUser id={userId}
                                    onCallbackSubmit={this.injected.onCallbackSubmit}
                                    callbackOnChange={this.callbackOnChange}
                                    serial={serial}
                                    appareil={appareil}
                                    defauts={defauts}
                                    marque={marque}
                                    code={code}

                    />

                    <TableMachine
                        callbackOnChangeList={this.injected.callbackOnChangeList}
                        onCallbackAction={this.injected.onCallbackAction}
                        appareils={appareils}
                    />
                </div>
            </>
        );
    }

}));

export default UpdateMachine;
