import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import {CardBody, CardFooter, Container} from "reactstrap";
import Card from "reactstrap/lib/Card";
import {inject, observer} from "mobx-react";
import styles from './Stepper.module.css'
import getRoutes from "../../../../components/Akitatek/getRoutes";
import SearchOrAdd from "./SearchOrAdd";
import FormulairePriseEnChargeStepOne from "./FormulairePriseEnChargeStepOne";
import ColorlibStepIcon from "./ColorlibStepIcon";
import {ContentState, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import PrintImpression from "../../Printer/PrintImpression";

const Mousetrap = require("mousetrap");

const AddPriseEnChargeStepper = inject("rootStore")(observer(class AddPriseEnChargeStepper extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Prises En Charge", "Gérer les Prises En Charge", "priseencharge.png", this.injected.location.aboutProps?.subCategory ?? "", this.injected.location.aboutProps?.user)

        this.state = {
            activeStep: parseInt(this.injected.match.params.type ?? 0),
            steps: this.getSteps(),
            user: this.injected.location.aboutProps?.user ?? "",
            priseencharge: [],
            status: "ARRIVED",
            total: 0,
            title: "",
            online: "0",
            description_tech: "",
            description: "",
            suivi: "sans signature",
            userApp: [],
            appareils: [],
            prestas: [],
            typeAddMachine: "none",
            loading: false
        }
    }


    //get the values of commande by ID
    async onRouteChanged() {
        let id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (id) {
            await this.onReload(id)
        }
    }

    async onReload(id) {
        this.priseencharge = await this.injected.rootStore.prisenchargeStore.getPriseEnChargeForId(id);

        if (this.priseencharge) {
            if (this.priseencharge.description === null) {
                this.priseencharge.description = ""
            }
            let editorState = EditorState.createEmpty()

            if (this.priseencharge?.comment !== null) {
                let blocksFromHTML = htmlToDraft(this.priseencharge?.comment);
                const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
                editorState = EditorState.createWithContent(contentState);
            }

            this.setState({
                priseencharge: this.priseencharge,
                notes: this.priseencharge?.notes,
                description_by_tech: this.priseencharge.description_by_tech,
                userApp: this.priseencharge?.user_app,
                appareils: this.priseencharge?.appareils ?? [],
                comment: editorState,
                total: this.priseencharge?.total,
                status: this.priseencharge.status ?? "DRAFT",
                title: this.priseencharge.title ?? "",
                online: this.priseencharge?.online?.toString() ?? "0",
                user: this.priseencharge?.user ?? [],
                description_tech: this.priseencharge.description_tech ?? "",
                description: this.priseencharge.description ?? "",
                suivi: this.priseencharge.suivi ?? "sans signature",
                pec_lignes: this.priseencharge?.pec_lignes,
                prestas: this.priseencharge?.prestas ?? [],
                poids: this.priseencharge?.poids,
            })
        }
    }


    //check if the values are up to date
    async componentDidMount() {
        if (!this.injected.location.aboutProps?.user) {
            if (this.injected.rootStore.savedDatasStore.user) {
                this.setState({
                    user: this.injected.rootStore.savedDatasStore.user
                })
            }
        }

        if (this.injected.match.params.id) {
            await this.onRouteChanged()
        }

        if (!this.injected.match.params.type) {
            this.injected.history.replace(getRoutes("Créer une Prise En Charge", this.injected.match.params.id, "0"));
        }

        Mousetrap.bind("tab", e => this.setState((state) => {
            this.injected.history.replace(getRoutes("Créer une Prise En Charge", this.state.priseencharge?.id, state.activeStep + 1));
            if (this.getSteps().length === state.activeStep) {
                this.injected.history.replace(getRoutes("Créer une Prise En Charge", this.state.priseencharge?.id, "0"));
                return {activeStep: 0}
            } else {
                this.injected.history.replace(getRoutes("Créer une Prise En Charge", this.state.priseencharge?.id, state.activeStep + 1));
                return {activeStep: state.activeStep + 1}
            }
        }));
    }


    //Callback
    callbackOnChange = (name, value) => {
        this.setState({[name]: value});
    }

    callbackRefresh = async (childData) => {
        this.setState({
            priseencharge: childData,
        })
    };

    //actions
    async onSubmit(index = 0) {
        this.setState({
            loading: true
        })
        let data = {
            suivi: this.state.suivi,
            user_id: this.state.user?.id,
            title: this.state.title ?? "Prise en charge",
            status: this.state.status,
            description_tech: this.state.description_tech,
            priority: this.state.priority,
            poids: this.state.poids,
            prise_en_charge_id: this.state.priseencharge.id ?? "",
            description: this.state.description,
            online: this.state.online,
            total: this.state.total,
        }

        let authResponse = await this.injected.rootStore.prisenchargeStore.savePriseEnCharge(data)

        if (authResponse?.status === "success") {
            this.setState({
                priseencharge: authResponse.datas,
                user: authResponse.datas.user,
                activeStep: index,
                userApp: authResponse.datas?.user_app,
                appareils: authResponse.datas?.appareils,
            })

            this.injected.history.replace(getRoutes("Créer une Prise En Charge", authResponse.datas?.id, index.toString()));
            this.injected.rootStore.savedDatasStore.setUser(this.state.priseencharge?.user)
        }

        this.setState({
            loading: false
        })
    }


    async finalStep() {
        await this.onSubmit()
        this.injected.history.replace(getRoutes("Gérer une Prise En Charge", this.state.priseencharge?.id, "0"));
    }

    async onDeleteUserMachine(index) {
        let appareil = this.state.appareils[index];

        let data = {
            user_appareil_id: appareil.pivot?.user_annuaire_appareil_id ?? "",
            prise_en_charge_id: this.state.priseencharge?.id,
        }
        let authResponse = await this.injected.rootStore.prisenchargeStore.deleteUserMachine(data);

        if (authResponse?.status === "success") {
            this.setState({appareils: authResponse.datas, userApp: authResponse.userApp});
        }
    }


    async onUpdateUserMachine(index) {
        let appareil = this.state.appareils[index];

        let authResponse
        let data = {
            accessoires: appareil.pivot?.accessoires ?? "",
            defauts: appareil.defauts ?? "",
            serial: appareil.serial ?? "",
            user_appareil_id: appareil.pivot?.user_annuaire_appareil_id ?? "",
            prise_en_charge_id: this.state.priseencharge?.id,
            code: appareil.code,
            casier_id: appareil.pivot?.casier?.id
        }

        authResponse = await this.injected.rootStore.prisenchargeStore.updateUserMachine(data)

        if (authResponse?.status === "success") {
            this.setState({appareils: authResponse.datas, userApp: authResponse.userApp});
        }
    }

    onCallbackAction = (index, action) => {
        if (action === "update") {
            this.onUpdateUserMachine(index)
        } else {
            this.onDeleteUserMachine(index)
        }
    }

    callbackOnChangeList = (index, name, value) => {
        let appareils = [...this.state.appareils];

        if (name === "accessoires" || name === "casier") {
            appareils[index]['pivot'][name] = value
        } else {
            appareils[index][name] = value
        }
        this.setState({appareils});
    }


    //Deal witch change
    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <FormulairePriseEnChargeStepOne
                    user={this.state.user}
                    id={this.state.priseencharge.id}
                    callbackOnChange={this.callbackOnChange}
                    status={this.state.status}
                    online={this.state.online}
                    title={this.state.title}
                    suivi={this.state.suivi}
                    total={this.state.total}
                    description_tech={this.state.description_tech}
                    description={this.state.description}
                    prestations={this.state.prestas}
                    description_by_tech={this.state.description_by_tech}
                />
            case 1:
                return <SearchOrAdd
                    userApp={this.state.userApp ?? []}
                    appareils={this.state.appareils}
                    callbackOnChange={this.callbackOnChange}
                    user={this.state.user}
                    id={this.state.priseencharge?.id}
                    typeAddMachine={this.state.typeAddMachine}
                    onCallbackAction={this.onCallbackAction}
                    callbackOnChangeList={this.callbackOnChangeList}
                    message={this.state.message}/>
            case 2: return <PrintImpression online={this.state.priseencharge?.online}
                                        id={this.state.priseencharge?.id}
                                        datas={this.state.appareils}
                                        api={this.injected.rootStore.prisenchargeStore}
                                        type={"pec"}/>

            default:
                return 'Une erreur est survenue. Désolée pour le dérangement. Merci de réessayer ou de contacter la société ' + this.injected.rootStore.authStore.nameCompany;
        }
    }


    setActiveStep(index, childData) {
        if (this.state.user?.id) {
            this.onSubmit(index)
            this.injected.history.replace(getRoutes("Créer une Prise En Charge", this.state.priseencharge?.id, index.toString()));
        }


    }


    getSteps() {
        return ['Choisir ou créer un client', 'Ajouter les machines', "Impression des étiquettes"];
    }

    render() {
        return (
            <>
                <Container className={"mt-2"} fluid>
                    <Card>
                        <div className={styles.root}>
                            <Stepper activeStep={this.state.activeStep} alternativeLabel>
                                {this.state.steps.map((label, key) => (
                                    <Step key={label}
                                          style={{cursor: "pointer"}}
                                          onClick={e => this.setActiveStep(key)}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <div>
                                <CardBody>
                                    {this.state.activeStep !== this.state.steps.length && (
                                        <div>
                                            {this.getStepContent(this.state.activeStep)}
                                        </div>)}
                                </CardBody>
                                <CardFooter>
                                    {this.state.activeStep !== this.state.steps.length - 1 ? (
                                            <div>
                                                <Button
                                                    disabled={this.state.activeStep === 0 || this.state.loading}
                                                    onClick={e => this.setActiveStep(this.state.activeStep - 1)}
                                                    className={styles.backButton}
                                                >
                                                    Précédent
                                                </Button>
                                                <Button
                                                    disabled={!this.state.user?.id || this.state.loading}
                                                    variant="contained" color="primary"
                                                    onClick={e => this.setActiveStep(this.state.activeStep + 1)}>
                                                    {this.state.activeStep === this.state.steps.length ? 'Terminé' : 'Suivant'}
                                                </Button>
                                            </div>) :
                                        (<div>
                                            <Button
                                                disabled={!this.state.user?.id || this.state.loading}
                                                variant="contained" color="primary"
                                                onClick={e => this.finalStep()}>
                                                Voir
                                            </Button>
                                        </div>)}
                                </CardFooter>
                            </div>
                        </div>
                    </Card>
                </Container>
            </>
        );
    }
}));


export default AddPriseEnChargeStepper;




