import React from "react";
import {inject, observer} from "mobx-react";
import {Input} from "reactstrap";

const HeaderMarque = inject("rootStore")(observer(class HeaderMarque extends React.Component {
    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }

    getLoad(e, data, search) {
        e.preventDefault();

        if (this.props.name === "produit") {
            this.injected.reload("marque", data, search)
        }

        if (this.props.name === "marque") {
            this.injected.reload("mymarque", "", search)
        }

        if (this.props.name === "prestation") {
            this.injected.reload("product", data, search)
        }

        if (this.props.name === "prestationPec") {
            this.injected.reload("product", data, search)
        }
    }


    render() {
        const {search, text, onBack, data, name} = this.props

        return (
            <h4 className="mb-0" style={{display: "flex", alignItems: "center"}}>
                {onBack && (<i onClick={onBack} style={{cursor: "pointer"}}
                               className="fa fa-arrow-left mr-2" aria-hidden="true"/>)}
                <div style={{"width": "30em"}}>  {text}</div>

                {name !== "prestationPec" && (
                    <Input
                        style={{
                            "height": "3em",
                            "width": "50em"
                        }}
                        placeholder={"Rechercher"}
                        type="text"
                        value={search}
                        onChange={(e) => {
                            this.getLoad(e, data, e.target.value)
                        }}/>

                )}

            </h4>

        )
    }
}));


export default HeaderMarque;
