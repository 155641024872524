import React from "react";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {Button} from "react-bootstrap";
import {UncontrolledTooltip} from "reactstrap";
import moment from "moment";
import ActionPencilLink from "../../../components/Akitatek/Table/ActionsPencilLink";


const ActionPriseEnChargeRow = inject("rootStore")(observer(class PriseEnChargeRow extends React.Component {

    data;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        moment.locale('fr');
        this.toggleDanger = this.toggleDanger.bind(this);
    }


    check(title) {
        return (<span
            className={"badge badge-pill badge" + this.injected.rootStore.savedDatasStore.setColor(this.props.data.status).replace("bg", "")}>{title}</span>)
    }

    toggleDanger(event) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning("la prise en charge #" + this.props.data?.id, event => this.onDelete(this.props.data?.id), this.props.data?.status === "DRAFT")
    }


    async onDelete(deleteId) {
        await this.injected.rootStore.prisenchargeStore.delete(deleteId);
    }


    render() {
        const {data} = this.props

        return (
            <>
                <td className={"th-actions"}>
                    <div style={{display: "flex", justifyContent: "space-around"}}>

                        <ActionPencilLink title={{
                            "title": "Actions",
                            "action": "actionpencillink",
                            "href": "Gérer une Prise En Charge",
                            "key": "id",
                            "filter": "id"
                        }} data={data} href={"Gérer une Prise En Charge"}/>

                        {this.injected.rootStore.authStore.modules['print'] === 1 && (
                            <Button color="primary" className="mr-1"
                                    onClick={e => this.injected.rootStore.prisenchargeStore.downloadPdf(e, data.id, "all", "")}
                            >
                                <i className="fa fa-print"/>

                            </Button>)}


                        {data?.status !== "SUBMIT" && (
                            <Button variant="danger" type="button" className="mr-1" onClick={this.toggleDanger}
                                    id={"UncontrolledTooltipExampleSelection" + data.id}>
                                <i className="fa fa-close" aria-hidden="true"/>
                                <UncontrolledTooltip placement="top"
                                                     target={"UncontrolledTooltipExampleSelection" + data.id}>
                                    {data?.status === "DRAFT" ? (
                                        "Supprimée") : ("Cloturée")}
                                </UncontrolledTooltip>
                            </Button>
                        )}
                    </div>
                </td>
            </>
        );
    }

}));

export default withRouter(ActionPriseEnChargeRow);
