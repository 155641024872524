import React from "react";
import {FormGroup, Input} from "reactstrap";
import {Col} from "react-bootstrap";
import styles from "../../../views/admin/Annuaire/Prestation/Prestation.module.css";

function FormControlInput({value, onChange, name, placeholder, type = "text", required = ""}) {
    return <Col lg="6">
        <FormGroup>
            <label
                className={styles.typeField}
                htmlFor="input-city"
            >
                {placeholder}

            </label>
            <Input
                autocomplete="chrome-off"
                className="form-control-alternative"
                id={"input " + name}
                name={name}
                required={required}
                type={type}
                value={value || ''}
                onChange={onChange}
            />
        </FormGroup>
    </Col>
}

export default FormControlInput;
