import React from "react";
import {CardHeader} from "reactstrap";


function StatsCmdClient({stats}) {
    return <CardHeader className={"mb-2"} style={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "start"
    }}>

        <div className="callout callout-info b-t-1 b-r-1 b-b-1 mr-2 ml-2">
            <small className="text-muted">Nombre de commande passés : </small><br/>
            <strong className="h4">{stats?.nb}</strong>
        </div>

        <div className="callout callout-danger b-t-1 b-r-1 b-b-1 mr-2 ml-2">
            <small className="text-muted"> Total des commandes passés : </small> <br/>
            <strong className="h4">{stats?.sum} €</strong>
        </div>

        <div className="callout callout-warning b-t-1 b-r-1 b-b-1 mr-2 ml-2">
            <small className="text-muted">Nombre de commande du mois : </small> <br/>
            <strong className="h4">{stats?.encours}</strong>
        </div>

        <div className="callout callout-primary b-t-1 b-r-1 b-b-1 mr-2 ml-2">
            <small className="text-muted"> Total des commandes du mois: </small> <br/>
            <strong className="h4">{stats?.sumencours} €</strong>
        </div>
    </CardHeader>
}

export default StatsCmdClient;
