import React from "react";
import {Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";

const Fournisseurs = (inject("rootStore")(observer(class Fournisseurs extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des fournisseurs", "Gérer les fournisseurs / Regrouper les commandes", "facture.jpg", this.injected.rootStore.fournisseurStore)
    }

    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericTableAffichage api={this.injected.rootStore.fournisseurStore}
                                           user={false}
                                           filters={this.injected.rootStore.fournisseurStore.defaultFilter}
                                           status={false}
                                           statusHeader={false}
                                           icon={<i className="fa fa-user-secret mr-2" aria-hidden="true"/>}
                                           name={"Fournisseurs "}
                                           type="fournisseurs"/>
                </Container>
            </>
        );
    }
})));

export default Fournisseurs;
