import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardHeader, Container} from "reactstrap";
import Input from "reactstrap/lib/Input";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import styles from "./../CardUser.module.css"

const AddMachineUser = inject("rootStore")(observer(class AddMachineUser extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            card: true
        }

        this.search = this.search.bind(this);
    }

    componentDidMount() {
        this.injected.rootStore.annuaireStore.marqueStore.getAllMarques()
        this.injected.rootStore.annuaireStore.productStore.getProducts(this.state.marque?.id, "")
    }


    search(event) {
        event.preventDefault();
        this.injected.rootStore.annuaireStore.productStore.getProducts(this.state.marque?.id, event.target.value)
    }

    //Refresh
    onChange(e) {
        this.injected.callbackOnChange(e.target.name, e.target.value)
    };

    setTitle() {
        return (
            <div style={{display: "flex", alignItems: "center", fontSize: "1.2em"}}>
                <div className="mr-4 ml-2"> Ajouter une Machine Client</div>
            </div>
        )
    }

    async enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            await this.onSubmit(e)
        }
    }

    disabled(appareil) {
        return !appareil
    }

    //Submit
    onSubmit(e) {
        e.preventDefault()
        this.injected.onCallbackSubmit("machineUser")
    };

    setMode(card, code, defauts, serial, appareil) {
        return (
            <div className={card ? styles.cardFlex : ""}>
                <div className={"item"}>
                    <Input
                        placeholder={"Numéro de série"}
                        onKeyPress={e => this.enter(e)}
                        value={serial}
                        name={"serial"}
                        className={card ? "mr-2" : "mb-2"}
                        type="text"
                        onChange={e => this.onChange(e)}
                    >
                    </Input>
                </div>

                <div className={"item"}>
                    <Input
                        name={"code"}
                        placeholder={"Code"}
                        onKeyPress={e => this.enter(e)}
                        value={code}
                        className={this.state.card ? "mr-2" : "mb-2"}
                        type="text"
                        onChange={e => this.onChange(e)}
                    >
                    </Input>
                </div>
                <div className={"item"}>
                    <Input
                        name={"defauts"}
                        placeholder={"Defauts"}
                        onKeyPress={e => this.enter(e)}
                        value={defauts}
                        className={card ? "mr-2" : "mb-2"}
                        type="textarea"
                        onChange={e => this.onChange(e)}
                    >
                    </Input>
                </div>

                {!card && (
                    <div className={styles.cardFlex}>
                        <Button
                            disabled={this.disabled(appareil)}
                            color={"success"}
                            onClick={e => this.onSubmit(e)}> Ajouter <i className={"fa fa-plus"}/>
                        </Button>
                    </div>)}

                {card && (
                    <div>
                        <Button
                            disabled={this.disabled(appareil)}
                            color={"success"}
                            onClick={e => this.onSubmit(e)}>
                            <i className={"fa fa-plus"}/> </Button>
                    </div>)}
            </div>)
    }

    addMachineUser(marque, appareil) {
        return (<div style={{display: "flex", justifyContent: "center"}}>
            <Autocomplete
                className={"mr-2"}
                value={marque}
                options={this.injected.rootStore.annuaireStore.marqueStore.allMarques}
                getOptionLabel={(option) => {
                    if (option?.id) {
                        return "#" + option?.id + " " + option?.nom
                    } else {
                        return ""
                    }
                }}
                style={{width: 300}}
                onChange={async (event, newValue) => {
                    await this.injected.callbackOnChange("marque", newValue ?? "");
                    this.injected.rootStore.annuaireStore.productStore.getProducts(newValue?.id, "")
                }}
                onInputChange={async (event, newInputValue, reason) => {
                    if (reason === "clear") {
                        await this.setState({
                            appareil: ""
                        })
                    }
                }}
                selectOnFocus
                renderInput={(params) => <TextField {...params}
                                                    margin="normal"
                                                    label="Rechercher une marque"
                                                    variant="outlined"/>}
            />


            <Autocomplete
                value={appareil}
                options={this.injected.rootStore.annuaireStore.productStore.products}
                getOptionLabel={(option) => {
                    if (option?.id) {
                        return "#" + option?.id + " " + option?.nom
                    } else {
                        return ""
                    }
                }}
                style={{width: 300}}
                onChange={async (event, newValue) => {
                    await this.injected.callbackOnChange("appareil", newValue ?? "");
                    await this.injected.callbackOnChange("marque", newValue?.marque ?? "");


                }}
                selectOnFocus
                onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                        this.search(event, newInputValue);
                    }
                }}
                renderInput={(params) => <TextField {...params}
                                                    margin="normal"
                                                    label="Rechercher un produit"
                                                    variant="outlined"/>}
            />
        </div>)
    }

    render() {
        const {serial, defauts, marque, code, card, appareil} = this.props

        return (
            <>

                {card && (
                    <Card>
                        <CardHeader style={{padding: "0.2em"}} className={"bg-primary"}>
                            {this.setTitle()}
                        </CardHeader>
                        {this.addMachineUser(marque, appareil)}
                        {this.setMode(card, code, defauts, serial, appareil)}
                    </Card>
                )}

                {!card && (
                    <Container className="mt-2">
                        {this.addMachineUser(marque, appareil)}
                        {this.setMode(card, code, defauts, serial, appareil)}
                    </Container>
                )}


            </>)
    }
}));

export default AddMachineUser;
