import React from "react";
import {inject, observer} from "mobx-react";
import {Input} from "reactstrap";

const HeaderAnnuaire = inject("rootStore")(observer(class HeaderAnnuaire extends React.Component {
    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }

    getLoad(e, search) {
        e.preventDefault();

        if (this.props.name === "product") {
            this.injected.rootStore.annuaireStore.productStore.search = search
            this.injected.rootStore.annuaireStore.productStore.getProducts()

        }

        if (this.props.name === "marque") {
            this.injected.rootStore.annuaireStore.marqueStore.search = search
            this.injected.rootStore.annuaireStore.marqueStore.getMarques()
        }

        if (this.props.name === "prestations") {
            this.injected.rootStore.annuaireStore.prestationStore.search = search
            this.injected.rootStore.annuaireStore.prestationStore.getPrestation()
        }

    }


    render() {
        const {text, name, search} = this.props

        return (
            <h4 className="mb-0" style={{display: "flex", alignItems: "center"}}>
                <div style={{"width": "30em"}}>  {text}</div>
                    <Input
                        style={{
                            "height": "3em",
                            "width": "50em"
                        }}
                        placeholder={"Rechercher"}
                        type="text"
                        value={search}
                        onChange={(e) => {
                            this.getLoad(e, e.target.value)
                        }}/>
            </h4>

        )
    }
}));


export default HeaderAnnuaire;
