import axios from "axios";
import configuration from "../configuration/configuration";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import {default as i18n} from "i18next";

export default class PrestationAPI {

    async getOptionPrestation() {
        let url = `/api/annuaire/allprestations`;

        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}` + url,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async getAllPrestation() {
        let url = `/api/annuaire/prestations/all`;

        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}` + url,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    async getPrestation(search, appareil = "") {
        let url = `/api/annuaire/prestations?search=${search}&appareil=${appareil}`;

        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}` + url,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    createPrestation(datas) {
        return new Promise(async(resolve, reject) => {
            try {
                datas.annuaire_appareil_id = datas.productLabel?.value

                if(datas.editorState) {
                    datas.description = draftToHtml(convertToRaw(datas.editorState?.getCurrentContent()));
                }

                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/annuaire/prestations`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve({data: response.data.data, status: response.data.status, datas: response.data.datas })
            } catch (error) {
                reject(error)
            }
        })
    }

    createPromotion(datas) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/promotion`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    updatePrestation(datas) {
        return new Promise(async(resolve, reject) => {
            try {
                datas.annuaire_appareil_id = datas.productLabel?.value

                if(datas.editorState) {
                    datas.description = draftToHtml(convertToRaw(datas.editorState?.getCurrentContent()));
                }

                const response = await axios({
                    method: 'PUT',
                    url: `${configuration.API}/api/annuaire/prestations`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve({data: response.data.datas, status: response.data.status, datas: response.data.datas })
            } catch (error) {
                reject(error)
            }
        })
    }

    async deletePrestation(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/annuaire/prestation/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async findPrestationById(id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/annuaire/prestations/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async savePrestaLine(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/annuaire/lines/prestations/prestaLines`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async updatePrestaLine(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/annuaire/lines/prestations/prestaLines`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async deletePrestaLine(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/annuaire/lines/prestations/prestaLines`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

}