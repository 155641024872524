/* Global import */
import {decorate, observable} from 'mobx';
import Api from "../util/api";

/* Custom import */

export default class PecStepperStore {

    marqueId = ""
    appareilId = ""
    search = ""

    constructor(rootStore, annuaireStore) {
        this.rootStore = rootStore
        this.annuaireStore = annuaireStore;
        this.api = new Api();
    }

    async getMarques(search = "")  {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            const authResponse = await this.api.marques.getMarques(search);
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.datas;
        } catch(e) {}
    }

    async getPrestation(search = "")  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.prestations.getPrestation(search, this.appareilId );
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.datas;
        } catch(e) {this.rootStore.savedDatasStore.setLoading(false)}
    }

    async getProducts(marqueId, search = "")  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.products.getProducts(marqueId ? marqueId : this.marqueId, search);
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.datas;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveUserApp(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.priseencharges.saveUserApp(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( message ?? authResponse?.appareil + " ajoutée à la prise en charge", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async deleteUserApp(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.priseencharges.deleteUserApp(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( message ?? authResponse?.appareil + " ajoutée à la prise en charge", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(PecStepperStore, {
    products: observable,
    marques: observable,
    prestations: observable,
    marqueId: observable,
    appareilId: observable,
    user_appareil_pec_id: observable,
    appareil: observable,
    marque: observable,
});