import React from "react";
import ErrorBoundary from "../Error/ErrorBounday";
import TableGeneric from "../Headers/TableGeneric";
import DataRow from "./DataRow";
import {inject, observer} from "mobx-react";
import TypeTable from "./getTypeTable";
import ActionType from "./ActionType";
import {Card, CardBody, CardHeader} from "reactstrap";

const GenericTableAffichageWithMyDatas = inject("rootStore")(observer(class GenericTableAffichageWithMyDatas extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    get injected() {
        return this.props;
    }

    render() {
        let {type, datas} = this.props
        const [titles] = TypeTable(type)
        return (
            <>
                <ErrorBoundary key={"error" + type}>
                    <Card key={"card" + type}>
                        <CardBody>
                            <TableGeneric key={"table" + type}
                                          titles={titles}>
                                {datas?.map(data => {
                                    return <DataRow
                                        titles={titles ? titles : []}
                                        data={data}
                                        key={type + data.id}
                                        actions={<ActionType data={data} type={type}/>}
                                    />
                                })
                                }
                                {(datas?.length === 0) && (
                                    <tr>
                                        <td className={"no_td"}
                                            colSpan={titles?.length}>Aucunes données
                                        </td>
                                    </tr>
                                )}
                            </TableGeneric>
                        </CardBody>
                    </Card>
                </ErrorBoundary>
            </>
        );
    }

}));
export default GenericTableAffichageWithMyDatas;
