import React from "react";
import {inject, observer} from "mobx-react";
import {Card, CardBody, CardHeader} from "reactstrap";
import Form from "reactstrap/lib/Form";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import moment from "moment";

const SuiviCommande = inject("rootStore")(observer(class SuiviCommande extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }

    //Refresh
    onChange(e) {
        this.injected.callbackChange(e.target.name, e.target.value)
    };

    render() {
        const {status, refSuivi, date_envoi, date_reception} = this.props

        return (
            <div className="bg-transparent mt-1">

                <Card style={{minWidth: "20em"}}>
                    <CardHeader
                        style={{display: "flex", justifyContent: "center", alignItems: "center", color: "#20a8d8"}}>
                        <h5> Suivi </h5>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Label for="status">Status</Label>
                            <FormGroup>
                                <Input
                                    className={this.injected.rootStore.savedDatasStore.setColor(status)}
                                    type="select"
                                    name="status"
                                    value={status}
                                    disabled={status === "VALID"}
                                    onChange={async e => {
                                        this.onChange(e)
                                    }}>
                                    <option className={this.injected.rootStore.savedDatasStore.setColor("DRAFT")}
                                            value={"DRAFT"}> Brouillon
                                    </option>
                                    <option
                                        className={this.injected.rootStore.savedDatasStore.setColor("ORDERED")}
                                        value={"ORDERED"}> Commandé
                                    </option>
                                    <option
                                        className={this.injected.rootStore.savedDatasStore.setColor("SEND")}
                                        value={"SEND"}> Expédié
                                    </option>
                                    <option
                                        className={this.injected.rootStore.savedDatasStore.setColor("RECEPTION")}
                                        value={"RECEPTION"}> Réceptionné
                                    </option>
                                    <option
                                        className={this.injected.rootStore.savedDatasStore.setColor("VALID")}
                                        value={"VALID"}> Validé
                                    </option>

                                </Input>
                            </FormGroup>

                            {(status === "SEND" || status === "RECEPTION" || status === "VALID") && (
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="refSuivi">Numéro de Suivi</Label>
                                            <Input type="text" name="refSuivi"
                                                   value={refSuivi}
                                                   onChange={e => {
                                                       this.onChange(e)
                                                   }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="date_envoi">Date d'envoi</Label>
                                            <Input
                                                type="date"
                                                name="date_envoi"
                                                value={moment(date_envoi).format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")}
                                                onChange={e => {
                                                    this.onChange(e)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>)}


                            {(status === "RECEPTION" || status === "VALID") && (
                                <FormGroup>
                                    <Label for="date_reception">Date de réception</Label>
                                    <Input
                                        type="date"
                                        name="date_reception"
                                        value={date_reception ?
                                            moment(date_reception).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                                        onChange={e => {
                                            this.onChange(e)
                                        }}
                                    />
                                </FormGroup>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            </div>

        )
    }
}));

export default SuiviCommande;
