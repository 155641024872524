import React from "react";
import {inject, observer} from "mobx-react";
import styles from "./Screen.module.css"
import Horloge from "../board/Horloge";
import ScreenMode from "./ScreenMode";
import GamePadMode from "./GamePadMode";
import {withRouter} from "react-router-dom";
import ClientModeComponent from "./ClientModeComponent";

const ScreenClient = (inject("rootStore")(observer(class ScreenClient extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            mode: "gamepad",
            logo: this.injected.rootStore.authStore.logo ?? "",
        }
    }

    async tryToConnect() {
        let url = window.location.hash.replace("#/screen/", "")
        let authResponse = await this.injected.rootStore.authStore.loginUrl(url);
        if (authResponse?.status === "success") {
            this.setState({
                logo: authResponse.logo
            })
        }
    }

    componentDidMount() {
        this.tryToConnect()
        this.injected.rootStore.getEcho()
            .channel(`mode`)
            .listen('ModeScreenEvent', ev => {
                this.setState({
                    mode: ev.type,
                    datas: ev.data
                })

            })

        this.injected.rootStore.getEcho().connector.socket.on('connect', () => {
            this.injected.rootStore.authStore.isLoggedEcho = true;
        });

        this.injected.rootStore.getEcho().connector.socket.on('disconnect', () => {
            this.injected.rootStore.authStore.isLoggedEcho = false;
        });

    }


    get injected() {
        return this.props;
    }

    setMode() {
        if(this.state.mode === "publicité") {
            return  <ScreenMode/>
        }

        if(this.state.mode === "client") {
            return  <ClientModeComponent datas={this.state.datas}/>
        }

        if(this.state.mode === "gamepad") {
            return  <GamePadMode/>
        }
    }


    render() {
        return (
            <>

                <div className={"boardPec"}>
                    <Horloge></Horloge>
                        {this.injected.rootStore.authStore.isLoggedDash ? (
                            this.setMode()) :
                            <div className={"container-fluid pt-5 min-vh-100 " + styles.backgroundMain}>
                                <div></div>
                            </div>

                        }
                </div>
            </>
        );
    }
})));

export default withRouter(ScreenClient);
