import {decorate, observable} from "mobx";
import Api from "../util/api";

export default class datastore {

    datas = observable([]);
    tags = observable([]);

    pickAsset;

    defaultFilter = {
        type: "images",
        search: "",
        status: "",
        page: "0",
        limit: "15",
        last_page: "1",

    }

    filter = {
        type: "images",
        search: "",
        page: "0",
        limit: "15",
        status: "",
        last_page: "1",

    }

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }


    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.images.getImage(this.filter);

            if (this.datas.length > 0) {
                this.datas.replace(authResponse.data)
            } else {
                this.datas = authResponse.data;
            }

            this.filter.page = authResponse.current_page;
            this.filter.last_page = authResponse.last_page;
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.datas
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async findById(id) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.images.find(id);
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.datas;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async save(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.images.save(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Images ajouté(e)s", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveRelationAsset(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.resource.save("relation/assets", datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Images ajouté(e)s", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateRelationAsset(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.resource.save("updaterelation/assets", datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Images modidié(e)s", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deleteRelationAsset(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.resource.save("delete/relation/assets", datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Images supprimé(e)s", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async saveOne(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.images.saveOne(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Images ajouté(e)s", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async update(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.images.update(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Image #" + authResponse.datas.id + " modifiée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.images.delete(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Image # " + id + " supprimée", "success");
                this.getdatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getAllTags() {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.images.getTags();

            if (this.tags.length > 0) {
                this.tags.replace(authResponse)
            } else {
                this.tags = authResponse;
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async createTag(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.images.createTag(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Tag ajouté", "success");
                this.getAllTags()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(datastore, {
    datas: observable,
    tags: observable,
    search: observable,
    limit: observable,
    currentPage: observable,
    lastPage: observable,
    pickAsset: observable,
    filter: observable,
    defaultFilter: observable
});

