import React from "react";
import {Card, CardBody, CardHeader, Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import TableGeneric from "../../../../components/Akitatek/Headers/TableGeneric";
import FicheRow from "./ActionFiche";
import GenericTableAffichage from "../../../../components/Akitatek/Table/GenericTableAffichage";

/*
const Fiches = (inject("rootStore")(observer(class Fiches extends React.Component {

    componentDidMount() {
        this.injected.rootStore.ficheStore.getDatas();
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des fiches", "Aide à la réparation", "devis.jpg")
    }

    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <Card className="shadow">
                        <CardHeader
                            style={{display: "flex", justifyContent: "space-between"}}>
                            <h4 className="mb-0">
                                <i className="fa fa-plus-circle mr-2" aria-hidden="true"/>
                                Gérer les fiches
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <TableGeneric key="card1"
                                          titles={["id", "Nom", "Actions"]}>
                                {this.injected.rootStore.ficheStore.datas
                                    .map(data => {
                                        return <FicheRow data={data} key={data.id}/>
                                    })
                                }
                            </TableGeneric>
                        </CardBody>

                    </Card>
                </Container>
            </>
        );
    }
})));

export default Fiches;*/


const Fiches = (inject("rootStore")(observer(class Fiches extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des fiches", "Aide à la réparation", "devis.jpg")
    }

    get injected() {
        return this.props;
    }


    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericTableAffichage api={this.injected.rootStore.ficheStore}
                                           user={false}
                                           filters={this.injected.rootStore.ficheStore.defaultFilter}
                                           status={false}
                                           statusHeader={false}
                                           icon={<i className="fa fa-wikipedia-w o mr-2" aria-hidden="true"/>}
                                           name={"Fiches "}
                                           type="fiche"/>
                </Container>
            </>
        );
    }
})));

export default Fiches;
