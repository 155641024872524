import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, Container, Row,} from "reactstrap";
import {withRouter} from "react-router-dom";
import Tabs from "../../../../components/Akitatek/Tabs/Tabs";
import NotesRich from "../Notes/NotesRich";
import Historique from "./Historique";
import getRoutes from "../../../../components/Akitatek/getRoutes";
import Dropdown from "react-bootstrap/Dropdown";
import PrintImpression from "../../Printer/PrintImpression";
import moment from "moment";
import SearchOrAdd from "../Stepper/SearchOrAdd";
import FormulairePriseEnChargeStepOne from "../Stepper/FormulairePriseEnChargeStepOne";
import FormOption from "./FormOption";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import GenericTableAffichageWithDatas from "../../../../components/Akitatek/Table/GenericTableAffichageWithDatas";

const Mousetrap = require("mousetrap");

const UpdatePriseEnCharge = inject("rootStore")(observer(class AddPriseEnCharge extends React.Component {

    errorStore = this.injected.rootStore.errorStore;

    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);

        moment.locale('fr');

        this.priseencharge = this.injected.location.aboutProps?.priseencharge;
        let editorState = EditorState.createEmpty()
        this.state = {
            activeTab: parseInt(this.injected.match.params.type ?? 0),
            typeAddMachine: "none",
            machineClient: "",
            titleNote: "",
            description_client: "",
            descriptionNote: "",
            description_tech: "",
            titleLigne: "",
            descriptionLigne: "",
            id: this.priseencharge?.id,
            statusname: this.priseencharge?.statusname,
            suivi_client: this.priseencharge?.suivi_client,
            pec_lignes: this.priseencharge?.pec_lignes,
            status: this.priseencharge?.status ?? "DRAFT",
            title: this.priseencharge?.title ?? "",
            online: this.priseencharge?.online?.toString() ?? "0",
            description: this.priseencharge?.description ?? "",
            suivi: this.priseencharge?.suivi ?? "sans signature",
            user: this.priseencharge?.user ?? {appareils: []},
            notes: this.priseencharge?.notes,
            factures: this.priseencharge?.factures,
            devis: this.priseencharge?.devis ?? [],
            prestas: this.priseencharge?.prestas,
            comment: editorState,
            total: this.priseencharge?.total ?? 0,
            date_accepted: this.priseencharge?.date_accepted,
            date_arrived: this.priseencharge?.date_arrived,
            date_end: this.priseencharge?.date_end,
            created_at: this.priseencharge?.created_at,
            userApp: this.priseencharge?.user_app,
            appareils: this.priseencharge?.appareils,
        };

        if (this.injected.location.aboutProps?.online === 1) {
            this.injected.rootStore.savedDatasStore.setHeader("Prises En Charge Internet", "Gérer les Prises En Charge", "facture.jpg", this.injected.rootStore.prisenchargeStore)
        } else {
            this.injected.rootStore.savedDatasStore.setHeader("Prises En Charge", "Gérer les Prises En Charge", "tools.jpg", this.injected.rootStore.prisenchargeStore)
        }
        this.acceptedPec = this.acceptedPec.bind(this);
        this.refusedPec = this.refusedPec.bind(this);
        this.createInvoice = this.createInvoice.bind(this);
        this.createCmd = this.createCmd.bind(this);
        this.createDevis = this.createDevis.bind(this);
        this.expediate = this.expediate.bind(this);
        this.arrivedPec = this.arrivedPec.bind(this);
    }


    //update component if the id change, or if there is a refresh
    async componentDidUpdate(prevProps) {
        if (this.injected.location !== prevProps.location) {
            await this.onRouteChanged()
        }
        if (this.state.priseencharge === "" && this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }


    //check if the values are up to date
    async componentDidMount() {
        if (this.injected.match.params.id) {
            await this.onRouteChanged()
        }

        if (!this.injected.match.params.type) {
            this.injected.history.replace(getRoutes("Gérer une Prise En Charge", this.injected.match.params.id, "0"));
        }

        Mousetrap.bind("tab", e => this.setState((state) => {
            if (this.getSteps().length === state.activeTab) {
                this.injected.history.replace(getRoutes("Gérer une Prise En Charge", this.state.id, "0"));
                return {activeTab: 0}
            } else {
                this.injected.history.replace(getRoutes("Gérer une Prise En Charge", this.state.id, state.activeTab + 1));
                return {activeTab: state.activeTab + 1}
            }
        }));
    }

    async expediate(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.id, e => this.onExpedition(this.state.id), this.state.title, "Je valide l'expédition de la pec #" + this.state.id)
    }


    async onExpedition(id) {
        var datas = {
            id: id
        }
        await this.injected.rootStore.prisenchargeStore.expediate(datas)
    }

    async arrivedPec(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.id, e => this.onArrived(this.state.id), this.state.title, "Je valide la réception de la pec #" + this.state.id)
    }


    async onArrived(id) {
        var datas = {
            status: "ARRIVED",
            id: id
        }
        await this.injected.rootStore.prisenchargeStore.changePriseEnCharge(datas)
        await this.onReload(id)
    }

    async acceptedPec(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.id, e => this.onValidate(this.state.id), this.state.title, "Je valide la demande #" + this.state.id, "#4dbd74")
    }

    async onValidate(id) {
        var datas = {
            status: "VALIDATE",
            id: id
        }
        await this.injected.rootStore.prisenchargeStore.changePriseEnCharge(datas)
        await this.onReload(id)

    }


    async refusedPec(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.id, e => this.onRemove(this.state.id), this.state.title, "Je refuse la demande #" + this.state.id)
    }

    async onRemove(id) {
        var datas = {
            status: "REFUSED",
            id: id
        }
        await this.injected.rootStore.prisenchargeStore.changePriseEnCharge(datas, "Prise en charge refusée")
        await this.onReload(id)
    }


    //get the values of commande by ID
    async onRouteChanged() {
        let id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (id) {
            await this.onReload(id)
        }
    }

    onReloadData(priseencharge) {
        if (priseencharge) {
            if (priseencharge.description === null) {
                priseencharge.description = ""
            }
            let editorState = EditorState.createEmpty()

            if (priseencharge?.comment !== null) {
                let blocksFromHTML = htmlToDraft(priseencharge?.comment);
                const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
                editorState = EditorState.createWithContent(contentState);
            }

            this.setState({
                priseencharge: priseencharge,
                notes: priseencharge?.notes,
                poids: priseencharge?.poids,
                id: priseencharge.id,
                userApp: priseencharge?.user_app,
                appareils: priseencharge?.appareils,
                comment: editorState,
                statusname: priseencharge.statusname,
                status: priseencharge.status ?? "DRAFT",
                title: priseencharge.title ?? "",
                online: priseencharge?.online?.toString() ?? "0",
                user: priseencharge?.user ?? {appareils: []},
                description: priseencharge.description ?? "",
                suivi: priseencharge.suivi ?? "sans signature",
                suivi_text: priseencharge?.suivi_text,
                pec_lignes: priseencharge?.pec_lignes,
                factures: priseencharge?.factures,
                suivi_client: priseencharge?.suivi_client,
                devis: priseencharge?.devis,
                prestas: priseencharge?.prestas,
                date_accepted: priseencharge?.date_accepted,
                date_arrived: priseencharge?.date_arrived,
                date_end: priseencharge?.date_end,
                created_at: priseencharge?.created_at,
                description_tech: priseencharge.description_tech ?? "",
                description_client: priseencharge.description_client ?? "",
                total: priseencharge?.total ?? 0

            })
        }
    }

    async onReload(id) {
        this.priseencharge = await this.injected.rootStore.prisenchargeStore.getPriseEnChargeForId(id);
        this.onReloadData(this.priseencharge)
    }

    async onUpdate(e) {
        e.preventDefault();

        let data = {
            prestations: this.state.prestas,
            user_id: this.state.user?.id,
            title: this.state.title,
            status: this.state.status,
            description_tech: this.state.description_tech,
            description: this.state.description,
            id: this.state.id,
            suivi_text: this.state.suivi_text,
            online: this.state.online,
            suivi_client: this.state.suivi_client,
            suivi: this.state.suivi,
            poids: this.state.poids,
            total: this.state.total,
            comment: draftToHtml(convertToRaw(this.state.comment.getCurrentContent())),
        }

        let authResponse = await this.injected.rootStore.prisenchargeStore.updatePriseEnCharge(data)

        if (authResponse?.status === "success") {
            this.onReloadData(authResponse.datas)
        }
    }

    async onSubmitHistorique() {
        let data = {
            prise_en_charge_id: this.state.priseencharge?.id,
            title: this.state.titleNote,
            description: this.state.descriptionNote
        }

        let authResponse = await this.injected.rootStore.prisenchargeStore.saveNote(data)

        if (authResponse?.status === "success") {
            this.setState({descriptionNote: "", titleNote: "", notes: authResponse.datas})
            document.getElementById("refTimelineHistorique").focus();
        }
    }

    async onSubmitLigne() {
        let data = {
            id: this.state.id,
            titre: this.state.titleLigne,
            texte: this.state.descriptionLigne
        }

        let authResponse = await this.injected.rootStore.prisenchargeStore.saveNotePec(data)

        if (authResponse?.status === "success") {
            this.setState({pec_lignes: authResponse.datas, titleLigne: "", descriptionLigne: ""})
            document.getElementById("refTimelinePec").focus();
        }
    }


    createButtonSave(title, color, onclickFunc) {
        return (
            <Button color={color}
                    type="submit"
                    onClick={onclickFunc}>{title}</Button>)
    }


    setDevis() {
        {/*<Assign datas={this.state.devis} select={this.state.selectDevis} type={"devis"}/>*/
        }

        return (<GenericTableAffichageWithDatas
            key={"devis"}
            api={this.injected.rootStore.devisStore}
            filters={{
                limit: "15",
                prise_en_charge_id: this.state.id,
                name: "updated_at",
                order: false,
            }}
            type="devis"/>)
    }


    setInvoices() {
        return (<GenericTableAffichageWithDatas
            api={this.injected.rootStore.invoiceStore}
            filters={{
                limit: "15",
                prise_en_charge_id: this.state.id,
                name: "updated_at",
                order: false,
            }}
            key={"invoice"}
            type="invoice"/>)
    }

    setCmd() {
        return (
            <div>
                {/*<Assign datas={this.state.commandes} select={this.state.selectCmd} type={"devis"}/>*/}
                <GenericTableAffichageWithDatas api={this.injected.rootStore.commandeStore}
                                                filters={
                                                    {
                                                        limit: "15",
                                                        prise_en_charge_id: this.state.id,
                                                        name: "updated_at",
                                                        order: false,
                                                    }
                                                }
                                                type="cmd"/>
            </div>
        )
    }


    //Callback
    callbackOnChange = (name, value) => {
        this.setState({[name]: value});

        if (name === "activeTab") {
            this.injected.history.replace(getRoutes("Gérer une Prise En Charge", this.state.id, value.toString()));
        }
    }

    //Callback
    callbackOnChangePresta = (name, value) => {
        const objet = Object.assign([], this.state.prestas);
        objet[name].pivot.total_actual = value;
        this.setState({prestas: objet});
        if (name === "activeTab") {
            this.injected.history.replace(getRoutes("Gérer une Prise En Charge", this.state.id, value.toString()));
        }
    }

    //Callback
    callbackSubmit = (type) => {
        if (type === "historique") {
            this.onSubmitHistorique()
        } else {
            this.onSubmitLigne()
        }
    }

    async createDevis(e) {
        e.preventDefault()
        let datas = {
            idPec: this.state.id,
            idUser: this.state.user?.id
        }
        let authResponse = await this.injected.rootStore.devisStore.saveDevisAuto(datas);

        if (authResponse?.status === "success") {
            this.injected.history.replace({
                pathname: getRoutes("Gérer un Devis", authResponse.datas.id),
                aboutProps: {
                    isCommandeValid: false,
                    devis: authResponse.datas
                },
            });
        }
    }

    async createInvoice(e) {
        e.preventDefault()
        let datas = {
            idPec: this.state.id,
            idUser: this.state.user?.id
        }
        let authResponse = await this.injected.rootStore.invoiceStore.saveInvoicePro(datas);

        if (authResponse?.status === "success") {
            this.injected.history.replace({
                pathname: getRoutes("Gérer une facture", authResponse.datas.id, "élaboration"),
                aboutProps: {
                    isCommandeValid: false,
                    invoice: authResponse.datas
                },
            });
        }
    }

    async createCmd(e) {
        e.preventDefault()
        let datas = {
            prise_en_charge_id: this.state.id,
        }
        let authResponse = await this.injected.rootStore.commandeStore.createCommande(datas);

        if (authResponse?.status === "success") {
            this.injected.history.replace({
                pathname: getRoutes("Gérer une commande", authResponse.datas.id),
            });
        }
    }

    async onDeleteUserMachine(index) {
        let appareil = this.state.appareils[index];

        let data = {
            user_appareil_id: appareil.pivot?.user_annuaire_appareil_id ?? "",
            prise_en_charge_id: this.state.id,
        }
        let authResponse = await this.injected.rootStore.prisenchargeStore.deleteUserMachine(data);

        if (authResponse?.status === "success") {
            this.setState({appareils: authResponse.datas, userApp: authResponse.userApp});
        }
    }


    async onUpdateUserMachine(index) {
        let appareil = this.state.appareils[index];

        let authResponse
        let data = {
            accessoires: appareil.pivot?.accessoires ?? "",
            defauts: appareil.defauts ?? "",
            serial: appareil.serial ?? "",
            user_appareil_id: appareil.pivot?.user_annuaire_appareil_id ?? "",
            prise_en_charge_id: this.state.id,
            code: appareil.code,
            casier_id: appareil.pivot?.casier?.id
        }

        authResponse = await this.injected.rootStore.prisenchargeStore.updateUserMachine(data)

        if (authResponse?.status === "success") {
            this.setState({appareils: authResponse.datas, userApp: authResponse.userApp});
        }
    }

    onCallbackAction = (index, action) => {
        if (action === "update") {
            this.onUpdateUserMachine(index)
        } else {
            this.onDeleteUserMachine(index)
        }
    }


    callbackOnChangeList = (index, name, value) => {
        let appareils = [...this.state.appareils];

        if (name === "accessoires" || name === "casier") {
            appareils[index]['pivot'][name] = value
        } else {
            appareils[index][name] = value
        }
        this.setState({appareils});
    }


    getSteps() {
        return ["Infos", this.state.online === "1" ? "Options" : "", "Machines", "Devis", "Factures", "Commandes", "Messages", "Impressions", "Notes", "Historique"];
    }

    render() {
        return (
            <>
                {this.state.id ? (
                        <Container className="mt-2" fluid>
                            <Row className="mb-3">
                                <div className="col">
                                    <Card className="shadow">
                                        <CardHeader
                                            className={this.injected.rootStore.savedDatasStore.setColor(this.state.status)}
                                            style={{display: "flex", justifyContent: "space-between"}}>

                                            <h4 className="mb-0">

                                                {this.state.id && (
                                                    "Prise En Charge #" + this.state.id + " " + this.state.statusname?.toUpperCase()
                                                )}
                                            </h4>
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <Dropdown className={"mr-2"}>
                                                    <Dropdown.Toggle variant="info">
                                                        <i className="fa fa-plus-circle" aria-hidden="true"/>
                                                        Nouveau
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={this.createInvoice}>Facture</Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={this.createDevis}>Devis</Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={this.createCmd}>Commande</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                {this.state.online === "1" && (
                                                    this.state.status === "SUBMIT" || this.state.status === "EXPEDIATE" || this.state.status === "VALIDATE" || this.state.status === "OVER"
                                                ) &&
                                                <Dropdown className={"mr-2"}>
                                                    <Dropdown.Toggle variant="warning">
                                                        <i className="fa fa-wrench" aria-hidden="true"/>
                                                        Actions
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {this.state.status === "SUBMIT" && (
                                                            <div>
                                                                <Dropdown.Item
                                                                    onClick={this.acceptedPec}>Accepter</Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={this.refusedPec}>Refuser</Dropdown.Item>
                                                            </div>
                                                        )}


                                                        {(this.state.status === "VALIDATE" || this.state.status === "EXPEDIATE") && (
                                                            <div>
                                                                <Dropdown.Item
                                                                    onClick={this.arrivedPec}>Réceptionner</Dropdown.Item>
                                                            </div>
                                                        )}


                                                        {this.state.status === "OVER" && (
                                                            <div>
                                                                <Dropdown.Item
                                                                    disabled={!this.state.suivi_text}
                                                                    onClick={this.expediate}>Marquer comme
                                                                    expédier</Dropdown.Item>
                                                            </div>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>}


                                                {
                                                    this.createButtonSave("Modifier", "success", e => this.onUpdate(e))
                                                }
                                            </div>


                                        </CardHeader>
                                        <Tabs
                                            callbackOnChange={this.callbackOnChange}
                                            activeTab={this.state.activeTab}
                                            online={this.state.online}
                                            children={[
                                                <FormulairePriseEnChargeStepOne
                                                    description_client={this.state.description_client}
                                                    user={this.state.user}
                                                    total={this.state.total}
                                                    callbackOnChangePresta={this.callbackOnChangePresta}
                                                    callbackOnChange={this.callbackOnChange}
                                                    status={this.state.status}
                                                    id={this.state.id}
                                                    online={this.state.online}
                                                    title={this.state.title}
                                                    suivi={this.state.suivi}
                                                    description_tech={this.state.description_tech}
                                                    description={this.state.description}
                                                    date_accepted={this.state.date_accepted}
                                                    date_arrived={this.state.date_arrived}
                                                    date_end={this.state.date_end}
                                                    created_at={this.state.created_at}
                                                    prestations={this.state.prestas}
                                                />,
                                                <FormOption
                                                    callbackOnChange={this.callbackOnChange}
                                                    suivi={this.state.suivi}
                                                    poids={this.state.poids}
                                                    suivi_client={this.state.suivi_client}
                                                    suivi_text={this.state.suivi_text}
                                                />,
                                                <SearchOrAdd
                                                    userApp={this.state.userApp ?? []}
                                                    appareils={this.state.appareils}
                                                    callbackClientMachine={this.callbackClientMachine}
                                                    callbackOnChange={this.callbackOnChange}
                                                    user={this.state.user}
                                                    id={this.state.id}
                                                    typeAddMachine={this.state.typeAddMachine}
                                                    onCallbackAction={this.onCallbackAction}
                                                    callbackOnChangeList={this.callbackOnChangeList}
                                                    message={this.state.message}/>,
                                                this.setDevis(), this.setInvoices(), this.setCmd(),
                                                <Historique notes={this.state.pec_lignes}
                                                            type={"lignes"}
                                                            titleNote={this.state.titleLigne}
                                                            descriptionNote={this.state.descriptionLigne}
                                                            callbackOnChange={this.callbackOnChange}
                                                            callbackSubmit={this.callbackSubmit}/>
                                                ,
                                                <PrintImpression online={this.state.online}
                                                                 id={this.state.id}
                                                                 datas={this.state.appareils}
                                                                 api={this.injected.rootStore.prisenchargeStore}
                                                                 type={"pec"}/>,
                                                <NotesRich
                                                    callbackOnChange={this.callbackOnChange}
                                                    callbackSubmit={this.callbackSubmit}
                                                    comment={this.state.comment}/>,
                                                <Historique notes={this.state.notes}
                                                            type={"historique"}
                                                            titleNote={this.state.titleNote}
                                                            descriptionNote={this.state.descriptionNote}
                                                            callbackOnChange={this.callbackOnChange}
                                                            callbackSubmit={this.callbackSubmit}

                                                />]}
                                            titles={this.getSteps()}/>
                                    </Card>
                                </div>
                            </Row>
                        </Container>) :
                    (<Container className={"mt-2"} fluid> <Card><CardBody>Prise en charge introuvable. Merci de
                        réessayer</CardBody></Card> </Container>)}
            </>
        );
    }
}));

export default withRouter(UpdatePriseEnCharge);

