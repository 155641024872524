import React from "react";
import {inject, observer} from "mobx-react";

const Pagination = inject("rootStore")(observer(class Pagination extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    get injected() {
        return this.props;
    }

    getPage(e, pageNumber) {
        e.preventDefault();
        this.injected.parentCallback(pageNumber);
    }

    createPageNumber(currentPage, lastPage) {
        let pagination = [];

        if (currentPage - 3 > 1) {
            for (let i = currentPage - 3; i < currentPage; i++) {
                pagination.push(i)
            }
        } else if (currentPage - 1 > 1) {
            for (let i = currentPage - 1; i < currentPage; i++) {
                pagination.push(i)
            }
        }

        if (currentPage !== lastPage && currentPage !== 1) {
            pagination.push(currentPage)
        }

        if (currentPage + 3 < lastPage) {
            for (let i = currentPage + 1; i <= currentPage + 3; i++) {
                pagination.push(i)
            }
        } else if (currentPage + 1 < lastPage) {
            for (let i = currentPage + 1; i <= currentPage + 1; i++) {
                pagination.push(i)
            }
        }


        return pagination

    };

    render() {
        let {lastPage, currentPage} = this.props

        let pagi = this.createPageNumber(currentPage, lastPage)

        return (
            <nav aria-label="Page navigation example" className="ml-auto mr-auto">
                <ul className="pagination justify-content-end">

                    {lastPage !== 1 &&
                    (
                        <span style={{display: "flex"}}>
                        <li key={1} className={currentPage === 1 ? "page-item active" : "page-item"}><a href="/#"
                                                                                                        className="page-link"
                                                                                                        key={1}
                                                                                                        onClick={e => this.getPage(e, 1)}>1ère
                            ... </a></li>

                            {pagi?.map((i) => (
                                <li key={i} className={currentPage === i ? "page-item active" : "page-item"}><a
                                    href="/#" className="page-link" key={i} onClick={e => this.getPage(e, i)}>{i}</a>
                                </li>
                            ))}


                            <li key={lastPage}
                                className={currentPage === lastPage ? "page-item active" : "page-item"}>
                        <a href="/#" className="page-link" key={lastPage}
                           onClick={e => this.getPage(e, lastPage)}>... Dernière</a></li>

                        </span>
                    )}

                </ul>
            </nav>
        )
    }
}));

export default Pagination;
