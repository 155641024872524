import React from "react";
import {inject, observer} from "mobx-react";
import {Link, withRouter} from "react-router-dom";
import {Button, UncontrolledTooltip} from "reactstrap";
import configuration from "../../../../configuration/configuration";

const PageRow = inject("rootStore")(observer(class PageRow extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }

    toggleDanger(event, data) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning("Page #" + data.id, event => this.onDelete(data.id))
    }

    async onDelete(deleteId) {
        await this.injected.rootStore.pageStore.delete(deleteId);
    }

    async copyToClipboard(event, copy) {
        event.preventDefault()
        await navigator.clipboard.writeText(copy)
        await this.injected.rootStore.notificationStore.setNotification(copy + " copié ! ", "success")
    }

    render() {

        const {data} = this.props

        return (
            <>
                <tr>
                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                                <Link to={{
                                    pathname: "/page/" + data?.url,
                                    aboutProps: {
                                        data: data
                                    }
                                }}>
                                #{data?.id}
                                 </Link>
                        </span>
                    </th>

                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {data?.name}
                        </span>
                    </td>


                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {data?.menu ? "Oui" : "Non"}
                        </span>
                    </td>


                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {data?.system ? "Oui" : "Non"}
                        </span>
                    </td>

                    <td className={"th-actions"}>
                        <div style={{display: "flex", justifyContent: "flex-start"}}>

                            <Link to={{
                                pathname: "/page/" + data?.url,
                                aboutProps: {
                                    data: data
                                }
                            }}>
                                <Button className={"ml-2 mr-2"} color="primary" type="button"
                                        id={"UncontrolledTooltipExampleVoir" + data?.id}>
                                    <i className="fa fa-eye" aria-hidden="true"/>
                                    <UncontrolledTooltip placement="top"
                                                         target={"UncontrolledTooltipExampleVoir" + data?.id}>
                                        Voir
                                    </UncontrolledTooltip>
                                </Button>
                            </Link>

                            <Button className={"bg-warning ml-2 mr-2"}
                                    id={"UncontrolledCopier"}
                                    onClick={e => this.copyToClipboard(e,configuration.API + '/page/' + data?.url)}
                            > <i className="fa fa-copy"></i>
                                <UncontrolledTooltip placement="top"
                                                     target={"UncontrolledCopier"}>
                                    Copier l'URL
                                </UncontrolledTooltip>
                            </Button>

                            {!data?.system && (
                                <Button className={"ml-2 mr-2"} color="danger" type="button"
                                        onClick={e => this.toggleDanger(e, data)}
                                        id={"UncontrolledTooltipExampleSelection" + data?.id}>
                                    <i className="fa fa-minus" aria-hidden="true"/>
                                    <UncontrolledTooltip placement="top"
                                                         target={"UncontrolledTooltipExampleSelection" + data?.id}>
                                        Supprimer
                                    </UncontrolledTooltip>
                                </Button>
                            )}

                        </div>
                    </td>
                </tr>
            </>
        );
    }

}));

export default withRouter(PageRow);
