import React from "react";
import {Input, Row} from "reactstrap";
import {Col} from "react-bootstrap";
import Label from "reactstrap/lib/Label";

function InputText({value, onChange, name, placeholder, type = "text"}) {
    return   <Row>
        <Col md={2}>
            <Label> {placeholder}</Label>
        </Col>
        <Col md={4}>
            <Input
                className={"mb-3"}
                placeholder={placeholder}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
            />
        </Col>
    </Row>
}

export default InputText;
