import React from "react";
import {Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";


const PriseEnChargeInternet = (inject("rootStore")(observer(class PriseEnChargeInternet extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Prises En Charge Internet", "Gérer les Prises En Charge", "tools.jpg", this.injected.rootStore.prisenchargeInternetStore)
    }

    get injected() {
        return this.props;
    }


    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericTableAffichage api={this.injected.rootStore.prisenchargeInternetStore}
                                           user={false}
                                           status={true}
                                           statusHeader={true}
                                           filters={this.injected.rootStore.prisenchargeInternetStore.defaultFilter}
                                           priseencharge={false}
                                           icon={<i className="fa fa-internet-explorer mr-2" aria-hidden="true"/>}
                                           name={"Prises en Charge Internet"}
                                           type="pecinternet"/>
                </Container>
            </>
        );
    }
})));

export default PriseEnChargeInternet;
