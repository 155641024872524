import {Button, CardBody, Container, Input, Row} from "reactstrap";
import {Col} from "react-bootstrap";
import Label from "reactstrap/lib/Label";
import FormGroup from "reactstrap/lib/FormGroup";
import React from "react";
import {inject, observer} from "mobx-react";
import InputTitle from "../../../../components/Akitatek/Form/InputTitle";
import moment from "moment";
import InputText from "../../../../components/Akitatek/Form/InputText";
import Card from "reactstrap/lib/Card";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const ContentPromotion = inject("rootStore")(observer(class ContentPromotion extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props);

        this.state = {
            prestation_id: this.injected.prestation?.id,
            title: "",
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            enabled: 1,
            type: "unique",
            prestation: this.injected.prestation ?? "",
            price_ttc: this.injected.price_ttc,
        }


    }

    async componentDidMount() {
        await this.injected.rootStore.annuaireStore.prestationStore.getAllPrestations()

        if (this.props.card) {
            this.injected.rootStore.savedDatasStore.setHeader("Promotions", "Faire des supers promos", "motherboard.jpg")
        }

        if (this.props.match && this.props.match.params.id) {
            this.injected.rootStore.savedDatasStore.setHeader("Promotions", "Faire des supers promos", "motherboard.jpg")
            await this.onRouteChanged()
        }
    }


    //get the values of commande by ID
    async onRouteChanged() {

        let id = this.props.match && parseInt(this.props.match.params.id, 10);
        if (id) {
            let promo = await this.injected.rootStore.promotionStore.findPromotionById(id);
            this.setState({
                id: promo.id,
                prestation: promo.prestation,
                prestation_id: promo.prestation?.id,
                title: promo.title,
                start_date: moment(promo.start_date).format("YYYY-MM-DD"),
                end_date: moment(promo.end_date).format("YYYY-MM-DD"),
                enabled: promo.enabled,
                type: promo.type,
                price_ttc: promo.price_ttc,
            })
        }
    }

    async onSubmit() {
        const datas = Object.assign({}, this.state);
        // Save my promotion
        let authResponse = ""
        if (!this.state.id) {
            authResponse = await this.injected.rootStore.promotionStore.create(datas)
        } else {
            authResponse = await this.injected.rootStore.promotionStore.update(datas)
        }
        if (authResponse?.status === "success") {
            if (this.props.card === false) {
                this.injected.onClose()
                this.injected.callbackOnChange("promotions", authResponse?.datas)
            }
        }
    }

    //Refresh
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    selectionPresta(suggestion) {
        this.setState({
            prestation_id: suggestion?.id,
            prestation: suggestion ?? "",
        })
    }

    setForm() {
        return (<CardBody>
            <Col>
                <InputTitle
                    placeholder="Titre (description courte)"
                    name="title"
                    value={this.state.title}
                    onChange={(e) => {
                        this.onChange(e)
                    }}
                />

                <Row>

                    <Col md={2}>
                        <Label> Ma Prestation
                        </Label>
                    </Col>
                    <Col>
                        <Autocomplete
                            key={"presta"}
                            value={this.state.prestation}
                            options={this.injected.rootStore.annuaireStore.prestationStore.allPrestations}
                            getOptionLabel={(option) => {
                                if (option?.id) {
                                    return "#" + option?.id + " " + option?.fullname
                                } else {
                                    return ""
                                }
                            }}
                            style={{width: 1000}}
                            onChange={(event, newValue) => {
                                this.selectionPresta(newValue);
                            }}
                            selectOnFocus
                            renderInput={(params) => {
                                return (<TextField {...params}
                                                   margin="normal"
                                                   label="Rechercher une prestation"
                                                   variant="outlined"/>)
                            }}
                        />

                    </Col>
                </Row>


                <InputText
                    type={"number"}
                    placeholder="Prix TTC"
                    name="price_ttc"
                    value={this.state.price_ttc}
                    onChange={(e) => {
                        this.onChange(e)
                    }}
                />
                {/*<Row form>*/}
                {/*    <Col md={2}>*/}
                {/*        <Label for="exampleEmail">Option</Label>*/}
                {/*    </Col>*/}
                {/*    <Col md={4}>*/}
                {/*        <FormGroup>*/}
                {/*            <FormGroup>*/}
                {/*                <Input type="select"*/}
                {/*                       name="typePromotion"*/}
                {/*                       value={this.state.type}*/}
                {/*                       onChange={(e) => {*/}
                {/*                           this.onChange(e)*/}
                {/*                       }}*/}

                {/*                >*/}
                {/*                    <option value={"periodic"}>Périodique</option>*/}
                {/*                    <option value={"anuel"}>Annuel</option>*/}
                {/*                    <option value={"unique"}>Unique</option>*/}
                {/*                </Input>*/}
                {/*            </FormGroup>*/}
                {/*        </FormGroup>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <Row>
                    <Col md={2}>
                        <Label> Date de début </Label>
                    </Col>
                    <Col md={4}>
                        {this.state.start_date && (
                            <FormGroup>
                                <Label for="dateExpedition">Date de création</Label>
                                <Input
                                    type="date"
                                    name="start_date"
                                    value={moment(this.state.start_date).format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")}
                                    onChange={e => {
                                        this.onChange(e)
                                    }}
                                />
                            </FormGroup>
                        )}

                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Label> Date de fin </Label>
                    </Col>
                    <Col md={4}>
                        {this.state.end_date && (
                            <FormGroup>
                                <Label for="dateExpedition">Date de création</Label>
                                <Input
                                    type="date"
                                    name="end_date"
                                    value={moment(this.state.end_date).format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")}
                                    onChange={e => {
                                        this.onChange(e)
                                    }}
                                />
                            </FormGroup>
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col style={{display: "flex", justifyContent: "center"}}>
                        <FormGroup className={"mr-2"}>
                            <label>
                                <input type="radio"
                                       name={"enabled"}
                                       checked={this.state.enabled === 1}
                                       onClick={e => this.setState({enabled: 1})}
                                />
                                <span className="badge badge-pill badge-success">Actif</span>
                            </label>
                        </FormGroup>
                        <FormGroup>
                            <label>
                                <input type="radio"
                                       name={"enabled"}
                                       checked={this.state.enabled === 0}
                                       onClick={e => this.setState({enabled: 0})}
                                />
                                <span className="badge badge-pill badge-warning">Inactif</span>
                            </label>
                        </FormGroup>
                    </Col>
                </Row>

                <Button className={"m-auto"} color="success" style={{display: "flex"}}
                        onClick={e => this.onSubmit(e)}>
                    Valider
                </Button>
            </Col>
        </CardBody>)
    }

    render() {
        let {card = true} = this.props

        return (
            <>
                {card ? (
                    <Container className="mt-2" fluid>
                        <Card>  {this.setForm()}</Card>
                    </Container>
                ) : (this.setForm())
                })
            </>
        );
    }
}));


export default ContentPromotion;


