import React from "react";
import ErrorBoundary from "../Error/ErrorBounday";
import TableGeneric from "../Headers/TableGeneric";
import DataRow from "./DataRow";
import Pagination from "../Pagination";
import {inject, observer} from "mobx-react";
import TypeTable from "./getTypeTable";
import ActionType from "./ActionType";
import SelectStatus from "./SelectStatus";
import {Card, CardBody, CardHeader} from "reactstrap";
import SetLoading from "../setLoading";
import Label from "reactstrap/lib/Label";
import FormGroup from "reactstrap/lib/FormGroup";
import Badge from "reactstrap/lib/Badge";
import Radio from "@material-ui/core/Radio";
import StatusHeaderType from "../StatusHeaderType";
import PictureHidden from "../PictureHidden";
import {SRLWrapper} from "simple-react-lightbox";
import {Link} from "react-router-dom";
import getRoutes from "../getRoutes";
import Button from "reactstrap/lib/Button";

const GenericTableAffichage = inject("rootStore")(observer(class GenericTableAffichage extends React.Component {

    async componentDidMount() {
        await this.reload();
    }

    async reload() {
        if (!this.injected.api.datas || this.injected.api.datas.length <= 0) {
            if (this.props.statusHeader) {
                if(this.props.status) {
                    await this.injected.api.getAllStatus();
                    await this.injected.api.getStatusname();
                }
            }
            await this.loadDatas()
        }
    }


    onChangeStatus = async (status) => {
        this.setState({
            loading: true
        })
        this.injected.api.filter = this.injected.api.defaultFilter
        if(this.props.status  ) {
            this.injected.api.filter.status = status
            await this.injected.api.getStatusname()
        }
        await this.injected.api.getDatas()
        this.setState({
            loading: false
        })
    }


    async loadDatas() {
        if (this.injected.filters) {
            this.injected.api.filter = this.injected.filters
        } else {
            this.injected.api.filter = Object.assign({}, this.injected.api.defaultFilter)
        }
        await this.load()

    }

    async load() {
        this.setState({
            loading: true
        })
        await this.injected.api.getDatas();

        this.setState({
            loading: false
        })
    }

    parentLoad = async () => {
        await this.load()
    };


    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            screenType: "list"
        }
        this.onClickOnline = this.onClickOnline.bind(this)
    }

    get injected() {
        return this.props;
    }

    parentCallback = async (childData) => {
        this.injected.api.filter.page = childData
        await this.load()
    };

    async onClickOnline(value) {
        this.injected.api.filter.online = value
        await this.load()
    }

    render() {
        let {type, api, status, name, icon, priseencharge, statusHeader, hasScreenType = false} = this.props
        const [titles, onFilter] = TypeTable(type, api)

        return (
            <>
                <ErrorBoundary>
                    <Card>

                        {name && (
                            <CardHeader
                                className={this.injected.rootStore.savedDatasStore.setColor(api.filter.status ? api.filter.status[0] : "")}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                <h4 className="mb-0">
                                    {icon}

                                    {status ? (
                                        api.filter.search !== "" ? (
                                        "Recherche " + api.filter.search + " "
                                    ) : (
                                        " " + name + " " + this.injected.api?.statusname + " "
                                    )) : (
                                        " " + name
                                    )}

                                    {type === "stock" && (
                                        this.injected.api?.filter?.shop === "1" ? "Magasin " : "Tout ")
                                    }

                                    {type === "stock" && (
                                        this.injected.api?.selectionCategory?.nom ?? "")
                                    }


                                </h4>

                                {statusHeader && (<StatusHeaderType type={type} onChange={this.onChangeStatus}/>)}
                                {hasScreenType && (
                                    <div>
                                        <Button
                                            className={"mr-2"}
                                            color={this.state.screenType === "list" ? "success" : "primary"}
                                            active={this.state.screenType === "list"}
                                            onClick={e => this.setState({screenType: "list"})}>
                                            <i className="fa fa-picture-o mr-1" aria-hidden="true"></i>
                                        </Button>
                                        <Button
                                            active={this.state.screenType === "grid"}
                                            color={this.state.screenType === "grid" ? "success" : "primary"}
                                            className={"mr-2"}
                                            onClick={e => this.setState({screenType: "grid"})}>
                                            <i className="fa fa-list mr-1" aria-hidden="true"></i>
                                        </Button>
                                    </div>
                                )}
                            </CardHeader>)}
                        {priseencharge && (
                            <CardHeader style={{display: "flex", padding: "0.1em"}}>
                                <FormGroup check>
                                    <Label check style={{display: "flex", alignItems: "center"}}>
                                        <Radio type="radio" name="myGroupName" onChange={e => this.onClickOnline("")}
                                               checked={this.injected.api.filter.online === ""}/>
                                        <h5><Badge color="primary">Tout</Badge></h5>
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check style={{display: "flex", alignItems: "center"}}>
                                        <Radio type="radio" name="myGroupName" onChange={e => this.onClickOnline("1")}
                                               checked={this.injected.api.filter.online === "1"}/>
                                        <h5><Badge color="danger">Internet</Badge></h5>
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check style={{display: "flex", alignItems: "center"}}>
                                        <Radio type="radio" name="myGroupName" onChange={e => this.onClickOnline("0")}
                                               checked={this.injected.api.filter.online === "0"}/>
                                        <h5><Badge color="warning">Magasin</Badge></h5>
                                    </Label>
                                </FormGroup>

                            </CardHeader>
                        )}
                        {/*{status && (<SelectStatus api={api} load={this.parentLoad}/>)}*/}
                        {!this.state.loading ? (
                                <CardBody>
                                    {this.state.screenType === "list" && (
                                        <TableGeneric key={type} onFilter={onFilter}
                                                      titles={titles}>
                                            {api?.datas?.map(data => {
                                                return <DataRow
                                                    titles={titles ? titles : []}
                                                    data={data}
                                                    key={data.id}
                                                    actions={<ActionType data={data} type={type}/>}
                                                />
                                            })
                                            }
                                            {(api?.datas?.length === 0) && (
                                                <tr>
                                                    <td className={"no_td"}
                                                        colSpan={titles?.length}>Aucunes données
                                                    </td>
                                                </tr>
                                            )}
                                        </TableGeneric>)}
                                    {api?.filter.page !== "" && api?.filter.last_page !== "1" && this.state.screenType === "list" && (
                                        <Pagination
                                            loading={this.state.loading}
                                            currentPage={api?.filter.page}
                                            lastPage={api?.filter.last_page}
                                            parentCallback={this.parentCallback}/>
                                    )}


                                    {this.state.screenType === "grid" && (
                                        <div style={{display: "flex"}}>
                                            {api.datas.filter(data => data.assets?.length > 0).map((data) => {
                                                return <SRLWrapper>
                                                    <div>
                                                        {data?.assets.map((asset, key) => {
                                                            return <PictureHidden assetName={asset.filename ?? ""}
                                                                                  display={key === 0 ? "block" : "none"}
                                                                                  height={"12em"} width={"12em"}/>
                                                        })}

                                                        <div className="text-center" style={{ maxWidth: "fit-content"}}>
                                                            <Link style={{color: "#20a8d8"}} to={{
                                                                pathname: getRoutes("Modifier stock", data.id),
                                                                aboutProps: {
                                                                    stock: data
                                                                }
                                                            }}>
                                                                {data.nom}
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </SRLWrapper>
                                            })}
                                        </div>
                                    )}

                                </CardBody>) :
                            (
                                <CardBody><SetLoading/></CardBody>
                            )}
                    </Card>
                </ErrorBoundary>
            </>
        );
    }

}));
export default GenericTableAffichage;
