import {decorate, observable} from "mobx";
import Api from "../../util/api";

export default class FicheStore {

    datas = observable([]);

    filter = {
        limit: "15",
        page: "1",
        last_page: "1",
        name: "created_at",
        order: false
    }

    defaultFilter = {
        limit: "15",
        page: "1",
        last_page: "1",
        name: "created_at",
        order: false
    }
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.resource.get("fiches", this.filter);

            if (this.datas.length > 0) {
                this.datas.replace(authResponse.data)
            } else {
                this.datas = authResponse.data;
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }


    async find(id) {
        return await this.api.resource.getAction("fiche", id);
    }

    async create(datas) {
        this.rootStore.savedDatasStore.setLoading(true)

        let authResponse = await this.api.resource.save("fiches", datas);

        if (authResponse?.status === "success") {
            this.rootStore.notificationStore.setNotification("Fiche ajoutée", "success");
        }
        this.rootStore.savedDatasStore.setLoading(false)

        return authResponse
    }

    async update(datas) {
        this.rootStore.savedDatasStore.setLoading(true)

        let authResponse = await this.api.resource.update("fiches", datas);

        if (authResponse?.status === "success") {
            this.rootStore.notificationStore.setNotification("Fiche modifiée", "success");
        }
        this.rootStore.savedDatasStore.setLoading(false)

        return authResponse
    }


    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)

            let authResponse = await this.api.resource.delete("fiches", id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Fiche # " + id + " supprimée", "success");
                this.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(FicheStore, {
    datas: observable
});

