import {action, decorate, observable} from "mobx";
import Api from "../util/api";

export default class SpoolerStore {

    datas = observable([]);
    filter = {
        status: "",
        search: "",
        page: "",
        last_page: "",
        user_id: ""

    }
    defaultFilter = {
        status: "",
        search: "",
        page: "",
        user_id: "",
        last_page: "",
    }

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    statusname = ""

    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.spooler.getDatas(this.rootStore.authStore.user.id)

            if (authResponse?.status === "success") {
                this.rootStore.savedDatasStore.setLoading(false)
                this.datas = authResponse.datas;
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async delete(spoolerId) {
        this.rootStore.savedDatasStore.setLoading(true)
        let authResponse = await this.api.spooler.delete(spoolerId);
        if (authResponse?.status === "success") {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deleteAll() {
        this.rootStore.savedDatasStore.setLoading(true)
        let authResponse = await this.api.spooler.deleteAll(this.rootStore.authStore.user.id);
        if (authResponse?.status === "success") {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async refresh(id = null) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.spooler.refresh(id);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( "On relance les impressions",   "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(SpoolerStore, {
    datas: observable,
    getPrinters: action,
    status: observable,
    statusname: observable
});

