import React from "react";
import Button from "reactstrap/lib/Button";
import i18n from "i18next";

function StatusHeaderType({type, onChange}) {
    if (type === "invoice") {
        return (<div style={{display: "flex"}}>
            <Button className="mr-2" color="primary"
                    onClick={event => onChange("")}>{i18n.t('page.admin.invoices.all')}</Button>
            <Button className="mr-2" color="danger"
                    onClick={event => onChange(["TO_PAID"])}>{i18n.t('page.admin.invoices.buttonTitleToPaid')}</Button>
            <Button className="mr-2" color="light"
                    onClick={event => onChange(["DRAFT"])}>{i18n.t('page.admin.invoices.buttonTitleDraft')}</Button>
            <Button className="mr-2" color="success"
                    onClick={event => onChange(["PAID"])}>{i18n.t('page.admin.invoices.buttonTitlePaid')}</Button>
            {/*<Button className="mr-2" color="warning"*/}
            {/*        onClick={event => onChange( ["ARCHIVED"])}>{i18n.t('page.admin.invoices.buttonTitleArchive')}</Button>*/}

        </div>)
    }

    if (type === "devis") {
        return (<div style={{display: "flex"}}>
                <Button className="mr-2" color="primary"
                        onClick={event => onChange("")}>{i18n.t('page.admin.invoices.all')}</Button>
                <Button className="mr-2" color="info"
                        onClick={event => onChange(["WAIT"])}>{i18n.t('page.admin.devis.buttonTitleWait')}</Button>
                <Button className="mr-2" color="light"
                        onClick={event => onChange(["DRAFT"])}>{i18n.t('page.admin.invoices.buttonTitleDraft')}</Button>
                <Button className="mr-2" color="success"
                        onClick={event => onChange(["VALID"])}>{i18n.t('page.admin.devis.buttonTitleValid')}</Button>
                <Button className="mr-2" color="danger"
                        onClick={event => onChange(["REFUSED"])}>{i18n.t('page.admin.devis.buttonTitleRefused')}</Button>
            </div>

        )
    }

    if (type === "pec") {
        return (<div style={{display: "flex"}}>
            <Button className="mr-2" color="primary"
                    onClick={event => onChange("")}>{i18n.t('page.admin.invoices.all')}</Button>
            <Button className="mr-2" color="warning"
                    onClick={event => onChange(["EN_COURS"])}>En
                cours</Button>
            <Button className="mr-2" color="success"
                    onClick={event => onChange(["OVER"])}>Terminés</Button>
            <Button className="mr-2" color="light"
                    onClick={event => onChange(["DRAFT"])}>En
                brouillon</Button>
        </div>)
    }

    if (type === "pecinternet") {
        return (<div style={{display: "flex"}}>
                <Button className="mr-2" color="primary"
                        onClick={event => onChange("")}>{i18n.t('page.admin.invoices.all')}</Button>
                <Button className="mr-2" color="danger"
                        onClick={event => onChange(["SUBMIT"])}>En
                    attente</Button>
                <Button className="mr-2" color="success"
                        onClick={event => onChange(["EXPEDIATE"])}>Expédié</Button>
                <Button className="mr-2" color="success"
                        onClick={event => onChange(["VALIDATE"])}>Validé</Button>
                <Button className="mr-2" color="info"
                        onClick={event => onChange(["ARRIVED"])}>Arrivé</Button>
                <Button className="mr-2" color="warning"
                        onClick={event => onChange(["EN_COURS"])}>En
                    cours</Button>
                <Button className="mr-2" color="light"
                        onClick={event => onChange(["EXPIRED"])}>Expiré</Button>
            </div>
        )
    }

    if (type === "cmdClient") {
        return (<div style={{display: "flex"}}>
                <Button className="mr-2" color="primary"
                        onClick={event => onChange("")}>{i18n.t('page.admin.invoices.all')}</Button>
                <Button className="mr-2" color="warning"
                        onClick={event => onChange(["PREPARATION"])}>Préparées</Button>
                <Button className="mr-2" color="info"
                        onClick={event => onChange(["ORDERED"])}>{i18n.t('page.admin.commandes.buttonTitleOrdered')}</Button>
                <Button className="mr-2" color="success"
                        onClick={event => onChange(["SEND"])}>{i18n.t('page.admin.commandes.buttonTitleSend')}</Button>
                <Button className="mr-2" color="danger"
                        onClick={event => onChange(["CANCEL"])}>Annulé</Button>
                <Button className="mr-2" color="light"
                        onClick={event => onChange(["DRAFT"])}> En cours ou échec</Button>

            </div>
        )
    }

    if (type === "cmd") {
        return (<div style={{display: "flex"}}>
                <Button className="mr-2" color="primary"
                        onClick={event => onChange("")}>{i18n.t('page.admin.invoices.all')}</Button>
                <Button className="mr-2" color="info"
                        onClick={event => onChange(["ORDERED"])}>{i18n.t('page.admin.commandes.buttonTitleOrdered')}</Button>
                <Button className="mr-2" color="danger"
                        onClick={event => onChange(["SEND"])}>{i18n.t('page.admin.commandes.buttonTitleSend')}</Button>
                <Button className="mr-2" color="secondary"
                        onClick={event => onChange(["RECEPTION"])}>{i18n.t('page.admin.commandes.buttonTitleReception')}</Button>
                <Button className="mr-2" color="success"
                        onClick={event => onChange(["VALID"])}>{i18n.t('page.admin.commandes.buttonTitleValid')}</Button>
                <Button className="mr-2" color="light"
                        onClick={event => onChange(["DRAFT"])}>{i18n.t('page.admin.commandes.buttonTitleOpen')}</Button>

            </div>
        )
    }


    return ""
}

export default StatusHeaderType