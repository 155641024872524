import {inject, observer} from "mobx-react";
import React from "react";
import {withRouter} from "react-router-dom";
import CardBody from "reactstrap/lib/CardBody";
import ChoiceImageInput from "../../../components/Akitatek/Form/ChoiceImageInput";
import Card from "reactstrap/lib/Card";
import {CardHeader} from "reactstrap";
import Col from "reactstrap/lib/Col";
import {SRLWrapper} from "simple-react-lightbox";


const Files = (inject("rootStore")(observer(class Files extends React.Component {

    product;

    get injected() {
        return this.props;
    }

    async componentDidUpdate(prevProps) {
        if (
            this.injected.id !== prevProps.id || this.injected.assets !== prevProps.assets ||
            this.injected.update !== prevProps.update || this.injected.type !== prevProps.type
        ) {

            this.setState({
                id: this.injected.id,
                assets: this.injected.assets,
                type: this.injected.type,
                update: this.injected.update ?? false,
            })
        }
    }

    async initData(product) {
        await this.setState({
            assetName: product?.asset?.filename,
            asset_id: product?.asset?.id,
        })
    }


    constructor(props, context) {
        super(props, context);
        this.state = {
            type: this.injected.type,
            id: this.injected.id,
            update: this.injected.update ?? false,
            assets: this.injected.assets ?? []
        }
    }


    async onSubmit(asset_id) {
        let data = {
            asset_id: asset_id,
            id: this.state.id,
            type: this.state.type,
        }

        const authResponse = await this.injected.rootStore.imageStore.saveRelationAsset(data)
        if (authResponse) {
            this.injected.onCallbackAsset(authResponse.datas)
        }
    }

    async onUpdate(asset_id, data) {
        let datas = {
            asset_id: asset_id,
            id: this.state.id,
            type: this.state.type,
            online: data,
        }

        const authResponse = await this.injected.rootStore.imageStore.updateRelationAsset(datas)
        if (authResponse) {
            this.injected.onCallbackAsset(authResponse.datas)
        }
    }



    async onRemove(asset_id) {
        let data = {
            asset_id: asset_id,
            id: this.state.id,
            type: this.state.type,
        }

        const authResponse = await this.injected.rootStore.imageStore.deleteRelationAsset(data)

        if (authResponse) {
            this.injected.onCallbackAsset(authResponse.datas)

        }
    }

    onPickAssetFilename = (action, id, data) => {

        if (action === "add") {
            let asset_id = this.injected.rootStore.imageStore.pickAsset?.id;
            this.onSubmit(asset_id);
        } else if(action === "delete") {
            this.onRemove(id)
        } else {
            this.onUpdate(id, data)
        }
    }


    render() {
        return (
            <>

                <Card>
                    <CardHeader>
                        <ChoiceImageInput
                            title={true}
                            update={false}
                            assetName={this.state.assetName}
                            onPickAssetFilename={this.onPickAssetFilename}/>
                    </CardHeader>
                    <CardBody>
                        Listes des fichiers
                        <SRLWrapper>
                            <div style={{display: "flex"}}>
                                {this.state.assets.map((asset) => {
                                    return <Col>
                                        <ChoiceImageInput
                                            update={this.state.update}
                                            title={false}
                                            id={asset.id}
                                            online={asset.pivot.online}
                                            assetName={asset.filename}
                                            onPickAssetFilename={this.onPickAssetFilename}/></Col>
                                })}
                            </div>
                        </SRLWrapper>
                    </CardBody>
                </Card>

            </>

        );
    }

})));

export default withRouter(Files);
