import React from "react";
import {inject, observer} from "mobx-react";
import Row from "reactstrap/lib/Row";
import styles from "./PECBoard.module.css"
import tests from '../../components/Akitatek/assets/tests.png'
import cheering from '../../components/Akitatek/assets/cheering_people_icon1.png'
import money from '../../components/Akitatek/assets/money.png'
import sandglass from '../../components/Akitatek/assets/icons8-time-100.png'
import web from '../../components/Akitatek/assets/2000px-Applications-internet.svg.png'
import tool from '../../components/Akitatek/assets/1f527.png'
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import Col from "reactstrap/lib/Col";
import {Carousel} from "react-bootstrap";
import {Animated} from "react-animated-css";
import {CarouselItem} from "reactstrap";
import Moment from "react-moment";
import moment from 'moment/min/moment-with-locales';
import Horloge from "./Horloge";

moment.locale('fr')

// Sets the moment instance to use.
Moment.globalMoment = moment;

// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'fr';

const PECBoard = (inject("rootStore")(observer(class PECBoard extends React.Component {

    constructor(props, context) {
        super(props, context);


        this.state = {
            isVisible: true,
        }
    }



    async tryToConnect() {
        let url = window.location.hash.replace("#/dash/", "")
        let authResponse = await this.injected.rootStore.authStore.loginUrl(url);
        if (authResponse?.status === "success") {
            this.setState({
                logo: authResponse.logo
            })
        }
    }


    componentDidMount() {
        this.injected.rootStore.prisenchargeStore.getPriseEnChargeBoard("", "board")
        this.injected.rootStore.statsStore.getLittleStats()

        this.tryToConnect()
        this.injected.rootStore.getEcho()
            .channel(`pec`)
            .listen('PecEvent', ev => {
                this.injected.rootStore.prisenchargeStore.getPriseEnChargeBoard("", "board")
                this.setState({
                    isVisible: !this.state.isVisible
                })
                this.injected.rootStore.statsStore.getLittleStats()

            })


        this.injected.rootStore.getEcho().connector.socket.on('connect', () => {
            this.injected.rootStore.authStore.isLoggedEcho = true;
            this.injected.rootStore.prisenchargeStore.getPriseEnChargeBoard("", "board")
            this.injected.rootStore.statsStore.getLittleStats()


        });

        this.injected.rootStore.getEcho().connector.socket.on('disconnect', () => {
            this.injected.rootStore.authStore.isLoggedEcho = false;
        });
    }

    get injected() {
        return this.props;
    }


    setCarouselFirst(elems) {
        return (<CarouselItem>
            <CardBody> <Row>
                {elems.map((elem) => {
                    return (
                        this.setElement(elem)
                    )
                })}
            </Row>
            </CardBody>
        </CarouselItem>)
    }

    setElement(elem) {
        if (elem.appareils.length > 0) {
            return (
                elem.appareils?.map((app, key) => {
                    return <Col sm={6} key={key}>
                        <Animated animationIn="pulse" animationOut="rubberBand" animationInDuration={1000}
                                  animationOutDuration={1000} isVisible={this.state.isVisible}>
                            <Card className={this.injected.rootStore.savedDatasStore.setColor(elem.status)} key={key}>
                                <CardBody>
                                    <Row className="align-items-center">
                                        <div className="col-4 text-center">
                                            <h2 className=""> {app.pivot?.casier?.stockage?.name} {app.pivot?.casier?.name}</h2>
                                            <h6 style={{fontStyle: 'italic'}}> {elem.statusname}</h6>
                                        </div>
                                        <div className="col-8">
                                            <p className="m-0">
                                                <h5 className="font-weight-bolder">
                                                    #{elem.id} - {elem.title}
                                                </h5>
                                                <h5> {elem.user?.fullname}
                                                    {elem.user?.profile?.telephone_portable ? " " + this.injected.rootStore.userStore.FrTel(elem.user?.profile?.telephone_portable) + " " : ""}
                                                    {elem.user?.profile?.telephone_fixe ? "/ " + this.injected.rootStore.userStore.FrTel(elem.user?.profile?.telephone_fixe) : ""} </h5>
                                                <h5> {app.appareil?.marque?.nom} {app.appareil?.nom}</h5>
                                                <span>
                                                    <Moment interval={60000} fromNow locale="fr">
                                                       {moment(elem.created_at)}
                                                </Moment>
                                            </span>
                                            </p>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Animated>
                    </Col>
                }))
        } else {
            return <Col sm={6}>
                <Animated animationIn="pulse" animationOut="rubberBand" animationInDuration={1000}
                          animationOutDuration={1000} isVisible={this.state.isVisible}>
                    <Card className={this.injected.rootStore.savedDatasStore.setColor(elem.status)}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-4 text-center">
                                    <h2 className=""> Stockage ?</h2>
                                    <h6 style={{fontStyle: 'italic'}}> {elem.statusname}</h6>
                                </div>
                                <div className="col-8">
                                    <p className="m-0">
                                        <h5 className="font-weight-bolder">
                                            #{elem.id} - {elem.title}
                                        </h5>
                                        <h5> {elem.user?.fullname}
                                            {elem.user?.profile?.telephone_portable ? " " + this.injected.rootStore.userStore.FrTel(elem.user?.profile?.telephone_portable) + " " : ""}
                                            {elem.user?.profile?.telephone_fixe ? "/ " + this.injected.rootStore.userStore.FrTel(elem.user?.profile?.telephone_fixe) : ""}
                                        </h5>
                                        <div> {elem.description}</div>
                                        <span>
                                            <Moment interval={60000} fromNow locale="fr">
                                                {moment(elem.created_at)}
                                                </Moment>
                                            </span>
                                    </p>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Animated>
            </Col>
        }


    }



    setInfosStats(text, image) {
        return (
            <Col sm={6} style={
                {
                    marginTop: "2em",
                    marginBottom: "2em",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    justifyItems: "stretch"
                }
            }>
                <div className={styles.imgFluid + " mr-4"}><img src={image} className={styles.imgFluid}
                                                                alt="Responsive image"/></div>
                <div><h2>{text}</h2></div>
            </Col>)
    }

    setCarouselItem(elem, key) {
        if (elem.appareils.length > 0) {
            return (
                <Carousel.Item>
                    <div style={{textAlign: "center"}}>
                        <h4># {elem.id} - {elem.title}  - {elem.statusname}</h4>
                        <div>Prise en charge {moment(elem.created_at).fromNow()}</div>
                        <br/>
                    </div>
                    <Row>
                        {elem.appareils?.map((app, key) => {
                            return (
                                <Col>
                                    <div>
                                        <div
                                            className="">{app.pivot?.casier?.stockage?.name} {app.pivot?.casier?.name}</div>
                                    </div>
                                    <span
                                        className="font-weight-bolder">{app.appareil?.marque?.nom} {app.appareil?.nom}</span>
                                    <br/>
                                </Col>
                            )
                        })}
                    </Row>
                </Carousel.Item>)
        } else {
            return (
                <Carousel.Item>
                    <div style={{textAlign: "center"}}>
                        <h4># {elem.id} - {elem.title}</h4>
                        <div>{moment(elem.created_at).fromNow()}</div>
                        <br/>
                    </div>
                    <Row>
                        {elem.appareils?.map((app, key) => {
                            return (
                                <Col>
                                    <div>
                                        <div className=""> Stockage ?</div>
                                    </div>
                                    <span className="font-weight-bolder">{elem.description} </span> <br/>
                                </Col>
                            )
                        })}
                    </Row>
                </Carousel.Item>)
        }
    }

    render() {
        return (
            <>
                <div className={"boardPec"}>
                    <Horloge></Horloge>
                    <div className={"container-fluid pt-5 min-vh-100 " + styles.backgroundMain}>
                        {this.injected.rootStore.authStore.isLoggedDash ? (
                                <div>
                                    <Carousel
                                        controls={false}
                                        keyboard={false}
                                        touch={false}
                                        pause={false}
                                        interval={this.state.transitionTime}
                                        style={{
                                            display: "flex",
                                            height: "70em",
                                            flexWrap: "wrap",
                                            alignContent: "start"

                                        }}>
                                        {this.injected.rootStore.prisenchargeStore.priseenchargesBoard.length > 0 && (
                                            this.injected.rootStore.prisenchargeStore.priseenchargesBoard.map((elems, key) => {
                                                return (this.setCarouselFirst(elems, key))
                                            })
                                        )}
                                    </Carousel>
                                    <div>
                                        <Card>
                                            <CardHeader className={"bg-warning"}>
                                                <h2 className="text-center"> {this.injected.rootStore.prisenchargeStore.priseEnChargeWait.length} PEC
                                                    en attente</h2>
                                            </CardHeader>
                                            <CardBody>
                                                <Carousel
                                                    controls={false}
                                                    keyboard={false}
                                                    touch={false}
                                                    pause={false}
                                                    interval={this.state.transitionTime}
                                                    style={{
                                                        height: "8em"
                                                    }}
                                                >
                                                    {this.injected.rootStore.prisenchargeStore.priseEnChargeWait.map((elems, key) => {
                                                        return this.setCarouselItem(elems, key)

                                                    })}
                                                </Carousel>
                                            </CardBody>
                                        </Card>

                                        <Card>
                                            <CardHeader>
                                                <h1 className="text-center"> Statistiques</h1>
                                            </CardHeader>
                                            <Carousel
                                                controls={false}
                                                keyboard={false}
                                                touch={false}
                                                pause={false}
                                                interval={this.state.transitionTime}
                                            >
                                                <CarouselItem>
                                                    <CardBody>

                                                        <Row>
                                       {/*                     {this.setInfosStats("?  abonnés", youtube)}
                                                            {this.setInfosStats("?  abonnés", fb)}*/}
                                                            {this.setInfosStats(this.injected.rootStore.statsStore.boardStats.waitPaiement + " PEC en attente de paiement", money)}
                                                            {this.setInfosStats(this.injected.rootStore.statsStore.boardStats.waitTests+ " PEC en attente de tests", tests)}
                                                            {this.setInfosStats(this.injected.rootStore.statsStore.boardStats.newClient + "  nouveaux clients ce mois / Total : " + this.injected.rootStore.statsStore.boardStats.totalClient, web)}
                                                            {this.setInfosStats(this.injected.rootStore.statsStore.boardStats.waitReparation + " PEC en cours", sandglass)}
                                                            {this.setInfosStats(this.injected.rootStore.statsStore.boardStats.nbClient + " clients facturés ce mois", cheering)}
                                                            {this.setInfosStats(this.injected.rootStore.statsStore.boardStats.nbReparation + " pec terminées ce mois / Total PEC : " + this.injected.rootStore.statsStore.boardStats.totalReparation, tool)}
                                                        </Row>
                                                    </CardBody>
                                                </CarouselItem>
                                            {/*    <CarouselItem>
                                                    <CardBody>

                                                        <Row>
                                                            <Col sm={12} className={"mb-4"}>
                                                                <Alert severity="error">
                                                                    <h2> A rendre ou A faire</h2>
                                                                </Alert>
                                                            </Col>
                                                            <Col sm={12} className={"mb-4"}>
                                                                <Alert severity="warning">
                                                                    <h2> En attente Pièces ou Devis — <strong> Commande de
                                                                        pièces, Réponse Client ou Paiement</strong></h2>
                                                                </Alert>
                                                            </Col>
                                                            <Col sm={12} className={"mb-4"}>
                                                                <Alert severity="info">
                                                                    <h2>Diagnostic / En cours </h2>
                                                                </Alert></Col>
                                                            <Col sm={12} className={"mb-4"}>
                                                                <Alert severity="success">
                                                                    <h2> Terminé </h2>
                                                                </Alert>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </CarouselItem>*/}
                                            </Carousel>

                                        </Card>
                                    </div>
                                </div>) :
                            <div></div>}
                    </div>
                </div>
            </>
        );
    }
})));

export default PECBoard;
