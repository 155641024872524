import {inject, observer} from "mobx-react";
import React from "react";
import {Container} from "react-bootstrap";
import GenericTableAffichageWithDatas from "../../../components/Akitatek/Table/GenericTableAffichageWithDatas";


const Day = (inject("rootStore")(observer(class Day extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Mes envois du jour", "Gestion des envois", "commande.jpg")
    }

    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericTableAffichageWithDatas
                        api={this.injected.rootStore.commandeClientStore}
                        filters={{
                            name: "created_at",
                            order: false,
                            page: "1",
                            limit: "10",
                            last_page: "1",
                            status: ["ORDERED", "PREPARATION"],
                            search: "",
                            user_id: ""
                        }}
                        status={"ORDERED"}
                        icon={<i className="fa fa-user-circle mr-2" aria-hidden="true"/>}
                        name={"Mes commandes clients à envoyer"}
                        type="cmdClient"/>

                    <GenericTableAffichageWithDatas
                        api={this.injected.rootStore.prisenchargeInternetStore}
                        status={"TO_SEND_BACK"}
                        filters={{
                            limit: "10",
                            search: "",
                            page: "1",
                            last_page: "1",
                            name: "updated_at",
                            order: false,
                            online: "1",
                            status: ["TO_SEND_BACK"]
                        }}
                        icon={<i className="fa fa-internet-explorer mr-2"
                                 aria-hidden="true"/>}
                        name={"Mes Prises en Charge à renvoyer"}
                        type="pecinternet"/>
                </Container>
            </>
        )
    }
})));

export default Day;
