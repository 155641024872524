import {decorate, observable} from "mobx";
import Api from "../util/api";

export default class ModeStore {

    pages = observable([]);

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }


    async updateMode(datas, fields = []) {
        this.rootStore.savedDatasStore.setLoading(true)

        let data = {
            mode: datas,
            datas: fields
        }

        let authResponse = await this.api.resource.update("boardmode", data);
        this.rootStore.savedDatasStore.setLoading(false)
        return authResponse
    }




}

decorate(ModeStore, {
    pages: observable
});

