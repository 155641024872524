import React from "react";
import {inject, observer} from "mobx-react";
import moment from "moment";
import {Link} from "react-router-dom";
import getRoutes from "../../../components/Akitatek/getRoutes";
import Button from "react-bootstrap/Button";


const TableLotRow = inject("rootStore")(observer(class TableLotRow extends React.Component {

    item;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }


    render() {
        const {type, item} = this.props
        var prix = ""
        var quantity = ""
        if (type === "lot" || type === "lots") {
            prix  = item.commande_produit?.prix_unitaire
            quantity = item.stock
        } else {
            prix = item.prix_unitaire
            quantity = item.quantity
        }


        return (
            <>
                <tr style={{textAlign: "center"}}>
                    <th>
                        <span className="mb-0 text-sm">
                                <Link style={{color: "#20a8d8"}} to={{
                                    pathname: getRoutes("Modifier stock", item?.product?.id),
                                    aboutProps: {
                                        stock: item?.product
                                    }
                                }}>
                                    <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
                                    {item?.product?.nom} {(type === "lots" || type === "lot") && "/ " + item?.pid}
                                </Link>
                        </span>
                    </th>

                    <td>
                        <Link to={{
                            pathname: getRoutes("Gérer une commande", item?.commande_id),
                        }}>
                            #{ item?.commande_id}

                        </Link>
                    </td>

                    {type === "lots" && (
                        <td>
                        <span className="mb-0 text-sm">
                            { item?.fournisseur?.nom}
                        </span>
                        </td>)}

                    <td>
                        <span className="mb-0 text-sm">
                            {prix} €
                        </span>
                    </td>

                    <td>
                        <span className="mb-0 text-sm">
                            {quantity}
                        </span>
                    </td>

                    <td className="th-price">
                        <span className="mb-0 text-sm">
                            {moment(item?.created_at).format("DD/MM/YYYY")}
                        </span>
                    </td>
                    {/*{type === "lots" && (*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <FormControl component="fieldset">*/}
                    {/*                <FormLabel component="legend">Impression par :</FormLabel>*/}
                    {/*                <RadioGroup row aria-label="position" name="position"*/}
                    {/*                            defaultValue={qtePrint}*/}
                    {/*                            onChange={e => {*/}
                    {/*                                this.setState({qtePrint: e.target.value})*/}
                    {/*                            }}>*/}
                    {/*                    <FormControlLabel value="1" control={<Radio/>} label="Lot(1)"/>*/}
                    {/*                    <FormControlLabel value={quantite.toString()} control={<Radio/>}*/}
                    {/*                                      label={"Quantite(" + quantite + ")"}/>*/}
                    {/*                </RadioGroup>*/}
                    {/*            </FormControl>*/}
                    {/*        </div>*/}
                    {/*    </td>)}*/}
                    {type === "lots" && (
                        <td>
                            {this.injected.rootStore.authStore.modules['print'] === 1 && (
                                <Button variant="warning"
                                        size="sm" type="button" className="mr-1"
                                        onClick={e => this.injected.rootStore.stockStore.downloadPdf(e, item?.product?.id, item?.id, "", "stockclient")}
                                >
                                    <i className="fa fa-print"/>
                                </Button>)}

                            <span className="mb-0 text-sm">

                                    <Button
                                        variant="primary"
                                        size="sm" type="button" className="mr-1"
                                        onClick={e => this.injected.rootStore.stockStore.openPdf(e, item?.id, "stockclient")}>
                                                                    <i className="fa fa-eye"/>
                            </Button>
                       {/*<Button*/}
                       {/*    onClick={(e) => {*/}
                       {/*        this.onUpdate(e);*/}
                       {/*    }} variant="success" size="sm" type="button" className="mr-1">*/}
                       {/*                             <i className="fa fa-check"/>*/}
                       {/*     </Button>*/}
                        </span>
                        </td>)}

                    {type === "lots" && (
                        <td>
                            {this.injected.rootStore.authStore.modules['print'] === 1 && (
                                <Button variant="warning"
                                        size="sm" type="button" className="mr-1"
                                        onClick={e => this.injected.rootStore.stockStore.downloadPdf(e, item?.product?.id, item?.id, "", "stock")}
                                >
                                    <i className="fa fa-print"/>
                                </Button>)}

                            <span className="mb-0 text-sm">

                                    <Button
                                        variant="primary"
                                        size="sm" type="button" className="mr-1"
                                        onClick={e => this.injected.rootStore.stockStore.openPdf(e, item?.id, "stock")}>
                                                                    <i className="fa fa-eye"/>
                            </Button>
                        </span>
                        </td>)}
                </tr>
            </>
        );
    }

}));

export default TableLotRow;
