import Api from "../util/api";
import {action, decorate, observable} from "mobx";

export default class StockageCasierStore {

    stockages = observable([]);
    casiers = observable([]);
    currentPage = 0;
    lastPage = 1;
    limit = 15
    pageNumber = 1
    treesCategories = observable([])
    treeCategory = 1;
    treesCategoriesId = observable([])
    stockage;
    selectionCategory

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    async getStockageCasier() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stockageCasier.getStockageCasier();

            if (this.stockages.length > 0) {
                this.stockages.replace(authResponse)
            } else {
                this.stockages = authResponse;
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }

    async getCasiers() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stockageCasier.getCasiers();

            if (this.casiers.length > 0) {
                this.casiers.replace(authResponse)
            } else {
                this.casiers = authResponse;
            }

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }

    async findCasier(id) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stockageCasier.findCasier(id);
            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async findStockage(stockageId) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stockageCasier.findStockage(stockageId);

            if (authResponse?.status === "success") {
                this.treesCategories = authResponse.datas
                // this.treesCategoriesId = authResponse.data.flat()
            }

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }

    async createStockage(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.stockageCasier.createStockage(myData);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Stockage " + authResponse.data.id + " sauvegardée", "success");
                this.getStockageCasier()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async createCategory(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.stockageCasier.createCategory(myData);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Casier " + authResponse.datas.name + " sauvegardée", "success");
                this.findStockage(myData.stockage_id)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateStockage(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.stockageCasier.updateStockage(myData);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Stockage #" + authResponse.data.id + " modifiée", "success");
                this.getStockageCasier()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async updateCategory(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.stockageCasier.updateCategory(myData);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Casier #" + authResponse.datas.name + " modifiée", "success");
                this.findStockage(this.stockage?.id)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deleteStockage(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.stockageCasier.deleteStockage(myData);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Stockage " + myData + " supprimée", "success");
                this.getStockageCasier()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deleteCategory(id) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.stockageCasier.deleteCategory(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Category " + id + " supprimée", "success");
                this.findStockage(this.stockage?.id)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    setCategories(categories) {
        this.treesCategories.replace(categories)
    }

}

decorate(StockageCasierStore, {
    stockages: observable,
    casiers: observable,
    getCasier: action,
    getStockageCasier: action,
    currentPage: observable,
    pageNumber: observable,
    lastPage: observable,
    limit: observable,
    stockage: observable,
    treesCategories: observable,
    treesCategoriesId: observable,
    treeCategory: observable,
});