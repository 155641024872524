import React from "react";
import ErrorBoundary from "../../../components/Akitatek/Error/ErrorBounday";
import {Link} from "react-router-dom";
import getRoutes from "../../../components/Akitatek/getRoutes";
import {inject, observer} from "mobx-react";

const CasierContent = (inject("rootStore")(observer(class Casier extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            data: ""
        }
    }

    get injected() {
        return this.props;
    }

    async componentDidMount() {
        let id = this.props.id
        const authResponse = await this.injected.rootStore.stockageCasierStore.findCasier(id);
        this.setState({
            data: authResponse?.datas
        })
    }

    render() {
        return (
            <>
                <ErrorBoundary>
                    <div>
                        {
                            this.state.data?.stock && this.state.data?.stock?.map((element) => {
                                return <Link style={{color: "#20a8d8"}}
                                             key={element?.id}
                                             to={{
                                                 pathname: getRoutes("Modifier stock", element.id),
                                                 aboutProps: {
                                                     stock: element
                                                 }
                                             }}>
                                    <li> {element.nom} - {element.stock} </li>
                                </Link>
                            })

                        }
                    </div>
                    <div>
                        {
                            this.state.data?.produits && this.state.data?.produits?.map((element) => {
                                return <Link style={{color: "#20a8d8"}}
                                             key={element?.id}
                                             to={{
                                                 pathname: getRoutes("Gérer une prise en charge", element.prise_en_charge_id, "0"),
                                                 aboutProps: {
                                                     stock: element
                                                 }
                                             }}>
                                    <li> {element.user_app?.appareil?.marque?.nom} {element.user_app?.appareil?.nom} - 1 </li>
                                </Link>
                            })

                        }
                    </div>

                </ErrorBoundary>
            </>
        );
    }
})));

export default CasierContent;
