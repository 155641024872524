import React from "react";
import {Button, Container, UncontrolledTooltip} from "reactstrap";
import {inject, observer} from "mobx-react";
import GenericStaticCard from "../../../components/Akitatek/Card/GenericStaticCard";
import Card from "react-bootstrap/Card";
import configuration from "../../../configuration/configuration";
import Pagination from "../../../components/Akitatek/Pagination";

import {SRLWrapper} from 'simple-react-lightbox'
import {Link} from "react-router-dom";

const Asset = (inject("rootStore")(observer(class Asset extends React.Component {


    constructor(props, context) {
        super(props, context);

        this.injected.rootStore.savedDatasStore.setHeader("Images", "Gestion des images", "motherboard.jpg",  this.injected.rootStore.imageStore)
    }

    UNSAFE_componentWillMount() {
        this.injected.rootStore.imageStore.getDatas();
    }

    get injected() {
        return this.props;
    }

    async toggleDelete(e, id) {
        e.preventDefault()

        await this.injected.rootStore.notificationStore.setWarning("Image #" + id, event => this.remove(id), true)
    }

    async remove(id) {
        await this.injected.rootStore.imageStore.delete(id)
    }

    parentCallback = (childData) => {
        this.injected.rootStore.imageStore.filter.page = childData
        this.injected.rootStore.imageStore.getDatas();
    };


    async copyToClipboard(copy) {
        navigator.clipboard.writeText(copy)
        await this.injected.rootStore.notificationStore.setNotification(copy + " copié ! ", "success")

    }


    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericStaticCard element={
                        <h4 className="mb-0">
                            <i className="fa fa-picture-o mr-2" aria-hidden="true"/>
                            {this.injected.rootStore.imageStore.filter.search !== "" ? (
                                "Recherche " + this.injected.rootStore.imageStore.filter.search
                            ) : (
                                "Mes Images"
                            )

                            }
                        </h4>
                    }>
                        <SRLWrapper>
                            <div style={{display: "flex", flexWrap: "wrap"}}>

                                {this.injected.rootStore.imageStore.datas
                                    .map((data, id) => {
                                        return (

                                            <Card className={"mr-4"} key={id} style={{flex: "0 0 10%"}}>
                                                <Card.Header style={{display: "flex"}}>
                                                    <Button id={"UncontrolledRemove"}
                                                            onClick={e => this.toggleDelete(e, data.id)}
                                                            className={"bg-danger"}> <i className="fa fa-close"></i>
                                                        <UncontrolledTooltip placement="top"
                                                                             target={"UncontrolledRemove"}>
                                                            Supprimer
                                                        </UncontrolledTooltip>
                                                    </Button>
                                                    <Link to={{
                                                        pathname: "/images/modifier/" + data.id,
                                                        aboutProps: {
                                                            file: data
                                                        }
                                                    }}>

                                                        <Button className={"bg-info"} id={"UncontrolledEdit"}
                                                        > <i className="fa fa-edit"></i>
                                                        </Button>

                                                        <UncontrolledTooltip placement="top"
                                                                             target={"UncontrolledEdit"}>
                                                            Editer
                                                        </UncontrolledTooltip>
                                                    </Link>
                                                    <Button className={"bg-primary"}
                                                            id={"UncontrolledCopier"}
                                                            onClick={e => this.copyToClipboard(configuration.PhotoBase + data.filename)}
                                                    > <i className="fa fa-copy"></i>
                                                        <UncontrolledTooltip placement="top"
                                                                             target={"UncontrolledCopier"}>
                                                            Copier
                                                        </UncontrolledTooltip>
                                                    </Button>
                                                </Card.Header>


                                                <Card.Img
                                                    style={{height: '10rem', objectFit: "contain"}} variant="top"
                                                    src={configuration.PhotoBase + data.filename}/>


                                                <Card.Subtitle className={"m-2"}> {data.nom}</Card.Subtitle>


                                                {this.injected.rootStore.imageStore.filter.search  !== "" && (
                                                    <Card.Subtitle className={"m-2"}>
                                                        {data.tags.filter(tag => tag.nom.includes(this.injected.rootStore.imageStore.filter.search )).map(tag => {
                                                            return <span>#{tag.nom}</span>
                                                        })}
                                                    </Card.Subtitle>
                                                )}


                                            </Card>


                                        )
                                    })
                                }
                            </div>
                        </SRLWrapper>

                        <Pagination
                            currentPage={this.injected.rootStore.imageStore.filter.page}
                            lastPage={this.injected.rootStore.imageStore.filter.last_page}
                            parentCallback={this.parentCallback}/>
                    </GenericStaticCard>

                </Container>
            </>
        );
    }
})));

export default Asset;
