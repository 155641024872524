import React from "react";
import {inject, observer} from "mobx-react";
import Button from "react-bootstrap/Button";
import {Input} from "reactstrap";
import {Link} from "react-router-dom";
import getRoutes from "../../../../components/Akitatek/getRoutes";
import ModalAkitatek from "../../../../components/Akitatek/Modal/Modal";
import Casier from "../../Casier/Casier";

const MachineUserRow = inject("rootStore")(observer(class MachineUserRow extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            addStockage: false
        }
        this.toggleDanger = this.toggleDanger.bind(this);
    }

    toggleDanger() {
        this.injected.rootStore.notificationStore.setWarning("Oui", event => this.onDelete())
    }

    async onDelete() {
        this.injected.onCallbackAction(this.props.index, "delete")
    }

    async onUpdate(e) {
        if(e) {
            e.preventDefault()
        }
        this.injected.onCallbackAction(this.props.index, "update")
    }

    enter(e) {
        if (e.key === 'Enter') {
            this.onUpdate()
        }

        if (e.key === 'Tab') {
            this.onUpdate()
        }
    }

    onCloseClearStockage = async () => {
        this.setState({
            addStockage: false
        })
        await this.injected.callbackOnChange(this.props.index, "casier", "")
        this.injected.callbackOnChange(this.props.index, "casier", "")
    };

    onCloseStockage = async (childData) => {
        this.setState({
            addStockage: false
        })

        await this.injected.callbackOnChange(this.props.index, "casier", childData)
        this.injected.onCallbackAction(this.props.index, "update")

    };

    onPec(e) {
        e.preventDefault()
        this.injected.callbackChange("typeAddMachine", "search")
        this.injected.reload("productSearchExist", this.props.appareil)
        this.injected.reload("prestationsApp", this.props.prestations)

        if (document.getElementById('prestationCard')) {
            document.getElementById('prestationCard').scrollIntoView()
        }

    }

    //Refresh
    onChange(e) {
        this.injected.callbackOnChange(this.props.index, e.target.name, e.target.value)
    };

    render() {
        const {serial, code, defauts, nom, marque, id, accessoires, casier, prestations, type} = this.props

        return (
            <>
                <tr>
                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                            <Link to={{
                                pathname: "/marques/update/" + marque?.id
                            }}>
                                {marque?.nom}
                            </Link>
                        </span>
                    </th>
                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                            <Link to={{
                                pathname: getRoutes("Afficher un Produit", id)
                            }}>
                                {nom}
                            </Link>
                        </span>
                    </th>
                    <th scope="row" className="text-center">
                        <Input
                            onKeyDown={e => this.enter(e)}
                            className="form-control-alternative"
                            type={"text"}
                            placeholder={"Serial"}
                            name="serial"
                            value={serial}
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                        />
                    </th>
                    <th scope="row" className="text-center">
                        <Input
                            onKeyDown={e => this.enter(e)}
                            className="form-control-alternative"
                            type={"text"}
                            placeholder={"Code"}
                            name="code"
                            value={code}
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                        />
                    </th>
                    <th scope="row" className="text-center">
                        <Input
                            onKeyDown={e => this.enter(e)}
                            className="form-control-alternative"
                            type={"textarea"}
                            placeholder={"Defauts..."}
                            value={defauts}
                            name="defauts"
                            onChange={(e) => {
                                this.onChange(e)
                            }}
                        />
                    </th>

                    {type === "pec" && (
                        <React.Fragment>
                            <th scope="row" className="text-center">
                                <Input
                                    onKeyDown={e => this.enter(e)}
                                    className="form-control-alternative"
                                    type={"textarea"}
                                    placeholder={"Accessoires"}
                                    value={accessoires}
                                    name="accessoires"
                                    onChange={(e) => {
                                        this.onChange(e)
                                    }}
                                />
                            </th>
                            <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                        {prestations?.map((presta) => {
                            return (<p><Link to={{
                                pathname: getRoutes("Modifier une prestation", presta.id),
                                aboutProps: {
                                    stock: presta.id
                                }
                            }}>
                                {presta.title} : {presta.pivot?.total_actual} € TTC
                            </Link></p>)
                        })}

                            <p>
                        <i onClick={e => this.onPec(e)} className="fa fa-search" style={{cursor: "pointer"}}
                           aria-hidden="true"></i>
                        </p>
                        </span>
                            </th>
                            <th>
                                {casier && (
                                    <div className={"mr-2"}> {casier.name} - {casier.stockage.name}</div>
                                )}
                                <Button
                                    onClick={e => this.setState({addStockage: true})}
                                    variant="warning" size="sm" type="button" className="mr-1">
                                    Choisir un stockage
                                </Button>
                            </th>
                        </React.Fragment>
                    )}

                    <td>
                        <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                            <Button
                                onClick={(e) => {
                                    this.onUpdate(e, true);
                                }} variant="success" size="sm" type="button" className="mr-1">
                                <i className="fa fa-check"/>
                            </Button>

                            <Button
                                onClick={(e) => {
                                    this.toggleDanger()
                                }} variant="danger" size="sm" type="button" className="mr-1">
                                <i className="fa fa-minus"/>
                            </Button>
                        </div>
                    </td>
                </tr>


                <ModalAkitatek title={"Choisir un stockage"} show={this.state.addStockage}
                               children={<Casier onSelection={false} onCallback={this.onCloseStockage}></Casier>}
                               onCallback={this.onCloseClearStockage}/>
            </>
        );
    }

}));

export default MachineUserRow;
