import React from "react";

function EnabledRow({data}) {
return (<td className="text-center">
                        <span className="mb-0 text-sm">
                            {data === 1 ? <span className="badge badge-pill badge-success">Actif</span>
                                : <span className="badge badge-pill badge-warning">Inactif</span>
                            }
                        </span>
</td>)
}

export default EnabledRow;
