import axios from "axios";
import configuration from "../configuration/configuration";

export default class CarrouselAPI {

    async getCarrousels() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/carrousel`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    createCarrousel(datas) {
        return new Promise(async (resolve, reject) => {
            try {

                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/carrousel`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data:    datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    updateCarrousel(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'PUT',
                    url: `${configuration.API}/api/carrousel`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data:  datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }
}