import Api from "../util/api";
import {action, decorate, observable} from "mobx";


export default class CommandeClientStore {
    datas = observable([]);

    filter = {
        name : "updated_at",
        order: false,
        page: "1",
        status: [""],
        limit: "15",
        search: "",
        last_page: "1",
        user_id: ""
    }

    defaultFilter = {
        name : "updated_at",
        order: false,
        page: "1",
        limit: "15",
        last_page: "1",
        status: [""],
        search: "",
        user_id: ""
    }
    statusname = "";
    stats = observable([]);;
    allStatus = observable([]);


    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    getStatusname() {
        this.statusname = ""
        this.allStatus.forEach(element => {
            if (element.value === this.filter.status[0]) {
                this.statusname = element.label
            }}
        );
    }

    async expediate(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.commandesClient.expediate(datas);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Commande #" + authResponse.datas.id + " expédiée",   "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getAllStatus()  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            this.allStatus = await this.api.commandesClient.getAllStatus();

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

        this.rootStore.savedDatasStore.setLoading(false)
    }


    async getStats()  {
        this.rootStore.savedDatasStore.setLoading(true)

        console.log("pay")
        try {
            let authResponse = await this.api.resource.get("getCommandeClient", "");
            this.stats = authResponse
            this.rootStore.savedDatasStore.setLoading(false)
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.commandesClient.getCommandes(this.filter);

            if (this.datas.length > 0) {
                this.setCommandes(authResponse.data)
            } else {
                this.datas = authResponse.data;
            }

            this.filter.page = authResponse.current_page;
            this.filter.last_page = authResponse.last_page;
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getDatasOnly(filters) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.commandesClient.getCommandes(filters ?? this.filter);
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    setStatus(status) {
        if (this.status !== status) {
            this.status = status

        }
    }



    async validate(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.commandesClient.validate(datas);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Commande préparée",   "success");
            } else {
                this.rootStore.notificationStore.setNotification( authResponse.message ,   "error");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async commande(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.commandesClient.commande(datas);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Ajout validé",   "success");
            } else {
                this.rootStore.notificationStore.setNotification( authResponse.message ,   "error");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async update(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.commandesClient.update(datas);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Commande Client #" + authResponse.datas.id + " modifiée",   "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async getCommandeForId(id) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.commandesClient.find(id);
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.datas;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async cancelCommande(id, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.commandesClient.cancelCommande(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Commande Client #" + authResponse.datas.id + " annulée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    setCommandes(datas) {
        this.datas.replace(datas)
    }
}

decorate(CommandeClientStore, {
    filter:observable,
    datas: observable,
    getDatas: action,
    allStatus: observable,
    statusname: observable,
    stats: observable,
    defaultFilter: observable
});