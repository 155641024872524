import React from "react";
import GenericStaticCard from "../../../../components/Akitatek/Card/GenericStaticCard";
import PickAction from "./PickAction";
import PickProductCard from "./PickProductCard";
import configuration from "../../../../configuration/configuration";
import HeaderMarque from "./HeaderMarque";

function ChoixElement({datas, onBack, text, type, ancre, callbackOnChange, reload, sendDataBack}) {
    return datas?.length > 0 ? (
        <GenericStaticCard element={
            <HeaderMarque
                          data={sendDataBack}
                          onBack={onBack}
                          text={text}
                          reload={reload}
                          name={type}/>
        }>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {type !== "prestationPec" && (
                    <PickAction
                        key={"add" + sendDataBack?.id}
                        reload={reload}
                        data={sendDataBack}
                        type={type}
                    />)}

                {type === "prestation" && (
                    <PickAction
                        key={"prestationRien" + sendDataBack?.id}
                        reload={reload}
                        data={sendDataBack}
                        type={"prestationRien"}/>)}

                {datas
                    .map((data, id) => {
                        return (
                            <PickProductCard
                                key={"card" + id}
                                data={data}
                                name={type}
                                reload={reload}
                                callbackOnChange={callbackOnChange}
                                url={data.asset ?? configuration.PhotoBase + data.asset?.filename}
                            />
                        )
                    })
                }
            </div>
        </GenericStaticCard>
    ) : (<GenericStaticCard element={
            <HeaderMarque
                data={sendDataBack}
                onBack={onBack}
                text={text}
                reload={reload}
                name={type}/>
        }>
            <div id={ancre} style={{display: "flex", flexWrap: "wrap"}}>
                <PickAction
                    reload={reload}
                    data={sendDataBack}
                    type={type}
                />


                {type === "prestation" && (
                    <PickAction
                        key={"prestationRien" + sendDataBack?.id}
                        reload={reload}
                        data={sendDataBack}
                        type={"prestationRien"}/>)}
            </div>
        </GenericStaticCard>
    )
}

export default ChoixElement