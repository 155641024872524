import {Button, Col, Form, Row} from "reactstrap";
import i18n from "i18next";
import PlaceAutoComplete from "../../../../components/Akitatek/PlaceAutoCompletes";
import React from "react";
import {inject, observer} from "mobx-react";
import FormControlInput from "../../../../components/Akitatek/Form/FormControlInput";

const CustomerAdresse = inject("rootStore")(observer(class CustomerAdresse extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            typeAccount: "clientaccount"
        }
    }

    //Submit
    onSubmit(e) {
        e.preventDefault()
        this.injected.onCallbackSubmit("userProfile")
    };

    //Refresh
    onChange(e) {
        this.injected.callbackOnChange(e.target.name, e.target.value)
    };

    handleChange = (address, city, postcode) => {
        this.injected.callbackOnChange("adresse_rue", address)

        if(city) {
            this.injected.callbackOnChange("ville",city)
        }

        if(postcode) {
            this.injected.callbackOnChange("codepostal", postcode)
        }
    };

    setForm() {
        const {typeAccount, adresse_name, adresse_firstname, adresse_entreprise, adresse_rue, adresse_ruesuite, telephone_portable, telephone_fixe, codepostal, ville} = this.props
        const displayPrenom = !!(typeAccount === 'clientaccount' | typeAccount === 'clientnoaccount')

        return (
            <Form style={{width: "100%"}}
                  role="form"
                  id="myFormUpdate"
                  onSubmit={e => this.onSubmit(e)}
            >
                <div className="pl-lg-4">
                    <h4> Adresse de facturation principale </h4>
                    <Row>
                        {!displayPrenom && (
                            <FormControlInput
                                placeholder="Nom de l'entreprise"
                                name="adresse_entreprise"
                                value={adresse_entreprise}
                                required={"required"}
                                onChange={(e) => {
                                    this.onChange(e)
                                }}
                            />)}
                    </Row>
                    <Row>
                        <FormControlInput
                            placeholder={i18n.t('page.admin.customers.name')}
                            name="adresse_name"
                            value={adresse_name}
                            required={"required"}
                            onChange={(e) => {
                                this.onChange(e)
                            }}/>
                        <FormControlInput
                            placeholder={i18n.t('page.admin.customers.firstname')}
                            name="adresse_firstname"
                            value={adresse_firstname}
                            onChange={(e) => {
                                this.onChange(e)
                            }}/>
                    </Row>
                </div>
                {/* Address */}
                <div className="pl-lg-4">
                    <Row>
                        <Col md="6">
                            <PlaceAutoComplete adresse={adresse_rue} handleChange={this.handleChange}/>
                        </Col>
                        <FormControlInput
                            placeholder={i18n.t('page.admin.customers.address2')}
                            name="adresse_ruesuite"
                            value={adresse_ruesuite}
                            onChange={(e) => {
                                this.onChange(e)
                            }}/>
                    </Row>
                    <Row>
                        <FormControlInput
                            placeholder={i18n.t('page.admin.customers.city')}
                            name="ville"
                            value={ville}
                            onChange={(e) => {
                                this.onChange(e)
                            }}/>

                        <FormControlInput
                            placeholder={i18n.t('page.admin.customers.postal')}
                            name="codepostal"
                            value={codepostal}
                            onChange={(e) => {
                                this.onChange(e)
                            }}/>
                    </Row>

                    <Row>
                        <FormControlInput
                            placeholder={i18n.t('page.admin.customers.phone')}
                            name="telephone_portable"
                            value={telephone_portable}
                            onChange={(e) => {
                                this.onChange(e)
                            }}/>

                        <FormControlInput
                            placeholder={i18n.t('page.admin.customers.phoneHome')}
                            name="telephone_fixe"
                            value={telephone_fixe}
                            onChange={(e) => {
                                this.onChange(e)
                            }}/>
                    </Row>

                </div>

                <div className="pl-lg-4" style={{textAlign: "center"}}>
                    <Row>
                        <Col>
                            <Button color="success" type="submit">
                                {i18n.t('button.update')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        )
    }

    render() {
        return this.setForm()
    }
}))

export default CustomerAdresse