import React from "react";
import {Input} from "reactstrap";

function InputTitle({value, onChange, name, placeholder}) {
    return <Input
        style={{textAlign: "center"}}
        className={"mb-3"}
        placeholder={placeholder}
        id="input-title"
        type="text"
        name= {name}
        value={value}
        onChange={onChange}
    />
}

export default InputTitle;
