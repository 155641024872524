import React from "react";
import {Checkbox} from "@material-ui/core";
import moment from "moment";
import {inject, observer} from "mobx-react";

const ToDoListGet = (inject("rootStore")(observer(class ToDoListGet  extends  React.Component {
    constructor(props, context) {
        super(props, context);
    }

    get injected() {
        return this.props;
    }

    async onSubmit(e, data) {
        e.preventDefault()
        data.isDone = data.isDone === 1 ? 0 : 1;
        await this.injected.rootStore.todoStore.update(data)
    }

    render() {
        const {data} = this.props
        return (
            data?.isDone === 0 ? (
                    <div style={{ display: "flex", alignItems: "center"}}>
                        <Checkbox checked={data.isDone} onClick={e => this.onSubmit(e, data)}/>
                        <h4>{data.content}</h4>
                        <div className="ml-2" style={{ fontStyle: "italic"}}> {" Le " + moment(data.created_at).format("lll") + " " + " par " + data.user?.fullname } </div>
                    </div>
                ) :
                    (
                        <div style={{ display: "flex", alignItems: "center", textDecoration: "line-through"}}>
                            <Checkbox checked={data.isDone} onClick={e => this.onSubmit(e, data)}/>
                            <h4>{data.content}</h4>
                            <div className="ml-2" style={{ fontStyle: "italic"}}> {" Le " + moment(data.date_done).format("lll") + " " + " par " + data.doer?.fullname } </div>
                        </div>
                    )

        );
    }
})));

export default ToDoListGet