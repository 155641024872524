import React from "react";
import Card from "react-bootstrap/Card";
import {inject, observer} from "mobx-react";
import ErrorBoundary from "../../../../components/Akitatek/Error/ErrorBounday";
import styles from "./Pec.module.css";
import configuration from "../../../../configuration/configuration";

const PickProductCard = inject("rootStore")(observer(class PickProductCard extends React.Component {
    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }

    async addMarque(data) {
        this.injected.reload("marque", data)

        if (document.getElementById('productCard')) {
            document.getElementById('productCard').scrollIntoView()
        }
    }


    async onLoadProduct(data) {
        this.injected.reload("product", data)

        if (document.getElementById('prestationCard')) {
            document.getElementById('prestationCard').scrollIntoView()
        }
    }

    getLoad(e, data) {
        e.preventDefault();

        if (this.props.name === "produit") {
            this.onLoadProduct(data)
        }

        if (this.props.name === "marque") {
            this.addMarque(data)
        }

        if (this.props.name === "prestation") {
            this.injected.reload("valid", data)
        }

        if (this.props.name === "prestationPec") {
            this.injected.reload("remove", data)
        }
    }

    title(data) {
        if (this.props.name === "produit") {
            return data.nom
        }

        if (this.props.name === "marque") {
            return data.nom
        }

        if (this.props.name === "prestation") {
            return data.title + " : " + data.global_price_mo + "€ TTC"
        }

        if (this.props.name === "prestationPec") {
            return data.title + " : " + data.global_price_mo + "€ TTC"
        }
    }

    show(data) {
        if(data.asset) {
            return configuration.PhotoBase + data.asset?.filename
        }

        if(this.props.name === "prestation" || this.props.name === "prestationPec" ) {
            return configuration.API + "/uploads/images/annuaire/prestations/" + data?.icon
        }

        if(this.props.name === "marque") {
            return configuration.API + "/uploads/images/annuaire/marque/" + data?.photo
        }

        if(this.props.name === "produit") {
            return configuration.API + "/uploads/images/annuaire/product/" + data?.photo
        }
    }

    render() {
        const {data} = this.props

        return (
            <ErrorBoundary>
                <Card className={styles.card} onClick={e => this.getLoad(e, data)}
                      style={{flex: "0 0 10%"}}>
                    {(data?.asset || data?.photo || data?.icon) &&
                    <Card.Img style={{height: '10rem', objectFit: "contain"}}
                              variant="top"
                              src={this.show(data)}/>
                    }
                    <Card.Body style={{display: "flex", alignItems: "flex-end"}}>
                        <Card.Title>
                            {this.title(data)}
                        </Card.Title>
                    </Card.Body>
                </Card>
            </ErrorBoundary>

        )
    }
}));


export default PickProductCard;
