import React from "react";
import ErrorBoundary from "../Error/ErrorBounday";
import TableGeneric from "../Headers/TableGeneric";
import DataRow from "./DataRow";
import Pagination from "../Pagination";
import {inject, observer} from "mobx-react";
import TypeTable from "./getTypeTable";
import ActionType from "./ActionType";
import {Card, CardBody, CardHeader} from "reactstrap";
import SetLoading from "../setLoading";

const GenericTableAffichageWithDatas = inject("rootStore")(observer(class GenericTableAffichageWithDatas extends React.Component {

    parentLoad = async (pageNumber) => {
        this.setState({
            loading: true
        })
        this.injected.filters.page = pageNumber

        await this.loadDatas()
        this.setState({
            loading: false
        })
    };

    constructor(props, context) {
        console.log("sjsjsj")
        super(props, context);
        this.state = {
            loading: false,
            datas: [],
            page: "1",
            last_page: "1"
        }
    }

    get injected() {
        return this.props;
    }

    async loadDatas() {

        const authReponse = await this.injected.api.getDatasOnly(this.injected.filters)

            this.setState({
            datas: authReponse.data ?? [],
            page: authReponse.current_page,
            last_page: authReponse.last_page
        })
    }


    async UNSAFE_componentWillMount () {
         await this.loadDatas()
    }

    render() {
        let {type, name, icon, status} = this.props
        const [titles] = TypeTable(type)
        return (
            <>
                <ErrorBoundary key={"error" + type}>
                    <Card key={"card" + type}>

                        {name && (
                            <CardHeader
                                key={"cardheader" + type}
                                className={this.injected.rootStore.savedDatasStore.setColor(status)}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                <h4 className="mb-0">
                                    {icon}
                                    {name}
                                </h4>
                            </CardHeader>)}
                        {!this.state.loading ? (
                                <CardBody>
                                    <TableGeneric key={"table" + type}
                                                  titles={titles}>
                                        {this.state.datas?.map(data => {
                                            return <DataRow
                                                titles={titles ? titles : []}
                                                data={data}
                                                key={type + data.id}
                                                actions={<ActionType data={data} type={type}/>}
                                            />
                                        })
                                        }
                                        {(this.state.datas?.length === 0) && (
                                            <tr>
                                                <td className={"no_td"}
                                                    colSpan={titles?.length}>Aucunes données
                                                </td>
                                            </tr>
                                        )}
                                    </TableGeneric>

                                    {this.state.page !== "" && this.state.last_page !== "1" && (
                                        <Pagination
                                            loading={this.state.loading}
                                            currentPage={this.state.page}
                                            lastPage={this.state.last_page}
                                            parentCallback={this.parentLoad}/>
                                    )}
                                </CardBody>) :
                            (
                                <CardBody><SetLoading/></CardBody>
                            )}
                    </Card>
                </ErrorBoundary>
            </>
        );
    }

}));
export default GenericTableAffichageWithDatas;
