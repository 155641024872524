import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Container} from "reactstrap";
import GenericStaticCard from "../../../../components/Akitatek/Card/GenericStaticCard";
import Tabs from "../../../../components/Akitatek/Tabs/Tabs";
import 'react-phone-input-2/lib/style.css'
import {withRouter} from "react-router-dom";
import getRoutes from "../../../../components/Akitatek/getRoutes";
import Dropdown from "react-bootstrap/Dropdown";
import PhoneRow from "../../../../components/Akitatek/Table/PhoneRow";
import CustomerAdresse from "./AdresseCustomer";
import FormUpdate from "./FormUpdate";
import UpdateMachine from "./UpdateMachine";
import GenericTableAffichageWithDatas from "../../../../components/Akitatek/Table/GenericTableAffichageWithDatas";

const UpdateCustomer = inject("rootStore")(observer(class UpdateCustomer extends React.Component {


    user
    invoices
    devis
    priseencharges

    get injected() {
        return this.props;
    }


    async componentDidUpdate(prevProps) {
        if (this.injected.user !== prevProps.user) {
            await this.setUser(this.injected.user);
        }
    }

    constructor(props, context) {
        super(props, context);
        if (this.injected.user?.facturation) {
            this.injected.user.profile = this.injected.user.facturation;
        }
        this.state = {
            devis: this.injected.devis,
            priseencharges: this.injected.priseencharges,
            invoices: this.injected.invoices,
            tickets: this.injected.tickets,
            appareils: this.injected.appareils ?? [],
            activeTab: this.injected.activeTab ?? 0,
            password: '',
            typeAccount: this.injected.rootStore.authStore.modules['espace_client'] ? 'clientaccount' : 'clientnoaccount',
            fullname: "",
            serial: "",
            appareil: "",
            defauts: "",
            marque: "",
            code: "",
        };

        this.setUser(this.injected.user)
        this.createInvoice = this.createInvoice.bind(this);
        this.createDevis = this.createDevis.bind(this);
        this.createPec = this.createPec.bind(this);
    }


    setUser(user) {
        let adresse = user?.facturation ? user?.facturation : user?.profile
        if (user) {
            this.setState({
                name: user?.nom,
                firstname: user?.prenom,
                email: user.email,
                telephone_portable: adresse?.telephone_portable,
                telephone_fixe: adresse?.telephone_fixe,
                entreprise: user.entreprise,
                adresse_name: adresse?.nom,
                adresse_firstname: adresse?.prenom,
                adresse_entreprise: adresse?.entreprise,
                adresse_rue: adresse?.adresse_rue,
                adresse_ruesuite: adresse?.adresse_ruesuite,
                codepostal: adresse?.codepostal,
                ville: adresse?.ville,
                preference: user?.preference,
                user: user,
                adresse: adresse,
                userId: user.id,
                appareils: user.appareils ?? [],
                comment: user.comment,
                siret: user.siret,
                website: user.website,
                typeAccount: user.typeAccount,
                fullname: user.fullname,
                activate: user.activate,
                ban: user.ban,
                displayPassword: user.typeAccount === "clientaccount" || user.typeAccount === "companyaccount",
                displayPrenom: user.typeAccount === "clientaccount" || user.typeAccount === "clientnoaccount",
                adresse_id: adresse?.id,
            });
        }
    }


    //Tables
    setDevis() {
        return (<GenericTableAffichageWithDatas
            key={"devis"}
            api={this.injected.rootStore.devisStore}
            filters={{
                page: "1",
                limit: "15",
                user_id: this.state.userId,
                name: "updated_at",
                order: false,
            }}
            type="devis"/>)
    }


    setInvoices() {
        return (<GenericTableAffichageWithDatas
            api={this.injected.rootStore.invoiceStore}
            filters={{
                limit: "15",
                user_id: this.state.userId,
                name: "updated_at",
                order: false,
            }}
            key={"invoice"}
            type="invoice"/>)
    }

    setPriseEnCharge() {
        return (<GenericTableAffichageWithDatas
            api={this.injected.rootStore.prisenchargeInternetStore}
            filters={{
                limit: "15",
                user_id: this.state.userId,
                name: "updated_at",
                order: false,
            }}
            key={"pec"}
            type="pec"/>)
    }


    // Buttons & Actions
    createDevis(e) {
        e.preventDefault()
        this.injected.history.replace({
            pathname: "/devis/edit/add",
            aboutProps: {
                isCommandeValid: false,
                user: this.state.user,
            },
        });
    }

    createInvoice(e) {
        e.preventDefault()
        this.injected.history.replace({
            pathname: getRoutes("Gérer une facture", "add"),
            aboutProps: {
                isCommandeValid: false,
                user: this.state.user,
            },
        });
    }

    onUserSelection(e) {
        e.preventDefault()
        this.injected.rootStore.savedDatasStore.setUser(this.state.user, 1)
    }

    createPec(e) {
        e.preventDefault()
        this.injected.history.replace({
            pathname: getRoutes("Créer une prise En Charge", "", "0"),
            aboutProps: {
                user: this.state.user,
            },
        });
    }

    async onSubmitMachine() {
        let authResponse
        let data = {
            defauts: this.state.defauts ?? "",
            serial: this.state.serial ?? "",
            annuaire_appareil_id: this.state.appareil?.id ?? "",
            user_id: this.state.userId,
            code: this.state.code
        }
        authResponse = await this.injected.rootStore.userStore.saveCustomersMachine(data)
        if (authResponse?.status === "success") {
            this.setState({
                appareils: authResponse?.datas,
                marque: "",
                appareil: "",
                serial: "",
                defauts: "",
                code: ""
            })
        }
    }

    async onSubmit() {
        if (this.state.userId) {
            let authResponse = await this.injected.rootStore.userStore.updateUser(this.state);

            if (authResponse?.status === "success") {
                this.setUser(authResponse.datas)
            }
        }

        let data = Object.assign({}, this.state)
        this.onLoad(data)
    }

    async onUserProfile() {
        if (this.state.userId) {
            let authResponse = await this.injected.rootStore.userStore.updateUserProfile(this.state);

            if (authResponse?.status === "success") {
                this.setUserProfile(authResponse.user)
            }
        }
    }


    setUserProfile(adresse) {
        if (adresse) {
            this.setState({
                adresse_name: adresse?.nom,
                adresse_firstname: adresse?.prenom,
                adresse_entreprise: adresse?.entreprise,
                adresse_rue: adresse?.adresse_rue,
                adresse_ruesuite: adresse?.adresse_ruesuite,
                telephone_portable: adresse?.telephone_portable,
                telephone_fixe: adresse?.telephone_fixe,
                codepostal: adresse?.codepostal,
                ville: adresse?.ville,
            });
        }
    }


    async toggleActivate(e) {
        e.preventDefault()
        await this.injected.rootStore.notificationStore.setQuestion("Voulez vous activer l'utilisateur " + this.state.user?.id, " Valider", e => {
            this.onActivate(this.state.user?.id)
        })
    }

    async toggleBan(e) {
        e.preventDefault()
        await this.injected.rootStore.notificationStore.setQuestion("Voulez vous bannir l'utilisateur " + this.state.user?.id, " Valider", e => {
            this.onBan(this.state.user?.id)
        })
    }

    async onBan(id) {
        let authResponse = await this.injected.rootStore.userStore.onBan(id)
        if (authResponse?.status === "success") {
            this.setState({
                ban: authResponse?.datas.ban
            })
        }
    }

    async onActivate(id) {
        let authResponse = await this.injected.rootStore.userStore.onActivate(id)
        if (authResponse?.status === "success") {
            this.setState({
                activate: authResponse?.datas.activate
            })
        }
    }

    //Callback
    callbackOnChange = (name, value) => {
        this.setState({[name]: value});
    }

    async onDelete(index) {
        let appareil = this.state.appareils[index];

        let data = {
            user_id: this.state.userId,
            id: appareil.pivot?.id
        }
        let authResponse = await this.injected.rootStore.userStore.deleteCustomersMachine(data);

        if (authResponse?.status === "success") {
            this.setState({appareils: authResponse.datas});
        }
    }


    async onUpdate(index) {
        let appareil = this.state.appareils[index];

        let data = {
            defauts: appareil.pivot.defauts ?? "",
            serial: appareil.pivot.serial ?? "",
            id: appareil.pivot?.id ?? "",
            user_id: this.state.userId,
            code: appareil.pivot.code
        }

        let authResponse = await this.injected.rootStore.userStore.updateCustomersMachine(data)

        if (authResponse?.status === "success") {
            this.setState({appareils: authResponse.datas});
        }
    }

    onCallbackAction = (index, action) => {
        if (action === "update") {
            this.onUpdate(index)
        } else {
            this.onDelete(index)
        }
    }


    callbackOnChangeList = (index, name, value) => {
        let appareils = [...this.state.appareils];
        appareils[index]["pivot"][name] = value
        this.setState({appareils});
    }


//CallbackSubmit
    callbackOnSubmit = (type) => {
        if (type === "user") {
            this.onSubmit()
        } else if (type === "userProfile") {
            this.onUserProfile()
        } else {
            this.onSubmitMachine()
        }
    }


    onLoad(data) {
        let datas = {
            "entreprise": data.entreprise,
            "nom": data.name,
            "firstname": data.firstname,
            "email": data.email,
            "phone": data.telephone_portable,
            "fixe": data.telephone_fixe,
            "Adresse": "Mon adresse:",
            "adresse_entr": data.adresse_entreprise,
            "adresse_name": data.adresse_name + " " +  data.adresse_firstname,
            "codepostal": data.codepostal,
            "ville": data.ville,
        }
        this.injected.rootStore.modeStore.updateMode("client", datas)
    }

    setCommande() {
        return <GenericTableAffichageWithDatas
            api={this.injected.rootStore.commandeClientStore}
            filters={{
                limit: "15",
                user_id: this.state.userId,
                name: "updated_at",
                order: false,
            }}
            type="cmdClient"/>
    }


    render() {
        return (
            <>
                <Container className="mt-2 mb-2" fluid>
                    <GenericStaticCard
                        background={this.state.ban ? "bg-danger" : "bg-transparent"}
                        color={this.state.ban ? "white" :   "#20a8d8"}
                        element={
                        <div className="mb-0" style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                            <h3>#{this.state.userId} {this.state.fullname + " "}
                                {!this.state.ban && (  <PhoneRow title={this.state.adresse?.telephone_portable}/>)}
                            </h3>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Dropdown className={"mr-2"}>
                                    <Dropdown.Toggle variant="info">
                                        <i className="fa fa-plus-circle" aria-hidden="true"/>
                                        Nouveau
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={this.createPec}> Pec </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={this.createInvoice}>Facture</Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={this.createDevis}>Devis</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button color={"primary"} className={"mr-2"} onClick={e => this.onUserSelection(e)}>
                                    <i className="fa fa-hand-rock-o" aria-hidden="true"/> Sélectionner
                                </Button>

                                {this.state.activate === "désactivé" && (
                                    <Button color={"success"} className={"mr-2"}
                                            onClick={e => this.toggleActivate(e)}>
                                        <i className="fa fa-toggle-on" aria-hidden="true"/>
                                        Activer</Button>
                                )}

                                <Button color={"danger"} className={"mr-2"}
                                        onClick={e => this.toggleBan(e)}>
                                    <i className="fa fa-toggle-on" aria-hidden="true"/>
                                    {this.state.ban === 1 ? "Réabiliter" : "Ban" }
                                </Button>
                            </div>

                        </div>
                    }>
                        <Tabs
                            callbackOnChange={this.callbackOnChange}
                            activeTab={this.state.activeTab}
                            children={[
                                <FormUpdate
                                    onCallbackSubmit={this.callbackOnSubmit}
                                    typeAccount={this.state.typeAccount}
                                    entreprise={this.state.entreprise}
                                    callbackOnChange={this.callbackOnChange}
                                    name={this.state.name}
                                    firstname={this.state.firstname}
                                    siret={this.state.siret}
                                    website={this.state.website}
                                    comment={this.state.comment}
                                    preference={this.state.preference}
                                    email={this.state.email}
                                    password={this.state.password}

                                />,
                                <CustomerAdresse
                                    callbackOnChange={this.callbackOnChange}
                                    onCallbackSubmit={this.callbackOnSubmit}
                                    adresse_name={this.state.adresse_name}
                                    adresse_firstname={this.state.adresse_firstname}
                                    adresse_entreprise={this.state.adresse_entreprise}
                                    adresse_rue={this.state.adresse_rue}
                                    adresse_ruesuite={this.state.adresse_ruesuite}
                                    telephone_portable={this.state.telephone_portable}
                                    telephone_fixe={this.state.telephone_fixe}
                                    codepostal={this.state.codepostal}
                                    ville={this.state.ville}
                                    typeAccount={this.state.typeAccount}/>,
                                <UpdateMachine appareils={this.state.appareils}
                                               serial={this.state.serial}
                                               nom={this.state.nom}
                                               defauts={this.state.defauts}
                                               marque={this.state.marque}
                                               code={this.state.code}
                                               appareil={this.state.appareil}
                                               userId={this.state.userId}
                                               callbackOnChange={this.callbackOnChange}
                                               onCallbackSubmit={this.callbackOnSubmit}
                                               onCallbackAction={this.onCallbackAction}
                                               callbackOnChangeList={this.callbackOnChangeList}

                                />,
                                this.setPriseEnCharge(),
                                this.setDevis(),
                                this.setInvoices(),
                                this.setCommande()]}
                            titles={["Infos", "Adresses", "Machines", "Prises en charge", "Devis", "Factures", "Commandes"]}/>
                    </GenericStaticCard>
                </Container>


            </>
        );
    }
}));

export default withRouter(UpdateCustomer);
