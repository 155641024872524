import React from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import {inject, observer} from "mobx-react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import TabContent from "reactstrap/lib/TabContent";
import classnames from 'classnames';

const Tabs = inject("rootStore")(observer(class Tabs extends React.Component {

    show
    titles
    component1 = <div> Bonjour, un problème avec l'affichage est survenu. Merci de contacter un administrateur. </div>

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }

    toggle(e, nb) {
        e.preventDefault()
        this.injected.callbackOnChange("activeTab", nb)
    }


    render() {
        const {titles, activeTab = 0, children, online} = this.props

        if(online === "0") {
            children.splice(1, 1)
        }
        return (
            <div>
                <Nav tabs className={"tab-color"}>
                    {titles.filter((val) => val !== "").map((title, index) => {
                        return (
                            <NavItem key={index}><NavLink
                                className={classnames({active: activeTab === index})}
                                onClick={e => this.toggle(e, index)}
                            >
                                {title}
                            </NavLink></NavItem>
                        )
                    })}

                </Nav>
                <TabContent activeTab={activeTab}>
                    <Row className={"mt-2"}>
                        <Col style={{minHeight: '30em'}} sm="12">
                            {children[activeTab]}
                        </Col>
                    </Row>
                </TabContent>
            </div>
        );
    }
}));

export default Tabs;

