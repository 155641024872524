import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import i18n from "i18next";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from "../Annuaire/Prestation/Prestation.module.css";
import CardImg from "react-bootstrap/CardImg";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import configuration from "../../../configuration/configuration";
import {SRLWrapper} from "simple-react-lightbox";

const UpdateAsset = (inject("rootStore")(observer(class UpdateAsset extends React.Component {
    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);

        this.state = {
            file: this.injected.location.aboutProps?.file,
            card: this.injected.card ?? false,
            inputValue: [],
            image: ""
        }

        if (!this.state.card) {
            this.injected.rootStore.savedDatasStore.setHeader("Images", "Gestion des images", "motherboard.jpg",  this.injected.rootStore.imageStore)
        }

        this.injected.rootStore.imageStore.getAllTags()
    }


    //update component if the id change, or if there is a refresh
    async componentDidUpdate(prevProps) {
        if (this.injected.location !== prevProps.location) {
            await this.onRouteChanged()
        }
        if (this.state.file === "" && this.injected.match.params.id) {
            await this.onRouteChanged()
        }

        if (
            this.injected.card !== prevProps.card
        ) {
            this.setState({
                card: this.injected.card
            })
        }
    }

    //check if the values are up to date
     componentDidMount() {
        if (this.injected.match.params.id) {
             this.onRouteChanged()
        }
    }

    //get the values of commande by ID
    async onRouteChanged() {
        let id = this.props.match && parseInt(this.props.match.params.id, 10);
        if (id) {
            let file = await this.injected.rootStore.imageStore.findById(id);
            this.setState({
                file: file
            })
        }
    }

    async onSubmit(e) {
        e.preventDefault();
        let authResponse = await this.injected.rootStore.imageStore.update(this.state)

        if (authResponse?.status === "success") {
            await this.injected.rootStore.imageStore.getDatas();
            this.setState({
                file: authResponse.datas
            })
        }
    }

    handleFile(longName) {
        var data = this.state.file;
        data.nom = longName
        this.setState({file: data});
    }

    handleFileDescription(description) {
        var data = this.state.file;
        data.description = description
        this.setState({file: data});
    }


    async handleInputChange(inputValue) {
        var data = this.state.file;
        if (data.tags === undefined) {
            data.tags = [];
        }
        this.state.file.tags = [];
        this.state.file.tagsId = [];

        if (inputValue) {
            for (const element of inputValue) {
                if (element.__isNew__ === true) {
                    var tag = await this.injected.rootStore.imageStore.createTag({
                        name: element.label
                    })
                    if (this.state.file.tags.indexOf(element) === -1) this.state.file.tags.push(tag);
                    if (this.state.file.tagsId.indexOf(element.value) === -1) this.state.file.tagsId.push(tag.id);
                } else {
                    if (this.state.file.tags.indexOf(element) === -1) this.state.file.tags.push({
                        nom: element.label,
                        id: element.value
                    });
                    if (this.state.file.tagsId.indexOf(element.value) === -1) this.state.file.tagsId.push(element.value);
                }
            }
        }

        this.setState({file: data});
    };

    async copyToClipboard(copy) {
        navigator.clipboard.writeText(copy)
        await this.injected.rootStore.notificationStore.setNotification(copy + " copié ! ", "success")

    }



    filterOption = (candidate, input) => {
        return input !== "" || candidate.nom === input;
    };

    setForm() {
        return (<Form style={{width: "100%"}}
                      role="form"
                      id="myFormUpdate"
                      onSubmit={e => this.onSubmit(e)}
        >

            <div className="pl-lg-4">
                <Card style={{flex: "0 0 30%"}}>
                    <SRLWrapper>
                        <CardImg style={{maxWidth: "10em", maxHeight: "10em"}}
                                 src={configuration.PhotoBase + this.state.file?.filename}>
                        </CardImg>
                    </SRLWrapper>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                    >
                                        Nom de l'image
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        required
                                        value={this.state.file?.nom || ''}
                                        onChange={e => this.handleFile(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                    >
                                        Tags
                                    </label>
                                    <CreatableSelect
                                        isClearable
                                        isMulti
                                        value={this.state.file?.tags?.map(opt => ({
                                            label: opt.nom,
                                            value: opt.id
                                        }))}
                                        options={this.injected.rootStore.imageStore.tags.map(opt => ({
                                            label: opt.nom,
                                            value: opt.id
                                        }))}
                                        onChange={e => this.handleInputChange(e)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Input type="file"
                                       name="file2"
                                       id="exampleFile"
                                       onChange={e => this.setState({image: e.target.files[0]})}
                                />
                            </Col>
                        </Row>
                        {this.injected.rootStore.authStore.options.metadata === 1 && (
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label
                                            className={styles.typeField}
                                        >
                                            Description - ALT
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            type="text"
                                            value={this.state.file?.description || ''}
                                            onChange={e => this.handleFileDescription(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>)}
                    </CardBody>
                </Card>

           {/*     <Card>
                    <CardHeader> Modifier l'Image </CardHeader>

                    <CardBody>
                        <Row>
                            <Col>
                                    <Input type="file"
                                           name="file2"
                                           id="exampleFile"
                                           onChange={e => this.setState({file2: e.target.files[0]})}
                                    />
                            </Col>
                            <Col>
                                <div>

                                    <Button onClick={e => this.onUpdateAsset(e)} color="success">Modifier</Button>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>*/}

                <div className="pl-lg-4 mt-3" style={{textAlign: "center"}}>
                    <Button color="success" className="mr-1" type="submit">
                        {i18n.t('button.submit')}
                    </Button>

                    <Button className={"bg-primary"} onClick={e => this.copyToClipboard(configuration.PhotoBase + this.state.file?.filename)}
                    > Copier l'url <i className="fa fa-copy"></i>
                    </Button>
                </div>
            </div>
        </Form>)
    }


    render() {
        return (
            <>
                {!this.state.card && (
                    <Container className="mt-2" fluid>
                        <Row className="mb-3">
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                        <h3 className="mb-0" style={{color: "#20a8d8"}}>
                                            {this.state.file?.id && (
                                                "Image #" + this.state.file?.id
                                            )}

                                        </h3>
                                    </CardHeader>
                                    <CardBody>
                                        {this.setForm()}
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                )}

                {this.state.card && (this.setForm())}
            </>
        );
    }
})));

export default UpdateAsset;
