import {decorate, observable} from "mobx";
import Api from "../util/api";

export default class PromotionStore {

    datas = observable([]);

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    defaultFilter = {
        status: "",
        page: "0",
        limit: "15",
        last_page: "1",
    }

    filter = {
        status: "",
        page: "0",
        limit: "15",
        last_page: "1",
    }
    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.promotion.get(this.filter);

            if (this.datas.length > 0) {
                this.datas.replace(authResponse.data)
            } else {
                this.datas = authResponse.data;
            }

            this.filter.last_page = authResponse.last_page;
            this.filter.page = authResponse.current_page;

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async create(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.promotion.createPromotion(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Promotion  ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }
    async update(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.promotion.updatePromotion(datas, datas.id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Promotion #" + datas.id + " modifiée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async findPromotionById(id)  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.promotion.find(id);
            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse;

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.promotion.delete(id);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( "Promotion # " + id  + " supprimée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(PromotionStore, {
    datas: observable,
    filter: observable,
});

