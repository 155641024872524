import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";
import encodeDataToURL from "../components/Akitatek/encodeDatatoURL";

export default class ResourceAPI {

    async get(url, filter) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/${url}?` + encodeDataToURL(filter),
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }



    async save(url, data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/${url}`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async update(url, data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/${url}`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async getAction(action, id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/${action}/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async findById(type, id, status) {
        var url = `${configuration.API}/api/${type}/` + id
        url = status ? url  + `/` + status : url
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: url,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    async sendPdf(url, mediaId) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/${url}/sendPdfByMail/` + mediaId,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data.status)
            } catch (error) {
                reject(error)
            }
        })
    }


    async getPdf(url, mediaId, print) {
        const response = await axios({
            method: 'get',
            url: `${configuration.API}/api/${url}/pdf/${mediaId}?print=${print}`,
            headers: {
                'content-type': '*/*'
            },
            responseType: 'blob'
        });

        const file = response.data;
        file.name =  response.data.filename;
        file.lastModified = new Date();

        return file;
    }

    async delete(url, id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/${url}/` + id ,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }
}