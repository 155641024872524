import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {Button, Input, ListGroupItem, TabPane} from "reactstrap";


const SecondAsidePage = inject("rootStore")(observer(class SecondAsidePage extends Component {

        get injected() {
            return this.props
        }

        async onUpdateLogo(e) {
            e.preventDefault()
            await this.injected.rootStore.authStore.updateSidebar(this.state)
        }


        async onAddSong(e) {
            e.preventDefault()
            let data = {
                file: this.state.file2,
                type: "audio"
            }
            await this.injected.rootStore.imageStore.saveOne(data)
        }


        render() {
            return (

                <TabPane tabId="2" className="p-3">


                    <div className="aside-options">
                        <div className="clearfix mt-4">
                            <small><b>Changer le background de la sidebar</b></small>
                        </div>
                        <div>
                            <small className="text-muted">
                                <div> Fichier actuel : {this.injected.rootStore.authStore.sidebar}</div>

                                <div>
                                    <Input type="file"
                                           name="file"
                                           id="exampleFile"
                                           onChange={e => this.setState({file: e.target.files[0]})}
                                    />
                                    <Button onClick={e => this.onUpdateLogo(e)} color="success">Modifier</Button>
                                </div>
                            </small>
                        </div>
                    </div>

                    <hr/>


                    <div className="aside-options">
                        <div className="clearfix mt-4">
                            <small><b>Ajouter une musique</b></small>
                        </div>
                        <div>
                            <small className="text-muted">
                                <div>
                                    <Input type="file"
                                           name="file"
                                           id="exampleFile"
                                           onChange={e => this.setState({file2: e.target.files[0]})}
                                    />
                                    <Button onClick={e => this.onAddSong(e)} color="success">Ajouter</Button>
                                </div>
                            </small>
                        </div>
                    </div>

                    <div className="aside-options">
                        <div className="clearfix mt-4">
                            <small><b>Liste des musiques pour les notifications</b></small>
                        </div>
                        <div>

                            {this.injected.rootStore.songStore.datas.map((song) => {
                                return (
                                    <ListGroupItem key={song.id} action tag="a">
                                        <div>{song.category.toLowerCase()} : <strong>  {song.asset?.nom} </strong></div>
                                    </ListGroupItem>
                                )
                            })}
                        </div>
                    </div>

                </TabPane>


            )


        }
    }
));

export default SecondAsidePage;
