import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Container,} from "reactstrap";
import GenericTableAffichageWithMyDatas from "../../../../components/Akitatek/Table/GenericTableAffichageWithMyDatas";
import ModalAkitatek from "../../../../components/Akitatek/Modal/Modal";
import ContentPromotion from "./ContentPromotion";


const Promotions = inject("rootStore")(observer(class Promotions extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            show: false
        }
    }

    // toggleDanger() {
    //     this.injected.rootStore.notificationStore.setWarning("Oui", event => this.onDelete())
    // }
    //
    // async onDelete() {
    //     this.injected.onCallbackAction(this.props.index, "delete")
    // }
    //
    // async onUpdate(e) {
    //     if (e) {
    //         e.preventDefault()
    //     }
    //     this.injected.onCallbackAction(this.props.index, "update")
    // }


    onClose() {
        this.setState({show: !this.state.show})
    }

    render() {
        let {datas, price, prestation} = this.props

        return (
            <>
                <Container className="mt-2" fluid>
                    <Button className="mr-2 ml-2" color="primary"
                            onClick={e => this.setState({show: !this.state.show})}> Ajouter une promotion </Button>

                    <GenericTableAffichageWithMyDatas datas={datas} type={"promotions"}/>
                </Container>

                <ModalAkitatek title={"Ajouter une promotion"} show={this.state.show}
                               children={<ContentPromotion
                                   card={false}
                                   prestation={prestation}
                                   prestation_id={prestation?.id}
                                   price_ttc={price}
                                   onClose={e => this.onClose()}
                                   callbackOnChange={this.injected.callbackOnChange}/>}
                               onCallback={e => this.onClose()}/>
            </>
        );
    }
}));

export default Promotions;
