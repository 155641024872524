import React from "react";
import {Link} from "react-router-dom";
import getRoutes from "../getRoutes";

function RowThLink({title, href, data}) {
    return <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                                <Link to={{
                                    pathname: getRoutes(href, data.id, href === "Gérer une Prise En Charge" || href === "Créer une prise En Charge" ? "0" : data.status) ,
                                    aboutProps: {
                                        isCommandeValid: true,
                                        data: data
                                    }
                                }}>#{title}
                                </Link>
                        </span>
    </th>

}

export default RowThLink;
