import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";
import encodeDataToURL from "../components/Akitatek/encodeDatatoURL";

export default class ImageAPI {
    async getImage(filter) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/assets?` + encodeDataToURL(filter),
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async getTags() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/tags`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    createTag(datas) {
        return new Promise(async (resolve, reject) => {
            try {

                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/tags`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    save(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                const data = new FormData();

                if (datas.files) {
                    const files = datas.files;
                    for (let i = 0; i < files.length; i++) {
                        data.append(`images[${i}]`, files[i])
                        data.append(`name[${i}]`, JSON.stringify(files[i].longname));
                        data.append(`description[${i}]`, JSON.stringify(files[i].description));
                        data.append(`tags[${i}]`, JSON.stringify(files[i].tags));

                    }
                }


                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/assets`,
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    data
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    saveOne(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                const data = new FormData();

                if (datas.file) {
                        data.append(`file`,  datas.file)
                        data.append(`type`,  datas.type);
                }


                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/file`,
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    data
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }



    async find(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/assets/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    update(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                const data = new FormData();

                if (datas.image) {
                    data.append(`image`,  datas.image)
                    data.append(`type`,  datas.type);
                }

                data.append(`id`,  datas.file?.id)
                data.append(`nom`,  datas.file?.nom)
                data.append(`description`,  datas.file?.description)



                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/asset`,
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    data
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    async delete(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/assets/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }
}