import {inject, observer} from "mobx-react";
import React from "react";
import {Col, Container} from "react-bootstrap";
import {Card, CardBody, CardHeader, Form, FormGroup, Input, Row, Table} from "reactstrap";
import i18n from "i18next";
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import AutoCompleteCommande from "./AutoCompleteCommande";
import DetailCommande from "./DetailCommande";
import Button from "reactstrap/lib/Button";
import LineCommandeRow from "./LineCommandeRow";
import SuiviCommande from "./SuiviCommande";
import moment from "moment";
import {withRouter} from "react-router-dom";
import AutoCompleteInvoices from "../../../components/Akitatek/AutoCompleteInvoices/AutoCompleteInvoices";
import Tabs from "../../../components/Akitatek/Tabs/Tabs";
import styles from "../Stock/Stock.module.css";
import TableLotRow from "../Stock/TableLotRow";
import getRoutes from "../../../components/Akitatek/getRoutes";


const AddCommande = (inject("rootStore")(observer(class AddCommande extends React.Component {

    commandeStore = this.injected.rootStore.commandeStore;
    commande


    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Commandes", "Gestion des commandes", "administration.jpg", this.injected.rootStore.commandeStore)

        //Pass data throught the last page
        this.state = {
            activeTab: 0,
            isCommandeValid: this.injected.location.aboutProps?.isCommandeValid ?? false,
            date_envoi: moment().format("YYYY-MM-DD"),
            status: "DRAFT",
            date_reception: moment().format("YYYY-MM-DD"),
            ref: "",
            fournisseur: "",
            remise: "",
            isLoading: false,
            lignes: [],
            id: "",
            fdp: "",
            comment: "",
            montant_tva: 0,
            totalht: 0,
            totalttc: 0,
            lots: this.injected.location.aboutProps?.commande?.lots ?? [],
            printers: [],
            printerId: "",
            casier: this.injected.location.aboutProps?.casier ?? "",
            stockage: this.injected.location.aboutProps?.casier?.stockage ?? "",

        }

        this.injected.rootStore.stockageCasierStore.getCasiers()
        this.injected.rootStore.stockageCasierStore.getStockageCasier()
    }

    //update component if the id change, or if there is a refresh
    async componentDidUpdate(prevProps) {
        if (this.injected.location !== prevProps.location) {
            await this.onRouteChanged()
        }

        if (this.state.commande === "" && this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }


    //check if the values are up to date
    async componentDidMount() {
        if (this.injected.match.params.id) {
            await this.onRouteChanged()
        }

        await this.injected.rootStore.prisenchargeStore.getPrinters()
        this.setState({
            printerId: this.injected.rootStore.prisenchargeStore.printers[0]?.id,

        })
    }

    //get the values of commande by ID
    async onRouteChanged() {
        let id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (id) {
            let authResponse = await this.injected.rootStore.commandeStore.findCommandById(id);
            if (authResponse?.status === "success") {
                this.setDatas(authResponse.datas)
            }
        } else if (this.props.match.params.id === "add") {
            this.setState({
                isCommandeValid: false,
                date_envoi: moment().format("YYYY-MM-DD"),
                status: "DRAFT",
                date_reception: moment().format("YYYY-MM-DD"),
                ref: "",
                fournisseur: "",
                lignes: [],
                id: "",
                fdp: "",
                comment: "",
                montant_tva: 0,
                totalht: 0,
                totalttc: 0,
                lots: this.injected.location.aboutProps?.commande?.lots ?? []
            })
        }
    }

    setDatas(authResponse) {
        this.setState({
            commande: authResponse,
            date_envoi: authResponse?.suivi?.date_envoi ?? moment().format("YYYY-MM-DD"),
            status: authResponse?.status,
            date_reception: authResponse?.suivi?.date_reception ?? moment().format("YYYY-MM-DD"),
            ref: authResponse?.ref,
            refSuivi: authResponse?.suivi?.ref,
            suivi_id: authResponse?.suivi?.id,
            suivi: authResponse?.suivi,
            fournisseur: authResponse?.fournisseur,
            isCommandeValid: true,
            created_at: authResponse?.created_at,
            remise: authResponse?.remise,
            lignes: authResponse?.lignes,
            id: authResponse?.id,
            prise_en_charge_id: authResponse?.prise_en_charge_id,
            montant_tva: authResponse?.montant_tva,
            totalht: authResponse?.totalht,
            totalttc: authResponse?.totalttc,
            fdp: authResponse?.fdp,
            title: authResponse?.title,
            comment: authResponse?.comment,
            lots: authResponse?.lots ?? []
        })
    }

    get injected() {
        return this.props;
    }

    async onUpdateCommande(event) {
        event.preventDefault();
        this.setState({
            isLoading: true,
        })
        const datas = Object.assign({}, this.state);
        let authResponse = await this.injected.rootStore.commandeStore.updateCommande(datas, true)
        if (authResponse?.status === "success") {
            this.setDatas(authResponse.datas)
        }
        this.setState({
            isLoading: false,
        })
    }


    async saveAll(event) {
        event.preventDefault();
        this.setState({
            isLoading: true,
        })
        const datas = Object.assign({}, this.state);

        let authResponse = await this.injected.rootStore.commandeStore.saveAllCommande(datas)
        if (authResponse?.status === "success") {
            this.setDatas(authResponse.datas)
        }
        this.setState({
            isLoading: false,
        })
    }

    async onSubmitFirstStep(event) {
        event.preventDefault();
        this.setState({
            isLoading: true,
        })
        const datas = Object.assign({}, this.state);

        let authResponse = await this.injected.rootStore.commandeStore.saveCommande(datas)
        if (authResponse?.status === "success") {
            this.setDatas(authResponse.datas)
            this.setState({
                isCommandeValid: true,
            })
            this.injected.history.replace(authResponse.datas.id.toString())
        }
        this.setState({
            isLoading: false,
        })
    }

    setTitle() {
        if (this.state.isCommandeValid) {
            return "Commande #" + this.state.id
        } else {
            return "Ajouter une commande"
        }
    }


    //Callback
    callbackChange = async (name, value) => {
        this.setState({[name]: value});

        if (name === "lignes") {
            let authResponse = await this.injected.rootStore.commandeStore.findCommandById(this.state.id);
            if (authResponse?.status === "success") {
                this.setDatas(authResponse.datas)
            }
        }
    }

    //Refresh commande
    callbackRefresh = () => {
        this.refreshList()
    };

    //Refresh commande
    callbackRefreshTotal = () => {
        this.calculateTotal()
    };

    calculateTotal() {
        let totalht = this.state.lignes.reduce((a, {totalht}) => parseFloat(a) + parseFloat(totalht), 0);
        let totalttc = this.state.lignes.reduce((a, {totalttc}) => parseFloat(a) + parseFloat(totalttc), 0);

        // if (this.state.remise != null) {
        //     let remise = parseFloat(totalht) * (parseFloat(this.state.remise) / parseFloat(100))
        //     totalht = parseFloat(totalht) - parseFloat(remise);
        //     totalttc = parseFloat(totalttc) - parseFloat(remise);
        // }

        if (this.state.fdp != null) {
            totalttc = parseFloat(totalttc) + parseFloat(this.state.fdp)
        }

        this.setState({
            totalttc: totalttc.toFixed(2),
            totalht: totalht.toFixed(2)
        })
    }


    setActionsStatus() {
        let title;
        let status;
        if (this.state.status === "DRAFT") {
            status = "ORDERED"
            title = "Commandée"
        } else if (this.state.status === "ORDERED") {
            status = "SEND"
            title = "Expédiée"
        } else if (this.state.status === "SEND") {
            status = "RECEPTION"
            title = "Réceptionnée"
        } else if (this.state.status === "RECEPTION") {
            status = "VALID"
            title = "Validée"
        }

        return <Button block
                       className={this.injected.rootStore.savedDatasStore.setColor(status)}
                       onClick={e => this.setStatus(e, status, title)}
                       color="warning">Marqué comme {title}</Button>

    }

    async setStatus(e, status, title) {
        e.preventDefault()
        const datas = Object.assign({}, this.state);
        datas.status = status
        let authResponse = await this.injected.rootStore.commandeStore.updateCommande(datas, true, "Commande # " + this.state.id + " " + title)
        if (authResponse?.status === "success") {
            this.setDatas(authResponse.datas)
        }
    }


    refreshList = () =>
        this.setState({refreshList: !this.state.refreshList})


    disabled() {
        return this.state.status === "SEND" || this.state.status === "RECEPTION" || this.state.status === "VALID"
    }

    async toggleDelete(e) {
        e.preventDefault()

        await this.injected.rootStore.notificationStore.setWarning("Commande #" + this.state.id, event => this.onDelete(this.state.id), false)
    }

    async onDelete(id) {
        let authResponse = await this.injected.rootStore.commandeStore.delete(id)

        if (authResponse?.status === "success") {
            this.injected.history.push("/command");
        }
    }

    setForm() {
        return (
            <Form
                className="mb-4"
                id="form-add-invoice"
                name="form-add-invoice"
                style={{textAlign: "center"}}>
                <CardBody>
                    <Col>
                        <Row className="ml-2 mr-2" style={{justifyContent: "space-between"}}>
                            <DetailCommande
                                reference={this.state.ref}
                                title={this.state.title}
                                comment={this.state.comment}
                                created_at={this.state.created_at}
                                callbackChange={this.callbackChange}
                            />
                            <SuiviCommande
                                refSuivi={this.state.refSuivi}
                                date_envoi={this.state.date_envoi}
                                date_reception={this.state.date_reception}
                                status={this.state.status}
                                callbackChange={this.callbackChange}
                            />
                            <AutoCompleteCommande
                                callbackChange={this.callbackChange}
                                fournisseur={this.state.fournisseur}
                            />
                        </Row>


                        {
                            this.injected.rootStore.errorStore.error.has && (
                                <div className="alert alert-danger" role="alert">
                                    {this.errorStore.error.field} est nécessaire.
                                </div>
                            )
                        }

                        {(this.state.isCommandeValid && !this.disabled()) && (
                            <AutoCompleteInvoices
                                id={this.state.id}
                                type={"commande"}
                                lignes={this.state.lignes}
                                callbackRefreshLignes={this.callbackChange}
                            />
                        )}

                        {this.state.isCommandeValid && (
                            <TableGeneric titles={[
                                <i className="fa fa-bars" aria-hidden="true"/>,
                                "Reférence",
                                "Designation",
                                i18n.t('page.admin.invoices.facturation.quantity'),
                                i18n.t('page.admin.invoices.facturation.tarifu'),
                                "Tarif U HT",
                                i18n.t('page.admin.invoices.facturation.remise'),
                                i18n.t('page.admin.invoices.facturation.tva'),
                                "Montant TVA",
                                i18n.t('page.admin.invoices.facturation.totalHT'),
                                i18n.t('page.admin.invoices.facturation.totalTTC'),
                                "Quantités reçues",
                                "Quantités en attente",
                                "Quantités à recevoir",
                                "Actions"
                            ]}>


                                {this.state.lignes?.map((lines, index) => {
                                    return (<LineCommandeRow
                                        key={index}
                                        id={lines.id}
                                        callbackRefreshTotal={this.callbackRefreshTotal}
                                        commande={this.state.commande}
                                        callbackChange={this.callbackChange}
                                        line={lines}
                                        index={index}
                                        refresh={this.state.refreshList}
                                    />);

                                })}


                            </TableGeneric>)
                        }
                    </Col>


                    {this.state.isCommandeValid && (
                        <Row className={"mr-2"} style={{justifyContent: "flex-end"}}>
                            <Card style={{width: "25em"}}>
                                <CardHeader>
                                    Total dû
                                </CardHeader>
                                <CardBody>

                                    <Table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <label className="form-control-label">
                                                    Total HT hors frais de port
                                                </label>
                                            </td>
                                            <td>
                                                <FormGroup className={"mt-2"} style={{display: "flex"}}>
                                                    {this.state.totalht}
                                                    <span style={{margin: "auto"}}>€ </span>
                                                </FormGroup>
                                            </td>
                                        </tr>

                                        {/*<tr>*/}
                                        {/*    <td>*/}
                                        {/*        <label*/}
                                        {/*            className="form-control-label"*/}
                                        {/*            htmlFor="input-deplacement"*/}
                                        {/*        >*/}
                                        {/*            Remise*/}
                                        {/*        </label>*/}

                                        {/*    </td>*/}
                                        {/*    <td>*/}
                                        {/*        <FormGroup className={"mt-2"} style={{display: "flex"}}>*/}
                                        {/*            <Input*/}
                                        {/*                disabled={this.disabled()}*/}
                                        {/*                className="form-control-alternative"*/}
                                        {/*                type="number"*/}
                                        {/*                value={this.state.remise}*/}
                                        {/*                onChange={async e => {*/}
                                        {/*                    await this.setState({remise: e.target.value})*/}
                                        {/*                    this.calculateTotal()*/}
                                        {/*                }}*/}
                                        {/*                min={0}*/}
                                        {/*            />*/}
                                        {/*            <span style={{margin: "auto"}}>% </span>*/}
                                        {/*        </FormGroup>*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td>
                                                <label className="form-control-label">
                                                    Montant TVA
                                                </label>
                                            </td>
                                            <td>
                                                {this.state.montant_tva} €
                                            </td>
                                        </tr>

                                        <tr>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-deplacement"
                                                >
                                                    Frais de port
                                                </label>

                                            </td>
                                            <td>
                                                <FormGroup className={"mt-2"} style={{display: "flex"}}>
                                                    <Input
                                                        disabled={this.disabled()}
                                                        className="form-control-alternative"
                                                        type="number"
                                                        value={this.state.fdp || ''}
                                                        onChange={async e => {
                                                            await this.setState({fdp: e.target.value})
                                                            this.calculateTotal()
                                                        }}
                                                        min={0}
                                                    />
                                                    <span style={{margin: "auto"}}>€ </span>
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="form-control-label">
                                                    Total TTC
                                                </label>
                                            </td>
                                            <td>
                                                <FormGroup className={"mt-2"} style={{display: "flex"}}>
                                                    {this.state.totalttc}
                                                    <span style={{margin: "auto"}}>€ </span>
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Row>
                    )
                    }

                    {!this.state.isLoading && (
                        <Row>
                            {this.state.isCommandeValid && (
                                <Col>
                                    <Button block color="success" className={"mr-2"}
                                            onClick={e => this.onUpdateCommande(e)}>Sauvegarder</Button>
                                </Col>

                            )}

                            {this.state.isCommandeValid && (this.state.status !== "RECEPTION" && this.state.status !== "VALID") && (
                                <Col>
                                    {this.setActionsStatus()}
                                </Col>
                            )}

                            {this.state.isCommandeValid && this.state.status === "RECEPTION" && (
                                <Col>
                                    <Button block onClick={e => this.saveAll(e)} color="primary">Tout valider et mettre
                                        en
                                        stock</Button>
                                </Col>
                            )}

                            {!this.state.isCommandeValid && (
                                <Col>
                                    <Button block color="success"
                                            onClick={e => this.onSubmitFirstStep(e)}>Valider</Button>
                                </Col>
                            )
                            }

                            {this.state.isCommandeValid && this.state.status !== "VALID" && (
                                <Col>
                                    <Button block color={"danger"}
                                            type="submit"
                                            onClick={e => this.toggleDelete(e)}>Supprimer la commande</Button>
                                </Col>)
                            }
                        </Row>)}
                        </CardBody>
            </Form>
        )
    }


    setImpression() {
        return (
            <div>
                <div className={[styles.headerTab]}>
                    <p> Classer & Imprimer les différents lots pour cette commande. </p>
                </div>
                <div style={{minHeight: "20em"}}>
                    <TableGeneric

                        key="cardLot"
                        titles={["Id", "Commande", "Fournisseur", "Prix unitaire TTC", "Quantité disponible", i18n.t('page.admin.invoices.created'), "Actions Etiquette Client", "Actions Etiquette Stock"]}>
                        {this.state.lots
                            .map(lot => {
                                return <TableLotRow item={lot} type="lots" key={lot.id}/>
                            })
                        }
                        {(this.state.lots.length === 0) && (
                            <tr>
                                <td className={"no_td"}
                                    colSpan={["Id","Commande",  "Fournisseur", "Prix unitaire",  "Quantité", i18n.t('page.admin.invoices.created'),  "Actions Etiquette Client", "Actions Etiquette Stock"].length}>Pas
                                    de lots disponibles
                                </td>
                            </tr>
                        )}

                    </TableGeneric>
                </div>

                <Card>
                    <CardBody>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "self-end"}}>
                            {this.injected.rootStore.authStore.modules['print'] === 1 && (
                                <Button
                                    onClick={e => this.injected.rootStore.commandeStore.downloadPdf(e, this.state.id, this.state.printerId)}

                                    color="primary"
                                    style={{width: "25%"}}


                                >
                                    Imprimer tout
                                </Button>)}
                        </div>
                    </CardBody></Card>

            </div>)
    }

    //Callback
    callbackOnChange = (name, value) => {
        this.setState({[name]: value});
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <Row className="mb-3">
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader
                                    className={this.injected.rootStore.savedDatasStore.setColor(this.state.status)}
                                    style={{display: "flex", justifyContent: "center"}}>
                                    <h4 className="mb-0"> {this.setTitle()} </h4>
                                </CardHeader>
                                {this.state.prise_en_charge_id && (
                                    <CardHeader>
                                        <a style={{color: "#20a8d8", cursor: "pointer"}}
                                           onClick={e => this.injected.history.push(getRoutes("Gérer une prise en charge", this.state.prise_en_charge_id, "0"))}> Prise
                                            en charge n° {this.state.prise_en_charge_id}</a>
                                    </CardHeader>)}
                                <Tabs
                                    callbackOnChange={this.callbackOnChange}
                                    activeTab={this.state.activeTab} children={[this.setForm(), this.setImpression()]}
                                    titles={["General", "Impression"]}/>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
})));

export default withRouter(AddCommande);
