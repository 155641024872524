import { zip } from "lodash";
import React from "react";
import { Article, Card, Row } from "./widgets";
import { GamepadItem } from "./GamepadItem";
import { Mini, MiniRow } from "./Mini";
import "./Gamepad.module.css"
import Mousetrap from "mousetrap";
import OrderTh from "../../../components/Akitatek/Headers/OrderTh";
function pollGamepads() {
  if (navigator.getGamepads) {
    return navigator.getGamepads();
  } else {
    return [];
  }
}

export function GamepadList() {
  const [gamepads, setGamepads] = React.useState([
  ]);

  const gpRef = React.useRef(gamepads);
  gpRef.current = gamepads;

  const [currentIdx, setCurrentIdx] = React.useState(0);

  function calculate() {
    var newIndex = currentIdx + 1

    if(newIndex >= gamepadArr.length) {
      newIndex = 0;
    }
    setCurrentIdx(newIndex)
  }


  React.useEffect(() => {
    Mousetrap.bind("tab", e => calculate());
  })



    React.useEffect(() => {
    let done = false;
    // this cleverness is necessary because chrome / ff don't have the same referential equality for the gamepad objects they hand over.
    var lastTimestamps = [];

    function tick() {
      if (done) return;
      var nextGamepadsFrame = pollGamepads();

      var nowTimestamps = nextGamepadsFrame.map((gp) => gp?.timestamp);
      var anyUpdated =
          nowTimestamps.length !== lastTimestamps.length ||
          zip(nowTimestamps, lastTimestamps).some((pair) => pair[0] !== pair[1]);

      if (anyUpdated) setGamepads(nextGamepadsFrame);

      lastTimestamps = nowTimestamps;

      window.requestAnimationFrame(tick);
      // window.setTimeout(() => tick(), 1000);
    }

    tick();

    return () => {
      done = true;
    };
  }, []);

  // the actual list of gamepads is not a normal array yet
  var gamepadArr = [].slice.call(gamepads);
   gamepadArr = gamepadArr.filter(function(el) { return el; });

  return (
      <>
        <Row>
          <Card>
            <Article>
              {gamepadArr.length > 0 && (
                "Manette détectée n° " + currentIdx + 1
                )}

              {gamepadArr.length > 1 && (
                  <div style={{ fontSize: "italic"}}>Pour changer de manettes, appuyez sur Tab</div>
              )}
              <GamepadItem gamepad={gamepadArr[currentIdx]} />
            </Article>
          </Card>
        </Row>
      </>
  );
  // }
}
export default GamepadList