import DevisCreateStore from "./devisCreateStore";
import Api from "../../util/api";
import {action, decorate, observable} from "mobx";
import printJS from "print-js";


export default class DevisStore {

    datas = observable([]);
    statusEnum = ""
    statusname = "";

    filter = {
        limit: "15",
        search: "",
        page: "1",
        last_page: "1",
        name : "updated_at",
        order: false,
        status: ["VALID"]
    }

    defaultFilter  = {
        limit: "15",
        search: "",
        page: "1",
        last_page: "1",
        name : "updated_at",
        order: false,
        status: ["VALID"]
    }

    allStatus = observable([]);

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.devisCreate = new DevisCreateStore(this.rootStore, this);
        this.api = new Api();
    }

    getStatusname() {
        this.statusname = ""
        this.allStatus.forEach(element => {
            var status = this.filter.status ? this.filter.status[0] : ""
            if (element.value === status) {
                this.statusname = element.label
            }}
        );
    }

    async getAllStatus()  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            this.allStatus = await this.api.devis.getAllStatus();

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

        this.rootStore.savedDatasStore.setLoading(false)
    }

    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.devis.getDevis(this.filter);
            if (this.datas.length > 0) {
                this.setDevis(authResponse.data)
            } else {
                this.datas = authResponse.data;
            }
            this.filter.last_page = authResponse.last_page;
            this.filter.page = authResponse.current_page;
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getDatasOnly(filters) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.devis.getDevis(filters ?? this.filter);
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    setDevis(datas) {
        this.datas.replace(datas)
    }

    setStatus(status, statusEnum) {
        if (this.status !== status) {
            this.statusEnum = statusEnum
            this.status = status

        }
    }

    async getPdf(mediaId, print = false) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let pdf = await this.api.devis.getPdfDevis(mediaId, print)
            this.rootStore.savedDatasStore.setLoading(false)
            return pdf;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async sendPdf(mediaId) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let status = await this.api.devis.sendPdfDevis(mediaId);

            if (status === "success") {
                this.rootStore.notificationStore.setNotification("Devis #" + mediaId + " envoyé", "success");
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }

    async saveDevis(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.devis.saveDevis(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Devis #" + authResponse.datas.id + " crée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveDevisAuto(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.devis.saveDevisAuto(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Devis #" + authResponse.datas.id + " créee", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async copyDevis(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.devis.copyDevis(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Devis #" + authResponse.datas.id + " ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async copyDevisToFacture(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.devis.copyDevisToFacture(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Facture #" + authResponse.datas.id + " ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateDevis(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.devis.updateDevis(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Devis #" + authResponse.datas.id + " modifié", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveDevisLigne(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.devis.saveDevisLigne(data);

            if (authResponse?.status === "success") {
                let index = data.position + 1
                this.rootStore.notificationStore.setNotification(message ?? "Ligne #" + index + " ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateDevisLigne(data, notHide) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.devis.updateDevisLigne(data);

            if (authResponse?.status === "success") {
                if (notHide) {
                    let index = data.position + 1
                    this.rootStore.notificationStore.setNotification("Ligne #" + index + " sauvegardée", "success");
                }
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deleteDevisLigne(data, index = "") {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.devis.deleteDevisLigne(data);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Ligne " + index + " supprimée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse.status
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.devis.delete(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Devis # " + id + " supprimé", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async togglePdf(e, id, email) {
        e.preventDefault()
        await this.rootStore.notificationStore.setQuestion("Etes vous sûr(e) de vouloir envoyer le devis par mail à " + email, " Envoyer", e => {this.sendMyPdf(id)})
    }

    async sendMyPdf(id) {
        if (id) {
            await this.sendPdf(id);
        }
    }

    async printPdf(e, id) {
        e.preventDefault();

        if (id) {
            const file = await this.getPdf(id, this.rootStore.authStore.modules['print'] === 1);

            if (file) {
                const fileURL = URL.createObjectURL(file);
                printJS(fileURL);

            }
        }
    }

    async downloadPdf(e, id, filename) {
        e.preventDefault();

        if (id) {
            const file = await this.getPdf(id, false);

            if (file) {
                const fileURL = URL.createObjectURL(file);
                var downloadLink = document.createElement("a");
                downloadLink.href = fileURL;
                downloadLink.download = filename

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);

            }
        }
    }



    async openPdf(e, id) {
        e.preventDefault();

        if (id) {
            const file = await this.getPdf(id);

            if (file) {
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }
        }
    }
}

decorate(DevisStore, {
    datas: observable,
    getDatas:  action,
    currentPage: observable,
    lastPage: observable,
    filter: observable,
    allStatus: observable,
    statusname: observable
});

