import React from "react";
import {inject, observer} from "mobx-react";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router-dom";
import moment from "moment";


const ActionSpooler = inject("rootStore")(observer(class ActionSpooler extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        moment.locale('fr');
        this.refresh = this.refresh.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
    }

    async refresh(event) {
        event.preventDefault()

        await this.injected.rootStore.spoolerStore.refresh(this.injected.datas?.id);
    }


    toggleDelete(event) {
        event.preventDefault()
        let text = "tout le spooler"
        if(this.injected.datas?.id) {
            text = " du spooler " + this.injected.datas?.id
        }
        this.injected.rootStore.notificationStore.setWarning(text, event => this.onDelete(this.injected.datas?.id))
    }


    async onDelete(deleteId) {
        if(deleteId) {
            await this.injected.rootStore.spoolerStore.delete(deleteId);
        } else {
            await this.injected.rootStore.spoolerStore.deleteAll()
        }
    }

    render() {
        return (
            <>
                <td class="text-center" style={{maxWidth: "10em"}}>
                    <Button className="mr-2"
                            variant="success"
                            type="button"
                            onClick={this.refresh}
                    >
                        <i className="fa fa-refresh" aria-hidden="true"/>
                    </Button>

                    <Button className="mr-2"
                            variant="danger"
                            type="button"
                            onClick={this.toggleDelete}
                    >
                        <i className="fa fa-remove" aria-hidden="true"/>
                    </Button>
                </td>
            </>
        );
    }

}));

export default withRouter(ActionSpooler);
