import React from "react";
import PictureHidden from "../PictureHidden";
import {SRLWrapper} from "simple-react-lightbox";

function AssetRow({data}) {
    return <td className="text-center"> <SRLWrapper>
        {data?.assets.map((asset, key) => {
          return  <PictureHidden assetName={asset.filename ?? ""} display={key === 0 ? "block" : "none"} height={"4em"} width={"4em"}/>
        })}</SRLWrapper>
    </td>
}

export default AssetRow;
