
import React from "react";
import {CardHeader, Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import {Link} from "react-router-dom";
import getRoutes from "../../../components/Akitatek/getRoutes";


const GlobalSearchPage = (inject("rootStore") (observer( class GlobalSearchPage extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Recherche", "Avec les codes barres", "default.jpg")
    }

    get injected() {
        return this.props;
    }

    setSearch(type, search) {
        if(type === "stocks") {
            return  <Link style={{ color: "#20a8d8"}} to={{
                pathname: getRoutes("Modifier stock", search.id),
                aboutProps: {
                    stock: search
                }}}>
                <ListGroupItem>
                    {this.injected.rootStore.savedDatasStore.typeSearch} #{search.id}
                </ListGroupItem>
            </Link>
        }

        if(type === "peca" || type === "pec") {
            return  <Link style={{ color: "#20a8d8"}} to={{
                    pathname:  getRoutes("Gérer une Prise En Charge", search.id, "0"),
                    aboutProps: {
                        isCommandeValid: true,
                        priseencharge: search
                    }
                }}>
                <ListGroupItem>
                    {this.injected.rootStore.savedDatasStore.typeSearch} #{search.id}
                </ListGroupItem>
            </Link>
        }

        if(type === "commandes") {
            return  <Link style={{ color: "#20a8d8"}} to={{
                pathname: "/command/update/" + search.id,
                aboutProps: {
                    isCommandeValid: true,
                    command: search
                }
            }}>
                <ListGroupItem>
                    {this.injected.rootStore.savedDatasStore.typeSearch} #{search.id}
                </ListGroupItem>
            </Link>
        }

        if(type === "users") {
            return  <Link style={{ color: "#20a8d8"}} to={{
                pathname: getRoutes("Modifier Client", search.id)
            }}>
                <ListGroupItem>
                    {this.injected.rootStore.savedDatasStore.typeSearch} #{search.id}
                </ListGroupItem>
            </Link>
        }

        if(type === "devis") {
            return  <Link style={{ color: "#20a8d8"}} to={{
                pathname: "/devis/edit/" + search.id,
                aboutProps: {
                    isCommandeValid: true,
                    devis: search
                }
            }}>
                <ListGroupItem>
                    {this.injected.rootStore.savedDatasStore.typeSearch} #{search.id}
                </ListGroupItem>
            </Link>
        }

        if(type === "factures") {
            return  <Link style={{ color: "#20a8d8"}} to={{
                pathname: getRoutes("Gérer une facture", search.id),
                aboutProps: {
                    isCommandeValid: true,
                    invoice: search
                }
            }}>
                <ListGroupItem>
                    {this.injected.rootStore.savedDatasStore.typeSearch} #{search.id}
                </ListGroupItem>
            </Link>
        }
    }

    render() {
        return (
            <>

                    <Container fluid>
                        <Card>
                            <CardHeader>Résultats {this.injected.rootStore.savedDatasStore.typeSearch}</CardHeader>
                            <CardBody>
                                {this.injected.rootStore.savedDatasStore.searchs?.map((search) => {
                                    return  this.setSearch(this.injected.rootStore.savedDatasStore.typeSearch, search)
                                })}


                                {(this.injected.rootStore.savedDatasStore.searchs.length === 0) && (
                                     <div> Aucun code barre correspondant détecté dans la base de données. </div>
                                )}
                            </CardBody>
                        </Card>
                    </Container>
            </>
        );
    }
})));

export default GlobalSearchPage;
