/* Global import */
import {action, computed, decorate, observable} from 'mobx';
/* Custom import */
import Api from '../util/api'

export default class AuthStore {
    isLogged = false;
    isLoggedDash = false;
    isLoggedEcho = false;

    error = '';
    loading = false;
    checkLoading = false;
    user = observable(
        {
            // observable properties:
            id: "",
            prenom: "",
            nom: ""
        });

    nameCompany;
    complement_adresse;
    postcode;
    telCompany;
    emailCompany;
    typeCompany;
    identifiant;
    website;
    color;
    city;
    horaires;
    logo;
    sidebar;
    options = observable([]);
    modules = observable([]);

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
        this.checkIsLogged();
    }

    get username() {
        return this.user.fullname;
    }

    async loginUrl(url) {
        this.clearError();
        try {
            const authResponse = await this.api.auth.loginUrl(url);
            localStorage.setItem('token', authResponse.token);
            this.logo = authResponse.logo;
            this.watchSession(authResponse.token)
            this.isLoggedDash = true;

            return authResponse
        } catch (error) {
            this.setError(error);
        }
    }

    async login(email, password) {
        this.loading = true;
        this.clearError();

        try {
            const authResponse = await this.api.auth.login(email, password);
            localStorage.setItem('token', authResponse.token);
            localStorage.setItem('userId', authResponse.data.user.id);
            this.user = authResponse.data.user;
            this.setLogged(authResponse);

            this.rootStore.getEcho().connect()

        } catch (error) {
            this.setError(error);
        }
    }

    async findAdmin() {
        try {
            let id = localStorage.getItem("userId");
            this.user = await this.api.users.findUserById(id);
        } catch (e) {
        }

    }

    async logout() {
        this.clearError();
        this.isLogged = false;
        this.isLoggedDash = false;
        localStorage.removeItem('token');
    }

    async checkIsLogged() {
        this.checkLoading = true;
        this.clearError();
        try {
            const token = window.localStorage.getItem('token');
            const authResponse = await this.api.auth.checkIsLoged(token || '');
            this.setLogged(authResponse);
        } catch (error) {
            this.setError(error);
        }
    }

    async updateLogo(datas) {
        this.rootStore.savedDatasStore.setLoading(true)
        let authResponse = await this.api.auth.updateLogo(datas);

        if (authResponse?.status === "success") {
            this.rootStore.notificationStore.setNotification("Logo modifié", "success");
            this.logo = authResponse.data
        }

        this.rootStore.savedDatasStore.setLoading(false)
        return authResponse
    }

    async updateSidebar(datas) {
        this.rootStore.savedDatasStore.setLoading(true)
        let authResponse = await this.api.auth.updateSidebar(datas);

        if (authResponse?.status === "success") {
            this.rootStore.notificationStore.setNotification("Sidebar modifiée", "success");
            this.sidebar = authResponse.data
        }

        this.rootStore.savedDatasStore.setLoading(false)
        return authResponse
    }

    async updateDetails(datas) {
        this.rootStore.savedDatasStore.setLoading(true)

        let authResponse = await this.api.auth.updateDetails(datas);

        if (authResponse?.status === "success") {
            this.rootStore.notificationStore.setNotification("Paramètres entreprise modifiés", "success");
            this.setDatas(authResponse.data)
        }
        this.rootStore.savedDatasStore.setLoading(false)

        return authResponse
    }


    setLogged(authResponse) {
        this.isLogged = true;
        this.loading = false;
        this.checkLoading = false;
        this.rootStore.userStore.setUser(authResponse.data.user);
        this.user = authResponse.data.user
        this.modules = authResponse.data.modules
        this.options = authResponse.data.options

        this.color =  this.options.color
        this.sidebar =  this.options.sidebar

        this.setDatas(authResponse.data.data)
        this.watchSession(authResponse.token)
    }

    setDatas(data) {
        this.nameCompany = data.name
        this.complement_adresse = data.complement_adresse
        this.postcode = data.postcode
        this.adresse = data.adresse
        this.typeCompany = data.type
        this.telCompany = data.tel
        this.emailCompany = data.email
        this.website = data.website
        this.city = data.city
        this.identifiant = data.identifiant
        this.logo = data.logo
        this.horaires = data.horaires
    }

    isMicro() {
        return this.typeCompany === "micro";
    }

    async refreshToken() {
        try {
            let authResponse = await this.api.auth.refreshToken();
            localStorage.removeItem('token');
            localStorage.setItem('token', authResponse.token);
        } catch (e) {
        }
    }

    setError(error) {
        this.loading = false;
        this.checkLoading = false;
        this.isLogged = false;
        this.isLoggedDash = false;
        this.error = error || '';
        localStorage.removeItem('token')
    }


    watchSession(token) {
        setInterval(() => {
            this.refreshToken(token)
        }, 14400000)
    }

    clearError() {
        this.error = '';
    }
}

decorate(AuthStore, {
    clearError: action,
    login: action,
    watchSession: action,
    setLogged: action,
    setError: action,
    checkIsLogged: action,
    logout: action,
    checkLoading: observable,
    isLogged: observable,
    isLoggedDash: observable,
    isLoggedEcho: observable,
    error: observable,
    loading: observable,
    user: observable,
    username: computed,
    nameCompany: observable,
    complement_adresse: observable,
    postcode: observable,
    telCompany: observable,
    emailCompany: observable,
    typeCompany: observable,
    logo: observable,
    sidebar: observable,
    modules: observable,
    home: observable,
    identifiant: observable,
    options: observable
});