import React from "react";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import i18n from "i18next";
import TableLotRow from "./TableLotRow";


const DetailsLot = inject("rootStore")(observer(class TableStats extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            titles: this.injected.type === "lots" ? ["Id","Commande",  "Prix unitaire TTC", "Quantité disponible", i18n.t('page.admin.invoices.created')] :
                ["Id", "Commande", "Prix unitaire TTC",  "Quantité à venir", i18n.t('page.admin.invoices.created')],
            lots: this.injected.datas ?? [],
            type: this.injected.type
        }
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.datas !== prevProps.datas
        ) {
            this.setState({
                titles: this.injected.type === "lots" ? ["Id", "Commande",  "Prix unitaire TTC", "Quantité disponible", i18n.t('page.admin.invoices.created')] :
                    ["Id", "Commande", "Prix unitaire TTC",  "Quantité à venir", i18n.t('page.admin.invoices.created')],
                lots: this.injected.datas ?? [],
                type: this.injected.type
            })
        }
    }


    render() {
        return (
            <>
                <div>
                    <TableGeneric key="cardLot"
                                  titles={this.state.titles}>
                        {this.state.lots
                            .map(lot => {
                                return <TableLotRow
                                    type={this.state.type}
                                    item={lot}
                                    key={lot.id}/>
                            })
                        }
                        {(this.state.lots.length === 0) && (
                            <tr>
                                <td className={"no_td"}
                                    colSpan={this.state.titles}>Pas
                                    de lots disponibles
                                </td>
                            </tr>
                        )}
                    </TableGeneric>
                </div>
            </>
        );
    }

}));

export default withRouter(DetailsLot);
