import React from "react";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {Animated} from "react-animated-css";

const CLientModeComponent = (inject("rootStore")(observer(class CLientModeComponent extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    get injected() {
        return this.props;
    }

    render() {
        const {datas} = this.props
        return (
            <>
                <Animated animationIn="fadeInRight" animationOut="fadeInRight" animationInDuration={1000}>
                    <div className={"container-fluid pt-5 min-vh-100"} style={{ backgroundColor: "#38c7a3"}}>
                        <div style={{ color: "white"}}> <h1> Bonjour </h1>
                            <div style={{ fontSize: "2em"}}>
                            {
                                datas?.map(item => {
                                    return  <Animated animationIn="fadeInRight" animationOut="fadeInRight" animationInDuration={1000}> {item} </Animated>

                                })
                            }
                            </div>
                        </div>
                    </div></Animated>
            </>
        );
    }
})));

export default withRouter(CLientModeComponent);
