import {inject, observer} from "mobx-react";
import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import styles from "./Stock.module.css";
import {withRouter} from "react-router-dom";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Tabs from "../../../components/Akitatek/Tabs/Tabs";
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import i18n from "i18next";
import TableMouvRow from "./TableMouvRow";
import TableLotRow from "./TableLotRow";
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import InputGroup from "reactstrap/lib/InputGroup";
import {InputGroupAddon} from "reactstrap";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import PictureHidden from "../../../components/Akitatek/PictureHidden";
import ChoiceImageInput from "../../../components/Akitatek/Form/ChoiceImageInput";
import ChoiceCategory from "../../../components/Akitatek/Category/ChoiceCategory";
import CATEGORY from "../../../components/Akitatek/Category/Category";
import EditorWord from "../../../components/Akitatek/EditorWord";
import ModalAkitatek from "../../../components/Akitatek/Modal/Modal";
import Casier from "../Casier/Casier";
import PrintImpression from "../Printer/PrintImpression";
import getRoutes from "../../../components/Akitatek/getRoutes";
import Assets from "./Assets";
import Files from "./Files";


const EditStock = (inject("rootStore")(observer(class Stock extends React.Component {

    product;

    get injected() {
        return this.props;
    }

    async componentDidUpdate(prevProps) {
        if (
            this.injected.product !== prevProps.product
        ) {

            this.initData(this.injected.product)
        }

        if (this.injected.location !== prevProps.location) {
            await this.onRouteChanged()
        }

        if (this.state.produit_id === "" && this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }

    //check if the values are up to date
    async componentDidMount() {
        if (this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }

    //get the values of commande by ID
    async onRouteChanged() {
        let id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (id) {
            var product = await this.injected.rootStore.stockStore.productStore.getStocksForId(id)

            if (product) {
                this.initData(product, this.state.disabled)
            }
        }
    }

    async initData(product) {
        let editorState
        if (product?.online?.description != null) {
            let blocksFromHTML = htmlToDraft(product?.online?.description);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            editorState = EditorState.createWithContent(contentState);
        } else {
            editorState = EditorState.createEmpty()
        }

        await this.setState({
            marque: "",
            product: "",
            assets: product?.assets,
            nom: product?.nom ?? "",
            produit_id: product?.id ?? "",
            quantite: product?.stock ?? 0,
            parents: product?.category?.parents,
            path: product?.category?.path,
            pathBoutique: product?.online?.category?.path,
            prix_unitaire: product?.prix_unitaire ?? 0,
            second_com: product?.second_com ?? "",
            first_com: product?.first_com ?? "",
            reference: product?.ref ?? "",
            subtitle: product?.subtitle ?? "",
            mouvement_prov: product?.mouvement_prov ?? [],
            mouvement: product?.mouvement ?? [],
            lots: product?.lots ?? [],
            size: product?.online?.size,
            prix_vente: product?.prix_vente ?? 0,
            url: product?.online?.url,
            checkbox: product?.shop,
            shopcategory_id: product?.online?.category?.id,
            assetNameShop: product?.online?.asset?.filename,
            asset_id_shop: product?.online?.asset?.id,
            category_id: product?.category?.id,
            editorState: editorState,
            average_price: product?.average_price,
            quantity_alerte: product?.quantity_alerte ?? "",
            quantity_min: product?.quantity_min ?? "",
            quantity_boutique: product?.online?.quantity_boutique ?? "2",
            casier: product?.casier ?? "",
            video1: product?.online?.video1,
            video2: product?.online?.video2,
            video3: product?.online?.video3,
        })
    }


    constructor(props, context) {
        super(props, context);
        let product = this.injected.location.aboutProps?.product;

        this.state = {
            marque: "",
            product: "",
            allProducts: [],
            allMarques: [],
            quantity_alerte: product?.quantity_alerte ?? "",
            quantity_min: product?.quantity_min ?? "",
            quantity_boutique: product?.online?.quantity_boutique ?? "",
            nom: product?.nom ?? "",
            parents: product?.category?.parents,
            produit_id: product?.id ?? "",
            quantite: product?.stock ?? 0,
            size: product?.online?.size,
            pathBoutique: product?.shopcategory?.path,
            path: product?.category?.path,
            prix_vente: product?.prix_vente ?? 0,
            second_com: product?.second_com ?? "",
            first_com: product?.first_com ?? "",
            reference: product?.ref ?? "",
            checkbox: product?.shop,
            average_price: product?.average_price,
            subtitle: product?.subtitle ?? "",
            casier: product?.casier ?? "",
            stockage: product?.casier?.stockage ?? "",
            mouvement_prov: product?.mouvement_prov ?? [],
            mouvement: product?.mouvement ?? [],
            category_id: product?.category?.id,
            lots: product?.lots ?? [],
            url: product?.online?.url,
            assets: product?.assets,
            shopcategory_id: product?.online?.category?.id,
            assetNameShop: product?.online?.asset?.filename,
            asset_id_shop: product?.online?.asset?.id,
            video1: product?.online?.video1,
            video2: product?.online?.video2,
            video3: product?.online?.video3,
            description: EditorState.createEmpty()
        }


        this.onChangeCallbackCategory = this.onChangeCallbackCategory.bind(this)
        this.injected.rootStore.fournisseurStore.getAll();
        this.injected.rootStore.stockageCasierStore.getCasiers()
        this.injected.rootStore.stockageCasierStore.getStockageCasier()
        this.injected.rootStore.savedDatasStore.setHeader("Stock", "Gestion des stocks", "motherboard.jpg")

    }

    async refresh() {
        var product = await this.injected.rootStore.stockStore.productStore.getStocksForId(this.state.produit_id)

        if (product) {
            product = product[0]
            this.initData(product, !this.state.disabled)
        }

    }

    showValidate() {
        return <div>
            <Button variant="primary" className={"mr-2"} onClick={this.refresh.bind(this)}> Annuler </Button>
            <Button variant="warning" className={"mr-2"}  onClick={e => this.onSubmit(e, true)}> Valider </Button>
            {this.state.quantite === 0 && (
                <Button variant="danger" onClick={e => this.toggleDelete(e)}> Supprimer </Button>
            )}
        </div>
    }

    async onSubmit(e) {
        if(e) {
            e.preventDefault()
        }

        let data = {
            photo: this.state.file,
            nom: this.state.nom,
            quantite: this.state.quantite,
            produit_id: this.state.produit_id,
            prix_vente: this.state.prix_vente ?? 0,
            second_com: this.state.second_com,
            first_com: this.state.first_com,
            ref: this.state.reference,
            url: this.state.url,
            size: this.state.size,
            shop: this.state.checkbox,
            subtitle: this.state.subtitle,
            casier_id: this.state.casier?.id,
            shopcategory_id: this.state.shopcategory_id ?? "",
            category_id: this.state.category_id ?? "",
            asset_id: this.state.asset_id_shop,
            description: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
            quantity_alerte: this.state.quantity_alerte ?? "",
            quantity_min: this.state.quantity_min ?? "",
            quantity_boutique: this.state.quantity_boutique ?? "",
            video1: this.state.video1,
            video2: this.state.video2,
            video3: this.state.video3,
        }

        const authResponse = await this.injected.rootStore.stockStore.productStore.updateStock(data)

        if (authResponse) {
            this.initData(authResponse.data, true)
        }
    }

    toggleDelete(event) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning(this.state.nom, event => this.onDelete(this.state.produit_id))
    }

    async onDelete(deleteId) {
        let authResponse = await this.injected.rootStore.stockStore.productStore.delete(deleteId)

        if (authResponse?.status === "success") {
            this.injected.history.push(getRoutes("Mon stock"))
        }
    }

    change() {
        this.setState({disabled: !this.state.disabled})
    }

    async enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            await this.onSubmit(e, false)
        }
    }


    onChangeCallbackCategory(value) {
        this.setState({
            category: value
        })
    }

    onCommande = async (id) => {
        this.injected.history.push(getRoutes("Gérer une commande", id));
    }


    onUpdate = async () => {
        await this.refresh()
    }


    onChangeCategory = () => {
        if (this.injected.rootStore.annuaireStore.categoryStore.selectionCategory) {
            this.setState({
                parents: this.injected.rootStore.annuaireStore.categoryStore.selectionCategory.parents,
                path: this.injected.rootStore.annuaireStore.categoryStore.selectionCategory.path,
                category_id: this.injected.rootStore.annuaireStore.categoryStore.selectionCategory.id,
            })
        } else {
            this.setState({
                parents: "",
                path: "",
                category_id: "",
            })
        }
    }

    onPickAssetFilenameShop = (action) => {
        if (action === "add") {
            this.setState({
                assetNameShop: this.injected.rootStore.imageStore.pickAsset?.filename,
                asset_id_shop: this.injected.rootStore.imageStore.pickAsset?.id,
            })
        } else {
            this.setState({
                assetNameShop: "",
                asset_id_shop: "",
            })
        }
    }

    setForm() {
        return (<Card.Body>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="exampleNumber">Nom du stock</Label>
                        <Input disabled={this.state.disabled}
                               type="text"
                               onKeyPress={e => this.enter(e)}
                               name="name"
                               onChange={e => this.setState({nom: e.target.value})}
                               value={this.state.nom}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="exampleNumber">Sous titre</Label>
                        <Input disabled={this.state.disabled}
                               type="text"
                               onKeyPress={e => this.enter(e)}
                               name="name"
                               value={this.state.subtitle}
                               onChange={e => this.setState({subtitle: e.target.value})}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <ChoiceCategory
                            name={"Catégorie"}
                            path={this.state.path}
                            type={CATEGORY.NORMAL}
                            onChangeCategory={this.onChangeCategory}
                            defaultExpanded={this.state.parents}/>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="exampleRef">Référence</Label>
                        <Input disabled={this.state.disabled}
                               type="text"
                               onKeyPress={e => this.enter(e)}
                               name="name"
                               value={this.state.reference}
                               onChange={e => this.setState({reference: e.target.value})}
                        />
                    </FormGroup>
                </Col>
            </Row>


            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="exampleCom">Commentaire 1</Label>
                        <Input disabled={this.state.disabled}
                               type="textarea"
                               onKeyPress={e => this.enter(e)}
                               name="name"
                               value={this.state.first_com}
                               onChange={e => this.setState({first_com: e.target.value})}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="exampleSecondCom">Commentaire 2</Label>
                        <Input disabled={this.state.disabled}
                               type="textarea"
                               onKeyPress={e => this.enter(e)}
                               name="name"
                               value={this.state.second_com}
                               onChange={e => this.setState({second_com: e.target.value})}
                        />
                    </FormGroup>
                </Col>
            </Row>


            <p>Prix d'achat moyen TTC : {this.state.average_price} € </p>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label> Prix de vente choisi TTC</Label>
                        <InputGroup>
                            <Input
                                onKeyPress={e => this.enter(e)}
                                value={this.state.prix_vente}
                                onChange={e => this.setState({prix_vente: e.target.value})}
                                placeholder="Prix de vente conseillé" min={0} max={100} type="number" step="1"/>
                            <InputGroupAddon addonType="append">€</InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col md={6} style={{display: "flex", alignItems: "center"}}>
                    <Button
                        onClick={e => this.setState({addStockage: true})}
                        variant="warning" size="sm" type="button" className="mr-1">
                        Choisir un stockage
                    </Button>
                    {this.state.casier && (
                        <div className={"mr-2"}> {this.state.casier?.name} - {this.state.casier.stockage?.name}</div>
                    )}
                </Col>
            </Row>

        </Card.Body>)
    }

    setImpression() {
        return <PrintImpression id={this.state.produit_id} datas={this.state.lots}
                                api={this.injected.rootStore.stockStore} type={"stock"}/>
    }

    setLots() {
        return (
            <div>
                <div className={[styles.headerTab]}>
                    <p> Vous pouvez suivre les différents lots pour ce produit. </p>
                </div>
                <p>Prix d'achat moyen TTC : {this.state.average_price} € </p>

                <TableGeneric key="cardLot"
                              titles={["Id", "Commande", "Fournisseur", "Prix unitaire TTC", "Quantité disponible", i18n.t('page.admin.invoices.created'), "Actions Etiquette Client", "Actions Etiquette Stock"]}>
                    {this.state.lots
                        .map(lot => {
                            return <TableLotRow type="lots" item={lot} key={lot.id}/>
                        })
                    }
                    {(this.state.lots.length === 0) && (
                        <tr>
                            <td className={"no_td"}
                                colSpan={["Id","Commande",  "Fournisseur", "Prix unitaire",  "Quantité", i18n.t('page.admin.invoices.created'),  "Actions Etiquette Client", "Actions Etiquette Stock"].length}>Pas
                                de lots disponibles
                            </td>
                        </tr>
                    )}
                </TableGeneric>
            </div>)
    }

    setMouv(mouvements) {
        return (
            <div>
                <div className={[styles.headerTab]}>
                    <p> Vous pouvez suivre les différents mouvements des stocks sur chaque lot. </p>
                </div>

                <TableGeneric key="cardMuv"
                              titles={["Lot", "Facture", "Commande", "Commande Client", "Action", "Quantité", i18n.t('page.admin.invoices.created')]}>
                    {mouvements
                        .map(mouv => {
                            return <TableMouvRow item={mouv} key={mouv.id}/>
                        })
                    }
                    {(mouvements.length === 0) && (
                        <tr>
                            <td className={"no_td"}
                                colSpan={["Lot", "Facture", "Commande", "Action", "Quantité", i18n.t('page.admin.invoices.created')].length}>Pas
                                de mouvements disponibles
                            </td>
                        </tr>
                    )}
                </TableGeneric>
            </div>)
    }

    setStock() {
        return (
            <>
                <Card>
                    <CardHeader>
                        Quantité :
                    </CardHeader>
                    <CardBody>
                        <p> Quantité disponible : {this.state.quantite}</p>
                        {this.injected.rootStore.authStore.modules['boutique'] === 1 && (
                            <FormGroup>
                                <Label> Seuil boutique: </Label>
                                <InputGroup>
                                    <Input
                                        onKeyPress={e => this.enter(e)}
                                        value={this.state.quantity_boutique}
                                        onChange={e => this.setState({quantity_boutique: e.target.value})}
                                        placeholder="Seuil boutique" min={0} max={100} type="number" step="1"/>
                                </InputGroup>
                            </FormGroup>)}
                        <FormGroup>
                            <Label> Seuil d'alerte : </Label>
                            <InputGroup>
                                <Input
                                    onKeyPress={e => this.enter(e)}
                                    value={this.state.quantity_alerte}
                                    onChange={e => this.setState({quantity_alerte: e.target.value})}
                                    placeholder="Seuil d'alerte" min={0} max={100} type="number" step="1"/>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label> Seuil minimum : </Label>
                            <InputGroup>
                                <Input
                                    onKeyPress={e => this.enter(e)}
                                    value={this.state.quantity_min}
                                    onChange={e => this.setState({quantity_min: e.target.value})}
                                    placeholder="Seuil minimum" min={0} max={100} type="number" step="1"/>
                            </InputGroup>
                        </FormGroup>
                    </CardBody>
                </Card>
                <Card>
                    <Button variant="success" size="lg" onClick={e => this.setEntryModal()}>
                        <i className="fa fa-plus" aria-hidden="true"></i>{' '}
                        Ajouter une entrée de stock
                    </Button>{' '}
                    <Button variant="warning" size="lg" onClick={e => this.setOutModal()}>
                        <i className="fa fa-minus" aria-hidden="true"></i>{' '}
                        Ajouter une sortie de stock
                    </Button>{' '}
                    <Button href="#" variant="danger" size="lg" onClick={e => this.inventaire()}>
                        <i className="fa fa-wrench" aria-hidden="true"></i>{' '}
                        Inventaire
                    </Button>
                    <Button href="#" variant="primary" size="lg" onClick={e => this.createCommande()}>
                        <i className="fa fa-shopping-bag" aria-hidden="true"></i>{' '}
                        Créer une commande rapide
                    </Button>
                </Card>
            </>)
    }


    onEditorStateChange = (editorState) => {
        this.setState({
            editorState:
            editorState,
        });
    };

    setVente() {
        return (<CardBody>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label> Mettre en vente dans la boutique : </Label>
                        <InputGroup>
                            <Input
                                checked={this.state.checkbox}
                                className={"ml-2"}
                                type="checkbox"
                                name="name"
                                value={1}
                                onChange={e => this.setState({checkbox: this.state.checkbox === 1 ? 0 : 1})}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                {this.state.checkbox === 1 && (
                    <Col md={6}>
                        <FormGroup>
                            <p>Prix d'achat moyen TTC : {this.state.average_price} € </p>
                            <Label> Prix de vente choisi TTC</Label>
                            <InputGroup>
                                <Input
                                    onKeyPress={e => this.enter(e)}
                                    value={this.state.prix_vente}
                                    onChange={e => this.setState({prix_vente: e.target.value})}
                                    placeholder="Prix de vente conseillé" min={0} max={100} type="number" step="1"/>
                                <InputGroupAddon addonType="append">€</InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                )}
            </Row>

            <Row>
                {this.state.checkbox === 1 && (
                    <Col md={6}>
                        <FormGroup>
                            <Label> URL </Label>
                            <InputGroup>
                                <Input
                                    onKeyPress={e => this.enter(e)}
                                    value={this.state.url}
                                    onChange={e => this.setState({url: e.target.value})}
                                    />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                )}
                {this.state.checkbox === 1 && (
                    <Col md={6}>
                        <Label> Taille de l'item </Label>
                        <FormGroup>
                            <label>
                                <input type="radio"
                                       onClick={e => this.setState({size: 0})}
                                       checked={this.state.size === 0}
                                />
                                <span className="badge badge-pill badge-warning">Petit</span>
                            </label>
                        </FormGroup>
                        <FormGroup className={"mr-2"}>
                            <label>
                                <input type="radio"
                                       onClick={e => this.setState({size: 1})}
                                       checked={this.state.size === 1}
                                />
                                <span className="badge badge-pill badge-success">Grand </span>
                            </label>
                        </FormGroup>
                    </Col>
                )}
            </Row>

            {this.state.checkbox === 1 && (
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label> Seuil boutique: </Label>
                            <InputGroup>
                                <Input
                                    onKeyPress={e => this.enter(e)}
                                    value={this.state.quantity_boutique}
                                    onChange={e => this.setState({quantity_boutique: e.target.value})}
                                    placeholder="Seuil boutique" min={0} max={100} type="number" step="1"/>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>)}

            <Row>
                {this.state.checkbox === 1 && (
                    <Col md={6}>
                        <FormGroup>
                            <Label> Lien de la vidéo 1 </Label>
                            <InputGroup>
                                <Input
                                    onKeyPress={e => this.enter(e)}
                                    value={this.state.video1}
                                    onChange={e => this.setState({video1: e.target.value})}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                )}
                {this.state.checkbox === 1 && (
                    <Col md={6}>
                        <FormGroup>
                            <Label> Lien de la vidéo 2 </Label>
                            <InputGroup>
                                <Input
                                    onKeyPress={e => this.enter(e)}
                                    value={this.state.video2}
                                    onChange={e => this.setState({video2: e.target.value})}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                )}
                {this.state.checkbox === 1 && (
                    <Col md={6}>
                        <FormGroup>
                            <Label> Lien de la vidéo 3 </Label>
                            <InputGroup>
                                <Input
                                    onKeyPress={e => this.enter(e)}
                                    value={this.state.video3}
                                    onChange={e => this.setState({video3: e.target.value})}
                                    />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                )}
            </Row>

            <Row form>
                {this.state.checkbox === 1 && (
                    <Col>
                        <EditorWord editorState={this.state.editorState}
                                    onEditorStateChange={this.onEditorStateChange}/>
                    </Col>
                )}

            </Row>


            <Row form>
                {this.state.checkbox === 1 && !this.state.disabled && (
                    <ChoiceImageInput assetName={this.state.assetNameShop}
                                      onPickAssetFilename={this.onPickAssetFilenameShop}/>
                )}

                {this.state.checkbox === 1 && this.state.disabled && (
                    <Col md={6} className={'m-auto'}
                         style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                        <PictureHidden assetName={this.state.assetName ?? ""}/>
                    </Col>
                )}
            </Row>
        </CardBody>)
    }


    setEntryModal() {
        var options = [];

        this.injected.rootStore.fournisseurStore.fournisseurs.map((value) => {
            return options[value.id] = value.nom;
        });

        this.injected.rootStore.notificationStore.entryStock("Combien voulez vous faire entrer de produits pour ce lot?", "Quel fournisseur?", options, "#4dbd74", this.state.produit_id, "ADD_MANUALLY", e => this.onUpdate())
    }

    setOutModal() {
        var options = [];

        this.state.lots.filter((p) => p.stock > 0).map((value) => {
            return options[value.id] = value.pid + "-Qte: " + value.stock ;
        });

        this.injected.rootStore.notificationStore.entryStock("Combien voulez vous faire sortir de produits pour ce lot?", "Quel lot?", options, "#ffc107", this.state.produit_id, "REMOVE_MANUALLY", e => this.onUpdate())
    }



    createCommande() {
        var options = [];

        this.injected.rootStore.fournisseurStore.fournisseurs.map((value) => {
            return options[value.id] = value.nom;
        });

        this.injected.rootStore.notificationStore.entryStock("Combien voulez vous faire entrer de produits pour la commande?", "Quel fournisseur?", options, "#4dbd74", this.state.produit_id, "BUY",  this.onCommande)
    }

    inventaire() {
        this.injected.rootStore.notificationStore.inventaire('Corriger le stock', this.state.quantite, this.state.produit_id, e => this.onUpdate())
    }


    //Callback
    callbackOnChange = (name, value) => {
        this.setState({[name]: value});
    }

    onCloseClearStockage = () => {
        this.setState({
            addStockage: false,
            activeTab: "0"
        })
    };

    onCloseStockage = async (childData) => {
        await this.setState({
            addStockage: false,
            activeTab: "0",
            casier: childData
        })

        this.onSubmit()
    };

    render() {
        return (
            <>

                <CardBody>
                    <Card className={styles.item}>
                        <Card.Header className={[styles.header, "bg-success"]}>
                            <a onClick={e => {
                                e.preventDefault()
                                this.injected.history.push('/stock/index')
                            }} style={{display: "flex", alignItems: "center"}}>
                                <i style={{cursor: "pointer"}} className="fa fa-arrow-left mr-2" aria-hidden="true"/>
                            </a>
                            <h2># {this.state.produit_id} - {this.state.nom} - Quantité
                                : {this.state.quantite} - {this.state.casier?.name} - {this.state.casier?.stockage?.name} </h2>
                            <div>
                                {this.showValidate()}
                            </div>
                        </Card.Header>
                        <Tabs activeTab={this.state.activeTab}
                              callbackOnChange={this.callbackOnChange}
                              children={this.injected.rootStore.authStore.modules['boutique'] === 1 ? [this.setForm(), <Assets id={this.state.produit_id} type="stock" assets={this.state.assets} onCallbackAsset={this.callbackOnChange}/>,  this.setVente(), this.setLots(), this.setMouv(this.state.mouvement), this.setMouv(this.state.mouvement_prov), this.setStock(), <Files id={this.state.produit_id} type="stock" assets={this.state.assets} onCallbackAsset={this.onCallbackAsset}/>,] : [this.setForm(), <Assets id={this.state.produit_id} type="stock" assets={this.state.assets} onCallbackAsset={this.onCallbackAsset}/>, this.setLots(), this.setMouv(), this.setStock(), <Files id={this.state.produit_id} type="stock" assets={this.state.assets} onCallbackAsset={this.onCallbackAsset}/>]}
                              titles={this.injected.rootStore.authStore.modules['boutique'] === 1 ? ["Infos", "Images", "Vente", "Lots", "Mouvements", "Mouvements en attente", "Gérer le stock", "Fichiers"] : ["Infos", "Images", "Lots", "Mouvements", "Gérer le stock", "Fichiers"]}/>
                    </Card>
                </CardBody>
                <ModalAkitatek title={"Choisir un stockage"} show={this.state.addStockage}
                               children={<Casier onSelection={false} onCallback={this.onCloseStockage}></Casier>}
                               onCallback={this.onCloseClearStockage}/>
            </>

        );
    }

})));

export default withRouter(EditStock);
