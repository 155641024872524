import {inject, observer} from "mobx-react";
import React from "react";
import {Container} from "react-bootstrap";
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import styles from "./Stats.module.css"
import * as moment from 'moment'
import 'moment/locale/fr';
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import TableStats from "./TableStats";
import PieStats from "./Pie";


const StatsDetails = (inject("rootStore")(observer(class Stats extends React.Component {


    statsStore = this.injected.rootStore.statsStore;

    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);
        moment.locale('fr');

        this.statsStore.getDetailYear(this.statsStore.actual.moisNumber,  this.statsStore.actual.year);

        this.injected.rootStore.savedDatasStore.setHeader("Statistique de l'activité", "", "stats.jpg")
    }

    async sendRequest(month, year) {
        this.statsStore.actual.year = year;
        this.statsStore.actual.moisNumber = month;
        await this.statsStore.getDetailYear(month, year);

    }


    async getToPrecedent(e) {
        e.preventDefault();
        this.statsStore.actual.year = this.statsStore.precedent.moisMoment.format("YYYY");
        this.statsStore.actual.moisNumber = this.statsStore.precedent.moisMoment.format("M");
        await this.statsStore.getDetailYear(this.statsStore.precedent.moisMoment.format("MM"), this.statsStore.precedent.moisMoment.format("YYYY"));

    }

    async getToNext(e) {
        e.preventDefault();
        this.statsStore.actual.year = this.statsStore.precedent.moisMoment.format("YYYY");
        this.statsStore.actual.moisNumber = this.statsStore.precedent.moisMoment.format("M");
        await this.statsStore.getDetailYear(this.statsStore.suivant.moisMoment.format("MM"), this.statsStore.suivant.moisMoment.format("YYYY"));
    }

    setOptions() {
        let m = moment();
        let options = [];
        for (let i = 0; i < 12; i++) {
            let month = m.months(i).format('MMMM');
            options.push(
                <option
                    className={styles.customInput}
                    value={i + 1}>
                    {month.charAt(0).toUpperCase() + month.slice(1)}
                </option>
            );
        }

        return options;
    }


    render() {
        return (
            <Container fluid>

                <div className="animated fadeIn">
                    <Row>

                        <Col xs="12" sm="4" lg="4">
                            <Card className="text-white bg-success">
                                <CardBody className="pb-0" style={{textAlign: "center", height: "10em"}}>
                                    <Row>
                                        <Col className={styles.imageCard} sm={4}>
                                            <i className="fa fa-chevron-left fa-5x" style={{cursor: "pointer"}}
                                               onClick={e => this.getToPrecedent(e)}/>
                                        </Col>

                                        <Col className={"m-auto"}>
                                            <div className="text-value  mb-4"><span
                                                style={{textTransform: "capitalize"}}>    {this.injected.rootStore.statsStore.precedent.moisString} </span>
                                            </div>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs="12" sm="4" lg="4">
                            <Card className="text-white bg-success">
                                <CardBody className="pb-0" style={{textAlign: "center", height: "10em"}}>
                                    <Row>
                                        <Col>
                                            <Input style={{
                                                fontSize: "1.3125rem",
                                                fontWeight: "600",
                                                backgroundColor: "transparent",
                                                color: "white"
                                            }}
                                                   type="select" name="select"
                                                   value={this.statsStore.actual.moisNumber || 0}
                                                   onChange={e => this.sendRequest(e.target.value, this.statsStore.actual.year)}
                                            >
                                                {this.setOptions()}
                                            </Input>
                                        </Col>
                                        <Col>
                                            <Input style={{
                                                fontSize: "1.3125rem",
                                                fontWeight: "600",
                                                backgroundColor: "transparent",
                                                color: "white"
                                            }}
                                                   type="number" name="year"
                                                   value={this.statsStore.actual.year || 0}
                                                   onChange={e => this.sendRequest(this.statsStore.actual.moisNumber, e.target.value)}
                                            >
                                            </Input>
                                        </Col>
                                    </Row>

                                    <div className={"mt-2"}>
                                        Ca HT: {this.statsStore.caDetails.total} € =
                                        Services HT : {this.statsStore.caDetails.mo} € /
                                        Marchandises HT : {this.statsStore.caDetails.othersAll} €
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs="12" sm="4" lg="4">
                            <Card className="text-white bg-warning">
                                <CardBody className="pb-0" style={{textAlign: "center", height: "10em"}}>
                                    <Row>

                                        <Col className={"m-auto"}>
                                            <div className="text-value  ml-4"><span
                                                style={{textTransform: "capitalize"}}>    {this.injected.rootStore.statsStore.suivant.moisString} </span>
                                            </div>
                                        </Col>

                                        <Col className={styles.imageCard} sm={4}>
                                            <i className="fa fa-chevron-right fa-5x" style={{cursor: "pointer"}}
                                               onClick={e => this.getToNext(e)}/>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <Card>
                    <TableGeneric key="card1"
                                  titles={["Numéro Facture", "Date Création", "Date Encaissement", "Total Service", "Total Marchandises", "Total Facture HT", "Montant TVA", "Total Facture HT2 (Frais de port)", "Total Facture TTC", "Total Réglement"]}>
                        {this.injected.rootStore.statsStore.details
                            .map(invoice => {
                                return <TableStats invoice={invoice} key={invoice.id}/>
                            })
                        }
                    </TableGeneric>

                    <div>
                        <div className={"mt-2"}>
                            <div> Total CA de ce mois HT: <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.total} €</span></div>
                            <div> Total CA de l'année jusqu'à la fin de ce mois HT: <span
                                style={{fontWeight: "bold"}}> {this.statsStore.detailsYear.totalCAUntil} € </span></div>
                        </div>
                    </div>
                </Card>
            </Container>)
    }
})));

export default StatsDetails;
