/* Global import */
import {observable, action, decorate} from 'mobx'
import getRoutes from "../components/Akitatek/getRoutes";
const Swal = require('sweetalert2')

// type  = 'info' | 'success' | 'warning' | 'error';

export default class NotificationStore {
    title;
    message;
    state;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    setNotification(title,  state, time = 5000, setUrl = false) {
        this.title = title;
        this.state = state;


        Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: time,
            icon: state, //built in icons: success, warning, error, info
            padding: "1.25rem",
            title: title
        }).then(okay => {
            if (okay && setUrl) {
                setUrl()
            }
        });

    }

     async setWarning(texte, onDelete, onRemove = true) {
         let retour = false;
         await Swal.fire({
             title: onRemove ? "Êtes vous sur de supprimer?" : "Êtes vous sur?" ,
             text: "Impossible de revenir en arrière",
             icon: 'warning',
             showCancelButton: true,
             confirmButtonColor: '#d33',
             confirmButtonText: onRemove ? 'Supprimer ' + texte : 'Oui ' + texte,
             cancelButtonText: 'Non, annuler!',
             reverseButtons: true
         }).then((result) => {
             if (result.value) {
                 onDelete()
                 retour = true;
             } else if (
                 result.dismiss === Swal.DismissReason.cancel
             ) {
                 Swal.fire(
                     'Annuler',
                     onRemove ?  texte + ' n\'est pas supprimé' : texte + ' n\'est pas cloturé',
                     'error'
                 )
                 retour = false;
             }
         })
         return retour;
     }

    async setQuantity(title, texte, stockage_row_id, stockage_id) {
        let retour = false;

        await Swal.fire({
            title: title ,
            input: 'text',
            icon: 'warning',
            confirmButtonText:  texte,
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let data = {
                    name: result.value,
                    stockage_row_id: stockage_row_id,
                    stockage_id: stockage_id
                }
                this.rootStore.stockageCasierStore.createCategory(data)

                retour = true;
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Annuler',
                    texte,
                    'error'
                )
                retour = false;
            }
        })

        return retour;
    }

    async setQuestion(title, texte, onChange) {
         let retour = false;

         await Swal.fire({
             title: title,
             icon: 'question',
             iconHtml: '?',
             confirmButtonText: 'Oui ' + texte,
             cancelButtonText: 'Annuler',
             showCancelButton: true,
             showCloseButton: true,
             reverseButtons: true
         }).then((result) => {
             if (result.value) {
                 onChange()
                 retour = true;
             } else if (
                 result.dismiss === Swal.DismissReason.cancel
             ) {
                 Swal.fire(
                     'Annuler',
                     texte,
                     'error'
                 )
                 retour = false;
             }
         })

         return retour;
     }

     async setEncaissement(valeurEncaissementTotal, type, onEncaisser) {
         let retour = false;

         await Swal.fire({
             title: '<strong>Encaisser '+valeurEncaissementTotal+'€ en '+ type +' ? <i class="fa fa-money"></i> </strong>',
             icon: 'warning',
             html: "<p> <hr> </p> ",
             showCloseButton: true,
             showCancelButton: true,
             focusConfirm: false,
             confirmButtonText:
                 '<i class="fa fa-check-circle"></i> Confirmer encaissement '+ valeurEncaissementTotal +'€ en '+ type + ' - <i class="fa fa-money"></i>',
             confirmButtonAriaLabel: 'Confirmer encaissement',
             cancelButtonText:
                 '<i class="fa fa-ban"></i> Annuler',
             cancelButtonAriaLabel: 'Annuler',
         }).then((result) => {
             if (result.value) {
                onEncaisser()
             } else if (
                 result.dismiss === Swal.DismissReason.cancel
             ) {
                 retour = false;
             }
         }

         );

         return retour;
     }

    async validateWarning(htmlWarningSave, onValidate, isValid = true) {
         Swal.fire({
            title: '<strong>Attention :</strong>',
            type: 'info',
            html: htmlWarningSave,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
            isValid ? '<i class="fa fa-thumbs-up"></i> Valider' : '<i class="fa fa-thumbs-down"></i> Refuser',
            confirmButtonColor:  isValid ? 'primary' : 'red',
            confirmButtonAriaLabel: isValid ? 'Valider' : 'Refuser',
            cancelButtonText:
                '<i class="fa fa-ban"></i> Annuler',
            cancelButtonAriaLabel: 'Annuler',
        }).then((result) => {
            if (result.value) {
                onValidate()
            }
        });
    }


    async entryStock(title = 'Combien de produits voulez vous faire entrer dans le stock?', title2, inputOptions, color, productId, type, onUpdate) {


        Swal.mixin({
            input: 'text',
            confirmButtonText: type === "ADD_MANUALLY" ? "Valider" : 'Suivant &rarr;',
            showCancelButton: true,
            confirmButtonColor:color,
            cancelButtonText: 'Retour',
            progressSteps: type === "ADD_MANUALLY" ? [] : ['1', '2', '3']
        }).queue(this.getDatas(title2, inputOptions, type, title))
            .then((result) => {
            if (result.value) {
                let data = {
                    quantite: result.value[0],
                    produit_id: productId,
                    type: type
                }

                if(type !== "ADD_MANUALLY") {
                    data.lot_id = result.value[1]
                    data.reason = result.value[2]
                }

                if(type === "BUY") {
                    data.fournisseur_id = result.value[1]
                }


                if(type !== "BUY") {
                    this.rootStore.stockStore.mouvStore.saveMouv(data)
                        .then(response => {

                            if(response === "success") {
                                onUpdate()
                            }
                        } )
                } else {
                    this.rootStore.commandeStore.createCommande(data)
                        .then(response => {
                            if(response?.status === "success") {
                                onUpdate(response?.datas?.id)
                            }
                        } )
                }


            }
        })
    }

    getDatas(title2, inputOptions, type, title) {
        if(type === "ADD_MANUALLY") {
            return [
                {
                    title: title,
                    input: 'number',
                    inputAttributes: {
                        min: 1
                    },
                    inputValue:1,
                },
            ]
        } else if (type === "REMOVE_MANUALLY") {
            return  [
                {
                    title: title,
                    input: 'number',
                    inputAttributes: {
                        min: 1
                    },
                    inputValue:1,
                },
                {
                    title: title2,
                    input: 'select',
                    inputOptions: inputOptions,
                },
                {
                    title: "raison ?",
                    input: 'text',
                    inputOptions: inputOptions,
                },
            ];
        } else {
           return  [
                {
                    title: title,
                    input: 'number',
                    inputAttributes: {
                        min: 1
                    },
                    inputValue: 1,
                },
                {
                    title: title2,
                    input: 'select',
                    inputOptions: inputOptions,
                },
            ]
        }
    }

    async inventaire(title = 'Corriger le stock', quantite = "5", productId, onUpdate) {
        Swal.fire({
            title:title,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonText: 'Retour',
            icon: 'warning',
            input: 'number',
            inputValue: quantite
        }).then((result) => {
            if (result.value) {

                let data = {
                    quantite: result.value,
                    produit_id: productId,
                }

                this.rootStore.stockStore.mouvStore.inventaire(data)
                    .then(response => {

                        if(response === "success") {
                            onUpdate()
                        }
                    } )
            }
        });
    }


    remove(id, onRemove, message, message2, color){
        Swal.fire({
            title: 'Etes vous sûr(e)?',
            text: message ?? "Une facture supprimée ne peut être recuperée",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: color ?? '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: message2 ?? 'Je supprime cette facture #' + id,
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.value) {
               onRemove()
            }
        });
    }


    async changeLot(id, type, line, quantite, produit, onUpdate) {
        var options = [];

        await this.rootStore.commandeStore.getLots(produit?.id)
        this.rootStore.commandeStore.lots.map((value) => {
            let fournisseur =  value.fournisseur?.nom ?? this.rootStore.authStore.nameCompany
            return options[value.pid] = value.pid + " " + fournisseur;
        });


        Swal.fire({
            title: 'Voulez vous changer de lot?',
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            confirmButtonText: `Oui par code barre`,
            denyButtonText: `Oui mais par selection`,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Entrer votre code barre',
                    input: 'text',
                    inputValue: "",
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return 'Vous devez saisir un code barre!'
                        }
                    }
                }).then((result) => {
                    if(result.value) {
                        this.rootStore.stockStore.productStore.updateLot({
                            pid: result.value,
                            type: type,
                            id: id,
                            line_id: line,
                            quantite: quantite,
                            produit_id: produit?.id
                        }).then(response => {
                            if (response?.status === "success") {
                                onUpdate(response.datas)
                            }
                        })
                    }
                })

            } else if (result.isDenied) {
                Swal.fire({
                    title: 'Choisir votre code barre',
                    input: 'select',
                    inputOptions: options,
                    showCancelButton: true
                }).then((result) => {
                    if(result.value) {
                        this.rootStore.stockStore.productStore.updateLot({
                            pid: result.value,
                            type: type,
                            id: id,
                            line_id: line,
                            quantite: quantite,
                            produit_id: produit?.id
                        }).then(response => {
                            if (response?.status === "success") {
                                onUpdate(response.datas)
                            }
                        })
                    }
                })
            }
        })
    }


}



decorate(NotificationStore, {
    setNotification: action,
    title: observable,
    message: observable,
    state: observable
});