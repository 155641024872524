import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Col, Form, FormGroup, Input, Row} from "reactstrap";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import styles from "../../Annuaire/Prestation/Prestation.module.css";
import i18n from "i18next";
import FormControlInput from "../../../../components/Akitatek/Form/FormControlInput";

const FormUpdate = inject("rootStore")(observer(class FormUpdate extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props);

        this.state = {
            typeAccount: "clientaccount"
        }
    }

    //Refresh
    onChange(e) {
        this.injected.callbackOnChange(e.target.name, e.target.value)
    };

    //Submit
    onSubmit(e) {
        e.preventDefault()
        this.injected.onCallbackSubmit("user")
    };


    render() {
        const {typeAccount, entreprise, name, firstname, siret, website, comment, email, password, preference} = this.props
        const displayPassword = !!(typeAccount === 'clientaccount' | typeAccount === 'companyaccount')
        const displayPrenom = !!(typeAccount === 'clientaccount' | typeAccount === 'clientnoaccount')
        return (
            <>
                <Form style={{width: "100%"}}
                      role="form"
                      id="myFormUpdate"
                      onSubmit={e => this.onSubmit(e)}
                >
                    <div className="pl-lg-4">
                        <Row>
                            <Col style={{textAlign: "center"}}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Type de compte</FormLabel>
                                    <RadioGroup row aria-label="position" defaultValue={typeAccount}
                                                value={typeAccount}>
                                        <div>
                                            <FormControlLabel id={"clientaccount"} value="clientaccount"
                                                              onClick={e => this.injected.callbackOnChange("typeAccount", "clientaccount")}
                                                              control={<Radio/>} label="Client"/>
                                            <FormControlLabel id={"companyaccount"} value="companyaccount"
                                                              onClick={e => this.injected.callbackOnChange("typeAccount", "companyaccount")}
                                                              control={<Radio/>} label="Entreprise"/>
                                        </div>
                                        <FormControlLabel id={"clientnoaccount"} value="clientnoaccount"
                                                          onClick={e => this.injected.callbackOnChange("typeAccount", "clientnoaccount")}
                                                          control={<Radio/>} label="Client sans Compte"/>
                                        <FormControlLabel id={"companynoaccount"} value="companynoaccount"
                                                          onClick={e => this.injected.callbackOnChange("typeAccount", "companynoaccount")}
                                                          control={<Radio/>} label="Entreprise sans Compte"/>
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                        </Row>
                        {!displayPrenom && (
                            <FormControlInput
                                placeholder="Nom de l'entreprise"
                                name="entreprise"
                                value={entreprise}
                                required={"required"}
                                onChange={(e) => {
                                    this.onChange(e)
                                }}
                            />
                        )}
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-last-name"
                                    >
                                        Nom du compte
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-last-name"
                                        type="text"
                                        name="name"
                                        value={name || ''}
                                        onChange={e => this.onChange(e)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-first-name"
                                    >
                                        Prenom du compte
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-first-name"
                                        type="text"
                                        name="firstname"
                                        value={firstname || ''}
                                        onChange={e => this.onChange(e)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        {displayPassword && (
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label
                                            className={styles.typeField}
                                            htmlFor="input-email"
                                        >
                                            Email
                                        </label>
                                        <Input
                                            autocomplete="chrome-off"
                                            className="form-control-alternative"
                                            id="input-email"
                                            type="email"
                                            name="email"
                                            value={email || ''}
                                            onChange={e => this.onChange(e)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <label
                                            className={styles.typeField}
                                            htmlFor="input-password"
                                        >
                                            {i18n.t('page.auth.password')}

                                        </label>
                                        <Input
                                            autocomplete="chrome-off"
                                            className="form-control-alternative"
                                            id="input-password"
                                            type="text"
                                            name="password"
                                            value={password || ''}
                                            onChange={e => this.onChange(e)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>)}

                        {!displayPrenom && (
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className={styles.typeField}
                                            htmlFor="input-first-name"
                                        >
                                            Siret
                                        </label>
                                        <Input
                                            autocomplete="chrome-off"
                                            className="form-control-alternative"
                                            id="input-first-name"
                                            type="text"
                                            name="siret"
                                            value={siret || ''}
                                            onChange={e => this.onChange(e)}
                                        />
                                    </FormGroup></Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className={styles.typeField}
                                            htmlFor="input-first-name"
                                        >
                                            Website
                                        </label>
                                        <Input
                                            autocomplete="chrome-off"
                                            className="form-control-alternative"
                                            id="input-first-name"
                                            type="text"
                                            name="website"
                                            value={website || ''}
                                            onChange={e => this.onChange(e)}
                                        />
                                    </FormGroup></Col>
                            </Row>
                        )}

                        <Row><Col>
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-first-name"
                                >
                                    Comment
                                </label>
                                <Input
                                    autocomplete="chrome-off"
                                    className="form-control-alternative"
                                    id="input-first-name"
                                    type="textarea"
                                    name="comment"
                                    value={comment || ''}
                                    onChange={e => this.onChange(e)}
                                />
                            </FormGroup>
                        </Col>
                        </Row>
                        {preference && (
                        <Row className={"mb-2"}>
                            <Col> <span  className={styles.typeField}>Préférences de contact :</span>  {preference?.method_contact} de {preference?.horaire_contact_debut}H à {preference?.horaire_contact_fin}H</Col>
                            <Col><span  className={styles.typeField}>Mails / Newsletters : </span> {preference?.email_newsletter ? "oui" : "non"}</Col>
                       </Row> )}
                    </div>
                    <div className="pl-lg-4" style={{textAlign: "center"}}>
                        <Row>
                            <Col>
                                <Button color="success" type="submit">
                                    {i18n.t('button.update')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </>
        );
    }
}));


export default FormUpdate;


