import {inject, observer} from "mobx-react";
import React from "react";
import {Container} from "react-bootstrap";
import styles from "./Stock.module.css";
import {Button, CardHeader} from "reactstrap";
import Card from "reactstrap/lib/Card";
import GenericStaticCard from "../../../components/Akitatek/Card/GenericStaticCard";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";
import AnnuaireCategory from "../Annuaire/Category/Category";
import ModalAkitatek from "../../../components/Akitatek/Modal/Modal";
import DetailsLot from "./DetailsLots";


const Stock = (inject("rootStore")(observer(class Stock extends React.Component {


    stock;

    get injected() {
        return this.props;
    }

    refresh(e, shop, alerte) {
        e.preventDefault()
        this.injected.rootStore.stockStore.productStore.filter.search = ""
        this.injected.rootStore.stockStore.productStore.filter.page = "1"
        this.injected.rootStore.stockStore.productStore.filter.shop = shop;
        this.injected.rootStore.stockStore.productStore.filter.category = "";
        this.injected.rootStore.stockStore.productStore.filter.alerte = alerte;
        this.injected.rootStore.stockStore.productStore.selectionCategory = null;
        this.injected.rootStore.stockStore.productStore.getDatas();
    }

    componentDidMount() {
        this.injected.rootStore.annuaireStore.categoryStore.getCategoriesByTree()
        this.injected.rootStore.stockStore.productStore.calculateStock();

    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            showEnCommande: false,
            showEnStock: false
        }
        this.injected.rootStore.savedDatasStore.setHeader("Stock", "Gestion des stocks", "motherboard.jpg", this.injected.rootStore.stockStore.productStore)
    }

    setProductName() {
        return (
            <Card className={styles.item}>
                <GenericTableAffichage api={this.injected.rootStore.stockStore.productStore}
                                       user={false}
                                       filters={this.injected.rootStore.stockStore.productStore.defaultFilter}
                                       hasScreenType={true}
                                       status={false}
                                       statusHeader={false}
                                       icon={<i className="fa fa-file-pdf-o mr-2" aria-hidden="true"/>}
                                       name={"Stocks"}
                                       type="stock"/>

            </Card>)
    }

    render() {
        return (
            <>
                <ModalAkitatek
                    title={"Liste des produits en stock"} show={this.state.showEnCommande}
                    children={<DetailsLot type={"commande"}
                                          datas={this.injected.rootStore.stockStore.productStore.commandes}/>
                    } onCallback={e => this.setState({
                    showEnCommande: false
                })}/>

                <ModalAkitatek title={"Liste des produits par lots en stock"} show={this.state.showEnStock}
                               children={<DetailsLot type={"lot"}
                                                     datas={this.injected.rootStore.stockStore.productStore.lots}
                               />
                               } onCallback={e => this.setState({
                    showEnStock: false
                })}/>
                <Container className={"mt-2"} fluid>
                    <div style={{display: "flex", justifyContent: "space-between", alignContent: "center"}}>
                        <GenericStaticCard search={false} category="stocks" key="card1"
                                           element={<h4 className="mb-0">Chercher les pièces par catégorie</h4>}>
                            <Card style={{margin: 0, cursor: "pointer"}}>
                                <CardHeader style={{justifyContent: "center", textAlign: "center"}}>
                                    <button className={"btn-lg btn-primary 2x"}
                                            onClick={e => this.refresh(e, "", "")}>Tout
                                    </button>

                                    {this.injected.rootStore.authStore.modules['boutique'] === 1 && (
                                        <button className={"btn-lg btn-secondary"}
                                                onClick={e => this.refresh(e, "1", "")}>Magasin</button>
                                    )}

                                    <button className={"btn-lg btn-danger 2x"}
                                            onClick={e => this.refresh(e, "", "1")}>Alerte
                                    </button>
                                </CardHeader>
                            </Card>
                            <div style={{display: "block"}}>
                                <AnnuaireCategory
                                    api={this.injected.rootStore.annuaireStore.categoryStore}
                                    update={true}
                                />
                            </div>
                            <Card>
                                <Container fluid>
                                    <div className="alert alert-danger" role="alert" style={{
                                        display: "flex", justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <span>  <i className="fa fa-truck"/> En commande : </span>
                                        {this.injected.rootStore.stockStore.productStore.truck} €
                                        <Button color="danger" className={'mb-1'}
                                                onClick={e => this.setState({showEnCommande: true})}
                                        >
                                            <i className="fa fa-eye"/> </Button>
                                    </div>

                                    <div className="alert alert-warning" role="alert" style={{
                                        display: "flex", justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <span>  <i
                                            className="fa fa-shopping-basket"/> En stock : </span> {this.injected.rootStore.stockStore.productStore.calculate} €
                                        <Button color="warning" className={'mb-1'}
                                                onClick={e => this.setState({
                                                    showEnStock: true
                                                })}> <i className="fa fa-eye"/> </Button>
                                    </div>
                                </Container>
                            </Card>
                        </GenericStaticCard>
                        {this.setProductName()}
                    </div>
                </Container>
            </>
        );
    }
})));

export default Stock;
