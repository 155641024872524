import getRoutes from "../../../components/Akitatek/getRoutes";

export default {
    items: [
        {
            name: 'Accueil',
            url: '/dashboard',
            icon: 'fa fa-th fa-fw'
        },
        {
            name: 'Documents',
            url: getRoutes("Factures"),
            module: 'document',
            icon: 'fa fa-file-pdf-o fa-lg',
            children: [
                {
                    name: 'Tickets',
                    url: getRoutes("Tickets"),
                    icon: 'fa fa-list',
                },
                {
                    name: 'Devis',
                    url: getRoutes("Devis"),
                    icon: 'fa fa-plus-circle',
                },
                {
                    name: 'Facture',
                    url: getRoutes("Factures"),
                    icon: 'fa fa-file-pdf-o fa-lg',
                }
            ],
        },
        {
            name: 'Clients',
            url: getRoutes("Clients"),
            icon: 'fa fa-users fa-fw',
            children: [
                {
                    name: 'Gestion Client',
                    url: getRoutes("Clients"),
                    icon: 'fa fa-user-plus',
                },
            ],
        },
        {
            name: 'Réparation',
            url: getRoutes("prise en charge"),
            icon: 'fa fa-wrench',
            children: [
                {
                    name: 'Pec Internet',
                    url: getRoutes("Prise En Charge Internet"),
                    icon: 'fa fa-internet-explorer'
                },
                {
                    name: 'Pec',
                    url: getRoutes("Prise En Charge"),
                    icon: 'fa fa-briefcase'
                }
            ],
        },
        {
            name: 'Envois',
            icon: 'fa fa-truck',
            children: [
                {
                    name: 'Envois du jour',
                    url: getRoutes("Envois du jour"),
                    icon: 'fa fa-globe'
                },
                {
                    name: 'Commande Client',
                    url: getRoutes("Commande Client"),
                    icon: 'fa fa-truck',
                },
            ],
        },
        {
            name: 'Stock',
            url: getRoutes("Mon stock"),
            icon: 'fa fa-archive',
            module: 'stock',
            children: [
                {
                    name: 'Pieces',
                    url: getRoutes("Mon stock"),
                    icon: 'fa fa-puzzle-piece'
                },
                {
                    name: 'Commandes',
                    url: getRoutes("Commande"),
                    icon: 'fa fa-shopping-basket'
                },
                {
                    name: 'Fournisseurs',
                    url: getRoutes("Fournisseurs"),
                    icon: 'fa fa-user-secret'
                },
                {
                    name: 'Casiers',
                    url: getRoutes("Casier"),
                    icon: 'fa fa-th'
                },
            ],
        },
        {
            name: 'Annuaire',
            url: getRoutes("Annuaire"),
            icon: 'fa fa-laptop',
            children: [
                {
                    name: 'Marques',
                    url: getRoutes("Annuaire"),
                    icon: 'fa fa-star',
                },
                {
                    name: 'Catégories',
                    url: getRoutes("Catégories"),
                    icon: 'fa fa-th',
                },
                {
                    name: 'Promotions',
                    url: getRoutes("Promotions"),
                    icon: 'fa fa-percent',
                }
            ],

        },
        {
            name: 'Site client',
            url: getRoutes("Blogs"),
            module: 'blog',
            icon: 'fa fa-internet-explorer',
            children: [
                {
                    name: 'Pages',
                    url: getRoutes("Gérer les pages"),
                    icon: 'fa fa-newspaper-o',
                },
                {
                    name: 'Mes Services',
                    url: getRoutes("Présenter vos services"),
                    icon: 'fa fa-newspaper-o',
                },
                {
                    name: 'Présentations',
                    url: getRoutes("Présenter vos atouts"),
                    icon: 'fa fa-newspaper-o',
                },
                {
                    name: 'Articles',
                    url: getRoutes("Blogs"),
                    module: 'blog',
                    icon: 'fa fa-newspaper-o',
                },
                {
                    name: 'Gestion Carousel',
                    url: getRoutes("Gestion Carousel"),
                    icon: 'fa fa-picture-o',
                    identifiant: 'akitatek',
                },
            ],
        },
        // {
        //     name: 'Outils',
        //     url: '/outils',
        //     module: 'document',
        //     icon: 'fa fa-gamepad'
        // },
        {
            name: 'Images',
            module: 'web_client',
            url: getRoutes("Gestion des images"),
            icon: 'fa fa-picture-o'
        },
        {
            name: 'Impression',
            module: 'print',
            url: getRoutes("Paramètres d'impression"),
            icon: 'fa fa-print'
        },
        {
            name: 'Bric à brac',
            module: 'blog',
            icon: 'fa fa-shopping-bag',
            children: [
                {
                    name: 'To Do List',
                    url: getRoutes("To Do List"),
                    icon: 'fa fa-list-alt',
                },
                {
                    name: 'Fiches',
                    url: getRoutes("Fiches"),
                    icon: 'fa fa-wikipedia-w',
                },
         /*       {
                    name: 'Mode d Emploi',
                    url: getRoutes("Présenter vos atouts"),
                    icon: 'fa fa-newspaper-o',
                }*/
            ],
        },
        {
            name: 'Stats',
            module: 'document',
            url: getRoutes("Statistiques"),
            icon: 'fa fa-line-chart'
        },
        {
            name: 'Details CA',
            module: 'document',
            url: getRoutes("Details CA"),
            icon: 'fa fa-line-chart'
        },
    ],
};
