import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {TabPane} from "reactstrap";
import ListGroup from "reactstrap/lib/ListGroup";
import ListGroupItem from "reactstrap/lib/ListGroupItem";
import ActionSpooler from "../../../admin/Printer/ActionSpooler";


const ThreeAsidePage = inject("rootStore")(observer(class ThreeAsidePage extends Component {

        get injected() {
            return this.props
        }

        constructor(props) {
            super(props);
            this.injected.rootStore.spoolerStore.getDatas()
        }


        //check if the values are up to date
        async componentDidMount() {
            this.injected.rootStore.getEcho()
                .channel(`spooler`)
                .listen('SpoolerEvent', ev => {
                    console.log(ev)
                    this.injected.rootStore.spoolerStore.getDatas()
                })

            this.injected.rootStore.getEcho()
                .channel(`spooler`)
                .listen('SpoolerEndEvent', ev => {
                    console.log(ev)
                    this.injected.rootStore.spoolerStore.getDatas()
                })
        }

        render() {
            return (


                <TabPane tabId="3" className="p-3">
                    <h6>Spooler</h6>
                    <ActionSpooler/>
                    <ListGroup>
                        {this.injected.rootStore.spoolerStore.datas.map((element) => (
                            <ListGroupItem> # {element.id} - {element.filename} - {element.printer.name} <ActionSpooler
                                datas={element}/></ListGroupItem>
                        ))}
                    </ListGroup>

                    {/*<div className="aside-options">*/}
                    {/*    <div className="clearfix mt-4">*/}
                    {/*        <small><b>Option 1</b></small>*/}
                    {/*        <AppSwitch className={'float-right'} variant={'pill'} label color={'success'} defaultChecked size={'sm'}/>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <small className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod*/}
                    {/*            tempor incididunt ut labore et dolore magna aliqua.*/}
                    {/*        </small>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="aside-options">*/}
                    {/*    <div className="clearfix mt-3">*/}
                    {/*        <small><b>Option 2</b></small>*/}
                    {/*        <AppSwitch className={'float-right'} variant={'pill'} label color={'success'} size={'sm'}/>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <small className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod*/}
                    {/*            tempor incididunt ut labore et dolore magna aliqua.*/}
                    {/*        </small>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="aside-options">*/}
                    {/*    <div className="clearfix mt-3">*/}
                    {/*        <small><b>Option 3</b></small>*/}
                    {/*        <AppSwitch className={'float-right'} variant={'pill'} label color={'success'} defaultChecked size={'sm'} disabled/>*/}
                    {/*        <div>*/}
                    {/*            <small className="text-muted">Option disabled.</small>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="aside-options">*/}
                    {/*    <div className="clearfix mt-3">*/}
                    {/*        <small><b>Option 4</b></small>*/}
                    {/*        <AppSwitch className={'float-right'} variant={'pill'} label color={'success'} defaultChecked size={'sm'} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<hr />*/}
                    {/*<h6>System Utilization</h6>*/}

                    {/*<div className="text-uppercase mb-1 mt-4">*/}
                    {/*    <small><b>CPU Usage</b></small>*/}
                    {/*</div>*/}
                    {/*<Progress className="progress-xs" color="info" value="25" />*/}
                    {/*<small className="text-muted">348 Processes. 1/4 Cores.</small>*/}

                    {/*<div className="text-uppercase mb-1 mt-2">*/}
                    {/*    <small><b>Memory Usage</b></small>*/}
                    {/*</div>*/}
                    {/*<Progress className="progress-xs" color="warning" value="70" />*/}
                    {/*<small className="text-muted">11444GB/16384MB</small>*/}

                    {/*<div className="text-uppercase mb-1 mt-2">*/}
                    {/*    <small><b>SSD 1 Usage</b></small>*/}
                    {/*</div>*/}
                    {/*<Progress className="progress-xs" color="danger" value="95" />*/}
                    {/*<small className="text-muted">243GB/256GB</small>*/}

                    {/*<div className="text-uppercase mb-1 mt-2">*/}
                    {/*    <small><b>SSD 2 Usage</b></small>*/}
                    {/*</div>*/}
                    {/*<Progress className="progress-xs" color="success" value="10" />*/}
                    {/*<small className="text-muted">25GB/256GB</small>*/}
                </TabPane>
            )
        }
    }
));

export default ThreeAsidePage;
