import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";
import encodeDataToURL from "../components/Akitatek/encodeDatatoURL";

export default class SpoolerAPI {
    async getDatas(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/spoolers/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async refresh(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/myspooler/refresh?id=` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async deleteAll(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/spoolers/` + id ,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject("Login failed")
            }
        })
    }

    async delete(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/spooler/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject("Login failed")
            }
        })
    }
}