import React from "react";
import {inject, observer} from "mobx-react";
import PickProduct from "./PickProduct";
import SearchMachine from "./SearchMachine";
import withRouter from "react-router-dom/es/withRouter";
import Swal from "sweetalert2";
import TableMachine from "../../Customers/UpdateCustomer/TableMachine";


const titles = [
    "Marque",
    "Produit",
    "Numéro de série",
    "Code",
    "Defauts",
    "Accessoires",
    "Prestations",
    "Casier",
    "Actions"
]

const SearchOrAdd = inject("rootStore")(observer(class SearchOrAdd extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            marques: [],
            products: [],
            prestations: [],
            marque: "",
            product: "",
            search:"",
            selectPrestationApp: false,
            selectPrestation: false,
            selectProduct: false,
            selectMarque: true,
            user_appareil_id: "",
            user_appareil_pec_id: "",
            prestationsPec: []
        }
    }

    //Callback
    callbackChange = (name, value) => {
        this.setState({[name]: value});
    }

    //Callback
    callbackOnChange = (name, value) => {
        this.injected.callbackOnChange(name, value)
    }


    async componentDidMount() {
        let authResponse = await this.injected.rootStore.pecStepperStore.getMarques();
        this.setState({
            marques: authResponse
        })
    }

    async onLoad(type = "none") {
        if (this.state.marques?.length === 0) {
            let authResponse = await this.injected.rootStore.pecStepperStore.getMarques("");
            this.setState({
                marques: authResponse
            })
        }

        this.injected.callbackOnChange("typeAddMachine", type)
        this.injected.callbackOnChange("message", "")

    }

    search() {
        this.injected.callbackOnChange("typeAddMachine", "search")
    }

    async clean(search = "") {
        let authResponse = await this.injected.rootStore.pecStepperStore.getMarques(search);

        this.setState({
            marques: authResponse,
            products: [],
            prestations: [],
            marque: "",
            product: "",
            selectPrestationApp: false,
            selectPrestation: false,
            selectProduct: false,
            selectMarque: true,
            user_appareil_id: "",
            user_appareil_pec_id: ""
        })
    }

    //Callback
    reload = async (type, childData, search = "") => {

        if (type === "clean") {
            await this.clean()
        }

        if (type === "mymarque") {
            await this.clean(search)
        }


        if (type === "marque") {
            let authResponse = await this.injected.rootStore.pecStepperStore.getProducts(childData?.id, search);
            this.setState({
                products: authResponse,
                marque: childData,
                selectProduct: true,
                selectMarque: false,
                selectPrestation: false,
            })
        }


        if (type === "productSearchExist") {
            this.injected.rootStore.pecStepperStore.appareilId = childData?.appareil?.id;
            let authResponse = await this.injected.rootStore.pecStepperStore.getPrestation(search);
            this.setState({
                prestations: authResponse,
                product: childData?.appareil,
                selectProduct: false,
                selectMarque: false,
                user_appareil_pec_id: childData?.pivot?.id,
                selectPrestation: true,
            })
        }

        if (type === "productSearch") {
            this.injected.rootStore.pecStepperStore.appareilId = childData?.appareil?.id;
            let authResponse = await this.injected.rootStore.pecStepperStore.getPrestation(search);
            this.setState({
                prestations: authResponse,
                product: childData?.appareil,
                selectProduct: false,
                user_appareil_id: childData?.id,
                selectMarque: false,
                selectPrestation: true,
            })
        }

        if (type === "prestationsApp") {
            this.setState({
                prestationsPec: childData,
                selectPrestationApp: true,
            })
        }

        if (type === "product") {
            this.injected.rootStore.pecStepperStore.appareilId = childData?.id;
            let authResponse = await this.injected.rootStore.pecStepperStore.getPrestation(search);
            this.setState({
                prestations: authResponse,
                product: childData,
                selectProduct: false,
                selectMarque: false,
                selectPrestation: true,
            })
        }

        if (type === "valid") {
            let data = {
                presta_id: childData?.id,
                prise_en_charge_id: this.props.id,
                product_id: this.state.product?.id,
                user_appareil_pec_id: this.state.user_appareil_pec_id,
                user_appareil_id: this.state.user_appareil_id

            }

            this.injected.rootStore.pecStepperStore.saveUserApp(data).then(response => {
                if (response?.status === "success") {
                    Swal.fire({
                        title: "Bravo et maintenant?",
                        icon: 'warning',
                        denyButtonText: "Ajouter une nouvelle prestation à cet appareil",
                        confirmButtonText: "Valider",
                        showCancelButton: true,
                        showDenyButton: true,
                    }).then((result2) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result2.isConfirmed) {
                            this.clean()
                            this.injected.callbackOnChange("typeAddMachine", "none")
                            this.injected.callbackOnChange("appareils", response.datas)
                            this.injected.callbackOnChange("userApp", response.userApp)

                        } else {
                            this.setState({
                                user_appareil_pec_id: response.user_appareil_pec_id
                            })
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Sauvegarde impossible',
                    })
                }
            })
        }

        if (type === "remove") {
            let data = {
                presta_id: childData?.id,
                prise_en_charge_id: this.props.id,
                product_id: this.state.product?.id,
                user_appareil_pec_id: childData?.pivot?.user_appareil_pec_id,
                user_appareil_id: this.state.user_appareil_id

            }

            this.injected.rootStore.pecStepperStore.deleteUserApp(data).then(response => {
                if (response?.status === "success") {
                    Swal.fire({
                        title: "Bravo et maintenant?",
                        icon: 'warning',
                        denyButtonText: "Ajouter une nouvelle prestation à cet appareil",
                        confirmButtonText: "Valider",
                        showCancelButton: true,
                        showDenyButton: true,
                    }).then((result2) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result2.isConfirmed) {
                            this.clean()
                            this.injected.callbackOnChange("typeAddMachine", "none")
                            this.injected.callbackOnChange("appareils", response.datas)
                            this.injected.callbackOnChange("userApp", response.userApp)

                        } else {
                            this.setState({
                                user_appareil_pec_id: response.user_appareil_pec_id
                            })
                        }
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Sauvegarde impossible',
                    })
                }
            })
        }
    }


    render() {
        const {user, message, typeAddMachine, appareils, id, userApp} = this.props

        return (
            <>
                <div>
                    {user?.id ? (
                        <div>
                            {message && (
                                <div className="alert alert-success" role="alert">
                                    {message}
                                </div>)}

                            {typeAddMachine === "none" && (
                                <div style={{"display": "flex", "justify-content": "center", "align-items": "center"}}>
                                    <button className={"btn btn-primary btn-lg mr-2"}
                                            onClick={e => {
                                                this.onLoad("add")
                                            }}>Ajouter une machine
                                    </button>

                                    {userApp?.length > 0 && (
                                        <span>
                                    ou
                                    <button className={"btn btn-secondary btn-lg ml-2"}
                                            onClick={e => this.search()}>Recherche une
                                        machine appartenant à {user?.fullname}   </button>
                                </span>)}
                                </div>
                            )}

                            {typeAddMachine === "add" && (
                                <PickProduct product={this.state.product}
                                             marque={this.state.marque}
                                             marques={this.state.marques}
                                             prestations={this.state.prestations}
                                             products={this.state.products}
                                             selectProduct={this.state.selectProduct}
                                             selectPrestation={this.state.selectPrestation}
                                             selectMarque={this.state.selectMarque}
                                             reload={this.reload}
                                             callbackChange={this.callbackChange}
                                             callbackOnChange={this.callbackOnChange}/>
                            )}
                            {typeAddMachine === "search" && (
                                <SearchMachine user={user}
                                               machineClient={this.state.product}
                                               machinesClient={userApp}
                                               selectPrestationApp={this.state.selectPrestationApp}
                                               selectPrestation={this.state.selectPrestation}
                                               prestations={this.state.prestations}
                                               reload={this.reload}
                                               prestationsPec={this.state.prestationsPec}
                                               callbackChange={this.callbackChange}
                                               callbackOnChange={this.callbackOnChange}/>
                            )}

                            <div className={"mt-2"}>
                                <TableMachine
                                    type={"pec"}
                                    reload={this.reload}
                                    callbackChange={this.callbackOnChange}
                                    callbackOnChangeList={this.injected.callbackOnChangeList}
                                    onCallbackAction={this.injected.onCallbackAction}
                                    appareils={appareils}
                                    titles={titles}
                                />
                            </div>

                        </div>
                    ) : (<PickProduct
                        id={id}
                        callback={this.callbackPresta}/>)
                    }
                </div>
            </>
        );
    }

}));


export default withRouter(SearchOrAdd);
