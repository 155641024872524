import {observer, Provider} from "mobx-react";
import * as React from "react";
import RootStore from "../store/rootStore";
import { HashRouter, Route, Switch} from "react-router-dom";
import PrivateRoute from "../components/Akitatek/Router/PrivateRoute";
import { Ring } from 'react-awesome-spinners'
import styles from './App.module.css';
import "../util/i18n.js";
import './App.scss';
import Login from "./auth/Login";
import PECBoard from "./board/PECBoard";
import 'animate.css'
import Caisse from "./caisse/Caisse";
import ScreenClient from "./screen/Screen";



const App =  observer(class App extends React.Component {
    rootStore;

    constructor(props) {
        super(props);
        this.rootStore = new RootStore();
    }


    render() {
        var name = this.rootStore.authStore.nameCompany ?? ""
        document.title = "Dashboard " + name
        return (
            <div>
                {
                    this.rootStore.authStore.checkLoading ?
                        this.renderLoading() :
                        this.renderRouting()
                }
            </div>
        )
    }

    renderLoading() {
        return (
            <div className={ styles.loading }>
                <Ring />
            </div>
        )
    }


    renderRouting() {
        return  <Provider rootStore={this.rootStore}>
            <HashRouter>
                <Switch>
                    <Route  path="/caisse" name="Caisse Page" component={ Caisse } />
                    <Route  path="/screen" name="Client Screen Page" component={ ScreenClient } />
                    <Route  path="/dash" name="Login Page" component={ PECBoard } />
                    <Route exact path="/login" name="Login Page" component={ Login } />
                    <PrivateRoute path="/" />
                </Switch>
            </HashRouter>
        </Provider>
    }
});

export default App;

