import React, {Component, Fragment, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar
} from '@coreui/react';
import routes from '../../../../routes';
import {inject, observer} from "mobx-react";
import Header from "../../../../components/Akitatek/Headers/Headers";
import HeaderCat from "../../../../components/Akitatek/Headers/HeaderCat";
import DefaultHeader from "./DefaultHeader";
import DefaultSidebar from "./DefaultSidebar";
import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";

const DefaultLayout = inject("rootStore")(observer(class DefaultLayout extends Component {

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  notifications;

    get injected() {
      return this.props
    }

      constructor(props) {
        super(props);
      }

      async signOut(e) {
        e.preventDefault();
        await this.injected.rootStore.authStore.logout();
        this.injected.history.push("/auth/login")
  }

      setHeader() {
      if(this.injected.rootStore.savedDatasStore.isHeader && this.injected.rootStore.savedDatasStore.title !== "None") {
        return( <div>
                <Header
            image={this.injected.rootStore.savedDatasStore.image}
            title={this.injected.rootStore.savedDatasStore.title}
            subtitle={this.injected.rootStore.savedDatasStore.subtitle}
        />
        <HeaderCat
            api={this.injected.rootStore.savedDatasStore.myApi}
            key={this.injected.rootStore.savedDatasStore.title} category={this.injected.rootStore.savedDatasStore.title}
                   subCategory={this.injected.rootStore.savedDatasStore.subCategory} user={this.injected.rootStore.savedDatasStore.userHeader}

        /></div>
      )
      }
  }


        render() {
        return (
            <Fragment>
                <div className="app">
                  <AppHeader fixed>
                      <DefaultHeader
                          onLogout={e=>this.signOut(e)}/>
                  </AppHeader>
                  <div className="app-body">

                      <AppSidebar fixed display="lg">
                        <Suspense>
                         <DefaultSidebar></DefaultSidebar>
                       </Suspense>
                     </AppSidebar>

                      <main className="main" style={{backgroundColor: this.injected.rootStore.authStore.color}}>
                        {this.setHeader()}
                      <Suspense fallback={this.loading()}>
                        <Switch>
                          {routes.map((route, idx) => {
                          return route.component && !route.module && !route.identifiant ? (
                          <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={props => (
                              <route.component {...props} />
                          )} />)
                              : null;
                        })}

                         {routes.map((route, idx) => {
                                return route.component && (route.module && this.injected.rootStore.authStore.modules[route.module.toString()]) &&  (!route.identifiant ||  route.identifiant && this.injected.rootStore.authStore.identifiant === route.identifiant) ? (
                                        <Route
                                            key={idx}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            render={props => (
                                                <route.component {...props} />
                                            )} />) : null;
                            })}

                          <Redirect from="/" to="/dashboard" />
                        </Switch>
                      </Suspense>

                  </main>
                  <AppAside fixed>
                    <Suspense fallback={this.loading()}>
                      <DefaultAside />
                    </Suspense>
                  </AppAside>
                </div>
                <AppFooter>
                  <Suspense fallback={this.loading()}>
                    <DefaultFooter />
                  </Suspense>
                </AppFooter>
              </div>
            </Fragment>
            );
          }
        }
      ));

export default observer(DefaultLayout);
