import {Link} from "react-router-dom";
import getRoutes from "../getRoutes";
import React from "react";

function PrestationRow({data}) {

    return (<td className="text-center">
                    <span className="mb-0  text-sm">
            {data.map(presta => {
                    return (<p key={data.id}
                               className={"mt-2"}><Link to={{
                        pathname: getRoutes("Modifier une prestation", presta.id),
                        aboutProps: {
                            stock: presta.id
                        }
                    }}>
                        {presta.title} {presta.pivot?.total_actual} €
                    </Link></p>)
                })}
           </span>
    </td>)
}

export default PrestationRow