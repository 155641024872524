import React from "react";
import {inject, observer} from "mobx-react";
import styles from "./Caisse.module.css"
import Horloge from "../board/Horloge";
import {Button} from "reactstrap";
import {Animated} from "react-animated-css";


const Caisse = (inject("rootStore")(observer(class Caisse extends React.Component {

    constructor(props, context) {
        super(props, context);


        this.state = {
            logo: this.injected.rootStore.authStore.logo ?? ""
        }
    }


    async tryToConnect() {
        let url = window.location.hash.replace("#/caisse/", "")
        let authResponse = await this.injected.rootStore.authStore.loginUrl(url);
        if (authResponse?.status === "success") {
            this.setState({
                logo: authResponse.logo
            })
        }
    }


    componentDidMount() {
        this.tryToConnect()
        this.injected.rootStore.getEcho().connector.socket.on('connect', () => {
            this.injected.rootStore.authStore.isLoggedEcho = true;
        });

        this.injected.rootStore.getEcho().connector.socket.on('disconnect', () => {
            this.injected.rootStore.authStore.isLoggedEcho = false;
        });
    }


    get injected() {
        return this.props;
    }


    render() {
        return (
            <>
                <div className={"boardPec"}>
                    <Horloge></Horloge>
                    <div className={"container-fluid pt-5 min-vh-100 "}>

                        {this.injected.rootStore.authStore.isLoggedDash ? (
                            <div>
                                <h1>CAISSE
                                </h1>

                            <div className={styles.lineCaisseMode}>
                                <Button className={styles.bigButton} color="primary" onClick={e => this.injected.rootStore.modeStore.updateMode("client")} size="lg">Client Mode</Button>{''}
                                <Button className={styles.bigButton}  color="secondary" onClick={e => this.injected.rootStore.modeStore.updateMode("gamepad")} size="lg">GamePad Mode</Button>{''}
                                <Button className={styles.bigButton} color="success" onClick={e => this.injected.rootStore.modeStore.updateMode("publicité")} size="lg">Publicité Mode</Button>{''}
                            </div>
                            </div>


                            )
                            :
                            <div></div>}
                    </div>
                </div>
            </>
        );
    }
})));

export default Caisse;
