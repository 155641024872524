import {decorate, observable} from "mobx";
import Api from "../../util/api";

export default class ToDoStore {

    datas = observable([]);

    filter = {
    }
    defaultFilter = {
    }

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.resource.get("todos", this.filter);

            if (this.datas.length > 0) {
                this.datas.replace(authResponse.datas)
            } else {
                this.datas = authResponse.datas;
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }


    async find(id) {
        return await this.api.resource.getAction("todo", id);
    }

    async create(datas) {
        this.rootStore.savedDatasStore.setLoading(true)

        let authResponse = await this.api.resource.save("todos", datas);

        if (authResponse?.status === "success") {
            this.rootStore.notificationStore.setNotification("Todo # " + authResponse?.datas.id + " ajoutée", "success");
            this.getDatas()
        }
        this.rootStore.savedDatasStore.setLoading(false)

        return authResponse
    }

    async update(datas) {
        this.rootStore.savedDatasStore.setLoading(true)

        let authResponse = await this.api.resource.update("todos", datas);

        if (authResponse?.status === "success") {
            this.rootStore.notificationStore.setNotification("Todo # " + datas.id + " modifiée", "success");
            this.getDatas()
        }
        this.rootStore.savedDatasStore.setLoading(false)

        return authResponse
    }


    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)

            let authResponse = await this.api.resource.delete("todos", id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Todo # " + id + " supprimée", "success");
                this.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(ToDoStore, {
    datas: observable
});

