import React from "react";
import {Animated} from "react-animated-css";
function ScreenMode() {
    return  <Animated animationIn="fadeInRight" animationOut="fadeInRight" animationInDuration={1000}>
        <div className={"container-fluid pt-5 min-vh-100"} style={{ backgroundColor: "#258f47"}}>
            <div style={{ color: "white"}}>
                <Animated animationIn="rubberBand" animationInDelay={1000} animationInDuration={2000}>
                <h1> Bienvenue chez AkitaTek </h1>
                </Animated>

            <Animated animationIn="rotateInUpLeft" animationInDelay={2000} animationInDuration={2000}>
                <h1> Nous réparons vos consoles de jeux et ordinateurs</h1>
            </Animated>
            </div>
        </div></Animated>


}
export default ScreenMode