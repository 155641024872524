import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";

export default class StockageCasierAPI {

    async getStockageCasier() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/stockagesCasier`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async getCasiers() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/casiers`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async findCasier(id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/find/casier/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    async findStockage(stockageId) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/stockage/${stockageId}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    //STOCKAGE PART

    async createStockage(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/casier/stockages`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    // CATEGORY PART

    async createCategory(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/casier/categories`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async updateStockage(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/casier/stockages`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async updateCategory(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/casier/categories`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async deleteStockage(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/casier/stockages/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }


    async deleteCategory(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/casier/categories/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async createPdf(mediaId) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/casier/pdf/${mediaId}`,
                    headers: {
                        'content-type': '*/*'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async createPdfStockage(mediaId) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/stockages/pdf/${mediaId}`,
                    headers: {
                        'content-type': '*/*'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async getPdf(mediaId) {
        const response = await axios({
            method: 'get',
            url: `${configuration.API}/api/casier/visualisePdf/${mediaId}`,
            headers: {
                'content-type': '*/*'
            },
            responseType: 'blob'
        });

        const file = response.data;
        file.name =  response.data.filename;
        file.lastModified = new Date();

        return file;
    }
}