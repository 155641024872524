import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Col} from "reactstrap";
import PictureHidden from "../PictureHidden";
import ModalAkitatek from "../Modal/Modal";
import PickAsset from "../../../views/admin/Asset/PickAsset";
import WidgetSearch from "./Search/WidgetSearch";

const ChoisirImageInput = inject("rootStore")(observer(class ChoisirImageInput extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            id: this.injected.id,
            checkbox: this.injected.online ?? 0,
            update: this.injected.update ?? false,
            title: this.injected.title ?? true,
            pickAsset: false
        }
        this.pickAsset = this.pickAsset.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onChange = this.onChange.bind(this)

    }

    async componentDidUpdate(prevProps) {
        if (
            this.injected.online !== prevProps.online || this.injected.update !== prevProps.update || this.injected.title !== prevProps.title ||
            this.injected.id !== prevProps.id
        ) {

            this.setState({
                id: this.injected.id,
                checkbox: this.injected.online ?? 0,
                update: this.injected.update ?? false,
                title: this.injected.title ?? true
            })
        }
    }


    onDelete() {
        this.injected.onPickAssetFilename("delete", this.state.id)
    }

    pickAsset(e, value) {
        if (e) {
            e.preventDefault()
        }

        this.setState({
            pickAsset: e ? e.target.value : value
        })
    }


    onCloseAsset = () => {
        this.pickAsset(null, false)
    }

    onPickAsset = () => {
        if (this.injected.rootStore.imageStore.pickAsset) {
            this.pickAsset(null, false)
            this.injected.onPickAssetFilename("add")
        } else {
            this.pickAsset(null, false)
        }
    }

    onChange() {
        let value = this.state.checkbox === 1 ? 0 : 1;
        this.injected.onPickAssetFilename("onchange", this.state.id, value)
    }

    render() {
        const {assetName, id} = this.props
        return (
            <>
                <Col md={6} className={'m-auto'}
                     style={{
                         display: "flex",
                         justifyContent: "space-around",
                         alignItems: "center",
                         flexDirection: "column"
                     }}>
                    {this.state.title && (
                        <Button className={"btn btn-primary"}
                                value={true}
                                onClick={this.pickAsset}
                        > Choisir une image dans la gallerie</Button>)}
                    <PictureHidden assetName={assetName ?? ""}/>

                    {this.state.update && (
                        <label>
                            <input type="checkbox"
                                   value={1}
                                   checked={this.state.checkbox}
                                   onChange={this.onChange}
                            />
                            <span className="badge badge-pill badge-success">PUBLIER</span>
                        </label>
                    )}

                    {assetName && (

                        <Button className={"btn btn-danger"}
                                value={true}
                                onClick={this.onDelete}
                        > Supprimer l'image</Button>
                    )}
                </Col>


                <ModalAkitatek show={this.state.pickAsset}
                               title={<WidgetSearch icon={"fa fa-search bg-primary p-1 font-10xl mr-3 float-left"}
                                                    col={"col-12"} category={"images"}/>}
                               children={<PickAsset onCallback={this.onPickAsset}/>}
                               onCallback={this.onCloseAsset}/>
            </>
        );
    }

}));

export default ChoisirImageInput;
