import React from "react";
import {inject, observer} from "mobx-react";
import {Button, UncontrolledTooltip} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import getRoutes from "../../../components/Akitatek/getRoutes";


const ActionsFournisseurs = inject("rootStore")(observer(class ActionsFournisseurs extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }

    toggleDanger(event, data) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning("le fournisseur #" + data?.nom, event => this.onDelete(data?.id))
    }


    async onDelete(deleteId) {
        await this.injected.rootStore.fournisseurStore.delete(deleteId);
    }

    render() {
        const {data} = this.props;
        return (
            <>
                <td className="th-actions text-center">
                    <Link to={{
                        pathname: getRoutes("Modifier un fournisseur", data?.id),
                        aboutProps: {
                            data: data
                        }
                    }}>
                        <Button color="danger" type="button" className="mr-1" onClick={e => this.toggleDanger(e, data)}
                                id={"UncontrolledTooltipExampleSelection" + data?.id}>
                            <i className="fa fa-minus" aria-hidden="true"/>
                            <UncontrolledTooltip placement="top"
                                                 target={"UncontrolledTooltipExampleSelection" + data?.id}>
                                Supprimer
                            </UncontrolledTooltip>
                        </Button>
                    </Link>
                </td>
            </>
        );
    }
}));

export default withRouter(ActionsFournisseurs);
