import React from "react";
import {Button, CardHeader, Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import Card from "react-bootstrap/Card";
import configuration from "../../../configuration/configuration";
import Pagination from "../../../components/Akitatek/Pagination";
import AddAsset from "./AddAsset";
import ChoiceSearch from "../../../components/Akitatek/Search/ChoiceSearch";
import styles from "../Customers/Customers.module.css";


const PickAsset = (inject("rootStore")(observer(class PickAsset extends React.Component {

    UNSAFE_componentWillMount() {
        this.injected.rootStore.imageStore.getDatas();
    }

    createWidgetSearch( icon, col = "col-6", category = "user") {
        return(<div key={category} className={col}>
            <div className="card" style={{ marginBottom: "0.2em", marginTop: "-1em"}}>
                <div className="card-block p-1 clearfix">
                    <i className={icon}></i>
                    <div className="text-primary text-uppercase font-xs">Rechercher</div>
                    <div> <ChoiceSearch category={category} onMenu={true}/></div>
                </div>
            </div>
        </div>)
    }

    get injected() {
        return this.props;
    }

    PickMe(asset) {
        this.injected.rootStore.imageStore.pickAsset = asset
        this.injected.onCallback()
    }


    parentCallback = (childData) => {
        this.injected.rootStore.imageStore.filter.page = childData
        this.injected.rootStore.imageStore.getDatas();
    };


    render() {
        return (
            <>
                <Container fluid>
                    <AddAsset card={true}></AddAsset>


                    <CardHeader className="bg-transparent"  style={{display: "flex", color: "#20a8d8"}}>
                        <i  className={[styles.hideButton]} />
                        <h4 className="mb-0">
                            <i className="fa fa-picture-o mr-2" aria-hidden="true"/>
                            {this.injected.rootStore.imageStore.filter.search  !== "" ? (
                                "Recherche " + this.injected.rootStore.imageStore.filter.search
                            ) : (
                                "Mes Images"
                            )

                            }
                        </h4>
                    </CardHeader>

                    <div className="mt-4" style={{display: "flex", flexWrap: "wrap"}}>
                        {this.injected.rootStore.imageStore.datas
                            .map((data, id) => {
                                return (
                                    <Card className={"mr-4 text-align-center"} key={id} style={{flex: "0 0 30%"}}>
                                        <Button onClick={e => this.PickMe(data)}
                                                className={"bg-success"}> Choisir </Button>
                                        <Card.Img style={{height: '10rem', objectFit: "contain"}}
                                                  variant="top" src={configuration.PhotoBase + data.filename}/>
                                        <Card.Subtitle className={"m-auto"}> {data.nom}</Card.Subtitle>

                                        {this.injected.rootStore.imageStore.filter.search  !== "" && (
                                            <Card.Subtitle className={"m-2"}>
                                                {data.tags.filter(tag => tag.nom.includes(this.injected.rootStore.imageStore.filter.search )).map(tag => {
                                                    return <span>#{tag.nom}</span>
                                                })}
                                            </Card.Subtitle>
                                        )}
                                    </Card>

                                )
                            })
                        }
                    </div>
                    <Pagination
                        currentPage={this.injected.rootStore.imageStore.filter.page}
                        lastPage={this.injected.rootStore.imageStore.filter.last_page}
                        parentCallback={this.parentCallback}/>
                </Container>
            </>
        );
    }
})));

export default PickAsset;
