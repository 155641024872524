import {decorate, observable} from "mobx";
import Api from "../util/api";

export default class SongStore {

    datas = observable([]);

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }


    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.songs.getDatas();

            if (this.datas.length > 0) {
                this.datas.replace(authResponse.datas)
            } else {
                this.datas = authResponse.datas;
            }
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async update(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.songs.save(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Musique modifiée #" + authResponse.datas.id + " modifiée", "success");

                this.rootStore.prisenchargeInternetStore.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }
}

decorate(SongStore, {
    datas: observable,

});

